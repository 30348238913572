import React, { useEffect } from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap'
import * as Yup from 'yup'
import { useParams, useHistory } from 'react-router-dom'
import { useLazyQuery, useMutation, gql } from '@apollo/client'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { Folder } from 'react-bootstrap-icons'

const PublicUserNotificationSettings = () => {
  const { userId } = useParams()
  const history = useHistory()

  const [
    getUser,
    { loading: queryLoading, error: queryError, data: queryData },
  ] = useLazyQuery(
    gql`
      query NotificationSettings($userId: ID!) {
        userNotificationSettings(id: $userId) {
          smsNotificationsEnabled
          emailNotificationsEnabled
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        userId: userId,
      },
    }
  )

  const [updateUser] = useMutation(
    gql`
      mutation UpdateNotificaitonSettings(
        $updateUserNotificationSettingsInput: UpdateUserNotificationSettingsInput!
      ) {
        updateUserNotificationSettings(
          input: $updateUserNotificationSettingsInput
        ) {
          updated
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Notification Settings Saved')
      },
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      emailNotificationsEnabled: '',
      smsNotificationsEnabled: '',
    },
    validationSchema: Yup.object().shape({
      emailNotificationsEnabled: Yup.bool().required(
        'Email notifications setting is required'
      ),
      smsNotificationsEnabled: Yup.bool().required(
        'SMS notifications setting is required'
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      updateUser({
        variables: {
          updateUserNotificationSettingsInput: {
            userNotificationSettings: {
              id: userId,
              emailNotificationsEnabled: values.emailNotificationsEnabled,
              smsNotificationsEnabled: values.smsNotificationsEnabled,
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (userId) {
      getUser()
    }
  }, [userId])

  useEffect(() => {
    if (queryData?.userNotificationSettings) {
      if (queryData.userNotificationSettings) {
        formik.setValues({
          emailNotificationsEnabled:
            queryData.userNotificationSettings.emailNotificationsEnabled,
          smsNotificationsEnabled:
            queryData.userNotificationSettings.smsNotificationsEnabled,
        })
      } else {
        history.push('/not-found')
      }
    }
  }, [queryData])

  if (queryLoading) return <></>
  if (queryError) return <p>Error loading notification settings</p>
  return (
    <>
      <div className="disableNotifications">
        <Row>
          <Col xs={12}>
            <h1 className="mb-4 mt-5">Notification Settings</h1>
          </Col>
        </Row>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="mt-5">
            <Form.Group as={Col} sm={12} md={2}>
              <Form.Check
                inline
                label="Receive Email"
                checked={formik.values.emailNotificationsEnabled}
                onChange={(e) =>
                  formik.setFieldValue(
                    `emailNotificationsEnabled`,
                    e.target.checked
                  )
                }
              />
              <div className="mt-5">
                <Form.Check
                  inline
                  label="Receive SMS"
                  checked={formik.values.smsNotificationsEnabled}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `smsNotificationsEnabled`,
                      e.target.checked
                    )
                  }
                />
              </div>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col md={2}>
              <Button
                type="submit"
                variant="outline-primary"
                size="small"
                className="mt-5"
                block
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default PublicUserNotificationSettings
