import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import {
  CaretRight,
  CaretDown,
  CheckLg,
  Lock,
  Unlock,
  SlashCircle,
  ArrowLeft,
  Trash,
  Folder,
} from 'react-bootstrap-icons'
import AuditLog from '../../../audit_log/AuditLog'
import APIKeyAuditLog from '../../AuditLog'
import APICalls from './APICalls'

const APIKeyForm = (props) => {
  const { apiKey, setView, setEditAPIKey } = props

  const [apiKeyInputType, setApiKeyInputType] = useState('password')
  const [submitting, setSubmitting] = useState(false)
  const [apiKey_, setApiKey] = useState()
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayRecordChanges, setDisplayRecordChanges] = useState(false)
  const [displayApiCalls, setDisplayApiCalls] = useState(false)

  const [deleteApiKey] = useMutation(
    gql`
      mutation DeleteApiKey($deleteApiKeyInput: DeleteAPIKeyInput!) {
        deleteApiKey(input: $deleteApiKeyInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setView('list')
        toast.success(`API Key Deleted`)
      },
      refetchQueries: ['APIKeys'],
    }
  )

  const [createApiKey] = useMutation(
    gql`
      mutation CreateAPIKey($input: CreateAPIKeyInput!) {
        createApiKey(input: $input) {
          apiKey {
            id
            apiKey
            enabled
            contentType {
              model
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        formik.setFieldValue('apiKey', data.createApiKey.apiKey.apiKey)
        setApiKey(data.createApiKey.apiKey)
        toast.success(`API Key Saved`)
      },
      refetchQueries: ['APIKeys'],
    }
  )

  const [updateApiKey] = useMutation(
    gql`
      mutation UpdateApiKey($input: UpdateAPIKeyInput!) {
        updateApiKey(input: $input) {
          apiKey {
            id
            name
            enabled
            apiKey
            contentType {
              model
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        formik.setFieldValue('name', data.updateApiKey.apiKey.name)
        setApiKey(data.updateApiKey.apiKey)
        toast.success(`API Key Saved`)
      },
      refetchQueries: ['APIKeys'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      apiKey: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      apiKey: Yup.string().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (apiKey_) {
        updateApiKey({
          variables: {
            input: {
              apiKeyInput: {
                id: apiKey_.id,
                name: values.name,
              },
            },
          },
        })
      } else {
        createApiKey({
          variables: {
            input: {
              apiKeyInput: {
                name: values.name,
              },
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (apiKey) {
      formik.setFieldValue('name', apiKey.name)
      formik.setFieldValue('apiKey', apiKey.apiKey)
      setApiKey(apiKey)
    }
  }, [apiKey])

  const handleDelete = () => {
    setSubmitting(true)
    deleteApiKey({
      variables: {
        deleteApiKeyInput: {
          apiKeyIds: [apiKey.id],
        },
      },
    })
  }

  return (
    <>
      <div id="equipmentRepairForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={2}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                as="input"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {formik.values.apiKey && (
            <>
              <Form.Group as={Row}>
                <Col md={2} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => {
                      if (apiKeyInputType === 'password') {
                        setApiKeyInputType('text')
                      } else {
                        setApiKeyInputType('password')
                      }
                    }}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {apiKeyInputType === 'password' ? (
                        <Unlock size={17} />
                      ) : (
                        <Lock size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    API Key
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    as="input"
                    type={apiKeyInputType}
                    name="apiKey"
                    disabled={true}
                    value={formik.values.apiKey}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.apiKey}
                  />
                </Col>
              </Form.Group>
            </>
          )}
          {apiKey_?.id && (
            <>
              <Form.Group as={Row} className="">
                <Col md={5} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayApiCalls(!displayApiCalls)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayApiCalls ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    API Calls
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayApiCalls && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <APICalls apiKeyId={apiKey_?.id} />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="">
                <Col md={5} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() =>
                      setDisplayRecordChanges(!displayRecordChanges)
                    }
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayRecordChanges ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    API Key Record Change History
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayRecordChanges && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <APIKeyAuditLog
                      apiKeyId={apiKey_?.id}
                      fetchPolicy={'no-cache'}
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="">
                <Col md={5} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayAuditLog(!displayAuditLog)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayAuditLog ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    API Key History
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayAuditLog && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <AuditLog
                      contentType={apiKey_.contentType.model}
                      id={apiKey_.id}
                    />
                  </Col>
                </Form.Group>
              )}
            </>
          )}
          <Form.Row className="mt-2">
            <Col md={2}>
              <Button
                block
                variant="outline-primary"
                onClick={() => {
                  setView('list')
                  setApiKey()
                  setEditAPIKey()
                  formik.resetForm()
                }}
              >
                <ArrowLeft className="mr-2" />
                Back
              </Button>
            </Col>
            <Col md={2}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {apiKey_ && (
              <>
                <Col md={2}>
                  <Button
                    variant={
                      apiKey_.enabled ? 'outline-danger' : 'outline-primary'
                    }
                    block
                    onClick={() => {
                      updateApiKey({
                        variables: {
                          input: {
                            apiKeyInput: {
                              id: apiKey_.id,
                              enabled: !apiKey_.enabled,
                            },
                          },
                        },
                      })
                    }}
                    disabled={submitting}
                  >
                    {apiKey_.enabled ? (
                      <>
                        <SlashCircle className="mr-2" />
                      </>
                    ) : (
                      <>
                        <CheckLg className="mr-2" />
                      </>
                    )}
                    {apiKey_.enabled ? <>Disable</> : <>Enable</>}
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    variant="outline-danger"
                    block
                    onClick={handleDelete}
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              </>
            )}
          </Form.Row>
        </Form>
      </div>
    </>
  )
}

export default APIKeyForm
