import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { Trash, Truck, CalendarWeek, List, Dot } from 'react-bootstrap-icons'
import FulfillmentModal from './FulfillmentModal'
import Loading from '../../common/Loading'
import DeleteFulfillmentModal from './DeleteFulfillmentModal'
import { DateTime } from 'luxon'
import { loggedInUserVar } from '../../../libs/apollo'
import FulfillmentsCalendar from './FulfillmentsCalendar'

const Fulfillments = (props) => {
  const {
    businessId,
    dealId,
    cargoPage,
    cargoProviderId,
    locationId,
    employeeId,
    productId,
    tableHeight,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editNode, setEditNode] = useState()
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const [view, setView] = useState('list')
  const STATUS = {
    NOT_READY_FOR_PICKUP: 'Not Ready for Pickup',
    READY_FOR_PICKUP: 'Ready for Pickup',
    CARGO_BOOKED: 'Cargo Booked',
    PICKED_UP: 'Picked Up',
    DELIVERED: 'Delivered',
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query CargoUnitInstanceContainerItems(
        $cursor: String
        $business: String
        $cargoProvider: String
        $location: String
        $deal: String
        $employee: String
        $product: String
        $search: String
      ) {
        cargoUnitInstanceContainerItems(
          first: 20
          after: $cursor
          business: $business
          cargoProvider: $cargoProvider
          location: $location
          deal: $deal
          product: $product
          employee: $employee
          orderBy: "-pickup_date"
          search: $search
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              pickupDate
              deliveryDate
              contentType {
                model
                id
              }
              dealItem {
                id
                productVariant {
                  product {
                    name
                  }
                }
                supplierQuantity
                supplierUnitOfMeasure {
                  shortName
                }
                buyerQuantity
                buyerUnitOfMeasure {
                  shortName
                }
                deal {
                  id
                  dealNumber
                  buyer {
                    business {
                      id
                      name
                    }
                  }
                }
                supplier {
                  business {
                    id
                    name
                  }
                }
              }
              pickupQuantity
              pickupUnitOfMeasure {
                id
                shortName
              }
              deliveryQuantity
              deliveryUnitOfMeasure {
                id
                shortName
              }
              cargoUnitInstanceContainer {
                cargoUnitInstance {
                  id
                  deadWeightUnitOfMeasure {
                    id
                    shortName
                  }
                  deadWeightThreshold
                  status
                  cargoUnit {
                    id
                    singularName
                  }
                  deal {
                    dealNumber
                  }
                  contentType {
                    model
                    id
                  }
                  pickupLocation {
                    id
                    fullAddress
                  }
                  deliveryLocation {
                    id
                    fullAddress
                  }
                  price
                  quickPayFee
                  additionalFees
                  cargoProvider {
                    id
                    name
                  }
                  loadId
                  shipperLoadId
                  billOfLading
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.cargoUnitInstanceContainerItems?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    if (businessId) {
      variables.business = businessId
    }
    if (cargoProviderId) {
      variables.cargoProvider = cargoProviderId
    }
    if (productId) {
      variables.product = productId
    }
    if (employeeId) {
      variables.employee = employeeId
    }
    if (dealId) {
      variables.deal = dealId
    }
    if (locationId) {
      variables.location = locationId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.cargoUnitInstanceContainerItems) {
      let text = 'Search 0 records'
      if (queryData.cargoUnitInstanceContainerItems.nodeCount > 0) {
        text = `Search ${queryData.cargoUnitInstanceContainerItems.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [
        ...prevState,
        row.node.cargoUnitInstanceContainer.cargoUnitInstance.id,
      ])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter(
          (id) =>
            id !== row.node.cargoUnitInstanceContainer.cargoUnitInstance.id
        )
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Provider',
      id: 'provider',
      accessor: (row) => {
        const cargoUnitInstance =
          row.node.cargoUnitInstanceContainer.cargoUnitInstance
        if (cargoUnitInstance.cargoProvider) {
          return cargoUnitInstance.cargoProvider.name
        }
      },
    },
    {
      Header: 'Staus',
      id: 'status',
      accessor: (row) => {
        const cargoUnitInstance =
          row.node.cargoUnitInstanceContainer.cargoUnitInstance
        if (cargoUnitInstance.status) {
          return STATUS[cargoUnitInstance.status]
        }
      },
    },
    {
      Header: 'Load',
      id: 'loadNumber',
      accessor: (row) => {
        const cargoUnitInstance =
          row.node.cargoUnitInstanceContainer.cargoUnitInstance
        return cargoUnitInstance.loadId
      },
    },
    {
      Header: 'Bill Of Lading',
      id: 'billOfLading',
      accessor: (row) => {
        const cargoUnitInstance =
          row.node.cargoUnitInstanceContainer.cargoUnitInstance
        return cargoUnitInstance.billOfLading
      },
    },
    {
      Header: 'Pickup',
      id: 'pickup',
      accessor: (row) => {
        let formattedDate
        if (row.node.pickupDate) {
          const date = DateTime.fromISO(row.node.pickupDate)
          formattedDate = date.toFormat('cccc LLLL dd, yyyy')
        }
        const cargoUnitInstance =
          row.node.cargoUnitInstanceContainer.cargoUnitInstance
        if (formattedDate) {
          return (
            <>
              <Dot /> {cargoUnitInstance.pickupLocation.fullAddress}
              <br />
              <Dot /> {formattedDate}
            </>
          )
        } else {
          return `${cargoUnitInstance.pickupLocation.fullAddress}`
        }
      },
    },
    {
      Header: 'Pickup Quantity',
      id: 'pickupQuantity',
      accessor: (row) => {
        return `${Math.round(row.node.pickupQuantity)} / ${
          row.node.pickupUnitOfMeasure.shortName
        }`
      },
    },
    {
      Header: 'Delivery',
      id: 'delivery',
      accessor: (row) => {
        let formattedDate
        if (row.node.deliveryDate) {
          const date = DateTime.fromISO(row.node.deliveryDate)
          formattedDate = date.toFormat('cccc LLLL dd, yyyy')
        }
        const cargoUnitInstance =
          row.node.cargoUnitInstanceContainer.cargoUnitInstance

        if (formattedDate) {
          return (
            <>
              <Dot /> {cargoUnitInstance.deliveryLocation.fullAddress}
              <br />
              <Dot /> {formattedDate}
            </>
          )
        } else {
          return `${cargoUnitInstance.deliveryLocation.fullAddress}`
        }
      },
    },
    {
      Header: 'Delivery Quantity',
      id: 'deliveryQuantity',
      accessor: (row) => {
        return `${Math.round(row.node.deliveryQuantity)} / ${
          row.node.deliveryUnitOfMeasure.shortName
        }`
      },
    },
    {
      Header: 'Product',
      id: 'product',
      accessor: (row) => {
        return row.node.dealItem.productVariant.product.name
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.cargoUnitInstanceContainerItems?.edges.forEach(
                    (edge) => {
                      if (
                        !checkedNodeIds.includes(
                          edge.node.cargoUnitInstanceContainer.cargoUnitInstance
                            .id
                        )
                      ) {
                        appendIds.push(
                          edge.node.cargoUnitInstanceContainer.cargoUnitInstance
                            .id
                        )
                      }
                    }
                  )
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(
                  row.node.cargoUnitInstanceContainer.cargoUnitInstance.id
                )}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  if (!dealId) {
    tableColumns.splice(3, 0, {
      Header: 'Deal',
      id: 'dealNumber',
      accessor: (row) => {
        return row.node.dealItem.deal.dealNumber
      },
    })
    tableColumns.splice(5, 0, {
      Header: 'Supplier',
      id: 'supplier',
      accessor: (row) => {
        return row.node.dealItem.supplier.business.name
      },
    })
    tableColumns.splice(8, 0, {
      Header: 'Buyer',
      id: 'buyer',
      accessor: (row) => {
        return row.node.dealItem.deal.buyer.business.name
      },
    })
  }
  if (cargoProviderId) {
    tableColumns.splice(0, 1)
  }

  if (queryError) return <>Error loading fulfillment</>
  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Fulfillment..." />
        </Col>
      </Row>
    )

  return (
    <>
      {!businessId &&
        !dealId &&
        !cargoPage &&
        !cargoProviderId &&
        !locationId &&
        !employeeId &&
        !productId && (
          <Row className="mb-2 mt-3">
            <Col>
              <div className="d-flex align-items-center">
                <h1 className="mb-0 d-inline">Fulfillment</h1>
              </div>
            </Col>
          </Row>
        )}
      <Row
        style={{
          marginTop:
            businessId ||
            dealId ||
            cargoPage ||
            cargoProviderId ||
            locationId ||
            employeeId ||
            productId
              ? '-10px'
              : '-15px',
        }}
      >
        <Col>
          <Button
            variant="link"
            onClick={() => {
              if (view === 'list') {
                setView('calendar')
              } else {
                setView('list')
              }
            }}
            className="mr-2"
          >
            <div className="d-flex align-items-center">
              {view === 'calendar' ? (
                <CalendarWeek className="text-primary mr-1" />
              ) : (
                <List className="text-primary mr-1" />
              )}

              {view === 'calendar' ? <span>Calendar</span> : <span>List</span>}
            </div>
          </Button>
          {canMutate && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <Truck className="mr-2" />
              New Fulfillment
            </Button>
          )}
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  Delete Fulfillment
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      {view === 'list' && (
        <Row className="mt-2 mb-3">
          <Col md="12">
            <SortableInfiniteTable
              tableData={
                queryData?.cargoUnitInstanceContainerItems?.edges
                  ? queryData?.cargoUnitInstanceContainerItems?.edges
                  : []
              }
              loadingMessage="Loading Fulfillment..."
              tableColumns={tableColumns}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={
                queryData?.cargoUnitInstanceContainerItems?.pageInfo
                  ?.hasNextPage
              }
              onTdClicks={{
                provider: (cell) => onTdClick(cell),
                loadNumber: (cell) => onTdClick(cell),
                billOfLading: (cell) => onTdClick(cell),
                pickup: (cell) => onTdClick(cell),
                delivery: (cell) => onTdClick(cell),
                product: (cell) => onTdClick(cell),
                quantity: (cell) => onTdClick(cell),
                dealNumber: (cell) => onTdClick(cell),
                supplier: (cell) => onTdClick(cell),
                buyer: (cell) => onTdClick(cell),
                status: (cell) => onTdClick(cell),
              }}
              tableHeight={tableHeight ? tableHeight : 800}
              rowPointer
              hideGlobalFilter
            />
          </Col>
        </Row>
      )}
      {view === 'calendar' && (
        <FulfillmentsCalendar
          fulfillments={queryData?.cargoUnitInstanceContainerItems?.edges}
          toggleModal={toggleModal}
        />
      )}
      {showForm && (
        <FulfillmentModal
          showModal={showForm}
          fulfillment={editNode}
          toggleModal={toggleModal}
          dealId={dealId}
          cargoProviderId={cargoProviderId}
        />
      )}
      <DeleteFulfillmentModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedNodeIds={setCheckedNodeIds}
        ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
        id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
      />
    </>
  )
}
export default Fulfillments
