import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const FulfillmentsCalendar = (props) => {
  const { fulfillments, toggleModal } = props
  const monthMilliseconds = 2592000000
  const DragAndDropCalendar = withDragAndDrop(Calendar)
  const localizer = momentLocalizer(moment)
  const eventStyleGetter = () => {
    const style = {
      color: 'black',
      borderRadius: '0px',
      fontSize: '.7em',
      border: 'none',
    }
    return {
      className: '',
      style,
    }
  }
  const calendarFulfillments = fulfillments
    .filter(
      (fulfillment) =>
        fulfillment.pickupDate !== null && fulfillment.deliveryDate !== null
    )
    .map((fulfillment) => ({
      ...fulfillment,
      startDate: new Date(fulfillment.node.pickupDate),
      endDate: new Date(fulfillment.node.deliveryDate),
    }))
  return (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <DragAndDropCalendar
            popup
            localizer={localizer}
            step={5}
            dayLayoutAlgorithm="no-overlap"
            timeslots={12}
            defaultDate={new Date()}
            views={['month', 'week', 'day']}
            defaultView={'month'}
            events={calendarFulfillments}
            style={{ height: 600 }}
            titleAccessor={(event) => {
              return `Deal #${event.node.dealItem.deal.dealNumber} | Supplier ${event.node.dealItem.supplier.business.name} | Buyer ${event.node.dealItem.deal.buyer.business.name}`
            }}
            showMultiDayTimes
            startAccessor="startDate"
            endAccessor="endDate"
            // onRangeChange={(rangeEvent) => handleRangeChange(rangeEvent)}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={(event) => {
              toggleModal(event.node)
            }}
            // onNavigate={(defaultDate) => handleNavigate(defaultDate)}
            // onView={(view) => handleViewChange(view)}
          />
        </Col>
      </Row>
    </>
  )
}

export default FulfillmentsCalendar
