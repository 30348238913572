import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import {
  CaretDown,
  CaretRight,
  Trash,
  Folder,
  Plus,
} from 'react-bootstrap-icons'
import AuditLog from '../../audit_log/AuditLog'
import Loading from '../../common/Loading'

const ModelOutputFormatForm = (props) => {
  const { modelOutputFormat, afterSubmit } = props
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [displayHistory, setDisplayHistory] = useState(false)

  const [deleteModelOutputFormat] = useMutation(
    gql`
      mutation DeleteOpenAIModelOutputFormat(
        $input: DeleteOpenAIModelOutputFormatInput!
      ) {
        deleteOpenAiModelOutputFormat(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Model Output Format Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['OpenAiModelOutputFormats'],
    }
  )

  const [createModelOutputFormat] = useMutation(
    gql`
      mutation CreateOpenAiModelOutputFormat(
        $input: CreateOpenAIModelOutputFormatInput!
      ) {
        createOpenAiModelOutputFormat(input: $input) {
          openAiModelOutputFormat {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Model Output Format Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['OpenAiModelOutputFormats'],
    }
  )

  const [updateModelOutputFormat] = useMutation(
    gql`
      mutation UpdateOpenAiModelOutputFormat(
        $input: UpdateOpenAIModelOutputFormatInput!
      ) {
        updateOpenAiModelOutputFormat(input: $input) {
          openAiModelOutputFormat {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Model Output Format Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['OpenAiModelOutputFormats'],
    }
  )

  let format = []
  if (modelOutputFormat && modelOutputFormat.format) {
    format = JSON.parse(modelOutputFormat.format)
  }
  const formik = useFormik({
    initialValues: modelOutputFormat
      ? {
          name: modelOutputFormat.name,
          format: format,
        }
      : {
          name: '',
          format: format,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      format: Yup.array().of(
        Yup.object().shape({
          name: Yup.mixed().required('Required'),
          context: Yup.mixed().nullable(),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (modelOutputFormat) {
        values.id = modelOutputFormat.id
        updateModelOutputFormat({
          variables: {
            input: {
              openAiModelOutputFormatInput: values,
            },
          },
        })
      } else {
        createModelOutputFormat({
          variables: {
            input: {
              openAiModelOutputFormatInput: values,
            },
          },
        })
      }
    },
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={4}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="input"
              name="name"
              className="form-control-sm"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div
          className={
            formik.values.format.length > 0 ? 'bg-light border p-4' : 'd-none'
          }
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          <Row>
            {formik.values.format.map((_, index) => (
              <>
                <Col md={4} className={index > 0 ? 'mt-2' : ''}>
                  <Form.Label>Field Name</Form.Label>
                  <Form.Control
                    as="input"
                    name={`format[${index}].name`}
                    className="form-control-sm"
                    value={formik.values.format[index].name}
                    onChange={formik.handleChange}
                    isInvalid={
                      index < formik.errors.format?.length &&
                      formik.errors.format[index]?.name
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {index < formik.errors.format?.length &&
                      formik.errors.format[index]?.name}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} className={index > 0 ? 'mt-2' : ''}>
                  <Form.Label>Field Context</Form.Label>
                  <Form.Control
                    as="input"
                    name={`format[${index}].context`}
                    className="form-control-sm"
                    value={formik.values.format[index].context}
                    onChange={formik.handleChange}
                    isInvalid={
                      index < formik.errors.format?.length &&
                      formik.errors.format[index]?.context
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {index < formik.errors.format?.length &&
                      formik.errors.format[index]?.context}
                  </Form.Control.Feedback>
                </Col>
                <Col className="mt-4">
                  <p>
                    <small
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => {
                        const newFormat = [...formik.values.format]
                        newFormat.splice(index, 1)
                        formik.setFieldValue('format', newFormat)
                      }}
                    >
                      <Trash className="me-1" /> Remove Output Field
                    </small>
                  </p>
                </Col>
              </>
            ))}
          </Row>
        </div>
        <Row>
          <Col>
            <Button
              className={
                formik.values.format.length > 0 ? ' mt-3 ps-0' : 'ps-0 mt-0'
              }
              variant="link"
              size="sm"
              onClick={() => {
                formik.setFieldValue('format', [
                  ...formik.values.format,
                  {
                    name: null,
                    context: null,
                  },
                ])
              }}
            >
              <Plus size={18} /> Add Output Field
            </Button>
          </Col>
        </Row>
        {modelOutputFormat && (
          <>
            <Row>
              <Col md={3} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayHistory(!displayHistory)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayHistory ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label column sm="12" md="auto">
                  History
                </Form.Label>
              </Col>
            </Row>
            {displayHistory && (
              <AuditLog
                contentType={modelOutputFormat.contentType.model}
                id={modelOutputFormat.id}
              />
            )}
            <div className="mb-3" />
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {modelOutputFormat && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deleteModelOutputFormat({
                      variables: {
                        input: {
                          ids: modelOutputFormat.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading
                message={
                  deleting
                    ? 'Deleting Model Output Format...'
                    : 'Saving Model Output Format...'
                }
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default ModelOutputFormatForm
