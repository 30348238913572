import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Trash, Folder } from 'react-bootstrap-icons'
import DeleteCargoProviderModal from './DeleteCargoProviderModal'

import Loading from '../../common/Loading'

const CargoProviderForm = (props) => {
  const TYPES = [
    {
      value: 'TRUCKING',
      description: 'Trucking',
    },
    {
      value: 'RAIL',
      description: 'Rail',
    },
    { value: 'SHIP', description: 'Ship' },
  ]
  const { cargoProvider, afterSubmit, hideAuditLog } = props
  const [submitting, setSubmitting] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [createCargoProvider] = useMutation(
    gql`
      mutation CreateCargoProvider($input: CreateCargoProviderInput!) {
        createCargoProvider(input: $input) {
          cargoProvider {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Cargo Provider Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['CargoProviders'],
    }
  )

  const [updateCargoProvider] = useMutation(
    gql`
      mutation UpdateCargoProvider($input: UpdateCargoProviderInput!) {
        updateCargoProvider(input: $input) {
          cargoProvider {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Cargo Provider Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['CargoProviders', 'CargoProvider'],
    }
  )

  const formik = useFormik({
    initialValues: cargoProvider
      ? {
          id: cargoProvider.id,
          name: cargoProvider.name,
          contactFirstName: cargoProvider.contactFirstName,
          contactLastName: cargoProvider.contactLastName,
          contactEmail: cargoProvider.contactEmail,
          contactPhone: cargoProvider.contactPhone,
          type: cargoProvider.type,
        }
      : {
          name: '',
          contactFirstName: '',
          contactLastName: '',
          contactEmail: '',
          contactPhone: '',
          type: '',
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      contactFirstName: Yup.string().required('Required'),
      contactLastName: Yup.string().required('Required'),
      contactEmail: Yup.string().required('Required'),
      contactPhone: Yup.string().required('Required'),
      type: Yup.string()
        .required('Required')
        .required('Required')
        .test('status', 'Required', (value) => value !== '- - -'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (cargoProvider) {
        updateCargoProvider({
          variables: {
            input: {
              cargoProviderInput: values,
            },
          },
        })
      } else {
        createCargoProvider({
          variables: {
            input: {
              cargoProviderInput: values,
            },
          },
        })
      }
    },
  })
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="input"
              name="name"
              className="form-control-sm"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
          <Col md={6}>
            <Form.Label>Type</Form.Label>
            <Form.Control
              name="type"
              as="select"
              className="form-control-sm"
              value={formik.values.type}
              onChange={formik?.handleChange}
              isInvalid={formik?.errors?.type}
            >
              <option value="- - -">- - -</option>
              {TYPES.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.type}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Contact First Name</Form.Label>
            <Form.Control
              as="input"
              name="contactFirstName"
              className="form-control-sm"
              value={formik.values.contactFirstName}
              onChange={formik.handleChange}
              isInvalid={formik.errors.contactFirstName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.contactFirstName}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>Contact Last Name</Form.Label>
            <Form.Control
              as="input"
              name="contactLastName"
              className="form-control-sm"
              value={formik.values.contactLastName}
              onChange={formik.handleChange}
              isInvalid={formik.errors.contactLastName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.contactLastName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Contact Email</Form.Label>
            <Form.Control
              as="input"
              name="contactEmail"
              className="form-control-sm"
              value={formik.values.contactEmail}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.contactEmail}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>Contact Phone</Form.Label>
            <Form.Control
              as="input"
              name="contactPhone"
              className="form-control-sm"
              value={formik.values.contactPhone}
              onChange={formik.handleChange}
              isInvalid={formik.errors.contactPhone}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.contactPhone}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {cargoProvider && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading message={'Saving Cargo Provider...'} />
            </div>
          )}
        </div>
      </Form>
      {showDeleteModal && (
        <DeleteCargoProviderModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          id={cargoProvider.id}
        />
      )}
    </>
  )
}

export default CargoProviderForm
