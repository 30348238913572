import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Col, Tab } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import EditEmployeeModal from './EditEmployeeModal'
import Notifications from '../notification/Notifications'
import Loading from '../common/Loading'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import { useBoxEnabled } from '../../libs/box'
import {
  PersonWorkspace,
  Bell,
  Buildings,
  GraphUpArrow,
  ClockHistory,
  CardChecklist,
  Truck,
} from 'react-bootstrap-icons'
import Deals from '../deal/Deals'
import Businesses from '../business/Businesses'
import Fulfillments from '../cargo/fulfilment/Fulfillments'
import TaskTabs from '../task/TaskTabs'

const EmployeeDetail = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { employeeId } = useParams()
  const boxEnabled = useBoxEnabled()
  const [activeTab, setActiveTab] = useState('Profile')
  const { error: employeeQueryError, data: employeeQueryData } = useQuery(
    gql`
      query EmployeeQuery($employeeId: ID!) {
        employee(id: $employeeId) {
          id
          contentType {
            model
          }
          user {
            id
            contentType {
              model
            }
            isActive
            lastLogin
            loginBrowser
            loginLocation
            email
            firstName
            lastName
            fullName
            abbreviatedName
            phoneNumber
            emailNotificationsEnabled
            smsNotificationsEnabled
            secondaryPhoneNumber
            secondaryEmail
            addressLineOne
            addressLineTwo
            state
            city
            zipCode
            groups {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
          smtpHost
          smtpTls
          smtpPort
          smtpUser
          smtpSsl
          smtpPassword
          smtpValid
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        employeeId,
      },
    }
  )

  if (!employeeQueryData)
    return (
      <span className="mt-3 mt-2">
        <Loading message="Loading Employee..." />
      </span>
    )
  if (employeeQueryError) return <>Error loading employee</>
  const { employee } = employeeQueryData
  let roles = '- - -'
  if (employee?.roles) {
    roles = ''
    employee?.roles?.edges.forEach((role) => {
      roles = `${roles} ${role.node.name},`
    })
    roles = roles.slice(0, -1)
  }
  return (
    <>
      <div
        style={
          loggedInUser.permissions.group === 'General Staff'
            ? {
                marginTop: '-10px',
              }
            : {}
        }
      >
        {loggedInUser.permissions.group !== 'General Staff' && (
          <Row className="mb-2 mt-3">
            <Col>
              <div className="d-flex align-items-center">
                <h1 className="mb-0 d-inline">{employee.user.fullName}</h1>
              </div>
            </Col>
          </Row>
        )}

        <div>
          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => {
              setActiveTab(tab)
            }}
          >
            <Tab
              key="Profile"
              eventKey="Profile"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <PersonWorkspace className="mr-1" />
                  Profile
                </b>
              }
            >
              <EditEmployeeModal employee={employee} />
            </Tab>
            <Tab
              key="Deals"
              eventKey="Deals"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <GraphUpArrow className="mr-1" />
                  Deals
                </b>
              }
            >
              <Deals employeeId={employee.id} />
            </Tab>
            <Tab
              key="Fulfillment"
              eventKey="Fulfillment"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Truck className="mr-1" />
                  Fulfillment
                </b>
              }
            >
              <Fulfillments employeeId={employee.id} />
            </Tab>
            <Tab
              key="Businesses"
              eventKey="Businesses"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Buildings className="mr-1" />
                  Businesses
                </b>
              }
            >
              <Businesses employeeId={employee.id} />
            </Tab>
            <Tab
              key="Tasks"
              eventKey="Tasks"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <CardChecklist className="mr-1" />
                  Tasks
                </b>
              }
            >
              <TaskTabs employeeId={employee.id} />
            </Tab>
            {['Administrator', 'Scheduling Manager'].includes(
              loggedInUser.permissions.group
            ) && (
              <Tab
                key="Notifications"
                eventKey="Notifications"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <Bell className="mr-1" />
                    Notifications
                  </b>
                }
              >
                <Notifications employee={employee} />
              </Tab>
            )}
            {['Administrator', 'Scheduling Manager'].includes(
              loggedInUser.permissions.group
            ) && (
              <Tab
                key="History"
                eventKey="History"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <ClockHistory className="mr-1" />
                    History
                  </b>
                }
              >
                <Row>
                  <Col md={12}>
                    <AuditLog
                      contentTypesRelayIds={{
                        [employee.contentType.model]: [employee.id],
                        [employee.user.contentType.model]: [employee.user.id],
                      }}
                    />
                  </Col>
                </Row>
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    </>
  )
}
export default EmployeeDetail
