import React from 'react'
import { Modal } from 'react-bootstrap'
import DealForm from './DealForm'
import { GraphUpArrow } from 'react-bootstrap-icons'
import Deal from './Deal'

const DealModal = (props) => {
  const { showModal, toggleModal, deal } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="dealModal">
        <Modal size="xl" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <GraphUpArrow className="mr-2" />
              {deal ? <>Deal #{deal.dealNumber}</> : <>New Deal</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deal && <Deal dealId={deal.id} />}
            {!deal && <DealForm afterSubmit={toggleModal} />}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DealModal
