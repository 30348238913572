import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, clearLoginVars, settingsVar } from '../../libs/apollo'
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar'
import {
  Unlock,
  Lock,
  CarFront,
  Buildings,
  GraphUpArrow,
  PersonWorkspace,
  Bell,
  CashCoin,
  Robot,
  People,
  Gear,
  Truck,
  BoxArrowInLeft,
  PersonBoundingBox,
  GeoAlt,
  FileEarmarkText,
  CardChecklist,
} from 'react-bootstrap-icons'
import './Navigation.css'
import { useLocation } from 'react-router-dom'

const Navigation = ({ setNavigationOpen }) => {
  let isCollapsed = true
  let locked = false
  let navState = localStorage.getItem('navState')
  if (navState) {
    navState = JSON.parse(navState)
    isCollapsed = navState.collapsed
    locked = navState.locked
  }
  const settings = useReactiveVar(settingsVar)
  const location = useLocation()
  const [navLocked, setNavLocked] = useState(locked)
  const { collapsed, collapseSidebar } = useProSidebar()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()

  const handleLogout = () => {
    clearLoginVars()
    localStorage.clear()
    history.push('/')
  }

  useEffect(() => {
    setNavigationOpen(!collapsed)
  }, [setNavigationOpen, collapsed])

  useEffect(() => {
    if (!collapsed) {
      setNavigationOpen(true)
    } else {
      setNavigationOpen(false)
    }
  }, [setNavigationOpen, collapsed])

  const toggleSidebar = (open) => {
    if (!navLocked) {
      localStorage.setItem(
        'navState',
        JSON.stringify({ collapsed: open, locked: navLocked })
      )
      collapseSidebar(open)
    }
  }

  if (!settings || !loggedInUser) return <></>
  const isBuyerSupplier =
    loggedInUser.permissions.isBuyer || loggedInUser.permissions.isSupplier
  const isEmployee = loggedInUser.permissions.isEmployee
  return (
    <>
      <div
        style={{ backgroundColor: settings.colorScheme, flex: 1 }}
        className="sidebar-container"
      >
        <Sidebar defaultCollapsed={isCollapsed} width="143px">
          <Menu>
            <>
              {isEmployee && (
                <>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname.includes('deal')
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/deals')}
                  >
                    {collapsed ? (
                      <GraphUpArrow />
                    ) : (
                      <>
                        <GraphUpArrow className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Deals</span>
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname.includes('cargo')
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/cargo')}
                  >
                    {collapsed ? (
                      <Truck />
                    ) : (
                      <>
                        <Truck className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Cargo</span>
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname.includes('task')
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/tasks')}
                  >
                    {collapsed ? (
                      <CardChecklist />
                    ) : (
                      <>
                        <CardChecklist className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Tasks</span>
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname.includes('business')
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/businesses')}
                  >
                    {collapsed ? (
                      <Buildings />
                    ) : (
                      <>
                        <Buildings className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Businesses</span>
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname.includes('client')
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/clients')}
                  >
                    {collapsed ? (
                      <People />
                    ) : (
                      <>
                        <People className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Clients</span>
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname === '/locations'
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/locations')}
                  >
                    {collapsed ? (
                      <GeoAlt />
                    ) : (
                      <>
                        <GeoAlt className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Locations</span>
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    onClick={() => history.push('/files')}
                    className={
                      location.pathname === '/files' ? 'menu-item-active' : ''
                    }
                  >
                    {collapsed ? (
                      <FileEarmarkText />
                    ) : (
                      <>
                        <FileEarmarkText className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Files</span>
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname.includes('products')
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/products')}
                  >
                    {collapsed ? (
                      <CarFront />
                    ) : (
                      <>
                        <CarFront className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Products</span>
                      </>
                    )}
                  </MenuItem>
                  {['Administrator'].includes(
                    loggedInUser.permissions.group
                  ) && (
                    <MenuItem
                      onMouseEnter={() => {
                        toggleSidebar(false)
                      }}
                      onMouseLeave={() => {
                        toggleSidebar(true)
                      }}
                      onClick={() => history.push('/notifications')}
                      className={
                        location.pathname === '/notifications'
                          ? 'menu-item-active'
                          : ''
                      }
                    >
                      {collapsed ? (
                        <Bell />
                      ) : (
                        <>
                          <Bell className="mr-2" />
                          <span style={{ fontSize: '11.5px' }}>
                            Notifications
                          </span>
                        </>
                      )}
                    </MenuItem>
                  )}
                  {!['General Staff', 'Equipment Manager'].includes(
                    loggedInUser.permissions.group
                  ) && (
                    <MenuItem
                      onMouseEnter={() => {
                        toggleSidebar(false)
                      }}
                      onMouseLeave={() => {
                        toggleSidebar(true)
                      }}
                      onClick={() => history.push('/billing')}
                      className={
                        location.pathname === '/billing' ||
                        location.pathname.includes('sales-order') ||
                        location.pathname.includes('purchase-order')
                          ? 'menu-item-active'
                          : ''
                      }
                    >
                      {collapsed ? (
                        <CashCoin />
                      ) : (
                        <>
                          <CashCoin className="mr-2" />
                          <span style={{ fontSize: '11.5px' }}>Billing</span>
                        </>
                      )}
                    </MenuItem>
                  )}

                  {!['General Staff', 'Equipment Manager'].includes(
                    loggedInUser.permissions.group
                  ) && (
                    <MenuItem
                      onMouseEnter={() => {
                        toggleSidebar(false)
                      }}
                      onMouseLeave={() => {
                        toggleSidebar(true)
                      }}
                      onClick={() => history.push('/ai')}
                      className={location.pathname.includes('ai')}
                    >
                      {collapsed ? (
                        <Robot />
                      ) : (
                        <>
                          <Robot className="mr-2" />
                          <span style={{ fontSize: '11.5px' }}>AI</span>
                        </>
                      )}
                    </MenuItem>
                  )}
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname.includes('employee') &&
                      location.pathname !==
                        `/employee/${loggedInUser?.employee?.id}`
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/employees')}
                    active={location.pathname === '/employees'}
                  >
                    {collapsed ? (
                      <PersonWorkspace />
                    ) : (
                      <>
                        <PersonWorkspace className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Employees</span>
                      </>
                    )}
                  </MenuItem>
                  {!['General Staff', 'Equipment Manager'].includes(
                    loggedInUser.permissions.group
                  ) && (
                    <MenuItem
                      onMouseEnter={() => {
                        toggleSidebar(false)
                      }}
                      onMouseLeave={() => {
                        toggleSidebar(true)
                      }}
                      onClick={() => history.push('/settings')}
                      className={
                        location.pathname === '/settings'
                          ? 'menu-item-active'
                          : ''
                      }
                      active={location.pathname === '/settings'}
                    >
                      {collapsed ? (
                        <Gear />
                      ) : (
                        <>
                          <Gear className="mr-2" />
                          <span style={{ fontSize: '11.5px' }}>Settings</span>
                        </>
                      )}
                    </MenuItem>
                  )}
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    onClick={() =>
                      history.push(`/employee/${loggedInUser?.employee?.id}`)
                    }
                    className={
                      location.pathname ===
                      `/employee/${loggedInUser?.employee?.id}`
                        ? 'menu-item-active'
                        : ''
                    }
                  >
                    {collapsed ? (
                      <PersonBoundingBox />
                    ) : (
                      <>
                        <PersonBoundingBox className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Profile</span>
                      </>
                    )}
                  </MenuItem>
                </>
              )}
            </>
            <MenuItem
              onClick={handleLogout}
              className="title-space"
              onMouseEnter={() => {
                toggleSidebar(false)
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {collapsed ? (
                <BoxArrowInLeft />
              ) : (
                <>
                  <BoxArrowInLeft className="mr-2" />
                  <span style={{ fontSize: '11.5px' }}>Logout</span>
                </>
              )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  'navState',
                  JSON.stringify({ collapsed, locked: !navLocked })
                )
                setNavLocked(!navLocked)
              }}
              onMouseEnter={() => {
                if (!collapsed) {
                  toggleSidebar(false)
                }
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {navLocked && <Lock style={{ fontSize: '14px' }} />}
              {!navLocked && <Unlock style={{ fontSize: '14px' }} />}
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  )
}

export default Navigation
