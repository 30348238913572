import React from 'react'
import { Route } from 'react-router-dom'
import OpenLayout from '../components/layout/OpenLayout'

const OpenRoute = ({
  component: Component,
  hideHeaderLogo,
  landing,
  ...rest
}) => {
  return (
    <OpenLayout hideHeaderLogo={hideHeaderLogo} landing={landing}>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </OpenLayout>
  )
}

export default OpenRoute
