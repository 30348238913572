import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

export const useBoxJwt = () => {
  const [_getJwt, setGetBoxJwt] = useState(false)
  const [boxJwt, setBoxJwt] = useState()
  const [getJwt] = useLazyQuery(
    gql`
      query BoxJwt {
        boxJwt {
          jwt
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBoxJwt(data.boxJwt.jwt)
      },
    }
  )
  useEffect(() => {
    if (_getJwt) {
      getJwt()
    }
  }, [_getJwt])
  return [boxJwt, setGetBoxJwt]
}

export const useBoxEnabled = () => {
  const { data } = useQuery(
    gql`
      query BoxConnectorValid {
        boxConnectors {
          edges {
            node {
              authValid
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    }
  )
  if (!data) return
  return data.boxConnectors.edges[0].node.authValid
}
