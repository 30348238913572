import React, { useState } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import { CarFront, Coin } from 'react-bootstrap-icons'
import Tabs from '../common/Tabs'
import Products from './product/Products'
import ProductPrices from './product_prices/ProductPrices'

const ProductTabs = () => {
  const [activeTab, setActiveTab] = useState('Products')
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Products
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Products"
          eventKey="Products"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CarFront className="mr-2" />
              Products
            </b>
          }
        >
          <Products productsPage />
        </Tab>
        <Tab
          key="ProductPrices"
          eventKey="ProductPrices"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Coin className="mr-2" />
              Product Prices
            </b>
          }
        >
          <ProductPrices />
        </Tab>
      </Tabs>
    </>
  )
}
export default ProductTabs
