import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Table, Form } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { DateTime } from 'luxon'
import { settingsVar } from '../../libs/apollo'
import DateFilter from '../common/DateFilter'
import Loading from '../common/Loading'

const AuditLog = (props) => {
  const {
    contentType,
    id,
    contentTypesRelayIds,
    tableHeight,
    searchWidth,
    topRowMargin,
    recordColumns,
    fetchPolicy,
  } = props
  const settings = useReactiveVar(settingsVar)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [cursor, setCursor] = useState()
  const [events, setEvents] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchText, setSearchText] = useState()
  const [hasMoreEvents, setHasMoreEvents] = useState(true)
  let tableHeight_ = tableHeight ? tableHeight : 600
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query LogEntries(
        $cursor: String
        $contentTypesRelayIds: String
        $contentType: String
        $relayId: String
        $searchTerm: String
        $startDateTimeGte: DateTime
        $startDateTimeLte: DateTime
      ) {
        logEntries(
          first: 50
          after: $cursor
          contentType_Model: $contentType
          relayId: $relayId
          timestamp_Gte: $startDateTimeGte
          timestamp_Lte: $startDateTimeLte
          objectRepr_Icontains: $searchTerm
          contentTypesRelayIds: $contentTypesRelayIds
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              timestamp
              objectPk
              changes
              action
              objectRepr
              apiCalls {
                edges {
                  node {
                    id
                    apiKey {
                      name
                    }
                  }
                }
              }
              actor {
                fullName
                email
                employee {
                  id
                }
              }
              contentType {
                model
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy ? fetchPolicy : 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
      variables: {
        contentType,
        relayId: id,
        contentTypesRelayIds: contentTypesRelayIds
          ? JSON.stringify(contentTypesRelayIds)
          : null,
      },
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query()
    }
  }, [initialQueryRun])

  useEffect(() => {
    if (queryData?.logEntries) {
      if (queryData?.logEntries?.pageInfo?.endCursor) {
        setCursor(queryData.logEntries.pageInfo.endCursor)
      }
      setHasMoreEvents(queryData?.logEntries?.pageInfo?.hasNextPage)
      if (queryData?.logEntries) {
        setEvents((prevEvents) => [
          ...prevEvents,
          ...queryData.logEntries.edges,
        ])
      }
      let text = 'Search 0 records'
      if (queryData?.logEntries?.nodeCount > 0) {
        text = `Search ${queryData.logEntries.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMoreEvents = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        if (fetchMoreResult.logEntries.pageInfo.endCursor) {
          setCursor(fetchMoreResult.logEntries.pageInfo.endCursor)
        }
        setHasMoreEvents(fetchMoreResult.logEntries.pageInfo.hasNextPage)
        setEvents((prevEvents) => [
          ...prevEvents,
          ...fetchMoreResult.logEntries.edges,
        ])
        return prev
      },
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      setCursor(null)
      const variables = {
        cursor: null,
        searchTerm,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
      }
      query({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setCursor(null)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    query({ variables })
  }

  const tableColumns = [
    {
      Header: 'Time',
      accessor: (row) => {
        const dateTime = DateTime.fromISO(row.node.timestamp)
        return dateTime
          .setZone(settings.timezone)
          .toFormat('MMMM dd yyyy hh:mm a')
      },
    },
    {
      Header: 'Action',
      accessor: (row) => {
        let action
        if (row.node.action.includes('0')) {
          action = 'Create'
        } else if (row.node.action.includes('1')) {
          action = 'Update'
        } else if (row.node.action.includes('2')) {
          action = 'Delete'
        } else if (row.node.action.includes('3')) {
          action = 'Access'
        }
        return action
      },
    },
    {
      Header: 'User',
      accessor: (row) => {
        return row.node.actor?.fullName
      },
    },
    {
      Header: 'User Type',
      accessor: (row) => {
        let type
        if (row.node.actor?.employee?.id) {
          type = 'Employee'
        } else if (row.node?.apiCalls?.edges.length > 0) {
          type = 'API'
        } else {
          type = 'System'
        }
        return type
      },
    },
    {
      Header: 'Changes',
      accessor: (row) => {
        if (row.node.changes) {
          let action
          if (row.node.action.includes('0')) {
            action = 'Create'
          } else if (row.node.action.includes('1')) {
            action = 'Update'
          } else if (row.node.action.includes('2')) {
            action = 'Delete'
          } else if (row.node.action.includes('3')) {
            action = 'Access'
          }
          if (action === 'Create') {
            return (
              <Table>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(JSON.parse(row.node.changes)).map(
                    (values, i) => {
                      let field = values[0]
                      if (field === 'user') {
                        field = 'user'
                      }
                      return (
                        <tr key={i}>
                          <td>{field}</td>
                          <td>{values[1][1]}</td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </Table>
            )
          } else if (action === 'Update') {
            return (
              <Table>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Intial</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(JSON.parse(row.node.changes)).map(
                    (values, i) => {
                      let field = values[0]
                      if (field === 'user') {
                        field = 'user'
                      }
                      return (
                        <tr key={i}>
                          <td>{field}</td>
                          <td>{values[1][0]}</td>
                          <td>{values[1][1]}</td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </Table>
            )
          }
        }
      },
    },
  ]
  if (recordColumns) {
    tableColumns.splice(
      1,
      0,
      ...[
        {
          Header: 'Record Type',
          accessor: (row) => {
            return row.node.contentType?.model === 'gaiauser'
              ? 'user'
              : row.node.contentType?.model
          },
        },
        {
          Header: 'Record',
          accessor: 'node.objectRepr',
        },
      ]
    )
  }

  if (!initialQueryRun && !queryData?.logEntries)
    return <Loading message="Loading History..." />
  if (queryError) return <>Error loading audit log</>
  if (!queryData?.logEntries || !settings)
    return <Loading message="Loading History..." />
  return (
    <>
      <div>
        <Row className={topRowMargin ? 'mt-4' : ''}>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                size="sm"
                type="text"
                name="searchTerm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col style={{ marginTop: '-8px' }}>
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="history start date"
              placeholderEnd="history end date"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <SortableInfiniteTable
              searchWidth={searchWidth}
              hideGlobalFilter
              loadingMessage="Loading History..."
              tableColumns={tableColumns}
              tableData={events}
              fetchMoreTableData={fetchMoreEvents}
              hasMoreTableData={hasMoreEvents}
              tableHeight={tableHeight_}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
export default AuditLog
