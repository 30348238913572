import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  from,
  makeVar,
  gql,
} from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import config from '../config'
import { CONSTANTS } from './constant'
import {
  isEmployee,
  isBuyer,
  isSupplier,
  getJwtLocalStorage,
  setLocalStorageObj,
  setJwtLocalStorage,
} from './utils'
import { toast } from 'react-hot-toast'
import { RetryLink } from 'apollo-link-retry'
import Observable from 'zen-observable'

let isRefreshingToken = false
let isLoggingOut = false

export const loggedInUserVar = makeVar(null)
export const timezoneVar = makeVar(null)
export const settingsVar = makeVar(null)

export const setLoginVars = (user) => {
  const userGroup = user.groups.edges[0].node.name
  let isEmployee_ = false
  let isSupplier_ = false
  let isBuyer_ = false
  user.groups.edges.forEach((edge) => {
    if (!isEmployee_ && isEmployee(edge.node.name)) {
      isEmployee_ = true
    }
    if (!isBuyer_ && isBuyer(edge.node.name)) {
      isBuyer_ = true
    }
    if (!isSupplier_ && isSupplier(edge.node.name)) {
      isSupplier_ = true
    }
  })
  user.permissions = {
    group: userGroup,
    isEmployee: isEmployee_,
    isBuyer: isBuyer_,
    isSupplier: isSupplier_,
  }
  loggedInUserVar(user)
  setLocalStorageObj(CONSTANTS.USER_VAR, user)
}

export const clearLoginVars = () => {
  loggedInUserVar(null)
}

const handleTokenRefreshFailure = () => {
  if (!isLoggingOut) {
    isLoggingOut = true
    toast.error('Login Session Has Expired')
    localStorage.clear()
    isLoggingOut = false
    window.location.href = '/'
  }
}

export const getNewToken = async () => {
  try {
    const jwt = getJwtLocalStorage()
    if (!jwt?.jwt) {
      handleTokenRefreshFailure()
    }
    const response = await client.mutate({
      mutation: gql`
        mutation refreshJwtToken($input: RefreshInput!) {
          refreshJwtToken(input: $input) {
            payload
            refreshExpiresIn
            token
            clientMutationId
          }
        }
      `,
      variables: {
        input: {
          token: jwt.jwt,
        },
      },
    })
    if (response?.data?.refreshJwtToken) {
      setJwtLocalStorage(response.data.refreshJwtToken)
    } else {
      handleTokenRefreshFailure()
    }
  } catch (e) {
    handleTokenRefreshFailure()
  }
}

let uri = `http://${window.location.hostname}:8000/graphql/`
if (config.ENVIRONMENT === 'production') {
  uri = 'https://api.mickey.io/graphql/'
} else if (config.ENVIRONMENT === 'qa') {
  uri = 'https://qa-api.mickey.io/graphql/'
}
const httpLink = createUploadLink({
  uri,
})

const authLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    let handle
    const asyncAuthLink = async () => {
      let jwt = getJwtLocalStorage()
      if (jwt?.jwt) {
        const now = new Date().getTime()
        if (!isRefreshingToken && now > jwt.refreshOn) {
          isRefreshingToken = true
          try {
            await getNewToken()
          } catch (e) {
            observer.error(e)
            return
          } finally {
            isRefreshingToken = false
          }
          jwt = getJwtLocalStorage()
        }
        operation.setContext(({ headers }) => ({
          headers: {
            ...headers,
            Authorization: `JWT ${jwt?.jwt}`,
          },
        }))
      }
      try {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      } catch (e) {
        observer.error(e)
      }
    }
    asyncAuthLink()
    return () => {
      if (handle) handle.unsubscribe()
    }
  })
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        users: relayStylePagination(['isActive', 'fullName_Icontains']),
        contentTypes: relayStylePagination(),
        logEntries: relayStylePagination(),
        files: relayStylePagination(),
        locations: relayStylePagination([
          'addressLineTwo_Icontains',
          'name_Icontains',
          'first',
          'addressLineOne_Icontains',
        ]),
        businesses: relayStylePagination(),
        notifications: relayStylePagination(),
        deals: relayStylePagination(),
        clients: relayStylePagination(),
        products: relayStylePagination(),
        cargoProviders: relayStylePagination(),
        salesOrders: relayStylePagination(),
        salesOrderItems: relayStylePagination(),
        openAIChatCompletionPrompts: relayStylePagination(),
        openAiModelOutputFormats: relayStylePagination(),
        purchaseOrders: relayStylePagination(),
        purchaseOrderItems: relayStylePagination(),
        cargoUnitInstanceContainerItems: relayStylePagination([
          'business',
          'cargoProvider',
          'location',
          'deal',
        ]),
        taskGroupTemplates: relayStylePagination(),
        taskGroups: relayStylePagination(),
        tasks: relayStylePagination(),
        taskTemplates: relayStylePagination(),
        employees: relayStylePagination(['user_FullName_Icontains']),
        employee: relayStylePagination(['user_FullName_Icontains']),
        stripePaymentIntents: relayStylePagination(),
        stripePaymentIntent: relayStylePagination(),
        stripeCustomer: relayStylePagination(),
        stripeCustomers: relayStylePagination(),
        stripeInvoice: relayStylePagination(),
        stripeInvoices: relayStylePagination(),
        stripeInvoiceItem: relayStylePagination(),
        stripeInvoiceItems: relayStylePagination(),
        settingsVar: {
          read() {
            return settingsVar()
          },
        },
        timezoneVar: {
          read() {
            return timezoneVar()
          },
        },
        loggedInUserVar: {
          read() {
            return loggedInUserVar()
          },
        },
      },
    },
    colleaguesData: {
      keyFields: ['colleagueId', 'colleagueName'],
    },
  },
})

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.message) {
        case 'Signature has expired': {
          getNewToken()
        }
      }
    }
  }
})

export const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, retryLink, httpLink]),
  connectToDevTools: config.ENVIRONMENT === 'development',
})
export const deserializeGraphQLErrors = (graphQLErrors) =>
  JSON.parse(graphQLErrors[0].message)
