import React, { useEffect, useState } from 'react'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Envelope } from 'react-bootstrap-icons'
import NewEmployeeModal from './NewEmployeeModal'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import BulkImportModal from './BulkImportModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { PersonWorkspace, Trash, CloudArrowDown } from 'react-bootstrap-icons'
import PermissionsModal from './PermissionsModal'
import DeleteEmployeeModal from './DeleteEmployeeModal'
import { useAWSS3 } from '../../libs/aws'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import { DateTime } from 'luxon'

const Employees = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const adminManager = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser.permissions.group
  )
  const [hasMoreEmployees, setHasMoreEmployees] = useState(true)
  const [cursor, setCursor] = useState()
  const [searchText, setSearchText] = useState()
  const [employees, setEmployees] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedEmployeeIds, setCheckedEmployeeIds] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [showNewEmployeeModal, setShowEmployeeModal] = useState(false)
  const [showImportEmployeeModal, setShowImportEmployeeModal] = useState(false)
  const [showPermissions, setShowPermissions] = useState(false)
  const [showDeleteEmployeesModal, setShowDeleteEmployeesModel] =
    useState(false)
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('Employees')
  const awsS3 = useAWSS3()

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => formatEmployeeName(row),
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (row) => {
        let email
        if (row.node.user.email) {
          email = row.node.user.email
        }
        if (row.node.user.secondaryEmail) {
          if (email) {
            email = `${email}, ${row.node.user.secondaryEmail}`
          } else {
            email = row.node.user.secondaryEmail
          }
        }
        return email
      },
    },
    {
      Header: 'Phone Number',
      id: 'phone',
      accessor: (row) => {
        let phone
        if (row.node.user.phoneNumber) {
          phone = row.node.user.phoneNumber
        }
        if (row.node.user.secondaryPhoneNumber) {
          if (phone) {
            phone = `${phone}, ${row.node.user.secondaryPhoneNumber}`
          } else {
            phone = row.node.user.secondaryPhoneNumber
          }
        }
        return phone
      },
    },
    {
      Header: 'Last Login',
      id: 'lastLogin',
      accessor: (row) => {
        if (row.node.user.lastLogin) {
          return DateTime.fromISO(row.node.user.lastLogin).toFormat(
            'MMMM d yyyy h:mm a'
          )
        }
      },
    },
    {
      Header: 'Send Email',
      accessor: (row) => {
        let email
        if (row.node.user.email) {
          email = row.node.user.email
        }
        if (row.node.user.secondaryEmail) {
          if (!email) {
            email = row.node.user.secondaryEmail
          } else {
            email = `${email}?cc=${row.node.user.secondaryEmail}`
          }
        }
        let sendEmail
        if (email) {
          sendEmail = (
            <>
              <a href={`mailto:${email}`}>
                <Envelope />
              </a>
            </>
          )
        }
        return sendEmail
      },
    },
  ]

  const handleEmployeeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedEmployeeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedEmployeeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }
  const [query, { error, data, fetchMore }] = useLazyQuery(
    gql`
      query EmployeesQuery($cursor: String, $searchTerm: String) {
        employees(
          first: 20
          after: $cursor
          search: $searchTerm
          orderBy: "user__full_name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              user {
                firstName
                lastName
                abbreviatedName
                email
                groups {
                  edges {
                    node {
                      name
                    }
                  }
                }
                secondaryEmail
                phoneNumber
                secondaryPhoneNumber
                lastLogin
                loginBrowser
                loginLocation
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query()
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (data) {
      if (data.employees.pageInfo.endCursor) {
        setCursor(data.employees.pageInfo.endCursor)
      }
      setHasMoreEmployees(data.employees.pageInfo.hasNextPage)
      setEmployees(data.employees.edges)
      let text = 'Search 0 records'
      if (data.employees.nodeCount > 0) {
        text = `Search ${data.employees.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [data])

  const fetchMoreResults = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        const newEmployees = fetchMoreResult.employees.edges
        const pageInfo = fetchMoreResult.employees.pageInfo
        setCursor(pageInfo.endCursor)
        setHasMoreEmployees(pageInfo.hasNextPage)
        setEmployees((prevState) => {
          const currentIds = new Set(newEmployees.map((item) => item.node.id))
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.node.id)
          )
          return [...filteredPrevState, ...newEmployees]
        })
      },
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setCursor(null)
    query({
      variables: {
        searchTerm: currentSearchTerm,
      },
    })
  }

  const formatEmployeeName = (employee) => {
    const { user } = employee.node
    return `${user.firstName} ${user.lastName}`
  }

  const toggleNewEmployeeModal = () => {
    setShowEmployeeModal((prevState) => !prevState)
  }

  const toggleImportEmployeeModal = () => {
    setShowImportEmployeeModal((prevState) => !prevState)
  }

  const onTdClick = (cell) => {
    if (adminManager) {
      history.push(`/employee/${cell.row.original.node.id}`)
    }
  }

  const getS3Object = async (Key, fileName, postDownload = null) => {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          let blob = new Blob([data.Body], { type: data.ContentType })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          postDownload()
        }
      }
    )
  }

  const [deleteFile] = useMutation(
    gql`
      mutation DeleteFile($deleteFileInput: DeleteFileInput!) {
        deleteFile(input: $deleteFileInput) {
          deleted
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const [downloadEmployeesXlsx] = useMutation(
    gql`
      mutation EmployeesXlsx($input: EmployeesXlsxInput!) {
        employeesXlsx(input: $input) {
          file {
            id
            fileName
            displayName
            contentType
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        getS3Object(
          data.employeesXlsx.file.fileName,
          data.employeesXlsx.file.displayName,
          () => {
            toast.success(`Employees Downloaded`)
            deleteFile({
              variables: {
                deleteFileInput: {
                  fileIds: data.employeesXlsx.file.id,
                },
              },
            })
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const onTdClicks = {
    name: onTdClick,
    email: onTdClick,
    phone: onTdClick,
    id: onTdClick,
    login: onTdClick,
  }

  if (!initialQueryRun && !data)
    return (
      <Row>
        <Col>
          <Loading message="Loading Employees..." />
        </Col>
      </Row>
    )
  if (error) return <>Error loading employees</>

  let actions = []
  if (
    ['Administrator', 'Scheduling Manager'].includes(
      loggedInUser.permissions.group
    )
  ) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  employees.forEach((employee) => {
                    if (!checkedEmployeeIds.includes(employee.node.id)) {
                      appendIds.push(employee.node.id)
                    }
                  })
                  setCheckedEmployeeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedEmployeeIds([])
                }
              }}
            />
            {checkedEmployeeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedEmployeeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedEmployeeIds.includes(row.node.id)}
                onChange={(e) => handleEmployeeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  const employeeTableJsx = (
    <>
      <Row className="mt-1">
        <Col md={4}>
          <Form.Group>
            <Form.Control
              size="sm"
              type="text"
              name="searchTerm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <SortableInfiniteTable
            hideGlobalFilter
            tableData={employees}
            loadingMessage="Loading Employees..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMoreResults}
            hasMoreTableData={hasMoreEmployees}
            onTdClicks={onTdClicks}
            rowPointer
            tableHeight={700}
          />
        </Col>
      </Row>
    </>
  )
  return (
    <>
      <Row className="mb-2 mt-3">
        <Col>
          <div className="d-flex align-items-center">
            <h1 className="mb-0 d-inline">Employees</h1>
          </div>
        </Col>
      </Row>
      {!adminManager && <>{employeeTableJsx}</>}
      {adminManager && (
        <>
          <Row style={{ marginTop: '-15px' }}>
            <Col>
              <div>
                <Button variant="link" onClick={toggleNewEmployeeModal}>
                  <PersonWorkspace className="mr-2" />
                  New Employee
                </Button>
                {/* <Button
                  variant="link"
                  onClick={() => {
                    downloadEmployeesXlsx({
                      variables: {
                        input: {
                          employeeIds: checkedEmployeeIds,
                        },
                      },
                    })
                  }}
                >
                  <CloudArrowDown className="mr-2" />
                  Download {checkedEmployeeIds.length > 0 && <>Excel</>}
                </Button> */}
                {checkedEmployeeIds.length > 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowDeleteEmployeesModel(true)
                    }}
                  >
                    <Trash className="mr-2" />
                    {checkedEmployeeIds.length === 1 ? (
                      <>Delete Employee</>
                    ) : (
                      <>Delete Employees</>
                    )}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          {employeeTableJsx}
          <NewEmployeeModal
            showModal={showNewEmployeeModal}
            toggleModal={toggleNewEmployeeModal}
          />
          <BulkImportModal
            showModal={showImportEmployeeModal}
            toggleModal={toggleImportEmployeeModal}
          />
          <DeleteEmployeeModal
            showModal={showDeleteEmployeesModal}
            toggleModal={() => {
              setShowDeleteEmployeesModel(false)
            }}
            setCheckedEmployeeIds={setCheckedEmployeeIds}
            employeeIds={
              checkedEmployeeIds.length > 1 ? checkedEmployeeIds : null
            }
            employeeId={
              checkedEmployeeIds.length === 1 ? checkedEmployeeIds[0] : null
            }
          />
          <PermissionsModal
            showModal={showPermissions}
            hideModal={() => setShowPermissions(false)}
          />
        </>
      )}
    </>
  )
}
export default Employees
