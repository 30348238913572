import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import LocationForm from './LocationForm'
import { GeoAlt } from 'react-bootstrap-icons'

const LocationModal = (props) => {
  const { showModal, toggleModal, locationId, businessId } = props
  const [locationDescription, setLocationDescription] = useState()

  useEffect(() => {
    if (!showModal) {
      setLocationDescription()
    }
  }, [showModal])

  if (!showModal) return <></>
  return (
    <>
      <div id="locationModal">
        <Modal
          size={locationId ? 'xl' : 'md'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <GeoAlt className="mr-2" />
              {locationDescription ? locationDescription : 'New Location'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LocationForm
              setLocationDescription={setLocationDescription}
              locationId={locationId}
              afterSubmit={toggleModal}
              businessId={businessId}
              showModal={showModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default LocationModal
