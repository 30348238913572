import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Plus, Trash, Folder } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import Attribute from './Attribute'
import DeleteProductModal from './DeleteProductsModal'

const ProductForm = (props) => {
  const { product, afterSubmit, hideAuditLog } = props
  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog_, setDisplayAuditLog] = useState(false)
  const [deleteProductId, setDeleteProductId] = useState()
  const location = useLocation()
  const history = useHistory()

  const [createProduct] = useMutation(
    gql`
      mutation CreateProduct($input: CreateProductInput!) {
        createProduct(input: $input) {
          product {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Product Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Products'],
    }
  )

  const [updateProduct] = useMutation(
    gql`
      mutation UpdateProduct($input: UpdateProductInput!) {
        updateProduct(input: $input) {
          product {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Product Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Products', 'Product'],
    }
  )
  const formik = useFormik({
    initialValues: product
      ? {
          name: product.name,
          attributes: product.attributeValues.map((attribute) => ({
            newAttribute: false,
            attributeId: attribute.attribute.id,
            attributeDescription: attribute.attribute.name,
            attributeValues: attribute.productAttributeValues.map(
              (productAttributeValue) => ({
                value: productAttributeValue.value,
              })
            ),
          })),
        }
      : {
          name: '',
          attributes: [],
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      attributes: Yup.array().of(
        Yup.object().shape({
          attributeId: Yup.mixed()
            .nullable()
            .test('has-values', 'Attribute Values Required', (_, context) => {
              return (
                context.parent.attributeValues &&
                context.parent.attributeValues.length > 0
              )
            }),
          newAttribute: Yup.boolean().required('Required'),
          attributeDescription: Yup.mixed().nullable(),
          newAttributeDescription: Yup.string()
            .nullable()
            .test('has-values', 'Attribute Values Required', (_, context) => {
              return (
                context.parent.attributeValues &&
                context.parent.attributeValues.length > 0
              )
            }),
          attributeValues: Yup.array().of(
            Yup.object().shape({
              value: Yup.mixed().required('Required'),
            })
          ),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const variables = {
        name: values.name,
        attributes: values.attributes.map((attribute) => ({
          id: attribute.attributeId,
          newAttributeName:
            attribute.newAttributeDescription &&
            !attribute.attributeId &&
            attribute.newAttributeDescription,
          attributeValues: attribute.attributeValues.map(
            (attributeValue) => attributeValue.value
          ),
        })),
      }
      if (product) {
        variables.id = product.id
        updateProduct({
          variables: {
            input: {
              productInput: variables,
            },
          },
        })
      } else {
        createProduct({
          variables: {
            input: {
              productInput: variables,
            },
          },
        })
      }
    },
  })

  return (
    <>
      {deleteProductId && (
        <DeleteProductModal
          showModal={deleteProductId}
          toggleModal={setDeleteProductId}
          id={deleteProductId}
        />
      )}
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col md={6}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              className="form-control-sm"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div
          className={
            formik.values.attributes.length > 0
              ? 'bg-light border p-4'
              : 'd-none'
          }
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          {formik.values.attributes.map((_, index) => (
            <Attribute key={index} formik={formik} index={index} />
          ))}
        </div>
        <Row>
          <Col>
            <Button
              className={
                formik.values.attributes.length > 0 ? ' mt-3 ps-0' : 'ps-0 mt-0'
              }
              variant="link"
              size="sm"
              onClick={() => {
                formik.setFieldValue('attributes', [
                  ...formik.values.attributes,
                  {
                    attributeId: '',
                    attributeDescription: '',
                    newAttribute: false,
                    newAttributeDescription: '',
                    attributeValues: [],
                  },
                ])
              }}
            >
              <Plus size={18} /> Add Attribute
            </Button>
          </Col>
        </Row>
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {product && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setDeleteProductId(product.id)
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading message="Saving Product..." />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default ProductForm
