import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Collapse, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { formatDateTimeToString } from '../../libs/utils'
import NotificationTableSearch from './NotificationTableSearch'
import { Binoculars } from 'react-bootstrap-icons'
import NotificationModal from './NotificationModal'
import Loading from '../common/Loading'

const NotificationsTable = (props) => {
  const {
    notificationsLoading,
    setNotificationsLoading,
    stripeInvoice,
    employee,
    user,
    business,
  } = props

  const [searchText, setSearchText] = useState('Search')
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [showNotification, setShowNotification] = useState()
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [filteredUsers, setFilteredUsers] = useState()
  const [componentMounted, setComponentMounted] = useState()
  const [notifications, setNotifications] = useState([])
  const [filteredJobs, setFilteredJobs] = useState()
  const [filteredEmployees, setFilteredEmployees] = useState()
  const [filteredOrganizations, setFilteredOrganizations] = useState()
  const [filteredSubjectGroups, setFilteredSubjectGroups] = useState()
  const [filteredSubjects, setFilteredSubjects] = useState()
  const [appliedSearchFilters, setAppliedSearchFilters] = useState({})
  const [jobDetailId, setJobDetailId] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [showSessionDetailModal, setShowSessionDetailModal] = useState()
  const [sessionDetailId, setSessionDetailId] = useState()
  const history = useHistory()
  const tableColumns = [
    {
      Header: 'To',
      accessor: (row) => {
        if (row.sendToRedirect) {
          return (
            <div
              className="sessions-buttons btn-link"
              onClick={() => {
                if (row.sendToRedirect.nodeType === 'employee') {
                  history.push(`/employee/${row.sendToRedirect.nodeId}`)
                } else if (row.sendToRedirect.nodeType === 'subject') {
                  history.push(`/subject/${row.sendToRedirect.nodeId}`)
                }
              }}
            >
              {row.sentTo}
            </div>
          )
        } else {
          return row.sentTo
        }
      },
    },
    {
      Header: 'Type',
      accessor: 'notificationTemplate',
    },
    {
      Header: 'On',
      accessor: 'on',
    },
    {
      Header: 'Business',
      accessor: (row) => {
        if (row.node.business) {
          return (
            <div
              className="sessions-buttons btn-link"
              onClick={() => {
                history.push(`/business/${row.node.business.id}`)
              }}
            >
              {row.node.business.name}
            </div>
          )
        }
      },
    },
    {
      Header: 'Deal',
      accessor: (row) => {
        if (row.node.deal) {
          return (
            <div
              className="sessions-buttons btn-link"
              onClick={() => {
                history.push(`/deal/${row.node.deal.id}`)
              }}
            >
              {row.node.deal.dealNumber}
            </div>
          )
        }
      },
    },
    {
      Header: 'View',
      id: 'content',
      accessor: (row) => {
        if (row.node.content) {
          return (
            <Button
              variant="link"
              onClick={() => {
                setShowNotificationModal(true)
                setShowNotification(row)
              }}
            >
              <Binoculars />
            </Button>
          )
        }
      },
    },
  ]

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query NotificationsQuery(
        $cursor: String
        $stripeInvoice: [ID]
        $users: [ID]
        $business: [ID]
        $employees: [ID]
        $startDateGte: DateTime
        $startDateLte: DateTime
      ) {
        notifications(
          orderBy: "-created"
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          employee: $employees
          business: $business
          stripeInvoice: $stripeInvoice
          user: $users
          first: 40
          after: $cursor
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              business {
                id
                name
              }
              deal {
                id
                dealNumber
              }
              user {
                fullName
                email
                dummyUsername
                employee {
                  id
                }
              }
              channel
              created
              content
              notificationTemplate {
                name
              }
              smtpConnector {
                id
              }
              twilioConnector {
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        if (notificationsLoading) {
          setNotificationsLoading(false)
        }
      },
    }
  )

  const closeNotificationModal = () => {
    setShowNotification()
    setShowNotificationModal(false)
  }

  useEffect(() => {
    if (queryData?.notifications) {
      let text = 'Search 0 records'
      if (queryData?.notifications.nodeCount > 0) {
        text = `Search ${queryData?.notifications.nodeCount} records`
      }
      setSearchText(text)
      const currentNotifications = queryData.notifications.edges.map(
        (notification) => {
          const notificationNode = notification.node
          let subjectGroupId
          let subjectGroup
          let organization
          let organizationId
          if (notificationNode.subjectGroup) {
            subjectGroupId = notificationNode.subjectGroup.id
            subjectGroup = notificationNode.subjectGroup.name
            organization = notificationNode.subjectGroup?.organization?.name
            organizationId = notificationNode.subjectGroup?.organization?.id
          }
          let jobId
          let job
          if (notificationNode.job) {
            jobId = notificationNode.job.id
            job = notificationNode.job.name
          }
          let sentTo
          let sendToRedirect
          const userNode = notificationNode.user
          if (userNode?.employee) {
            sentTo = `Employee ${userNode?.fullName}`
            sendToRedirect = {
              nodeType: 'employee',
              nodeId: userNode?.employee?.id,
            }
          } else if (userNode?.subject) {
            if (userNode?.fullName) {
              sentTo = userNode?.fullName
            } else if (userNode.email && !userNode.dummyEmail) {
              sentTo = userNode.email
            }
            sendToRedirect = {
              nodeType: 'subject',
              nodeId: userNode?.subject?.id,
            }
          } else if (notificationNode.adHocContact) {
            sentTo = `${notificationNode.adHocContact.firstName} ${notificationNode.adHocContact.lastName}`
          } else {
            sentTo = userNode?.fullName
          }
          let sessionId
          if (notificationNode?.session) {
            sessionId = notificationNode.session.id
          }
          let subject
          if (notificationNode?.subject) {
            subject = notificationNode.subject?.user?.fullName
          }
          return {
            notificationTemplate: notificationNode.notificationTemplate.name,
            on: formatDateTimeToString(new Date(notificationNode.created)),
            sentTo,
            sessionId,
            sendToRedirect,
            organization,
            organizationId,
            subjectGroup,
            subject,
            subjectGroupId,
            jobId,
            job,
            channel: notificationNode.channel,
            content: notificationNode.content,
            node: notificationNode,
          }
        }
      )
      setNotifications(currentNotifications)
    }
  }, [queryData])

  useEffect(() => {
    if (componentMounted) {
      const currentFilteredJobs = []
      const currentFilteredEmployees = []
      const currentFilteredSubjectGroups = []
      const currentFilteredOrganizations = []
      const currentFilteredSubjects = []
      const currentFilteredUsers = []
      Object.entries(appliedSearchFilters).forEach((appliedSearchFilter) => {
        const appliedSearchFilterEntries = appliedSearchFilter[1]
        if (appliedSearchFilterEntries.nodeType === 'Job') {
          currentFilteredJobs.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'Employee') {
          currentFilteredEmployees.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'SubjectGroup') {
          currentFilteredSubjectGroups.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'Organization') {
          currentFilteredOrganizations.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'Subject') {
          currentFilteredSubjects.push(appliedSearchFilterEntries.id)
        }
      })
      if (employee) {
        currentFilteredEmployees.push(employee.id)
      }
      if (user) {
        currentFilteredUsers.push(user.id)
      }
      let variables = {}
      if (currentFilteredJobs.length) {
        variables.jobs = currentFilteredJobs
      }
      if (stripeInvoice) {
        variables.stripeInvoice = [stripeInvoice]
      }
      if (currentFilteredEmployees.length) {
        variables.employees = currentFilteredEmployees
      }
      if (currentFilteredSubjectGroups.length) {
        variables.subjectGroups = currentFilteredSubjectGroups
      }
      if (currentFilteredOrganizations.length) {
        variables.organizations = currentFilteredOrganizations
      }
      if (currentFilteredSubjects.length) {
        variables.subjects = currentFilteredSubjects
      }
      if (currentFilteredUsers.length) {
        variables.users = currentFilteredUsers
      }
      if (startDateFilter) {
        variables.startDateGte = startDateFilter
      }
      if (endDateFilter) {
        variables.startDateLte = endDateFilter
      }
      query({ variables })
      setFilteredJobs(currentFilteredJobs)
      setFilteredEmployees(currentFilteredEmployees)
      setFilteredSubjectGroups(currentFilteredSubjectGroups)
      setFilteredOrganizations(currentFilteredOrganizations)
      setFilteredSubjects(currentFilteredSubjects)
      setFilteredUsers(currentFilteredUsers)
    }
  }, [appliedSearchFilters, componentMounted, startDateFilter, endDateFilter])

  const fetchMoreNotifications = () => {
    let variables = {}
    if (filteredJobs.length) {
      variables.jobs = filteredJobs
    }
    if (filteredEmployees.length) {
      variables.employees = filteredEmployees
    }
    if (filteredSubjectGroups.length) {
      variables.subjectGroups = filteredSubjectGroups
    }
    if (filteredOrganizations.length) {
      variables.organizations = filteredOrganizations
    }
    if (filteredSubjects.length) {
      variables.subjects = filteredSubjects
    }
    if (filteredUsers.length) {
      variables.users = filteredUsers
    }
    if (stripeInvoice) {
      variables.stripeInvoice = [stripeInvoice]
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    variables.cursor = queryData.notifications.pageInfo.endCursor
    queryFetchMore({ variables })
  }

  useEffect(() => {
    if (!componentMounted) {
      const currentFilteredJobs = []
      const currentFilteredEmployees = []
      const currentFilteredSubjectGroups = []
      const currentFilteredOrganizations = []
      const currentFilteredSubjects = []
      const currentFilteredUsers = []
      if (user) {
        currentFilteredUsers.push(user.id)
      }
      if (employee) {
        currentFilteredEmployees.push(employee.id)
      }
      let variables = {}
      if (currentFilteredJobs.length) {
        variables.jobs = currentFilteredJobs
      }
      if (currentFilteredEmployees.length) {
        variables.employees = currentFilteredEmployees
      }
      if (stripeInvoice) {
        variables.stripeInvoice = [stripeInvoice]
      }
      if (currentFilteredSubjectGroups.length) {
        variables.subjectGroups = currentFilteredSubjectGroups
      }
      if (currentFilteredOrganizations.length) {
        variables.organizations = currentFilteredOrganizations
      }
      if (currentFilteredUsers.length) {
        variables.users = currentFilteredUsers
      }
      query(variables)
      setFilteredJobs(currentFilteredJobs)
      setFilteredEmployees(currentFilteredEmployees)
      setFilteredSubjectGroups(currentFilteredSubjectGroups)
      setFilteredOrganizations(currentFilteredOrganizations)
      setFilteredSubjects(currentFilteredSubjects)
      setFilteredUsers(currentFilteredUsers)
      setComponentMounted(true)
    }
  }, [componentMounted])

  if (!componentMounted) return <></>
  if (queryError) return <>Error loading notifications</>
  return (
    <>
      <div className="notifications mt-3">
        <Collapse in={true}>
          <div id="notificationsCollapse">
            {(notifications?.length > 0 ||
              history.location.pathname === '/notifications') && (
              <div className="mb-3">
                <NotificationTableSearch
                  searchText={searchText}
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  appliedSearchFilters={appliedSearchFilters}
                  setAppliedSearchFilters={setAppliedSearchFilters}
                />
              </div>
            )}

            <Row className="mb-3">
              <Col>
                {!queryData && <Loading message="Loading Notifications..." />}
                {queryData && (
                  <SortableInfiniteTable
                    tableData={notifications}
                    tableColumns={tableColumns}
                    fetchMoreTableData={fetchMoreNotifications}
                    hasMoreTableData={
                      queryData?.notifications?.pageInfo?.hasNextPage
                    }
                    tableHeight={400}
                    rowPointer
                    hideGlobalFilter
                    loadingMessage={'Loading Notifications...'}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Collapse>
        <NotificationModal
          notification={showNotification}
          showModal={showNotificationModal}
          toggleModal={closeNotificationModal}
        />
      </div>
    </>
  )
}

export default NotificationsTable
