import React, { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { Folder } from 'react-bootstrap-icons'

const EditIntercom = (props) => {
  const { showModal, toggleModal, intercomAppId, settingsId } = props

  const [update] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Intercom Saved')
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['PublicSettingsQuery', 'SettingsQuery'],
    }
  )

  useEffect(() => {
    if (showModal && intercomAppId) {
      formik.setValues({
        intercomAppId,
      })
    }
  }, [showModal, intercomAppId])

  const formik = useFormik({
    initialValues: {
      intercomAppId: '',
    },
    validationSchema: Yup.object().shape({
      intercomAppId: Yup.string().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      update({
        variables: {
          updateSettingsInput: {
            settingsInput: {
              id: settingsId,
              intercomAppId: values.intercomAppId,
            },
          },
        },
      })
    },
  })

  return (
    <>
      <div className="newSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/intercom.png'}
                  style={{ height: '25px' }}
                  alt="Organization logo"
                />
                Configure Intercom
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="App Id"
                    name="intercomAppId"
                    value={formik.values.intercomAppId}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.intercomAppId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.intercomAppId}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row>
                <Col md={4}>
                  <Button
                    type="submit"
                    size="sm"
                    balock
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <Folder className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditIntercom
