import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table'
import 'bootstrap/dist/css/bootstrap.min.css'
import { settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import './SortableTable.css'

const SortableTable = (props) => {
  const {
    data,
    columns,
    onRowClick,
    tdStyle,
    trStyle,
    thStyle,
    onTdClicks,
    tableSize,
    displayHeader,
    rowPointer,
    trStyleGenerator,
    tdStyleGenerator,
    setPreGlobalFilteredRows,
    setTableState,
    setSetGlobalFilter,
    hideInfinteGlobalFilter,
    searchWidth,
    tableHeight,
    linkTds,
    discludeTrHover,
  } = props

  const settings = useReactiveVar(settingsVar)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  let rowStyle = {}
  if (trStyle) {
    rowStyle = trStyle
  } else {
    rowStyle = {
      margin: 0,
      padding: 0,
      fontSize: '14px',
    }
  }
  let headerStyle = {}
  if (thStyle) {
    headerStyle = thStyle
  } else {
    headerStyle = { margin: 0, fontSize: '14px' }
  }

  if (rowPointer) {
    rowStyle.cursor = 'pointer'
  }

  let sWidth = 6
  if (searchWidth) {
    sWidth = searchWidth
  }

  useEffect(() => {
    if (hideInfinteGlobalFilter === false) {
      setPreGlobalFilteredRows(preGlobalFilteredRows)
      setTableState(state)
      setSetGlobalFilter({
        fn: setGlobalFilter,
      })
    }
  }, [hideInfinteGlobalFilter])

  const constructRow = (row) => {
    prepareRow(row)
    const { key, ...restRowProps } = row.getRowProps()
    if (trStyleGenerator) {
      rowStyle = trStyleGenerator(row)
    }
    return (
      <tr
        key={key}
        id={`tableRow${row.id}`}
        style={rowStyle}
        {...restRowProps}
        onMouseDown={() => {
          if (onRowClick) {
            onRowClick(row)
          }
        }}
        className={
          discludeTrHover ? 'table-row' : 'table-row sortable-table-row-hover'
        }
      >
        {row.cells.map((cell) => {
          const { key, ...restCellProps } = cell.getCellProps()
          let tdStyle_ = tdStyle
          if (tdStyleGenerator) {
            tdStyle_ = tdStyleGenerator(cell)
          }
          return (
            <td
              key={key}
              style={tdStyle_}
              {...restCellProps}
              onMouseDown={() => {
                if (
                  onTdClicks &&
                  Object.keys(onTdClicks).includes(cell.column.id)
                ) {
                  return onTdClicks[cell.column.id](cell)
                }
              }}
              className="align-middle"
            >
              <div className="sortable-table">
                {linkTds && (
                  <div className="sessions-buttons btn-link">
                    {cell.render('Cell')}
                  </div>
                )}
                {!linkTds && <>{cell.render('Cell')}</>}
              </div>
            </td>
          )
        })}
      </tr>
    )
  }

  let style = {
    fontWeight: 'thin',
  }
  if (tableHeight) {
    style = {
      ...style,
      maxHeight: `${tableHeight}px`,
    }
  }
  if (!settings) return <></>
  return (
    <>
      <Table
        className="table-bordered"
        style={style}
        size={tableSize ? tableSize : 'sm'}
        responsive
        {...getTableProps()}
      >
        {displayHeader !== false && (
          <thead
            style={{
              backgroundColor: settings.colorScheme,
            }}
          >
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => {
                  if (column.hideHeader) {
                    return null
                  }
                  return (
                    <th
                      key={index}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="align-middle"
                      style={{ ...{ cursor: 'pointer' }, ...headerStyle }}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDown className="ml-2 sort-icon" />
                          ) : (
                            <ArrowUp className="ml-2 sort-icon" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            return constructRow(row)
          })}
        </tbody>
      </Table>
    </>
  )
}

export default SortableTable
