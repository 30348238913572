import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { Table, Trash } from 'react-bootstrap-icons'
import ModelOutputFormatModal from './ModelOutputFormatModal'
import Loading from '../../common/Loading'
import { loggedInUserVar } from '../../../libs/apollo'
import { useHistory } from 'react-router-dom'
import DeleteModelOutputFormatModal from './DeleteModelOutputFormatModal'

const ModelOutputFormats = (props) => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [editNode, setEditNode] = useState()
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const history = useHistory()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query OpenAiModelOutputFormats($cursor: String) {
        openAiModelOutputFormats(first: 20, after: $cursor) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              format
              contentType {
                model
              }
              openAiModels {
                nodeCount
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.openAiModelOutputFormats?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.openAiModelOutputFormats) {
      let text = 'Search 0 records'
      if (queryData.openAiModelOutputFormats.nodeCount > 0) {
        text = `Search ${queryData.openAiModelOutputFormats.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.openAiModelOutputFormats?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Model Output Formats..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>

  return (
    <>
      <Row style={{ marginTop: '-15px' }}>
        <Col>
          {canMutate && (
            <Button
              variant="link"
              onClick={() => {
                setShowForm(true)
              }}
            >
              <Table className="mr-2" />
              New Model Output Format
            </Button>
          )}
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedNodeIds.length === 1 ? (
                    <>Delete Model Output Format</>
                  ) : (
                    <>Delete Model Output Formats</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.openAiModelOutputFormats?.edges
                ? queryData?.openAiModelOutputFormats?.edges
                : []
            }
            loadingMessage="Loading Model Output Formats..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={
              queryData?.openAiModelOutputFormats?.pageInfo?.hasNextPage
            }
            onTdClicks={{
              name: onTdClick,
            }}
            tableHeight={800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      {showForm && (
        <ModelOutputFormatModal
          showModal={showForm}
          modelOutputFormat={editNode}
          toggleModal={toggleModal}
        />
      )}
      {showDeleteModal && (
        <DeleteModelOutputFormatModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedNodeIds={setCheckedNodeIds}
          ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
          id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
        />
      )}
    </>
  )
}
export default ModelOutputFormats
