import React, { useState } from 'react'
import { Row, Col, Button, Badge } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import { Gear, CaretDown, CaretRight } from 'react-bootstrap-icons'
import EditTwilioConnector from './EditTwilioConnector'

const TwilioConnector = () => {
  const { data } = useQuery(
    gql`
      query TwilioConnector {
        twilioConnectors {
          edges {
            node {
              customValid
              customAccountSid
              customAuthToken
              customSender
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [showEditModal, setShowEditModal] = useState(false)
  const [display, setDisplay] = useState(false)

  const toggleNewModal = () => {
    setShowEditModal((prevState) => !prevState)
  }
  if (!data) return <></>
  const twilio = data.twilioConnectors.edges[0].node
  return (
    <>
      <div className="studioInfo">
        <Row className="mb-1 mt-4">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplay(!display)}
              className="px-0 btn-link mr-2"
            >
              <>
                {display ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/twilio-logo.png'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
              Twilio
            </h4>
          </Col>
        </Row>
        {display && (
          <>
            <Row className="mt-2">
              <Col>
                {!twilio.customValid && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Disabled
                  </Badge>
                )}
                {(twilio.customAccountSid ||
                  twilio.customAuthToken ||
                  twilio.customSender) &&
                  twilio.customValid && (
                    <Badge
                      className="ml-2"
                      style={{ fontSize: '12px' }}
                      variant="success"
                    >
                      Enabled
                    </Badge>
                  )}
                {(twilio.customAccountSid ||
                  twilio.customAuthToken ||
                  twilio.customSender) &&
                  twilio.customValid === false && (
                    <Badge
                      className="ml-2"
                      style={{ fontSize: '12px' }}
                      variant="danger"
                    >
                      Invalid Twilio Credentials
                    </Badge>
                  )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div>
                  <Button variant="link" onClick={toggleNewModal}>
                    <Gear className="mr-2" />
                    Configure Twilio
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        <EditTwilioConnector
          twilio={twilio}
          showModal={showEditModal}
          toggleModal={toggleNewModal}
        />
      </div>
    </>
  )
}

export default TwilioConnector
