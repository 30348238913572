import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import { CardChecklist, Trash, Dot } from 'react-bootstrap-icons'
import TaskModal from './TaskModal'
import Loading from '../../common/Loading'
// import DeleteBusinessModal from './DeleteBusinessModal'
import { loggedInUserVar } from '../../../libs/apollo'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'

const Tasks = (props) => {
  const { dealId, businessId, productId, tableHeight } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [editNode, setEditNode] = useState()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedNodeIds, setCheckedNodeIds] = useState([])

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Tasks(
        $cursor: String
        $deal: String
        $business: String
        $product: String
        $search: String
      ) {
        tasks(
          first: 20
          after: $cursor
          deal: $deal
          business: $business
          product: $product
          search: $search
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              order
              contentType {
                model
                id
              }
              removable
              employees {
                edges {
                  node {
                    id
                    user {
                      fullName
                    }
                  }
                }
              }
              clients {
                edges {
                  node {
                    user {
                      fullName
                    }
                  }
                }
              }
              dueDate
              complete
              taskGroup {
                id
                name
                deal {
                  dealNumber
                }
                cargoUnitInstance {
                  loadId
                }
                dealItem {
                  deal {
                    dealNumber
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.tasks?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    if (dealId) {
      variables.deal = dealId
    }
    if (businessId) {
      variables.business = businessId
    }
    if (productId) {
      variables.product = productId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.tasks) {
      let text = 'Search 0 records'
      if (queryData.tasks.nodeCount > 0) {
        text = `Search ${queryData.tasks.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Task Group',
      id: 'taskGroup',
      accessor: 'node.taskGroup.name',
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
    },
    {
      Header: 'Fulfillment',
      id: 'cargo',
      accessor: (row) => {
        if (row.node.taskGroup.cargoUnitInstance) {
          return row.node.taskGroup.cargoUnitInstance.loadId
        }
      },
    },
    {
      Header: 'Complete',
      id: 'complete',
      accessor: (row) => {
        return row.node.complete ? 'Yes' : 'No'
      },
    },
    {
      Header: 'Due',
      id: 'due',
      accessor: (row) => {
        if (row.node.dueDate) {
          return row.node.dueDate
        }
      },
    },
    {
      Header: 'Assignees',
      id: 'assignees',
      accessor: (row) => {
        return (
          <>
            {row.node.employees.edges.map((edge, index) => (
              <span key={index}>
                <Dot className="mr-2" />
                {edge.node.user.fullName}
                <br />
              </span>
            ))}
          </>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.tasks?.edges.forEach((edge) => {
                    if (
                      !checkedNodeIds.includes(edge.node.id) &&
                      edge.node.removable
                    ) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            {row.node.removable && (
              <Form.Group as={ButtonGroup} className="align-items-center">
                <Form.Check
                  className="ml-2 mt-2"
                  type="checkbox"
                  checked={checkedNodeIds.includes(row.node.id)}
                  onChange={(e) => handleNodeCheck(e, row)}
                />
              </Form.Group>
            )}
          </>
        )
      },
    },
  ]
  if (!dealId) {
    tableColumns.splice(0, 0, {
      Header: 'Deal',
      id: 'deal',
      accessor: (row) => {
        if (row.node.taskGroup.deal) {
          return row.node.taskGroup.deal.dealNumber
        }
      },
    })
  } else {
    tableColumns.splice(0, 0, {
      Header: 'Order',
      id: 'order',
      accessor: 'node.order',
    })
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Tasks..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>
  return (
    <>
      <Row
        style={{
          marginTop: canMutate && !productId && !businessId ? '-10px' : '',
        }}
      >
        <Col>
          {canMutate && !productId && !businessId && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <CardChecklist className="mr-2" />
              New Task
            </Button>
          )}
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedNodeIds.length === 1 ? (
                    <>Delete Task</>
                  ) : (
                    <>Delete Tasks</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={queryData?.tasks?.edges ? queryData?.tasks?.edges : []}
            loadingMessage="Loading Tasks..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.tasks?.pageInfo?.hasNextPage}
            onTdClicks={{
              name: (cell) => onTdClick(cell),
            }}
            tableHeight={tableHeight ? tableHeight : 800}
            rowPointer
            hideGlobalFilter
            trStyleGenerator={(row) => {
              let style = {}
              if (row.original.node.completed) {
                style = {
                  cursor: 'pointer',
                  backgroundColor: 'rgba(0, 128, 0, 0.2)',
                }
              } else {
                style = {
                  cursor: 'pointer',
                  backgroundColor: 'rgba(255, 0, 0, 0.2)',
                }
              }
              return style
            }}
          />
        </Col>
      </Row>
      {showForm && (
        <TaskModal
          showModal={showForm}
          task={editNode}
          toggleModal={toggleModal}
          dealId={dealId}
        />
      )}

      {/* <DeleteBusinessModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedNodeIds={setCheckedNodeIds}
        ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
        id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
      /> */}
    </>
  )
}
export default Tasks
