import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { CarFront, Trash, Dot, Plus } from 'react-bootstrap-icons'
import ProductModal from './ProductModal'
import Loading from '../../common/Loading'
import { loggedInUserVar } from '../../../libs/apollo'
import { useHistory } from 'react-router-dom'
import DeleteProductModal from './DeleteProductsModal'

const Products = (props) => {
  const { dealItemForm, productPriceForm, formik, formikIndex } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const history = useHistory()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Products($cursor: String, $search: String) {
        products(first: 20, after: $cursor, search: $search) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              dealCount
              name
              contentType {
                model
              }
              attributes {
                id
              }
              attributeValues {
                attribute {
                  id
                  name
                }
                productAttributeValues {
                  id
                  value
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.products?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.products) {
      let text = 'Search 0 records'
      if (queryData.products.nodeCount > 0) {
        text = `Search ${queryData.products.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    if (!dealItemForm && !productPriceForm) {
      history.push(`/product/${cell.row.original.node.id}`)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
    },
    {
      Header: 'Attributes',
      id: 'attributes',
      accessor: (row) => {
        return (
          <>
            {row.node.attributeValues.map((attributeValue, index) => (
              <span key={index}>
                <Dot className="mr-2" />
                <span>{attributeValue.attribute.name} - </span>
                {attributeValue.productAttributeValues.map(
                  (attributeValueEdge, attributeValueIndex) => (
                    <small key={attributeValueIndex}>
                      {attributeValueEdge.value}{' '}
                      {attributeValueIndex <
                        attributeValue.productAttributeValues.length - 1 && (
                        <>|</>
                      )}{' '}
                    </small>
                  )
                )}
                <br />
              </span>
            ))}
          </>
        )
      },
    },
  ]

  if (dealItemForm || productPriceForm) {
    tableColumns.push({
      Header: '',
      id: 'addProduct',
      accessor: (row) => {
        return (
          <Button
            variant="link"
            onClick={() => {
              let formikFieldPrefix = ''
              if (dealItemForm) {
                formikFieldPrefix = `dealItems[${formikIndex}].`
              }
              formik.setFieldValue(`${formikFieldPrefix}productId`, row.node.id)
              formik.setFieldValue(
                `${formikFieldPrefix}productName`,
                row.node.name
              )
              formik.setFieldValue(
                `${formikFieldPrefix}attributeValues`,
                row.node.attributeValues
              )
              formik.setFieldValue(
                `${formikFieldPrefix}selectedProductAttributeValues`,
                row.node.attributes.map((_) => ({
                  id: null,
                }))
              )
            }}
          >
            <Plus className="mr-2" />
            Select Attributes
          </Button>
        )
      },
    })
  } else {
    tableColumns.push({
      Header: 'Deals',
      id: 'deals',
      accessor: 'node.dealCount',
    })
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.businesses?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Products..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>

  return (
    <>
      {!dealItemForm && !productPriceForm && (
        <>
          <Row style={{ marginTop: '-15px' }}>
            <Col>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowForm(true)
                  }}
                >
                  <CarFront className="mr-2" />
                  New Product
                </Button>
              )}
              {checkedNodeIds.length > 0 && (
                <>
                  {canMutate && (
                    <Button
                      variant="link"
                      onClick={() => {
                        setShowDeleteModal(true)
                      }}
                    >
                      <Trash className="mr-2" />
                      {checkedNodeIds.length === 1 ? (
                        <>Delete Product</>
                      ) : (
                        <>Delete Products</>
                      )}
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.products?.edges ? queryData?.products?.edges : []
            }
            loadingMessage="Loading Products..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.products?.pageInfo?.hasNextPage}
            discludeTrHover={dealItemForm || productPriceForm}
            onTdClicks={{
              name: onTdClick,
              attributes: onTdClick,
              deals: onTdClick,
            }}
            tableHeight={dealItemForm || productPriceForm ? 250 : 800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      {showForm && (
        <ProductModal showModal={showForm} toggleModal={setShowForm} />
      )}
      {showDeleteModal && (
        <DeleteProductModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedNodeIds={setCheckedNodeIds}
          ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
          id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
        />
      )}
    </>
  )
}
export default Products
