import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Trash, ArrowCounterclockwise, Plus } from 'react-bootstrap-icons'
import AttributeSearchInput from '../../common/node_search_input/AttributeSearchInput'

const Attribute = (props) => {
  const { formik, index } = props
  return (
    <>
      <div
        className={
          formik.values.attributes.length > 1
            ? 'border border-secondary bg-white rounded my-3 px-3 pb-3'
            : ''
        }
      >
        <Form.Row className={formik.values.attributes.length > 1 ? 'mt-3' : ''}>
          <Col sm="12" md={4}>
            <Form.Label>
              Attribute
              <Form.Check
                inline
                name={`attributes[${index}].newAttribute`}
                className="position-absolute small ms-2"
                label="new attribute"
                checked={formik.values.attributes[index].newAttribute}
                onChange={(e) => {
                  if (formik.values.attributes[index].newAttribute) {
                    formik.setFieldValue(
                      `attributes[${index}].newAttribute`,
                      false
                    )
                    formik.setFieldValue(
                      `attributes[${index}].newAttributeDescription`,
                      ''
                    )
                  } else {
                    formik.setFieldValue(
                      `attributes[${index}].newAttribute`,
                      true
                    )
                  }
                }}
              />
            </Form.Label>
            {!formik.values.attributes[index].newAttribute && (
              <AttributeSearchInput
                formik={formik}
                formikValue={`attributes[${index}].attribute`}
                error={
                  index < formik.errors.attributes?.length &&
                  formik.errors.attributes[index]?.attributeId
                }
              />
            )}
            {formik.values.attributes[index].newAttribute && (
              <>
                <Form.Control
                  type="text"
                  name={`attributes[${index}].newAttributeDescription`}
                  placeholder="new attribute"
                  className="form-control-sm"
                  value={
                    formik.values.attributes[index].newAttributeDescription
                  }
                  onChange={formik.handleChange}
                  isInvalid={
                    index < formik.errors.attributes?.length &&
                    formik.errors.attributes[index]?.newAttributeDescription
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {index < formik.errors.attributes?.length &&
                    formik.errors.attributes[index]
                      ?.newAttributeDescription && (
                      <>
                        {
                          formik.errors.attributes[index]
                            ?.newAttributeDescription
                        }
                      </>
                    )}
                </Form.Control.Feedback>
              </>
            )}
          </Col>
        </Form.Row>
        <Form.Row>
          {formik.values.attributes[index].attributeValues.map(
            (_, attributeValueIndex) => (
              <Col md={3} key={attributeValueIndex} className="mt-3">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <small
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      const newAttributeValues = [
                        ...formik.values.attributes[index].attributeValues,
                      ]
                      newAttributeValues.splice(attributeValueIndex, 1)
                      formik.setFieldValue(
                        `attributes[${index}].attributeValues`,
                        newAttributeValues
                      )
                    }}
                  >
                    <Trash className="mr-1" />
                  </small>
                  <Form.Control
                    type="text"
                    name={`attributes[${index}].attributeValues[${attributeValueIndex}].value`}
                    className="form-control-sm"
                    placeholder="value"
                    value={
                      formik.values.attributes[index].attributeValues[
                        attributeValueIndex
                      ].value
                    }
                    onChange={formik.handleChange}
                    isInvalid={
                      index < formik.errors.attributes?.length &&
                      attributeValueIndex <
                        formik.errors.attributes[index].attributeValues
                          ?.length &&
                      formik.errors.attributes[index].attributeValues[
                        attributeValueIndex
                      ].value
                    }
                  />
                </div>
              </Col>
            )
          )}
        </Form.Row>

        <Form.Row className="mt-3">
          <Col>
            <div>
              <p size="sm">
                <small
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => {
                    const newAttribtues = [...formik.values.attributes]
                    newAttribtues.splice(index, 1)
                    formik.setFieldValue('attributes', newAttribtues)
                  }}
                >
                  <Trash className="mr-1" /> Remove Attribute
                </small>
                <small
                  style={{ color: 'rgb(13, 110, 253)', cursor: 'pointer' }}
                  className="ml-2"
                  onClick={() => {
                    formik.setFieldValue(
                      `attributes[${index}].attributeValues`,
                      [
                        ...formik.values.attributes[index].attributeValues,
                        {
                          value: '',
                        },
                      ]
                    )
                  }}
                >
                  <Plus size={18} /> Add Attribute Value
                </small>
              </p>
            </div>
          </Col>
        </Form.Row>
      </div>
    </>
  )
}

export default Attribute
