import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { Envelope, Trash, People } from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import ClientModal from './ClientModal'
import Loading from '../common/Loading'
import DeleteClientModal from './DeleteClientModal'
import { loggedInUserVar } from '../../libs/apollo'

const Clients = (props) => {
  const { businessId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editNode, setEditNode] = useState()
  const [checkedNodeIds, setCheckedNodeIds] = useState([])

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Clients($cursor: String, $business: ID) {
        clients(first: 20, after: $cursor, business: $business) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              receiveInvoiceEmails
              user {
                id
                fullName
                firstName
                lastName
                email
                secondaryEmail
                phoneNumber
                secondaryPhoneNumber
                lastLogin
                isActive
                emailNotificationsEnabled
                smsNotificationsEnabled
                contentType {
                  model
                  id
                }
              }
              business {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.clients?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (businessId) {
      variables.business = businessId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.clients) {
      let text = 'Search 0 records'
      if (queryData.clients.nodeCount > 0) {
        text = `Search ${queryData.clients.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.searchTerm = currentSearchTerm
    } else {
      variables.searchTerm = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.user.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.user.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Business',
      id: 'business',
      accessor: (row) => {
        return row.node.business.name
      },
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => {
        return row.node.user.fullName
      },
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: (row) => {
        return row.node.user.email
      },
    },
    {
      Header: 'Last Login',
      id: 'lastLogin',
      accessor: (row) => {
        if (row.node.user.lastLogin) {
          return DateTime.fromISO(row.node.user.lastLogin).toFormat(
            'MMMM d yyyy h:mm a'
          )
        }
      },
    },
    {
      Header: 'Send Email',
      accessor: (row) => {
        let email
        if (row.node.user.email) {
          email = row.node.user.email
        }
        if (row.node.user.secondaryEmail) {
          if (!email) {
            email = row.node.user.secondaryEmail
          } else {
            email = `${email}?cc=${row.node.user.secondaryEmail}`
          }
        }
        let sendEmail
        if (email) {
          sendEmail = (
            <>
              <a href={`mailto:${email}`}>
                <Envelope />
              </a>
            </>
          )
        }
        return sendEmail
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.clients?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.user.id)) {
                      appendIds.push(edge.node.user.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.user.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  if (queryError) return <>Error loading clients</>
  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Clients..." />
        </Col>
      </Row>
    )

  return (
    <>
      {!businessId && (
        <Row className="mb-2 mt-3">
          <Col>
            <div className="d-flex align-items-center">
              <h1 className="mb-0 d-inline">Clients</h1>
            </div>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: businessId ? '-10px' : '-15px' }}>
        <Col>
          {canMutate && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <People className="mr-2" />
              New Client
            </Button>
          )}
          {checkedNodeIds.length > 0 && canMutate && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedNodeIds.length === 1 ? (
                    <>Delete Client</>
                  ) : (
                    <>Delete Clients</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.clients?.edges ? queryData?.clients?.edges : []
            }
            loadingMessage="Loading Clients..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.clients?.pageInfo?.hasNextPage}
            onTdClicks={{
              business: (cell) => onTdClick(cell),
              name: (cell) => onTdClick(cell),
              email: (cell) => onTdClick(cell),
              lastLogin: (cell) => onTdClick(cell),
            }}
            tableHeight={800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      {showForm && (
        <ClientModal
          showModal={showForm}
          client={editNode}
          toggleModal={toggleModal}
          businessId={businessId}
        />
      )}
      <DeleteClientModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedNodeIds={setCheckedNodeIds}
        ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
        id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
      />
    </>
  )
}
export default Clients
