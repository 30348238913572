import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col, Tab } from 'react-bootstrap'
import Tabs from '../../common/Tabs'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Plus,
  ArrowUpRightCircle,
  Trash,
  Folder,
  InfoCircle,
  CashCoin,
  Receipt,
  Bell,
  ClockHistory,
  FileEarmarkText,
} from 'react-bootstrap-icons'
import BusinessSearchInput from '../../common/node_search_input/BusinessSearchInput'
import Loading from '../../common/Loading'
import PurchaseOrderItem from './PurchaseOrderItem'
import { formatCurrency } from '../../../libs/utils'
import AuditLog from '../../audit_log/AuditLog'
import NotificationsTable from '../../notification/NotificationsTable'
import Invoices from '../invoice/Invoices'
import Payments from '../payment/Payments'
import { FilePond } from 'react-filepond'
import Files from '../../files/Files'

const PurchaseOrderForm = (props) => {
  const { purchaseOrder, afterSubmit, showModal, deal } = props
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [activeTab, setActiveTab] = useState('Files')
  const history = useHistory()
  const TERMS = [
    {
      value: 'NET_5_WEEK_ENDING_DELIVERY',
      description: 'Net 5 Week Ending Delivery',
    },
    {
      value: 'NET_10_WEEK_ENDING_DELIVERY',
      description: 'Net 10 Week Ending Delivery',
    },
    { value: 'NET_5', description: 'Net 5' },
    { value: 'NET_10', description: 'Net 10' },
  ]

  const [deletePurchaseOrder] = useMutation(
    gql`
      mutation DeletePurchaseOrder($input: DeletePurchaseOrderInput!) {
        deletePurchaseOrder(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Purchase Order Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['PurchaseOrders'],
    }
  )

  const [createPurchaseOrder] = useMutation(
    gql`
      mutation CreatePuchaseOrder($input: CreatePurchaseOrderInput!) {
        createPurchaseOrder(input: $input) {
          purchaseOrder {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Purchase Order Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['PurchaseOrders'],
    }
  )

  const [updatePurchaseOrder] = useMutation(
    gql`
      mutation UpdatePurchaseOrder($input: UpdatePurchaseOrderInput!) {
        updatePurchaseOrder(input: $input) {
          purchaseOrder {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Purchase Order Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['PurchaseOrders'],
    }
  )

  let initialValues = {
    supplierBusinessId: '',
    supplierId: '',
    supplierBusinessDescription: '',
    number: '',
    total: 0,
    fileSupplierVisibility: true,
    fileBuyerVisibility: true,
    deletePreviousFiles: true,
    netTerms: null,
    termsFee: null,
    termsOn: false,
    purchaseOrderItems: [],
  }
  if (purchaseOrder) {
    initialValues = {
      supplierBusinessId: purchaseOrder.supplier.business.id,
      supplierId: purchaseOrder.supplier.id,
      supplierBusinessDescription: purchaseOrder.supplier.business.name,
      number: purchaseOrder.number,
      total: 0,
      netTerms: purchaseOrder.netTerms,
      fileSupplierVisibility: true,
      fileBuyerVisibility: true,
      deletePreviousFiles: true,
      termsFee: purchaseOrder.termsFee,
      termsOn: purchaseOrder.termsOn,
      purchaseOrderItems: purchaseOrder.purchaseOrderItems.edges.map((edge) => {
        const purchaseOrderItem = edge.node
        const dealItem = purchaseOrderItem.dealItem
        const deal = dealItem.deal
        let dealDescription = deal.dealNumber
        if (deal.buyer) {
          dealDescription = `${dealDescription} | Buyer ${deal.buyer.business.name}`
        }
        if (dealItem.supplier) {
          dealDescription = `${dealDescription} | Supplier ${dealItem.supplier.business.name}`
        }
        return {
          id: purchaseOrderItem.id,
          dealId: deal.id,
          dealItemId: dealItem.id,
          dealDescription: dealDescription,
          quantity: purchaseOrderItem.quantity,
          price: purchaseOrderItem.amount,
          unitOfMeasureId: purchaseOrderItem.unitOfMeasure.id,
          unitOfMeasureDescription: purchaseOrderItem.unitOfMeasure.pluralName,
        }
      }),
    }
  } else if (deal) {
    initialValues = {
      supplierBusinessId: deal ? deal.supplier?.business.id : '',
      supplierId: deal ? deal.supplier?.id : '',
      supplierBusinessDescription: deal ? deal.supplier?.business.name : '',
      number: '',
      total: 0,
      netTerms: null,
      fileSupplierVisibility: true,
      fileBuyerVisibility: true,
      deletePreviousFiles: true,
      termsFee: null,
      termsOn: false,
      purchaseOrderItems:
        deal.dealItems.edges.length > 0
          ? deal.dealItems.edges.map((edge) => {
              const dealItem = edge.node
              let dealDescription = deal.dealNumber
              if (deal.buyer) {
                dealDescription = `${dealDescription} | Buyer ${deal.buyer.business.name}`
              }
              if (dealItem.supplier) {
                dealDescription = `${dealDescription} | Supplier ${dealItem.supplier.business.name}`
              }
              return {
                dealId: deal.id,
                dealItemId: dealItem.id,
                dealDescription: dealDescription,
                quantity: dealItem.supplierQuantity,
                price: dealItem.supplierPrice,
                unitOfMeasureId: dealItem.supplierUnitOfMeasure
                  ? dealItem.supplierUnitOfMeasure.id
                  : '',
                unitOfMeasureDescription: dealItem.supplierUnitOfMeasure
                  ? dealItem.supplierUnitOfMeasure.pluralName
                  : '',
              }
            })
          : [],
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      supplierBusinessId: Yup.string().required('Required'),
      supplierId: Yup.string().required('Required'),
      fileSupplierVisibility: Yup.boolean().nullable(),
      fileBuyerVisibility: Yup.boolean().nullable(),
      deletePreviousFiles: Yup.boolean().nullable(),
      supplierBusinessDescription: Yup.string().required('Required'),
      number: Yup.string().required('Required'),
      netTerms: Yup.mixed().test('required', 'Required', (value, context) => {
        let valid = true
        if (context.parent.termsOn && (!value || value === '- - -')) {
          valid = false
        }
        return valid
      }),
      termsFee: Yup.mixed().test('required', 'Required', (value, context) => {
        let valid = true
        if (context.parent.termsOn && !value) {
          valid = false
        }
        return valid
      }),
      file: Yup.mixed().nullable(),
      termsOn: Yup.boolean().required('Required'),
      purchaseOrderItems: Yup.array().of(
        Yup.object().shape({
          dealId: Yup.string().required('Required'),
          dealItemId: Yup.string().required('Required'),
          dealDescription: Yup.string().required('Required'),
          quantity: Yup.mixed().required('Required'),
          price: Yup.mixed().required('Required'),
          unitOfMeasureId: Yup.string().required('Required'),
          unitOfMeasureDescription: Yup.string().required('Required'),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (formik.values.purchaseOrderItems.length === 0) {
        formik.setFieldError('missingPurchaseOrderItems', 'Required')
      } else {
        const variables = {
          supplierId: values.supplierId,
          number: values.number,
          netTerms: values.termsOn ? values.netTerms : null,
          termsFee: values.termsOn ? values.termsFee : null,
          termsOn: values.termsOn,
          purchaseOrderItems: values.purchaseOrderItems.map(
            (purchaseOrderItem) => ({
              id: purchaseOrderItem.id,
              dealItemId: purchaseOrderItem.dealItemId,
              quantity: purchaseOrderItem.quantity,
              amount: purchaseOrderItem.price,
              unitOfMeasureId: purchaseOrderItem.unitOfMeasureId,
            })
          ),
        }
        if (values.file) {
          variables.file = values.file[0].file
          variables.fileBuyerVisibility = values.fileBuyerVisibility
          variables.fileSupplierVisibility = values.fileSupplierVisibility
          if (purchaseOrder) {
            variables.deleteFiles = values.deletePreviousFile
          }
        }
        if (purchaseOrder) {
          variables.id = purchaseOrder.id
          updatePurchaseOrder({
            variables: {
              input: {
                purchaseOrderInput: variables,
              },
            },
          })
        } else {
          createPurchaseOrder({
            variables: {
              input: {
                purchaseOrderInput: variables,
              },
            },
          })
        }
      }
    },
  })

  useEffect(() => {
    if (
      !formik.values.supplierBusinessId &&
      formik.values.purchaseOrderItems.length > 0
    ) {
      formik.setFieldValue('purchaseOrderItems', [])
    }
  }, [formik.values.supplierBusinessId, formik.values.purchaseOrderItems])

  useEffect(() => {
    formik.setFieldValue(
      'total',
      formik.values.purchaseOrderItems.reduce((accumulator, item) => {
        return accumulator + item.quantity * item.price
      }, 0)
    )
  }, [formik.values.purchaseOrderItems])

  useEffect(() => {
    if (showModal === false) {
      formik.resetForm()
    }
  }, [showModal])

  const setAdditionalBusinessFields = (node, _) => {
    formik.setFieldValue('supplierId', node.supplier.id)
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={3}>
            <Form.Label>Purchase Order Number</Form.Label>
            <Form.Control
              as="input"
              name="number"
              className="form-control-sm"
              value={formik.values.number}
              onChange={formik.handleChange}
              isInvalid={formik.errors.number}
            />
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.number}
            </Form.Control.Feedback>
          </Col>
          <Col md={3}>
            <Form.Label>Total</Form.Label>
            <Form.Control
              as="input"
              className="form-control-sm"
              disabled={true}
              value={formatCurrency(formik.values.total)}
            />
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>
              Supplier
              {formik.values.supplierBusinessId && (
                <span
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 btn-link p-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(
                      `/business/${formik.values.supplierBusinessId}`
                    )
                  }}
                >
                  <ArrowUpRightCircle />
                </span>
              )}
            </Form.Label>
            <BusinessSearchInput
              formik={formik}
              suppliers
              disabled={deal}
              setAdditionalFields={setAdditionalBusinessFields}
              formikValue="supplierBusiness"
              searchDescription="suppliers"
              error={formik.errors.supplierId}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={12} md={2}>
            <Form.Label>Terms</Form.Label>
            <Form.Check
              label={formik.values.termsOn ? 'Yes' : 'No'}
              onChange={(e) => {
                formik.setFieldValue(`termsOn`, e.target.checked)
                if (!e.target.checked) {
                  formik.setFieldValue('netTerms', '- - -')
                }
              }}
              checked={formik.values.termsOn}
              isInvalid={formik.errors.termsOn}
            />
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.termsOn}
            </Form.Control.Feedback>
          </Col>
          {formik.values.termsOn && (
            <>
              <Col sm="12" md={4}>
                <Form.Label>Terms Fee</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  name={'termsFee'}
                  className="form-control-sm"
                  step="0.01"
                  min="1"
                  isInvalid={formik.errors.termsFee}
                  value={formik.values.termsFee}
                  onChange={formik.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formik?.errors?.termsFee}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Net Terms</Form.Label>
                <Form.Control
                  name="netTerms"
                  as="select"
                  className="form-control-sm"
                  value={formik.values.netTerms}
                  onChange={formik?.handleChange}
                  isInvalid={formik?.errors?.netTerms}
                >
                  <option value="- - -">- - -</option>
                  {TERMS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik?.errors?.netTerms}
                </Form.Control.Feedback>
              </Col>
            </>
          )}
        </Form.Group>
        {!purchaseOrder && (
          <>
            <Form.Group as={Row}>
              <Col>
                <FilePond
                  maxFileSize="100MB"
                  allowFileSizeValidation
                  files={formik.values.file}
                  maxFiles={1}
                  labelMaxFileSize="Maximum file size is {filesize}"
                  onupdatefiles={(file) => {
                    formik.setFieldValue('file', file)
                  }}
                  labelIdle='Drag and drop file or <span class="filepond--label-action">Browse</span>'
                />
                {formik.errors?.file && (
                  <div>
                    <small className="text-danger">{formik.errors.file}</small>
                  </div>
                )}
              </Col>
            </Form.Group>
            {formik.values.file?.length === 1 && (
              <Form.Group as={Row} style={{ marginTop: '-15px' }}>
                <Col md={4}>
                  <Form.Label>File Visible to Suppliers</Form.Label>
                  <Form.Check
                    label={formik.values.fileSupplierVisibility ? 'Yes' : 'No'}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `fileSupplierVisibility`,
                        e.target.checked
                      )
                    }}
                    checked={formik.values.fileSupplierVisibility}
                  />
                </Col>
                <Col md={4}>
                  <Form.Label>File Visible to Buyers</Form.Label>
                  <Form.Check
                    label={formik.values.fileBuyerVisibility ? 'Yes' : 'No'}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `fileBuyerVisibility`,
                        e.target.checked
                      )
                    }}
                    checked={formik.values.fileBuyerVisibility}
                  />
                </Col>
              </Form.Group>
            )}
          </>
        )}
        <div
          className={
            formik.values.purchaseOrderItems.length > 0
              ? 'bg-light border p-4'
              : 'd-none'
          }
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          {formik.values.purchaseOrderItems.map((_, index) => (
            <PurchaseOrderItem
              key={index}
              formik={formik}
              index={index}
              deal={deal}
            />
          ))}
        </div>
        {formik.errors.missingPurchaseOrderItems && (
          <small className="text-danger">
            {formik.errors.missingPurchaseOrderItems}
          </small>
        )}
        {formik.values.supplierBusinessId && (
          <Form.Group as={Row}>
            <Col>
              <Button
                className={
                  formik.values.purchaseOrderItems.length > 0
                    ? ' mt-3 ps-0'
                    : 'ps-0 mt-0'
                }
                variant="link"
                size="sm"
                onClick={() => {
                  formik.setFieldValue('purchaseOrderItems', [
                    ...formik.values.purchaseOrderItems,
                    {
                      dealId: '',
                      dealDescription: '',
                      dealItemId: '',
                      dealItemDescription: '',
                      quantity: null,
                      amount: null,
                      unitOfMeasureId: null,
                      unitOfMeasureDescription: null,
                    },
                  ])
                }}
              >
                <Plus size={18} /> Add Purchase Order Item
              </Button>
            </Col>
          </Form.Group>
        )}
        {purchaseOrder && (
          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => {
              setActiveTab(tab)
            }}
          >
            <Tab
              key="Files"
              eventKey="Files"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <FileEarmarkText className="mr-2" />
                  Files
                </b>
              }
            >
              <Files
                purchaseOrderId={purchaseOrder.id}
                purchaseOrderDescription={purchaseOrder.description}
                tableHeight={200}
              />
            </Tab>
            <Tab
              key="Payments"
              eventKey="Payments"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <CashCoin className="mr-2" />
                  Payments
                </b>
              }
            >
              <Payments purchaseOrderId={purchaseOrder.id} tableHeight={200} />
            </Tab>
            <Tab
              key="Invoice"
              eventKey="Invoices"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Receipt className="mr-2" />
                  Invoices
                </b>
              }
            >
              <Invoices purchaseOrderId={purchaseOrder.id} tableHeight={200} />
            </Tab>
            <Tab
              key="Notifications"
              eventKey="Notifications"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Bell className="mr-2" />
                  Notifications
                </b>
              }
            >
              <NotificationsTable
                purchaseOrderId={purchaseOrder.id}
                tableHeight={200}
              />
            </Tab>
            <Tab
              key="History"
              eventKey="History"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <ClockHistory className="mr-2" />
                  History
                </b>
              }
            >
              <AuditLog
                contentTypesRelayIds={{
                  [purchaseOrder.contentType.model]: [purchaseOrder.id],
                  [purchaseOrder.purchaseOrderItems.edges[0].node.contentType
                    .model]: purchaseOrder.purchaseOrderItems.edges.map(
                    (edge) => edge.node.id
                  ),
                }}
                recordColumns
                tableHeight={400}
              />
            </Tab>
          </Tabs>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {purchaseOrder && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deletePurchaseOrder({
                      variables: {
                        input: {
                          ids: purchaseOrder.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-3">
              <Loading
                message={
                  deleting
                    ? 'Deleting Purchase Order...'
                    : 'Saving Purchase Order...'
                }
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default PurchaseOrderForm
