import React, { useState } from 'react'
import { Row, Col, Badge, Button } from 'react-bootstrap'
import { Gear } from 'react-bootstrap-icons'
import { CaretDown, CaretRight } from 'react-bootstrap-icons'
import { useQuery, gql } from '@apollo/client'
import EditBoxConnector from './EditBoxConnector'
import Loading from '../../common/Loading'

const StudioBoxFiles = () => {
  const [displayBoxFiles, setDisplayBoxFiles] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const toggleModal = () => {
    setShowEditModal((prevState) => !prevState)
  }

  const { data } = useQuery(
    gql`
      query BoxConnector {
        boxConnectors {
          edges {
            node {
              id
              authAdded
              asUser
              rootBoxFolderId
              enabled
              authValid
              creatingEnvironment
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 4000,
    }
  )

  if (!data) return <></>
  const boxConnector = data.boxConnectors.edges[0].node
  return (
    <>
      <div>
        <Row className="mt-3 mb-1">
          <Col md={12} className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplayBoxFiles(!displayBoxFiles)}
              className="px-0 btn-link mr-2"
            >
              <>
                {displayBoxFiles ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/box-logo.png'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
            </h4>
          </Col>
        </Row>
        {displayBoxFiles && (
          <>
            <Row className="mt-2">
              <Col>
                {!boxConnector.authAdded && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Disabled
                  </Badge>
                )}
                {!boxConnector.authValid && boxConnector.authAdded && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Invalid Credentials
                  </Badge>
                )}
                {boxConnector.authValid && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="success"
                  >
                    Enabled
                  </Badge>
                )}
              </Col>
            </Row>
            {boxConnector.creatingEnvironment && (
              <Row>
                <Col md={3}>
                  <Loading message="Creating Box Environment..." dna />
                </Col>
              </Row>
            )}
            {!boxConnector.creatingEnvironment && (
              <Row>
                <Col md={2}>
                  <div>
                    <Button variant="link" onClick={toggleModal}>
                      <Gear className="mr-2" />
                      Configure Box
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
        <EditBoxConnector
          boxConnector={boxConnector}
          showModal={showEditModal}
          toggleModal={toggleModal}
        />
      </div>
    </>
  )
}

export default StudioBoxFiles
