import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const DealItemSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    multiple,
    searchDescription,
    setAdditionalFields,
    deal,
    dropdown,
    error,
  } = props
  const variables = {}
  if (deal) {
    variables.deal = deal
  }
  return (
    <SearchInput
      formik={formik}
      multiple={multiple}
      formikValue={formikValue}
      searchDescription={searchDescription ? searchDescription : 'deal items'}
      nodeName="dealItem"
      error={error}
      setAdditionalFields={setAdditionalFields}
      nodeNamePlural="dealItems"
      disabled={disabled}
      variables={variables}
      dropdown={dropdown}
      gql={gql`
        query DealItems($after: String, $search: String, $deal: ID) {
          dealItems(
            first: 250
            after: $after
            orderBy: "-created"
            search: $search
            deal: $deal
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                description
                productVariant {
                  product {
                    name
                  }
                }
                deal {
                  id
                }
                buyerQuantity
                buyerUnitOfMeasure {
                  id
                  shortName
                }
                supplierQuantity
                supplierUnitOfMeasure {
                  id
                  shortName
                }
                buyerPrice
                supplierPrice
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.description
      }}
    />
  )
}

export default DealItemSearchInput
