import React, { useState } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import { Truck, TruckFront } from 'react-bootstrap-icons'
import Tabs from '../common/Tabs'
import Fulfillments from './fulfilment/Fulfillments'
import CargoProviders from './cargo_provider/CargoProviders'

const CargoTabs = () => {
  const [activeTab, setActiveTab] = useState('Fulfillments')
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Cargo
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Fulfillments"
          eventKey="Fulfillments"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Truck className="mr-2" />
              Fulfillment
            </b>
          }
        >
          <Fulfillments cargoPage />
        </Tab>
        <Tab
          key="CargoProviders"
          eventKey="CargoProviders"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <TruckFront className="mr-2" />
              Cargo Providers
            </b>
          }
        >
          <CargoProviders />
        </Tab>
      </Tabs>
    </>
  )
}
export default CargoTabs
