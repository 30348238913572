import { gql } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { GeoAlt, Trash, Map as MapIcon, List } from 'react-bootstrap-icons'
import LocationModal from './LocationModal'
import Map from './Map'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import DeleteLocationModal from './DeleteLocationModal'
import Loading from '../common/Loading'

const Locations = (props) => {
  const { businessId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canModify = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [cursor, setCursor] = useState()
  const [hasMoreLocations, setHasMoreLocations] = useState(false)
  const [view, setView] = useState('list')
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const [locations, setLocations] = useState([])
  const [editLocation, setEditLocation] = useState()
  const [checkedLocationIds, setCheckedLocationIds] = useState([])

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Locations($fullAddress: String, $after: String, $businessId: ID) {
        locations(
          fullAddress_Icontains: $fullAddress
          after: $after
          first: 250
          business: $businessId
          orderBy: "name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              dealCount
              name
              fullAddress
              salesTax
              addressLineOne
              billingLocation
              addressLineTwo
              city
              state
              zipCode
              archived
              latitude
              mapDefault
              longitude
              contentType {
                model
                id
              }
              business {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (hasMoreLocations) {
      fetchMore()
    }
  }, [hasMoreLocations, locations])

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = { after: null }
      if (businessId) {
        variables.businessId = businessId
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.locations) {
      setHasMoreLocations(queryData.locations.pageInfo.hasNextPage)
      setCursor(queryData.locations.pageInfo.endCursor)
      setLocations(queryData.locations.edges)
      let text = 'Search 0 records'
      if (queryData.locations.nodeCount > 0) {
        text = `Search ${queryData.locations?.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      after: cursor,
    }
    if (businessId) {
      variables.businessId = businessId
    }
    if (searchTerm) {
      variables.fullAddress = searchTerm
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.locations) return prev
        const pageInfo = fetchMoreResult.locations.pageInfo
        setCursor(pageInfo.endCursor)
        setHasMoreLocations(pageInfo.hasNextPage)
        setLocations((prevState) => {
          const currentIds = new Set(
            fetchMoreResult.locations.edges.map((item) => item.node.id)
          )
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.id)
          )
          return [...filteredPrevState, ...fetchMoreResult.locations.edges]
        })
      },
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = { after: null }
    if (businessId) {
      variables.businessId = businessId
    }
    variables.fullAddress = currentSearchTerm
    query({ variables })
  }

  const onRowClick = (row) => {
    toggleModal(row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditLocation(node)
    } else {
      setEditLocation()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedLocationIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedLocationIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => {
        return row.node.name
      },
    },
    {
      Header: 'Address Line One',
      id: 'addressLineOne',
      accessor: (row) => {
        return row.node.addressLineOne
      },
    },
    {
      Header: 'Address Line Two',
      id: 'addressLineTwo',
      accessor: (row) => {
        return row.node.addressLineTwo
      },
    },
    {
      Header: 'City',
      id: 'city',
      accessor: (row) => {
        return row.node.city
      },
    },
    {
      Header: 'State',
      id: 'state',
      accessor: (row) => {
        return row.node.state
      },
    },
    {
      Header: 'Zip Code',
      id: 'zipCode',
      accessor: (row) => {
        return row.node.zipCode
      },
    },
    {
      Header: 'Billing',
      id: 'billing',
      accessor: (row) => {
        return row.node.billingLocation ? 'Yes' : 'No'
      },
    },
    {
      Header: 'Deals',
      id: 'dealCount',
      accessor: (row) => {
        return row.node.dealCount
      },
    },
  ]

  if (!businessId) {
    tableColumns.splice(1, 0, {
      Header: 'Business',
      id: 'business',
      accessor: (row) => {
        if (row.node?.business?.name) {
          return row.node.business.name
        }
      },
    })
    tableColumns.splice(tableColumns.length - 1, 0, {
      Header: 'Map Default',
      id: 'mapDefault',
      accessor: (row) => (row.node.mapDefault ? 'Yes' : null),
    })
  }
  if (canModify) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  locations.forEach((location) => {
                    if (!checkedLocationIds.includes(location.node.id)) {
                      appendIds.push(location.node.id)
                    }
                  })
                  setCheckedLocationIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedLocationIds([])
                }
              }}
            />
            {checkedLocationIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedLocationIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedLocationIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  if (queryError) return <>Error loading locations</>
  if ((!initialQueryRun && !queryData) || hasMoreLocations) {
    return (
      <Row className="mt-3">
        <Col>
          <Loading message="Loading Locations..." />
        </Col>
      </Row>
    )
  }
  return (
    <>
      {!businessId && (
        <Row className="mb-2 mt-3">
          <Col>
            <div className="d-flex align-items-center">
              <h1 className="mb-0 d-inline">Locations</h1>
            </div>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: businessId ? '-10px' : '-15px' }}>
        <Col>
          {canModify && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <GeoAlt className="mr-2" />
              New Location
            </Button>
          )}
          {view === 'map' && (
            <Button
              variant="link"
              onClick={() => {
                setView('list')
              }}
            >
              <List className="mr-2" />
              List
            </Button>
          )}
          {view === 'list' && (
            <Button
              variant="link"
              onClick={() => {
                setView('map')
              }}
            >
              <MapIcon className="mr-2" />
              Map
            </Button>
          )}
          {canModify && checkedLocationIds.length > 0 && (
            <Button
              variant="link"
              onClick={() => {
                setShowDeleteForm(true)
              }}
            >
              <Trash className="mr-2" />
              {checkedLocationIds.length === 1
                ? 'Delete Location'
                : 'Delete Locations'}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      {view === 'list' && (
        <>
          <Row className="mt-2 mb-3">
            <Col md="12">
              <SortableInfiniteTable
                tableData={locations}
                tableColumns={tableColumns}
                fetchMoreTableData={fetchMore}
                loadingMessage="Loading Locations..."
                hasMoreTableData={hasMoreLocations}
                onTdClicks={{
                  name: (cell) => {
                    onRowClick(cell.row)
                  },
                  type: (cell) => {
                    onRowClick(cell.row)
                  },
                  addressLineOne: (cell) => {
                    onRowClick(cell.row)
                  },
                  business: (cell) => {
                    onRowClick(cell.row)
                  },
                  addressLineTwo: (cell) => {
                    onRowClick(cell.row)
                  },
                  city: (cell) => {
                    onRowClick(cell.row)
                  },
                  state: (cell) => {
                    onRowClick(cell.row)
                  },
                  zipCode: (cell) => {
                    onRowClick(cell.row)
                  },
                  mapDefault: (cell) => {
                    onRowClick(cell.row)
                  },
                  dealCount: (cell) => {
                    onRowClick(cell.row)
                  },
                  billing: (cell) => {
                    onRowClick(cell.row)
                  },
                }}
                tableHeight={700}
                rowPointer
                hideGlobalFilter
              />
            </Col>
          </Row>
        </>
      )}
      {view === 'map' && (
        <>
          <Map
            locations={
              queryData?.locations?.edges ? queryData?.locations?.edges : []
            }
          />
        </>
      )}
      {showForm && (
        <LocationModal
          showModal={showForm}
          locationId={editLocation?.id}
          toggleModal={toggleModal}
          businessId={businessId}
        />
      )}
      {showDeleteForm && (
        <DeleteLocationModal
          ids={checkedLocationIds.length > 1 ? checkedLocationIds : null}
          id={checkedLocationIds.length === 1 ? checkedLocationIds[0] : null}
          setCheckedLocationIds={setCheckedLocationIds}
          showModal={showDeleteForm}
          toggleModal={setShowDeleteForm}
        />
      )}
    </>
  )
}
export default Locations
