import React, { useState, useEffect } from 'react'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import {
  Binoculars,
  BinocularsFill,
  Trash,
  Folder,
} from 'react-bootstrap-icons'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import validator from 'validator'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import { US_STATES } from '../../libs/constant'
import { client, loggedInUserVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import PermissionTable from './PermissionTable'

const NewEmployeeModal = (props) => {
  const { showModal, toggleModal } = props
  let roles = []
  let groups = []
  const [submitting, setSubmitting] = useState(false)
  const [showPermissions, setShowPermissions] = useState(false)

  const [groupsQuery, { data: groupsQueryData }] = useLazyQuery(
    gql`
      query GroupsQuery {
        groups {
          edges {
            node {
              name
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [createEmployeeMutation] = useMutation(
    gql`
      mutation CreateEmployee($createEmployeeInput: CreateEmployeeInput!) {
        createEmployee(input: $createEmployeeInput) {
          employee {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        innerToggleModel()
        toast.success(`Employee Saved`)
      },
      onError: (error) => {
        setSubmitting(false)
        innerToggleModel()
        toast.success(`Employee Saved`)
      },
      refetchQueries: ['Employees'],
    }
  )

  const handleGroupsQueryData = () => {
    const currentGroups = []
    groupsQueryData.groups.edges.forEach((group) => {
      if (group.node.name !== 'Supplier' && group.node.name !== 'Buyer') {
        currentGroups.push(group.node)
      }
    })
    return currentGroups
  }

  const { data: smtpData } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customHost
              customPort
              customUser
              customTls
              customPassword
              customValid
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (showModal) {
      // rolesQuery()
      groupsQuery()
    }
  }, [showModal])

  useEffect(() => {
    if (smtpData) {
      const smtpConnector = smtpData.smtpConnectors.edges[0].node
      formik.setFieldValue('smtpHost', smtpConnector.customHost)
      formik.setFieldValue('customTls', smtpConnector.customTls)
      formik.setFieldValue('smtpPort', smtpConnector.customPort)
    }
  }, [smtpData])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      active: true,
      phoneNumber: '',
      internalEmployeeId: '',
      smsNotificationsEnabled: true,
      emailNotificationsEnabled: true,
      password: '',
      confirmPassword: '',
      defaultRoleId: '',
      groupId: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: 'Illinois',
      zipCode: '',
      salesPercent: 0,
      rainPercent: 0,
      taskPercent: 0,
      servicePercent: 0,
      photoPercent: 0,
      payCommission: 0,
      smtpHost: '',
      smtpPort: 0,
      smtpUser: '',
      smtpTls: false,
      smtpPassword: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'First name is too short')
        .max(100, 'First name  is too long')
        .required('First name  is required'),
      lastName: Yup.string()
        .min(2, 'Last name  is too short')
        .max(100, 'Last name  is too long')
        .required('Last name  is required'),
      abbrevietedName: Yup.string()
        .min(2, 'Abbrevieted name  is too short')
        .max(100, 'Abbrevieted name  is too long')
        .nullable(),
      email: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .required('Email  is required')
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        })
        .test(
          'isUnique',
          'Account with this email already exists',
          async (value) => {
            let valid = true
            if (value) {
              const { data } = await client.query({
                query: gql`
                  query NewSubjectFormUsersQuery($emailIexact: String) {
                    users(email_Iexact: $emailIexact) {
                      nodeCount
                      edges {
                        node {
                          email
                        }
                      }
                    }
                  }
                `,
                fetchPolicy: 'network-only',
                variables: {
                  emailIexact: value,
                },
              })
              valid = data.users.nodeCount === 0
            }
            return valid
          }
        )
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      smsNotificationsEnabled: Yup.bool().required('Required'),
      emailNotificationsEnabled: Yup.bool().required('Required'),
      secondaryEmail: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .nullable()
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      secondaryPhoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      phoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value)) {
            valid = false
          }
          return valid
        }),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
        )
        .max(100, 'Password is too long')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .min(2, 'Confirm password is too short')
        .max(100, 'Confirm password is too long')
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      addressLineOne: Yup.string()
        .min(1, 'Address line one is too short')
        .max(100, 'Address line one is too long')
        .nullable(),
      addressLineTwo: Yup.string()
        .min(1, 'Address line two is too short')
        .max(100, 'Address line two is too long')
        .nullable(),
      city: Yup.string()
        .min(1, 'City is too short')
        .max(100, 'City is too long')
        .nullable(),
      state: Yup.string()
        .min(3, 'State is too short')
        .max(100, 'State is too long')
        .nullable()
        .test('isState', 'Invalid state', (value) => {
          let valid = true
          if (value && !US_STATES.includes(value)) {
            valid = false
          }
          return valid
        }),
      zipCode: Yup.string()
        .min(3, 'Zip code is too short')
        .max(100, 'Zip code is too long')
        .nullable(),

      // defaultRoleId: Yup.string()
      //   .required('Default role is required')
      //   .test('isRole', 'Invalid default role', (value) => {
      //     let valid = false;
      //     if (value) {
      //       roles.map(role => {
      //         if (!valid && value === role.id){
      //           valid = true;
      //         }
      //       })
      //     }
      //     return valid;
      //   }),
      groupId: Yup.string()
        .required('Required')
        .test('isGroup', 'Invalid permissions', (value) => {
          let valid = false
          if (value) {
            groups.map((group) => {
              if (!valid && value === group.id) {
                valid = true
              }
            })
          }
          return valid
        }),
      active: Yup.bool().required('Required'),
      smtpTls: Yup.boolean().nullable(),
      smtpHost: Yup.string().nullable(),
      smtpPort: Yup.number().nullable(),
      smtpUser: Yup.string().nullable(),
      salesPercent: Yup.number().integer().nullable().min(0).max(100),
      photoPercent: Yup.number().integer().nullable().min(0).max(100),
      payCommission: Yup.number().integer().nullable().min(0).max(100),
      rainPercent: Yup.number().integer().nullable().min(0).max(100),
      taskPercent: Yup.number().integer().nullable().min(0).max(100),
      servicePercent: Yup.number().integer().nullable().min(0).max(100),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      createEmployeeMutation({
        variables: {
          createEmployeeInput: {
            employeeInput: {
              userActive: values.active,
              userFirstName: values.firstName,
              userLastName: values.lastName,
              userAbbreviatedName: values.abbrevietedName,
              userEmail: values.email,
              userSecondaryEmail: values.secondaryEmail,
              userSecondaryPhoneNumber: values.secondaryPhoneNumber,
              userPassword: values.password,
              userPhoneNumber: values.phoneNumber,
              userEmailNotificationsEnabled: values.emailNotificationsEnabled,
              userSmsNotificationsEnabled: values.smsNotificationsEnabled,
              userAddressLineOne: values.addressLineOne,
              userAddressLineTwo: values.addressLineTwo,
              userCity: values.city,
              userState: values.state,
              userZipCode: values.zipCode,
              salesPercent: values.salesPercent,
              photoPercent: values.photoPercent,
              payCommission: values.payCommission,
              rainPercent: values.rainPercent,
              taskPercent: values.taskPercent,
              servicePercent: values.servicePercent,
              smtpHost: values.smtpHost,
              smtpPort: values.smtpPort,
              smtpUser: values.smtpUser,
              smtpTls: values.smtpTls,
              smtpPassword: values.smtpPassword,
              groupId: values.groupId,
            },
          },
        },
      })
    },
  })

  if (!groupsQueryData || !showModal) return <></>
  groups = handleGroupsQueryData()
  const innerToggleModel = () => {
    toggleModal()
    formik.resetForm()
    setSubmitting(false)
  }
  if (!smtpData) return <></>

  return (
    <>
      <Modal size="lg" show={showModal} onHide={innerToggleModel}>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {showPermissions ? 'Permissions' : 'New Employee'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!showPermissions ? (
              <>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label className="mb-0">First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.firstName}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label className="mb-0">Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.lastName}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label className="mb-0">Abbreviated Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="abbrevietedName"
                        value={formik.values.abbrevietedName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.abbrevietedName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.abbrevietedName}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Secondary Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="secondaryEmail"
                        value={formik.values.secondaryEmail}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.secondaryEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.secondaryEmail}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Phone Number</Form.Label>
                      <PhoneInput
                        country="us"
                        name="phoneNumber"
                        regions={['north-america']}
                        value={formik.values.phoneNumber}
                        onChange={(phone, country) =>
                          formik.setFieldValue('phoneNumber', phone)
                        }
                      />
                      {formik.errors.phoneNumber ? (
                        <span className="text-danger">
                          {formik.errors.phoneNumber}
                        </span>
                      ) : null}
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Secondary Number</Form.Label>
                      <PhoneInput
                        country="us"
                        name="secondaryPhoneNumber"
                        regions={['north-america']}
                        value={formik.values.secondaryPhoneNumber}
                        onChange={(phone, country) =>
                          formik.setFieldValue('secondaryPhoneNumber', phone)
                        }
                      />
                      {formik.errors.secondaryPhoneNumber ? (
                        <span className="text-danger">
                          {formik.errors.secondaryPhoneNumber}
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label>Address Line One</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLineOne"
                        value={formik.values.addressLineOne}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.addressLineOne}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.addressLineOne}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label>Address Line two</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLineTwo"
                        value={formik.values.addressLineTwo}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.addressLineTwo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.addressLineTwo}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.city}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>State</Form.Label>

                      <Form.Control
                        name="state"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.values.state}
                        isInvalid={formik.errors.state}
                      >
                        {US_STATES.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.state}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="zipCode"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.zipCode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.zipCode}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col md={12}>
                    <p className="text-secondary title-space mb-1">
                      ACCESS RIGHTS
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <hr />
                  </Col>
                </Row>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Password</Form.Label>

                      <Form.Control
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Confirm Password</Form.Label>

                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Permissions</Form.Label>
                      {/* <Button
                        type="button"
                        onClick={() => setShowPermissions(!showPermissions)}
                        className="px-0 btn-link ml-2"
                      >
                        <>
                          {showPermissions ? (
                            <>
                              <BinocularsFill size={15} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                Hide
                              </span>
                            </>
                          ) : (
                            <>
                              <Binoculars size={15} />{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                View
                              </span>
                            </>
                          )}
                        </>
                      </Button> */}
                      <Form.Control
                        name="groupId"
                        as="select"
                        value={formik.values.groupId}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.groupId}
                      >
                        <option>Select permissions</option>
                        {groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.groupId}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2} className="d-flex align-items-center">
                      <Form.Label className="mb-0">Login Access</Form.Label>
                    </Col>
                    <Col xs={12} md={4} className="mt-3">
                      <Form.Check
                        name="active"
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                        checked={formik.values.active}
                        onChange={(e) =>
                          formik.setFieldValue('active', e.target.checked)
                        }
                        isInvalid={formik.errors.active}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.active}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={12}>
                      <p className="text-secondary title-space mb-1">
                        NOTIFCATIONS
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Check
                        inline
                        label="Receive SMS"
                        checked={formik.values.smsNotificationsEnabled}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'smsNotificationsEnabled',
                            e.target.checked
                          )
                        }
                      />

                      <Form.Check
                        inline
                        label="Receive Email"
                        checked={formik.values.emailNotificationsEnabled}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'emailNotificationsEnabled',
                            e.target.checked
                          )
                        }
                      />
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
                {!loggedInUserVar}
                <Row className="mt-3">
                  <Col md={12}>
                    <p className="text-secondary title-space mb-1">
                      PERSONAL EMAIL SENDER
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <hr />
                  </Col>
                </Row>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label>Email Server Host</Form.Label>

                      <Form.Control
                        type="string"
                        name="smtpHost"
                        value={formik.values.smtpHost}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.smtpHost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpHost}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label>Email Server Port</Form.Label>

                      <Form.Control
                        type="number"
                        name="smtpPort"
                        value={formik.values.smtpPort}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.smtpPort}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpPort}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label>Email Server User</Form.Label>
                      <Form.Control
                        type="string"
                        name="smtpUser"
                        value={formik.values.smtpUser}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.smtpUser}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpUser}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>Email Server Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="smtpPassword"
                        value={formik.values.smtpPassword}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.smtpPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpPassword}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>Email Server Tls</Form.Label>

                      <Form.Check
                        name="smtpTls"
                        type="checkbox"
                        checked={formik.values.smtpTls}
                        onChange={formik.handleChange}
                        // isInvalid={formik.errors.smtpTls}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {formik.errors.smtpTls}
                  </Form.Control.Feedback> */}
                    </Col>
                  </Row>
                </Form.Group>
                {window.location.hostname.includes('srp.sbx') && (
                  <>
                    <Row className="mt-3">
                      <Col md={12}>
                        <p className="text-secondary title-space mb-1">
                          PROFIT SHARING
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <hr />
                      </Col>
                    </Row>
                    <Form.Group>
                      <Row>
                        <Col xs={12} md={4}>
                          <Form.Label>Photo Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="photoPercent"
                            value={formik.values.photoPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.photoPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.photoPercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Sales Percent</Form.Label>

                          <Form.Control
                            type="number"
                            name="salesPercent"
                            value={formik.values.salesPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.salesPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.salesPercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Rain Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="rainPercent"
                            value={formik.values.rainPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.rainPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.rainPercent}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row>
                        <Col xs={12} md={4}>
                          <Form.Label>Service Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="servicePercent"
                            value={formik.values.servicePercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.servicePercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.servicePercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Task Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="taskPercent"
                            value={formik.values.taskPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.taskPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.taskPercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Pay Commission</Form.Label>

                          <Form.Control
                            type="number"
                            name="payCommission"
                            value={formik.values.payCommission}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.payCommission}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.payCommission}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                )}
                <Row>
                  <Col md={3}>
                    <Button
                      type="submit"
                      block
                      variant="outline-primary"
                      className="mt-2"
                      disabled={submitting}
                    >
                      <Folder className="mr-2" />
                      Save
                    </Button>
                  </Col>
                </Row>

                {submitting && (
                  <Row>
                    <Col>
                      <Loading message="Saving Employee..." />
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                <Button
                  type="submit"
                  variant="outline-primary"
                  className="mt-1 mb-3"
                  onClick={() => {
                    setShowPermissions(false)
                  }}
                >
                  Back
                </Button>
                <PermissionTable />
              </>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    </>
  )
}

export default NewEmployeeModal
