import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import { Buildings, Trash, Funnel } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import BusinessModal from './BusinessModal'
import Loading from '../common/Loading'
import DeleteBusinessModal from './DeleteBusinessModal'
import { loggedInUserVar } from '../../libs/apollo'
import SortableInfiniteTable from '../common/SortableInfiniteTable'

const Businesses = (props) => {
  const { employeeId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const [filterSupplier, setFilterSupplier] = useState()
  const [filterBuyer, setFilterBuyer] = useState()
  const history = useHistory()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Businesses(
        $cursor: String
        $searchTerm: String
        $buyers: Boolean
        $suppliers: Boolean
        $employee: String
      ) {
        businesses(
          first: 20
          after: $cursor
          buyers: $buyers
          suppliers: $suppliers
          name_Icontains: $searchTerm
          orderBy: "name"
          employee: $employee
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              dealCount
              formattedStatus
              contentType {
                model
                id
              }
              supplier {
                id
              }
              buyer {
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterSupplier, filterBuyer])

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.businesses?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (filterBuyer) {
      variables.buyers = true
    }
    if (employeeId) {
      variables.employee = employeeId
    }
    if (filterSupplier) {
      variables.suppliers = true
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.businesses) {
      let text = 'Search 0 records'
      if (queryData.businesses.nodeCount > 0) {
        text = `Search ${queryData.businesses.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.searchTerm = currentSearchTerm
    } else {
      variables.searchTerm = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    history.push(`/business/${cell.row.original.node.id}`)
  }

  const toggleModal = () => {
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
    },
    {
      Header: 'Supplier',
      id: 'supplier',
      accessor: (row) => {
        return row.node.supplier ? 'Yes' : 'No'
      },
    },
    {
      Header: 'Buyer',
      id: 'buyer',
      accessor: (row) => {
        return row.node.buyer ? 'Yes' : 'No'
      },
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'node.formattedStatus',
    },
    {
      Header: 'Deals',
      id: 'dealCount',
      accessor: 'node.dealCount',
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.businesses?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Businesses..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>
  return (
    <>
      {!employeeId && (
        <Row className="mb-2 mt-3">
          <Col>
            <div className="d-flex align-items-center">
              <h1 className="mb-0 d-inline">Businesses</h1>
            </div>
          </Col>
        </Row>
      )}
      <Row
        style={{
          marginTop: employeeId ? '-10px' : '-15px',
        }}
      >
        <Col>
          {canMutate && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <Buildings className="mr-2" />
              New Business
            </Button>
          )}
          <Button
            variant="link"
            onClick={() => {
              setFilterBuyer(!filterBuyer)
            }}
          >
            <span>
              <Funnel className="mr-2" />
              {!filterBuyer ? <>Buyers</> : <>All</>}
            </span>
          </Button>
          <Button
            variant="link"
            onClick={() => {
              setFilterSupplier(!filterSupplier)
            }}
          >
            <span>
              <Funnel className="mr-2" />
              {!filterSupplier ? <>Suppliers</> : <>All</>}
            </span>
          </Button>
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedNodeIds.length === 1 ? (
                    <>Delete Business</>
                  ) : (
                    <>Delete Businesses</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.businesses?.edges ? queryData?.businesses?.edges : []
            }
            loadingMessage="Loading Businesses..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.businesses?.pageInfo?.hasNextPage}
            onTdClicks={{
              name: (cell) => onTdClick(cell),
              supplier: (cell) => onTdClick(cell),
              buyer: (cell) => onTdClick(cell),
              status: (cell) => onTdClick(cell),
            }}
            tableHeight={800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      {showForm && (
        <BusinessModal showModal={showForm} toggleModal={toggleModal} />
      )}
      <DeleteBusinessModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedNodeIds={setCheckedNodeIds}
        ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
        id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
      />
    </>
  )
}
export default Businesses
