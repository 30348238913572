import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { Trash, FileEarmarkBarGraph, Dot } from 'react-bootstrap-icons'
import { useHistory, useLocation } from 'react-router-dom'
import Loading from '../../common/Loading'
import { loggedInUserVar } from '../../../libs/apollo'
import SalesOrderModal from './SalesOrderModal'
import DeleteSalesOrderModal from './DeleteSalesOrderModal'

const SalesOrders = (props) => {
  const { dealId, deal, id, fetchPolicy } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editNode, setEditNode] = useState()
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const location = useLocation()
  const TERMS = {
    NET_5_WEEK_ENDING_DELIVERY: 'Net 5 Week Ending Delivery',
    NET_10_WEEK_ENDING_DELIVERY: 'Net 10 Week Ending Delivery',
    NET_5: 'Net 5',
    NET_10: 'Net 10',
  }
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query SalesOrders(
        $cursor: String
        $search: String
        $deal: String
        $id: [ID]
      ) {
        salesOrders(
          first: 20
          after: $cursor
          search: $search
          deal: $deal
          id: $id
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              number
              description
              totalAmount
              netTerms
              termsFee
              termsOn
              description
              contentType {
                model
              }
              salesOrderItems {
                edges {
                  node {
                    id
                    unitOfMeasure {
                      id
                      shortName
                      pluralName
                    }
                    dealItem {
                      id
                      supplier {
                        business {
                          name
                        }
                      }
                      deal {
                        id
                        buyer {
                          business {
                            name
                          }
                        }
                        dealNumber
                      }
                    }
                    quantity
                    amount
                    contentType {
                      model
                    }
                    dealItem {
                      productVariant {
                        product {
                          name
                        }
                      }
                    }
                  }
                }
              }
              buyer {
                id
                business {
                  id
                  name
                  invoiceEmail
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy || 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.salesOrders?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    if (id) {
      variables.id = [id]
    }
    if (dealId) {
      variables.deal = dealId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.salesOrders) {
      let text = 'Search 0 records'
      if (queryData.salesOrders.nodeCount > 0) {
        text = `Search ${queryData.salesOrders.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Sales Order Number',
      id: 'number',
      accessor: 'node.number',
    },
    {
      Header: 'Deals',
      id: 'deal',
      accessor: (row) => {
        return (
          <>
            {row.node.salesOrderItems.edges.map((edge, index) => (
              <span key={index}>
                <Dot className="mr-2" /> {edge.node.dealItem.deal.dealNumber}
                <br />
              </span>
            ))}
          </>
        )
      },
    },
    {
      Header: 'Products',
      id: 'products',
      accessor: (row) => {
        return (
          <>
            {row.node.salesOrderItems.edges.map((edge, index) => {
              const product = edge.node.dealItem.productVariant.product
              return (
                <span key={index}>
                  <Dot className="mr-2" /> {product.name}
                  <br />
                </span>
              )
            })}
          </>
        )
      },
    },
    {
      Header: 'Amount',
      id: 'amount',
      accessor: (row) => {
        return <>${row.node.totalAmount}</>
      },
    },
    {
      Header: 'Terms',
      id: 'netTerms',
      accessor: (row) => {
        if (row.node.netTerms) {
          return TERMS[row.node.netTerms]
        }
      },
    },
  ]
  if (!id) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.salesOrders?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  if (!location.pathname.includes('business')) {
    tableColumns.splice(1, 0, {
      Header: 'Buyer',
      id: 'buyer',
      accessor: 'node.buyer.business.name',
    })
  }

  if (queryError) return <>Error loading sales orders</>
  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Sales Orders..." />
        </Col>
      </Row>
    )

  return (
    <>
      <Row>
        <Col>
          {canMutate && !id && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <FileEarmarkBarGraph className="mr-2" />
              New Sales Order
            </Button>
          )}
          {!canMutate && <div className="mt-3" />}
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  Delete Sales Order
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      {!id && (
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.salesOrders?.edges ? queryData?.salesOrders?.edges : []
            }
            loadingMessage="Loading Sales Orders..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.salesOrders?.pageInfo?.hasNextPage}
            onTdClicks={{
              number: (cell) => onTdClick(cell),
              buyer: (cell) => onTdClick(cell),
              products: (cell) => onTdClick(cell),
              amount: (cell) => onTdClick(cell),
              netTerms: (cell) => onTdClick(cell),
              deal: (cell) => onTdClick(cell),
            }}
            tableHeight={id ? 85 : 800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      {showForm && (
        <SalesOrderModal
          showModal={showForm}
          salesOrder={editNode}
          toggleModal={toggleModal}
          deal={deal}
        />
      )}
      {showDeleteModal && (
        <DeleteSalesOrderModal
          ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
          id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
          setCheckedNodeIds={setCheckedNodeIds}
          showModal={showDeleteModal}
          toggleModal={setShowDeleteModal}
        />
      )}
    </>
  )
}
export default SalesOrders
