import React from 'react'
import { Route } from 'react-router-dom'
import ScrapGoLayout from '../components/layout/ScrapGoLayout'

const ScrapGoRoute = ({ component: Component, ...rest }) => {
  return (
    <ScrapGoLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </ScrapGoLayout>
  )
}

export default ScrapGoRoute
