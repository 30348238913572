import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { Plus, Trash, Folder, ArrowUpRightCircle } from 'react-bootstrap-icons'
import BusinessSearchInput from '../common/node_search_input/BusinessSearchInput'
import Loading from '../common/Loading'
import EmployeeSearchInput from '../common/node_search_input/EmployeeSearchInput'
import DealItem from './DealItem'
import LocationSearchInput from '../common/node_search_input/LocationSearchInput'
import LocationModal from '../location/LocationModal'
import DeleteDealModal from './DeleteDealModal'
import { toTitleCase } from '../../libs/utils'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'

const DealForm = (props) => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { deal, afterSubmit } = props
  const AVAILABILITY = [
    {
      value: 'ONE_TIME',
      description: 'One Time',
    },
    {
      value: 'DAILY',
      description: 'Daily',
    },
    {
      value: 'WEEKLY',
      description: 'Weekly',
    },
    { value: 'MONTHLY', description: 'Monthly' },
    { value: 'YEARLY', description: 'Yearly' },
  ]
  const STATUS = [
    {
      value: 'NEW',
      description: 'New',
    },
    {
      value: 'ACTIVE',
      description: 'Active',
    },
    {
      value: 'SHIPPED',
      description: 'Shipped',
    },
    { value: 'DELIVERED', description: 'Delivered' },
    { value: 'CANCELLED', description: 'Cancelled' },
  ]
  const [submitting, setSubmitting] = useState(false)
  const [viewLocation, setViewLocation] = useState()
  const [deleteDealId, setDeleteDealId] = useState()
  const history = useHistory()

  const [createDeal] = useMutation(
    gql`
      mutation CreateDeal($input: CreateDealInput!) {
        createDeal(input: $input) {
          deal {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Deal Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
        history.push(`/deal/${data.createDeal.deal.id}`)
      },
      refetchQueries: ['Deals', 'Deal', 'Files'],
    }
  )

  const [updateDeal] = useMutation(
    gql`
      mutation UpdateDeal($input: UpdateDealInput!) {
        updateDeal(input: $input) {
          deal {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Deal Saved`)
        formik.values.dealItems.forEach((_, index) => {
          formik.setFieldValue(`dealItems[${index}].images`, [])
        })
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Deals', 'Deal', 'Files'],
    }
  )

  const formik = useFormik({
    initialValues: deal
      ? {
          supplierBusinessId: deal?.supplier ? deal.supplier.business.id : '',
          supplierBusinessDescription: deal?.supplier
            ? deal.supplier.business.name
            : '',
          buyerBusinessId: deal.buyer ? deal.buyer.business.id : '',
          buyerBusinessDescription: deal.buyer ? deal.buyer.business.name : '',
          buyerProductPrices: deal.buyer
            ? deal.buyer.effectiveProductVariantBusinessPrices
            : [],
          supplierProductPrices: deal.supplier
            ? deal.supplier.effectiveProductVariantBusinessPrices
            : [],
          dealNumber: deal.dealNumber,
          mobile: deal.mobile,
          availability: deal.availability,
          pickupLocationId: deal.pickupLocation ? deal.pickupLocation.id : '',
          pickupLocationDescription: deal.pickupLocation
            ? deal.pickupLocation.fullAddress
            : '',
          pickupLocation: deal.pickupLocation,
          deliveryLocation: deal.deliveryLocation,
          estimatedPickupDate: deal?.estimatedPickupDate
            ? new Date(deal.estimatedPickupDate)
            : new Date(),
          deliveryLocationId: deal.deliveryLocation
            ? deal.deliveryLocation.id
            : '',
          deliveryLocationDescription: deal.deliveryLocation
            ? deal.deliveryLocation.fullAddress
            : '',
          estimatedDeliveryDate: deal?.estimatedDeliveryDate
            ? new Date(deal.estimatedDeliveryDate)
            : new Date(),
          employees: deal.salespeople.edges.map((edge) => ({
            id: edge.node.id,
            description: edge.node.user.fullName,
          })),
          dealItems: deal.dealItems.edges.map((edge) => {
            const { node } = edge
            return {
              id: node.id,
              description: node.description,
              images: [],
              productId: node.productVariant.product.id,
              productName: node.productVariant.product.name,
              supplierPrice: node.supplierPrice,
              supplierSecondaryPrice: node.supplierSecondaryPrice,
              supplierQuantity: node.supplierQuantity,
              supplierUnitOfMeasureId: node.supplierUnitOfMeasure
                ? node.supplierUnitOfMeasure.id
                : '',
              supplierUnitOfMeasureDescription: node.supplierUnitOfMeasure
                ? toTitleCase(node.supplierUnitOfMeasure.pluralName)
                : '',
              buyerQuantity: node.buyerQuantity,
              buyerPrice: node.buyerPrice,
              buyerSecondaryPrice: node.buyerSecondaryPrice,
              buyerUnitOfMeasureId: node.buyerUnitOfMeasure
                ? node.buyerUnitOfMeasure.id
                : '',
              buyerUnitOfMeasureDescription: node.buyerUnitOfMeasure
                ? toTitleCase(node.buyerUnitOfMeasure.pluralName)
                : '',
              attributeValues: node.productVariant.product.attributeValues,
              selectedProductAttributeValues:
                node.productVariant.productAttributeValues.edges.map(
                  (edge) => ({
                    id: edge.node.id,
                  })
                ),
            }
          }),
        }
      : {
          pickupLocationId: '',
          pickupLocationDescription: '',
          estimatedPickupDate: new Date(),
          deliveryLocationId: '',
          buyerProductPrices: [],
          supplierProductPrices: [],
          deliveryLocationDescription: '',
          estimatedDeliveryDate: new Date(),
          supplierBusinessId: '',
          supplierBusinessDescription: '',
          buyerBusinessId: '',
          buyerBusinessDescription: '',
          dealNumber: '',
          mobile: false,
          availability: 'ONE_TIME',
          employees: [],
          dealItems: [
            {
              images: [],
              productId: null,
              productName: null,
              attributeValues: [],
              selectedProductAttributeValues: [],
              supplierPrice: null,
              supplierSecondaryPrice: null,
              supplierQuantity: null,
              supplierUnitOfMeasureId: null,
              supplierUnitOfMeasureDescription: null,
              buyerQuantity: null,
              buyerPrice: null,
              buyerSecondaryPrice: null,
              buyerUnitOfMeasureId: null,
              buyerUnitOfMeasureDescription: null,
            },
          ],
          status: 'NEW',
        },
    validationSchema: Yup.object().shape({
      pickupLocationId: Yup.string().required('Required'),
      pickupLocationDescription: Yup.string().nullable(),
      estimatedPickupDate: Yup.date().nullable(),
      deliveryLocationId: Yup.string().required('Required'),
      deliveryLocationDescription: Yup.string().nullable(),
      estimatedDeliveryDate: Yup.date().nullable(),
      supplierBusinessId: Yup.string().required('Required'),
      supplierBusinessDescription: Yup.string().nullable(),
      buyerBusinessId: Yup.string().required('Required'),
      status: Yup.string().nullable(),
      buyerBusinessDescription: Yup.string().nullable(),
      dealNumber: Yup.string().required('Required'),
      mobile: Yup.boolean().required('Required'),
      availability: Yup.string().required('Required'),
      employees: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
      dealItems: Yup.array().of(
        Yup.object().shape({
          productId: Yup.mixed().required('Required'),
          productName: Yup.mixed().nullable(),
          attributeValues: Yup.array().of(Yup.object()).nullable(),
          selectedProductAttributeValues: Yup.array().of(
            Yup.object().shape({
              id: Yup.mixed().required('Required'),
            })
          ),
          supplierPrice: Yup.mixed().nullable(),
          supplierSecondaryPrice: Yup.mixed().nullable(),
          supplierQuantity: Yup.mixed().required('Required'),
          supplierUnitOfMeasureId: Yup.mixed().required('Required'),
          supplierUnitOfMeasureDescription: Yup.mixed().nullable(),
          buyerQuantity: Yup.mixed().required('Required'),
          buyerPrice: Yup.mixed().nullable(),
          buyerSecondaryPrice: Yup.mixed().nullable(),
          buyerUnitOfMeasureId: Yup.mixed().required('Required'),
          buyerUnitOfMeasureDescription: Yup.string().nullable(),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      // setSubmitting(true)
      const dealInput = {
        dealNumber: values.dealNumber,
        supplierBusinessId: values.supplierBusinessId,
        buyerBusinessId: values.buyerBusinessId,
        mobile: values.mobile,
        status: values.status,
        availability: values.availability,
        salespeopleIds: values.employees.map((employee) => employee.id),
        pickupLocationId: values.pickupLocationId,
        deliveryLocationId: values.deliveryLocationId,
        estimatedPickupDate: values.estimatedPickupDate,
        estimatedDeliveryDate: values.estimatedDeliveryDate,
        dealItems: values.dealItems.map((dealItem) => ({
          images: dealItem.images.map((image) => image.file),
          id: dealItem.id,
          productId: dealItem.productId,
          supplierPrice: dealItem.supplierPrice || null,
          supplierSecondaryPrice: dealItem.supplierSecondaryPrice || null,
          supplierQuantity: dealItem.supplierQuantity,
          supplierUnitOfMeasureId: dealItem.supplierUnitOfMeasureId,
          buyerPrice: dealItem.buyerPrice || null,
          buyerSecondaryPrice: dealItem.buyerSecondaryPrice || null,
          buyerQuantity: dealItem.buyerQuantity,
          buyerUnitOfMeasureId: dealItem.buyerUnitOfMeasureId,
          productAttributeValueIds: dealItem.selectedProductAttributeValues.map(
            (selectedProductAttributeValue) => selectedProductAttributeValue.id
          ),
        })),
      }
      console.log(dealInput)
      if (deal) {
        dealInput.id = deal.id
        updateDeal({
          variables: {
            input: {
              dealInput,
            },
          },
        })
      } else {
        createDeal({
          variables: {
            input: {
              dealInput,
            },
          },
        })
      }
    },
  })
  console.log(formik)
  const setAdditionalLocationFields = (node, formikValue) => {
    if (formikValue === 'pickupLocationId') {
      formik.setFieldValue('pickupLocation', node)
    } else if (formikValue === 'deliveryLocationId') {
      formik.setFieldValue('deliveryLocation', node)
    }
  }
  useEffect(() => {
    if (!formik.values.supplierBusinessId) {
      if (formik.values.supplierProductPrices.length > 0) {
        formik.setFieldValue('supplierProductPrices', [])
      }
      if (formik.values.pickupLocationId) {
        formik.setFieldValue('pickupLocationId', '')
        formik.setFieldValue('pickupLocationDescription', '')
      }
    }
    if (!formik.values.buyerBusinessId) {
      if (formik.values.buyerProductPrices.length > 0) {
        formik.setFieldValue('buyerProductPrices', [])
      }
      if (formik.values.deliveryLocationId) {
        formik.setFieldValue('deliveryLocationId', '')
        formik.setFieldValue('deliveryLocationDescription', '')
      }
    }
  }, [formik.values])

  if (!loggedInUser) return <></>
  const isBuyer = loggedInUser.permissions.isBuyer
  const isSupplier = loggedInUser.permissions.isSupplier
  const isBuyerSupplier = isBuyer || isSupplier
  const pickupLocationComponent = (
    <Col sm="12" md={deal ? 4 : 6}>
      <Form.Label>
        Pickup Location
        {formik.values.pickupLocationId && (
          <span
            className="ml-2 btn-link p-0"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setViewLocation(formik.values.pickupLocation)
            }}
          >
            <ArrowUpRightCircle />
          </span>
        )}
      </Form.Label>
      <LocationSearchInput
        formik={formik}
        setAdditionalFields={setAdditionalLocationFields}
        formikValue="pickupLocation"
        businessId={formik.values.supplierBusinessId}
        disabled={!formik.values.supplierBusinessId || isSupplier}
        error={formik.errors.pickupLocationId}
      />
    </Col>
  )

  const setAdditionalBuyerFields = (node, _) => {
    formik.setFieldValue(
      'buyerProductPrices',
      node.buyer.effectiveProductVariantBusinessPrices
    )
  }

  const setAdditionalSupplierFields = (node, _) => {
    formik.setFieldValue(
      'supplierProductPrices',
      node.supplier.effectiveProductVariantBusinessPrices
    )
  }

  const deliveryLocationComponent = (
    <Col sm="12" md={deal ? 4 : 6}>
      <Form.Label>
        Delivery Location
        {formik.values.deliveryLocationId && (
          <span
            className="ml-2 btn-link p-0"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setViewLocation(formik.values.deliveryLocation)
            }}
          >
            <ArrowUpRightCircle />
          </span>
        )}
      </Form.Label>
      <LocationSearchInput
        formik={formik}
        formikValue="deliveryLocation"
        setAdditionalFields={setAdditionalLocationFields}
        businessId={formik.values.buyerBusinessId}
        disabled={!formik.values.buyerBusinessId || isBuyer}
        error={formik.errors.deliveryLocationId}
      />
    </Col>
  )
  return (
    <>
      {deleteDealId && (
        <DeleteDealModal
          showModal={deleteDealId}
          toggleModal={() => {
            setDeleteDealId()
          }}
          id={deleteDealId}
        />
      )}
      <LocationModal
        showModal={viewLocation}
        locationId={viewLocation?.id}
        toggleModal={() => setViewLocation()}
      />
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={deal ? 4 : 6}>
            <Form.Label>Deal Number</Form.Label>
            <Form.Control
              as="input"
              name="dealNumber"
              className="form-control-sm"
              disabled={isBuyerSupplier}
              value={formik.values.dealNumber}
              onChange={formik.handleChange}
              isInvalid={formik.errors.dealNumber}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.dealNumber}
            </Form.Control.Feedback>
          </Col>
          {isBuyer && <>{deliveryLocationComponent}</>}
          {isSupplier && <>{pickupLocationComponent}</>}
          {!isBuyerSupplier && (
            <Col md={deal ? 4 : 6}>
              <Form.Label>Salespeople</Form.Label>
              <EmployeeSearchInput
                formik={formik}
                multiple
                searchDescription="salespeople"
              />
            </Col>
          )}
        </Form.Group>
        {!isBuyerSupplier && (
          <>
            <Form.Group as={Row}>
              <Col sm="12" md={deal ? 4 : 6}>
                <Form.Label>
                  Supplier
                  {formik.values.supplierBusinessId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(
                          `/business/${formik.values.supplierBusinessId}`
                        )
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <BusinessSearchInput
                  formik={formik}
                  formikValue="supplierBusiness"
                  suppliers
                  setAdditionalFields={setAdditionalSupplierFields}
                  searchDescription="suppliers"
                  error={formik.errors.supplierBusinessId}
                />
              </Col>
              {pickupLocationComponent}
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="12" md={deal ? 4 : 6}>
                <Form.Label>
                  Buyer
                  {formik.values.buyerBusinessId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(
                          `/business/${formik.values.buyerBusinessId}`
                        )
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <BusinessSearchInput
                  formik={formik}
                  formikValue="buyerBusiness"
                  buyers
                  setAdditionalFields={setAdditionalBuyerFields}
                  searchDescription="buyers"
                  error={formik.errors.buyerBusinessId}
                />
              </Col>
              {deliveryLocationComponent}
            </Form.Group>
          </>
        )}

        <Form.Group as={Row}>
          <Col md={deal ? 4 : 6}>
            <Form.Label>Availability</Form.Label>
            <Form.Control
              name="availability"
              as="select"
              disabled={isBuyerSupplier}
              className="form-control-sm"
              value={formik.values.availability}
              onChange={formik?.handleChange}
              isInvalid={formik?.errors?.availability}
            >
              {AVAILABILITY.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.availability}
            </Form.Control.Feedback>
          </Col>
          <Col md={deal ? 3 : 5}>
            <Form.Label>Status</Form.Label>
            <Form.Control
              name="status"
              as="select"
              disabled={isBuyerSupplier}
              className="form-control-sm"
              value={formik.values.status}
              onChange={formik?.handleChange}
              isInvalid={formik?.errors?.status}
            >
              {STATUS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.status}
            </Form.Control.Feedback>
          </Col>
          <Col md={1}>
            <Form.Label>Mobile</Form.Label>
            <Form.Check
              disabled={isBuyerSupplier}
              label={formik.values.mobile ? 'Yes' : 'No'}
              onChange={(e) => {
                formik.setFieldValue(`mobile`, e.target.checked)
              }}
              checked={formik.values.mobile}
            />
          </Col>
        </Form.Group>
        <div
          className={
            formik.values.dealItems.length > 0
              ? 'bg-light border p-4'
              : 'd-none'
          }
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          {formik.values.dealItems.map((_, index) => (
            <DealItem key={index} formik={formik} index={index} deal={deal} />
          ))}
        </div>
        {!isBuyerSupplier && (
          <>
            <Row>
              <Col>
                <Button
                  className={
                    formik.values.dealItems.length > 0
                      ? ' mt-3 ps-0'
                      : 'ps-0 mt-0'
                  }
                  variant="link"
                  size="sm"
                  onClick={() => {
                    formik.setFieldValue('dealItems', [
                      ...formik.values.dealItems,
                      {
                        productId: null,
                        productMaterial: null,
                        productForm: null,
                        productAttributes: [],
                        selectedProductAttributeValues: [],
                        supplierPrice: null,
                        supplierSecondaryPrice: null,
                        supplierQuantity: null,
                        supplierUnitOfMeasureId: null,
                        supplierUnitOfMeasureDescription: null,
                        buyerQuantity: null,
                        buyerPrice: null,
                        images: [],
                        buyerSecondaryPrice: null,
                        buyerUnitOfMeasureId: null,
                        buyerUnitOfMeasureDescription: null,
                      },
                    ])
                  }}
                >
                  <Plus size={18} /> Add Product
                </Button>
              </Col>
            </Row>
            <div className="footer-modal">
              <Form.Row className="mt-2">
                <Col md={deal ? 2 : 3}>
                  <Button
                    type="submit"
                    block
                    variant="outline-primary"
                    disabled={submitting}
                  >
                    <Folder className="mr-2" />
                    Save
                  </Button>
                </Col>

                {deal && (
                  <Col md={2}>
                    <Button
                      variant="outline-danger"
                      block
                      onClick={() => {
                        setSubmitting(true)
                        setDeleteDealId(deal.id)
                      }}
                      disabled={submitting}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                )}
              </Form.Row>
              {submitting && (
                <div className="mt-3">
                  <Loading message="Saving Deal..." />
                </div>
              )}
            </div>
          </>
        )}
      </Form>
    </>
  )
}

export default DealForm
