import { useState } from 'react'
import AWS from 'aws-sdk'
import { useQuery, gql } from '@apollo/client'
import config from '../config'

export function useAWS() {
  const [aws, setAWS] = useState()
  useQuery(
    gql`
      query DecryptSecrets($encryptedSecrets: [String]!) {
        decryptSecrets(encryptedSecrets: $encryptedSecrets) {
          encryptedSecret
          decryptedSecret
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        encryptedSecrets: [config.AWS.ACCESS_KEY, config.AWS.SECRET_ACCESS_KEY],
      },
      onCompleted(data) {
        let awsAccessKey
        let awsSecretAccessKey
        data.decryptSecrets.forEach((secret) => {
          if (secret.encryptedSecret === config.AWS.ACCESS_KEY) {
            awsAccessKey = secret.decryptedSecret
          } else if (secret.encryptedSecret === config.AWS.SECRET_ACCESS_KEY) {
            awsSecretAccessKey = secret.decryptedSecret
          }
        })
        setAWS(authenticateAWS(awsAccessKey, awsSecretAccessKey))
      },
    }
  )
  if (!aws) return null
  return aws
}
export function encodeS3ImageData(imageDataChunks) {
  const encodedImageData = imageDataChunks.reduce(
    (accumulatedImageData, imageDataChunk) =>
      accumulatedImageData + String.fromCharCode(imageDataChunk),
    ''
  )
  return btoa(encodedImageData).replace(/.{76}(?=.)/g, '$&\n')
}
export function useAWSS3() {
  const [client, setClient] = useState()
  const [bucket, setBucket] = useState()
  useQuery(
    gql`
      query DecryptSecrets($encryptedSecrets: [String]!) {
        decryptSecrets(encryptedSecrets: $encryptedSecrets) {
          encryptedSecret
          decryptedSecret
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        encryptedSecrets: [
          config.AWS.ACCESS_KEY,
          config.AWS.SECRET_ACCESS_KEY,
          config.AWS.S3_BUCKET,
        ],
      },
      onCompleted(data) {
        let awsAccessKey
        let awsSecretAccessKey
        data.decryptSecrets.forEach((secret) => {
          if (secret.encryptedSecret === config.AWS.ACCESS_KEY) {
            awsAccessKey = secret.decryptedSecret
          } else if (secret.encryptedSecret === config.AWS.SECRET_ACCESS_KEY) {
            awsSecretAccessKey = secret.decryptedSecret
          } else if (secret.encryptedSecret === config.AWS.S3_BUCKET) {
            setBucket(secret.decryptedSecret)
          }
        })
        const aws = authenticateAWS(awsAccessKey, awsSecretAccessKey)
        setClient(new aws.S3())
      },
    }
  )
  if (!client || !bucket) return {}
  return { client, bucket, encodeS3ImageData }
}
export function authenticateAWS(accessKeyId, secretAccessKey) {
  AWS.config.update({
    credentials: new AWS.Credentials({
      accessKeyId,
      secretAccessKey,
    }),
    region: config.AWS.REGION,
  })
  return AWS
}

export const downloadS3Object = async (
  awsS3,
  Key,
  fileName,
  postDownload = null
) => {
  await awsS3.client.getObject({ Bucket: awsS3.bucket, Key }, (error, data) => {
    if (!error) {
      let blob = new Blob([data.Body], { type: data.ContentType })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      if (postDownload) {
        postDownload()
      }
    }
  })
}
