import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { BoxArrowInRight, Lock, FileEarmarkPerson } from 'react-bootstrap-icons'
import { Row, Col, Button, Form as BootstrapForm } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import * as Yup from 'yup'
import SendConfirmationEmailModal from '../user/SendConfirmationEmailModal'
import ResetPasswordModal from '../user/ResetPasswordModal'
import { setLoginVars } from '../../libs/apollo'
import { setJwtLocalStorage } from '../../libs/utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const EmailLogin = (props) => {
  const { colMd } = props
  const [openSendConfirmationEmailModal, setOpenSendConfirmationEmailModal] =
    useState(false)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const history = useHistory()

  const toggleSendConfirmationEmailModal = () => {
    setOpenSendConfirmationEmailModal(!openSendConfirmationEmailModal)
  }

  const toggleResetPasswordModal = () => {
    setOpenResetPasswordModal(!openResetPasswordModal)
  }

  const [getUser] = useMutation(
    gql`
      mutation CreateJwtTokenMutation(
        $createJwtTokenInput: CreateJSONWebTokenInput!
      ) {
        createJwtToken(input: $createJwtTokenInput) {
          token
          refreshExpiresIn
          user {
            id
            fullName
            email
            emailConfirmed
            employee {
              id
            }
            groups {
              edges {
                node {
                  id
                  name
                }
              }
            }
            employee {
              id
            }
            client {
              id
              business {
                id
                name
              }
            }
            firstName
            lastName
          }
        }
      }
    `,
    {
      onCompleted(data) {
        if (data.createJwtToken) {
          setJwtLocalStorage(data.createJwtToken)
          setLoginVars(data.createJwtToken.user)
          if (data.createJwtToken.user.client) {
            history.push(
              `/business/${data.createJwtToken.user.client.business.id}`
            )
          } else if (data.createJwtToken.user.employee) {
            history.push('/deals')
          }
        }
      },
      onError(data) {
        toast.error('Login Information Not Found')
      },
    }
  )

  return (
    <>
      <Row>
        <Col md={colMd ? colMd : 12}>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string().nullable(),
              password: Yup.string().nullable(),
            })}
            onSubmit={(values) => {
              getUser({
                variables: {
                  createJwtTokenInput: {
                    username: values.email,
                    password: values.password,
                  },
                },
              })
            }}
          >
            <Form>
              <BootstrapForm.Group>
                <BootstrapForm.Label className="small">
                  Email
                </BootstrapForm.Label>
                <Field
                  name="email"
                  type="text"
                  className="form-control form-control-sm"
                />
              </BootstrapForm.Group>
              <div className="text-danger">
                <ErrorMessage name="email" />
              </div>
              <BootstrapForm.Group>
                <BootstrapForm.Label className="small">
                  Password
                </BootstrapForm.Label>
                <Field
                  name="password"
                  type="password"
                  className="form-control form-control-sm"
                />
              </BootstrapForm.Group>
              <div className="text-danger">
                <ErrorMessage name="password" />
              </div>
              <Button variant="link" size="sm" type="submit">
                <BoxArrowInRight className="mr-2" />
                Login
              </Button>
            </Form>
          </Formik>

          <div style={{ marginBottom: '-18px', marginTop: '-18px' }}>
            <Button
              size="sm"
              variant="link"
              className="p-0"
              type="Button"
              onClick={() => setOpenResetPasswordModal(true)}
            >
              <Lock className="mr-2" />
              Reset Password
            </Button>
          </div>
          <div>
            <Button
              className="p-0"
              size="sm"
              variant="link"
              onClick={() => setOpenSendConfirmationEmailModal(true)}
            >
              <FileEarmarkPerson className="mr-2" />
              Resend Verification Email
            </Button>
          </div>
        </Col>
      </Row>
      <SendConfirmationEmailModal
        showModal={openSendConfirmationEmailModal}
        toggleModal={toggleSendConfirmationEmailModal}
      />
      <ResetPasswordModal
        showModal={openResetPasswordModal}
        toggleModal={toggleResetPasswordModal}
      />
    </>
  )
}

export default EmailLogin
