import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { CaretDown, CaretRight, Trash, Folder } from 'react-bootstrap-icons'
import AuditLog from '../../audit_log/AuditLog'
import TaskGroupSearchInput from '../../common/node_search_input/TaskGroupSearchInput'
import EmployeeSearchInput from '../../common/node_search_input/EmployeeSearchInput'
import Loading from '../../common/Loading'
import NotificationsTable from '../../notification/NotificationsTable'

const TaskForm = (props) => {
  const { task, afterSubmit, dealId } = props
  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayNotifications, setDisplayNotifications] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const [deleteTask] = useMutation(
    gql`
      mutation DeleteTask($input: DeleteTaskInput!) {
        deleteTask(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Task Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Tasks'],
    }
  )

  const [createTask] = useMutation(
    gql`
      mutation CreateTask($input: CreateTaskInput!) {
        createTask(input: $input) {
          task {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Task Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Tasks'],
    }
  )

  const [updateTask] = useMutation(
    gql`
      mutation UpdateTask($input: UpdateTask!) {
        updateTask(input: $input) {
          task {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Task Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Tasks'],
    }
  )

  const formik = useFormik({
    initialValues: task
      ? {
          name: task.name,
          taskGroupId: task.taskGroup.id,
          taskGroupDescription: task.taskGroup.name,
          employees: task.employees.edges.map((edge) => ({
            id: edge.node.id,
            description: edge.node.user.fullName,
          })),
          order: task.order,
          dueDate: task.dueDate,
          complete: task.complete,
          removable: task.removable,
        }
      : {
          name: '',
          taskGroupId: '',
          taskGroupDescription: false,
          employees: [],
          order: null,
          dueDate: null,
          complete: null,
          removable: false,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      taskGroupId: Yup.string().required('Required'),
      taskGroupDescription: Yup.string().required('Required'),
      order: Yup.mixed().required('Required'),
      dueDate: Yup.mixed().nullable(),
      complete: Yup.boolean().required('Required'),
      removeable: Yup.boolean().required('Required'),
      employees: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const variables = {
        name: values.name,
        taskGroupId: values.taskGroupId,
        order: values.order,
        dueDate: values.dueDate,
        complete: values.complete,
        removeable: values.removeable,
        employee: values.employees.map((employee) => employee.id),
      }
      if (task) {
        variables.id = task.id
        updateTask({
          variables: {
            input: {
              taskInput: variables,
            },
          },
        })
      } else {
        createTask({
          variables: {
            input: {
              taskInput: variables,
            },
          },
        })
      }
    },
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>Task Group</Form.Label>
            <TaskGroupSearchInput
              formik={formik}
              disabled={task}
              dealId={dealId}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="input"
              name="name"
              className="form-control-sm"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>Order</Form.Label>
            <Form.Control
              as="input"
              name="order"
              type="number"
              min={1}
              className="form-control-sm"
              max={100}
              step={1}
              value={formik.values.order}
              onChange={formik.handleChange}
              isInvalid={formik.errors.order}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.order}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md={3}>
            <div>
              <Form.Label>Due Date</Form.Label>
            </div>
            <div>
              <DatePicker
                name="dueDate"
                className="form-control"
                showPopperArrow={false}
                popperPlacement="auto"
                selected={formik.values.dueDate}
                onChange={(date) => formik.setFieldValue('dueDate', date)}
                popperModifiers={{
                  flip: {
                    behavior: ['bottom'],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
            </div>
            {formik.errors.dueDate && (
              <div>
                <small className="text-danger">{formik.errors.dueDate}</small>
              </div>
            )}
          </Col>
          <Col sm={12} md={3}>
            <Form.Label>Complete</Form.Label>
            <Form.Check
              label={formik.values.complete ? 'Yes' : 'No'}
              onChange={(e) => {
                formik.setFieldValue(`complete`, e.target.checked)
              }}
              checked={formik.values.supplier}
            />
          </Col>
          <Col sm={12} md={6}>
            <Form.Label>Removable</Form.Label>
            <Form.Check
              label={formik.values.removeable ? 'Yes' : 'No'}
              onChange={(e) => {
                formik.setFieldValue(`removeable`, e.target.checked)
              }}
              checked={formik.values.removeable}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>Employees</Form.Label>
            <EmployeeSearchInput formik={formik} multiple />
          </Col>
        </Form.Group>
        {task && (
          <>
            <Row className="mt-3 mb-2">
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayNotifications(!displayNotifications)}
                  className="px-0 btn-link mr-1"
                  style={{ marginTop: '-10px' }}
                >
                  <>
                    {displayNotifications ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label>Notifications</Form.Label>
              </Col>
            </Row>
            {displayNotifications && <NotificationsTable />}
            <Row>
              <Col md={3} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayAuditLog(!displayAuditLog)}
                  className="px-0 btn-link mr-1"
                  style={{ marginTop: '-10px' }}
                >
                  <>
                    {displayAuditLog ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label>History</Form.Label>
              </Col>
            </Row>
            {displayAuditLog && (
              <Row>
                <Col md={12}>
                  <AuditLog contentType={task.contentType.model} id={task.id} />
                </Col>
              </Row>
            )}
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {task?.removable && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deleteTask({
                      variables: {
                        input: {
                          ids: task.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading
                message={deleting ? 'Deleting Task...' : 'Saving Task...'}
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default TaskForm
