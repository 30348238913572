import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Lock, BoxArrowInRight } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'

const ConfirmEmail = () => {
  const history = useHistory()
  const { confirmationToken, resetPasswordToken } = useParams()
  const [confirmed, setConfirmed] = useState(null)
  const [confirmEmailMutation] = useMutation(
    gql`
      mutation ConfirmEmailMutation($input: ConfirmUserEmailInput!) {
        confirmUserEmail(input: $input) {
          confirmedEmail
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted(data) {
        if (data.confirmUserEmail) {
          setConfirmed(true)
          toast.success('Email Verified')
        } else {
          setConfirmed(false)
          toast.error('Invalid or Expired Email Verification Link')
        }
      },
    }
  )
  const [resetPasswordMutation] = useMutation(
    gql`
      mutation UpdateUserPassword(
        $updateUserPasswordInput: UpdateUserPasswordInput!
      ) {
        updateUserPassword(input: $updateUserPasswordInput) {
          resetPassword
        }
      }
    `,
    {
      onCompleted: () => {
        formik.setValues({
          password: '',
          confirmPassword: '',
        })
        toast.success('Password Reset')
        history.push('/')
      },
      onError: () => {
        toast.error('Password Reset Expired')
      },
    }
  )
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(5, 'Password is too short')
        .max(100, 'Password is too long')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .min(5, 'Confirm password is too short')
        .max(100, 'Confirm password is too long')
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      resetPasswordMutation({
        variables: {
          updateUserPasswordInput: {
            password: values.password,
            passwordResetToken: resetPasswordToken,
          },
        },
      })
    },
  })
  useEffect(() => {
    if (confirmationToken) {
      confirmEmailMutation({
        variables: {
          input: {
            emailConfirmationToken: confirmationToken,
          },
        },
      })
    }
  }, [confirmationToken])
  if (confirmed === null) return <></>
  return (
    <>
      {confirmed === false && (
        <h1 className="mt-3">Invalid Email Verification Link</h1>
      )}
      {confirmed && (
        <>
          <Row className="mt-4">
            <Col md={12}>
              <h1>Email Verified</h1>
              <p>
                Complete the form below if you would like to reset your password
              </p>
            </Col>
          </Row>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mt-2">
              <Form.Group as={Col} xs={12} md={3}>
                <Form.Label>New Password</Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={4}>
                <Form.Control
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs={12} md={3}>
                <Form.Label>Confirm New Password</Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={4}>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={2}>
                <Button variant="link" type="submit">
                  <Lock className="mr-2" />
                  Reset Password
                </Button>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xs={12} md={2}>
                <Button
                  variant="link"
                  type="submit"
                  onClick={() => {
                    history.push('/')
                  }}
                >
                  <BoxArrowInRight className="mr-2" />
                  Back to Login
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  )
}
export default ConfirmEmail
