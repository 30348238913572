import React, { useState } from 'react'
import { Importer, ImporterField } from 'react-csv-importer'
import { ExclamationCircle } from 'react-bootstrap-icons'

import validator from 'validator'
import { Form, Row, Col, Button, Modal, Table } from 'react-bootstrap'
import 'react-csv-importer/dist/index.css'
import { objEmpty } from '../../libs/utils'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, gql } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { useInterval } from '../../libs/utils'

export default function BulkImportModal(props) {
  const { showModal, toggleModal } = props

  const [importerKey, setImporterKey] = useState(Math.random())

  const validatePhone = (phone) => {
    let cleanPhone = phone.replace(/[^\w]/g, '')
    if (cleanPhone.length == 10) {
      cleanPhone = `${1}${cleanPhone}`
    }
    if (isNaN(parseInt(cleanPhone)) || cleanPhone.charAt(0) != 1) {
      cleanPhone = false
    }
    return cleanPhone
  }

  useInterval(() => {
    document
      .querySelectorAll('.CSVImporter_IconButton')
      .forEach((importerButton) => {
        if (!importerButton.hasAttribute('type')) {
          importerButton.setAttribute('type', 'button')
        }
      })
    document
      .querySelectorAll('.CSVImporter_TextButton')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Import') {
          importerTextButton.innerHTML = 'Validate Import'
        }
      })
    document
      .querySelectorAll('.CSVImporter_ProgressDisplay__status')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Complete') {
          importerTextButton.innerHTML = ''
        }
      })
  }, 100)

  const transformedData = []
  const [importComplete, setImportComplete] = useState(false)
  const [rowsMissingInfo, setRowsMissingInfo] = useState([])

  const [createImportEmployeeMutation] = useMutation(
    gql`
      mutation BulkCreateEmpltees($input: ProcessEmployeeCsvInput!) {
        processEmployeeCsv(input: $input) {
          processing
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success(`Employees Import Started`)
        innerToggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['EmployeesQuery'],
    }
  )

  const formik = useFormik({
    initialValues: {
      csvEmployees: [],
    },
    validationSchema: Yup.object().shape({
      csvEmployees: Yup.array().of(
        Yup.object().shape({
          userFirstName: Yup.string().nullable(),
          userLastName: Yup.string().nullable(),
          gaiaAbbreviatedName: Yup.string().nullable(),
          userAbbreviatedName: Yup.string().nullable(),
          userEmail: Yup.string().nullable(),
          userSecondaryEmail: Yup.string().nullable(),
          userSecondaryPhoneNumber: Yup.string().nullable(),
          userPhoneNumber: Yup.string().nullable(),
          userAddressLineOne: Yup.string().nullable(),
          userAddressLineTwo: Yup.string().nullable(),
          userCity: Yup.string().nullable(),
          userState: Yup.string().nullable(),
          userZipCode: Yup.string().nullable(),
          smtpTls: Yup.boolean().nullable(),
          smtpHost: Yup.string().nullable(),
          smtpPort: Yup.number().nullable(),
          smtpUser: Yup.string().nullable(),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      createImportEmployeeMutation({
        variables: {
          input: {
            employeeCsvInput: {
              csvEmployees: values.csvEmployees,
            },
          },
        },
      })
    },
  })

  const innerToggleModal = () => {
    setImportComplete(false)
    setRowsMissingInfo([])
    formik.resetForm()
    toggleModal()
  }

  const newUploadClick = () => {
    setImportComplete(false)
    setImporterKey(Math.random())
    setRowsMissingInfo([])
    formik.resetForm()
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Form onSubmit={formik.handleSubmit}>
          <Modal
            size="xl"
            show={showModal}
            onHide={innerToggleModal}
            aria-labelledby="newResitReason"
            className="invmodal detail"
          >
            <Modal.Header closeButton>
              <Modal.Title id="new-title">Import Employees</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                className={
                  formik.values.csvEmployees.length > 0 ||
                  rowsMissingInfo.length > 0
                    ? 'mt-2 mb-1'
                    : 'd-none'
                }
                onClick={newUploadClick}
                size="sm"
                variant="outline-primary"
              >
                Restart Import
              </Button>
              <Row>
                <Col>
                  <Importer
                    key={importerKey}
                    assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={false} // optional, lets user choose to upload another file when import is complete
                    processChunk={(rows, { startIndex }) => {
                      rows.forEach((row, i) => {
                        let role
                        if (row.roles) {
                          role = row.roles.split('|')
                        }
                        const errors = []
                        let phoneNumber
                        let secondaryPhone
                        if (row.secondaryPhoneNumber) {
                          secondaryPhone = validatePhone(
                            row.secondaryPhoneNumber
                          )
                          if (!secondaryPhone) {
                            errors.push({
                              row: startIndex + i + 1,
                              column: 'Secondary Phone Number',
                              error:
                                'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                            })
                          }
                        }
                        if (row.phoneNumber) {
                          phoneNumber = validatePhone(row.phoneNumber)
                          if (!phoneNumber) {
                            errors.push({
                              row: startIndex + i + 1,
                              column: 'Phone Number',
                              error:
                                'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                            })
                          }
                        }
                        if (!row.firstName) {
                          errors.push({
                            row: startIndex + i + 1,
                            column: 'First Name',
                            error: 'First Name is required',
                          })
                        }
                        if (!row.lastName) {
                          errors.push({
                            row: startIndex + i + 1,
                            column: 'Last Name',
                            error: 'Last Name is required',
                          })
                        }
                        if (!row.email) {
                          errors.push({
                            row: startIndex + i + 1,
                            column: 'Email',
                            error: 'Email is required',
                          })
                        } else if (!validator.isEmail(row.email)) {
                          errors.push({
                            row: startIndex + i + 1,
                            column: 'Email',
                            error: 'Invalid Email format',
                          })
                        }
                        if (!row.permission) {
                          errors.push({
                            row: startIndex + i + 1,
                            column: 'Permission',
                            error: 'Permission is required',
                          })
                        } else if (
                          ![
                            'Administrator',
                            'Scheduling Manager',
                            'Scheduling Analyst',
                            'Equipment Manager',
                            'General Staff',
                          ].includes(row.permission)
                        ) {
                          errors.push({
                            row: startIndex + i + 1,
                            column: 'Permission',
                            error:
                              'Permission must be Administrator, Scheduling Manager, Scheduling Analyst, Equipment Manager or General Staff',
                          })
                        }
                        if (objEmpty(errors)) {
                          transformedData.push({
                            userFirstName: row.firstName ? row.firstName : null,
                            userLastName: row.lastName ? row.lastName : null,
                            userAbbreviatedName: row.abbreviatedName
                              ? row.abbreviatedName
                              : null,
                            userEmail: row.email ? row.email : null,
                            userSecondaryEmail: row.secondaryEmail
                              ? row.secondaryEmail
                              : null,
                            userPhoneNumber: row.phoneNumber
                              ? phoneNumber
                              : null,
                            userSecondaryPhoneNumber: row.secondaryPhoneNumber
                              ? secondaryPhone
                              : null,
                            userAddressLineOne: row.addressLineOne
                              ? row.addressLineOne
                              : null,
                            userAddressLineTwo: row.addressLineTwo
                              ? row.addressLineTwo
                              : null,
                            userCity: row.city ? row.city : null,
                            userState: row.state ? row.state : null,
                            userZipCode: row.zipCode ? row.zipCode : null,
                            smtpUser: row.senderEmail ? row.senderEmail : null,
                            smtpPassword: row.senderPassword
                              ? row.senderPassword
                              : null,
                            smtpHost: row.smtpHost ? row.smtpHost : null,
                            smtpPort: row.smtpPort ? row.smtpPort : null,
                            smtpTls: row.smtpTls ? row.smtpTls : null,
                            groupName: row.permission ? row.permission : null,
                            roleNames: row.roles ? role : null,
                          })
                        } else {
                          setRowsMissingInfo((prevState) => {
                            errors.forEach((error) => {
                              prevState.push(error)
                            })
                            return prevState
                          })
                        }
                      })
                    }}
                    onComplete={({ file, preview, fields, columnFields }) => {
                      setImportComplete(true)
                      formik.setFieldValue('csvEmployees', transformedData)
                    }}
                    skipEmptyLines
                  >
                    <ImporterField
                      name="firstName"
                      label="First Name"
                      optional
                    />
                    <ImporterField name="lastName" label="Last Name" optional />
                    <ImporterField
                      name="abbreviatedName"
                      label="Abbreviated Name"
                      optional
                    />
                    <ImporterField name="email" label="Email" optional />
                    <ImporterField
                      name="secondaryEmail"
                      label="Secondary Email"
                      optional
                    />
                    <ImporterField
                      name="phoneNumber"
                      label="Phone Number"
                      optional
                    />
                    <ImporterField
                      name="secondaryPhoneNumber"
                      label="Secondary phone"
                      optional
                    />

                    <ImporterField
                      name="addressLineOne"
                      label="Address Line One"
                      optional
                    />
                    <ImporterField
                      name="addressLineTwo"
                      label="Address Line Two"
                      optional
                    />
                    <ImporterField name="city" label="City" optional />
                    <ImporterField name="state" label="State" optional />
                    <ImporterField name="zipCode" label="Zip" optional />

                    <ImporterField
                      name="senderEmail"
                      label="Personal Email Sender Email"
                      optional
                    />
                    <ImporterField
                      name="senderPassword"
                      label="Personal Email Sender Password"
                      optional
                    />
                    <ImporterField
                      name="smtpHost"
                      label="Personal Email Sender Host"
                      optional
                    />
                    <ImporterField
                      name="smtpPort"
                      label="Personal Email Sender Port"
                      optional
                    />
                    <ImporterField
                      name="smtpTls"
                      label="Personal Email Sender Tls"
                      optional
                    />
                    <ImporterField name="roles" label="Roles" optional />
                    <ImporterField
                      name="permission"
                      label="Permission"
                      optional
                    />
                  </Importer>
                </Col>
              </Row>

              <Row
                className={
                  formik.values.csvEmployees.length > 0
                    ? 'text-secondary'
                    : 'd-none'
                }
              >
                <Col>
                  <p
                    className={
                      formik.values.csvEmployees.length > 0
                        ? 'text-secondary mt-3 mb-0'
                        : 'd-none'
                    }
                  >
                    CSV Data
                  </p>
                  <small
                    className={
                      formik.values.csvEmployees.length > 0
                        ? 'text-secondary'
                        : 'd-none'
                    }
                  >
                    {formik.values.csvEmployees.length} rows
                  </small>
                  <Table
                    responsive
                    size="sm"
                    bordered
                    style={{ maxHeight: '400px' }}
                    className={
                      formik.values.csvEmployees.length > 0
                        ? 'mt-2 d-block'
                        : 'd-none'
                    }
                  >
                    <thead>
                      <tr>
                        <th>
                          <small className="font-weight-bold">First Name</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Last Name</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Abbreviated Name
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Email</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Secondary Email
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Phone</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Secondary Phone
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Address Line One
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Address Line Two
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">City</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">State</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Zip Code</small>
                        </th>

                        <th>
                          <small className="font-weight-bold">
                            Personal Email Sender Email
                          </small>
                        </th>

                        <th>
                          <small className="font-weight-bold">
                            Personal Email Sender Password
                          </small>
                        </th>

                        <th>
                          <small className="font-weight-bold">
                            Personal Email Sender Host
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Personal Email Sender Port
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Personal Email Sender Tls
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">
                            Permissions
                          </small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Role Names</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.csvEmployees.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userFirstName}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userLastName}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userAbbreviatedName}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '200px',
                                }}
                              >
                                {row.userEmail}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '200px',
                                }}
                              >
                                {row.userSecondaryEmail}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userPhoneNumber}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userSecondaryPhoneNumber}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '200px',
                                }}
                              >
                                {row.userAddressLineOne}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userAddressLineTwo}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userCity}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userState}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.userZipCode}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.smtpUser}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.smtpPassword}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.smtpHost}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '200px',
                                }}
                              >
                                {row.smtpPort}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '200px',
                                }}
                              >
                                {row.smtpTls}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.groupName}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {row.roleNames}
                              </small>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {rowsMissingInfo.length > 0 && (
                <>
                  <Row className="mt-3">
                    <Col>
                      <h3>
                        <ExclamationCircle className="mr-2 text-danger" />
                        Import Errors
                      </h3>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Table
                        responsive
                        size="sm"
                        bordered
                        style={{ maxHeight: '400px' }}
                      >
                        <thead>
                          <tr>
                            <th>
                              <small className="font-weight-bold">Row</small>
                            </th>
                            <th>
                              <small className="font-weight-bold">Column</small>
                            </th>
                            <th>
                              <small className="font-weight-bold">Error</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowsMissingInfo.map((csvError, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {csvError.row}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {csvError.column}
                                  </small>
                                </td>
                                <td>
                                  <small className="d-block">
                                    {csvError.error}
                                  </small>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              )}
              {importComplete && !rowsMissingInfo.length && (
                <Row>
                  <Col xs={4} sm={2}>
                    <Button
                      type="submit"
                      variant="outline-primary"
                      size="sm"
                      block
                      onClick={formik.handleSubmit}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Modal>
        </Form>
      </div>
    </>
  )
}
