import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { Trash, TruckFront } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import CargoProviderModal from './CargoProviderModal'
import Loading from '../../common/Loading'
import DeleteCargoProviderModal from './DeleteCargoProviderModal'
import { loggedInUserVar } from '../../../libs/apollo'
import { toTitleCase } from '../../../libs/utils'

const CargoProviders = (props) => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editNode, setEditNode] = useState()
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const history = useHistory()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query CargoProviders($cursor: String, $search: String) {
        cargoProviders(first: 20, after: $cursor, name_Icontains: $search) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              type
              contactFirstName
              contactLastName
              contactPhone
              contactEmail
              fax
              cargoUnitInstanceCount
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.cargoProviders?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.cargoProviders) {
      let text = 'Search 0 records'
      if (queryData.cargoProviders.nodeCount > 0) {
        text = `Search ${queryData.cargoProviders.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    history.push(`/cargo-provider/${cell.row.original.node.id}`)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => {
        return row.node.name
      },
    },
    {
      Header: 'Cargo Type',
      id: 'cargoType',
      accessor: (row) => {
        return toTitleCase(row.node.type)
      },
    },
    {
      Header: 'Contact Name',
      id: 'contactName',
      accessor: (row) => {
        let name
        if (row.node.contactFirstName) {
          name = row.node.contactFirstName
        }
        if (row.node.contactLastName) {
          name = `${name} ${row.node.contactFirstName}`
        }
        return name
      },
    },
    {
      Header: 'Contact Email',
      id: 'contactEmail',
      accessor: (row) => {
        return row.node.contactEmail && row.node.contactEmail
      },
    },
    {
      Header: 'Contact Phone',
      id: 'contactPhone',
      accessor: (row) => {
        return row.node.contactPhone && row.node.contactPhone
      },
    },
    {
      Header: 'Fulfillments',
      id: 'fulfillments',
      accessor: (row) => {
        return row.node.cargoUnitInstanceCount
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.businesses?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  if (queryError) return <>Error loading cargo prodivers</>
  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Cargo Providers..." />
        </Col>
      </Row>
    )

  return (
    <>
      <Row style={{ marginTop: '-10px' }}>
        <Col>
          {canMutate && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <TruckFront className="mr-2" />
              New Cargo Provider
            </Button>
          )}
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  Delete Cargo Provider
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.cargoProviders?.edges
                ? queryData?.cargoProviders?.edges
                : []
            }
            loadingMessage="Loading Cargo Providers..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.cargoProviders?.pageInfo?.hasNextPage}
            onTdClicks={{
              name: (cell) => onTdClick(cell),
              cargoType: (cell) => onTdClick(cell),
              contactName: (cell) => onTdClick(cell),
              contactEmail: (cell) => onTdClick(cell),
              contactPhone: (cell) => onTdClick(cell),
              fulfillments: (cell) => onTdClick(cell),
            }}
            tableHeight={800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      {showForm && (
        <CargoProviderModal showModal={showForm} toggleModal={toggleModal} />
      )}
      {showDeleteModal && (
        <DeleteCargoProviderModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedNodeIds={setCheckedNodeIds}
          ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
          id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
        />
      )}
    </>
  )
}
export default CargoProviders
