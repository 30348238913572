import React from 'react'
import { Modal } from 'react-bootstrap'
import ClientForm from './ClientForm'
import { People } from 'react-bootstrap-icons'

const ClientModal = (props) => {
  const { showModal, toggleModal, client, businessId } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal
          size={client ? 'xl' : 'lg'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <People className="mr-2" />
              {client ? <>Edit {client.user.fullName}</> : <>New Client</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ClientForm
              client={client}
              afterSubmit={toggleModal}
              modal
              businessId={businessId}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ClientModal
