import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  CaretDown,
  CaretRight,
  CloudArrowDown,
  ArrowUpRightCircle,
  Trash,
  Folder,
} from 'react-bootstrap-icons'
import AuditLog from '../audit_log/AuditLog'
import DealSearchInput from '../common/node_search_input/DealSearchInput'
import BusinessSearchInput from '../common/node_search_input/BusinessSearchInput'
import CargoUnitInstanceContainerItemSearchInput from '../common/node_search_input/CargoUnitInstanceContainerItemSearchInput'
import SalesOrderSearchInput from '../common/node_search_input/SalesOrderSearchInput'
import PurchaseOrderSearchInput from '../common/node_search_input/PurchaseOrderSearchInput'
import DealItemSearchInput from '../common/node_search_input/DealItemSearchInput'
import Loading from '../common/Loading'
import { FilePond } from 'react-filepond'
import { useAWSS3, downloadS3Object } from '../../libs/aws'

const FileForm = (props) => {
  const {
    file,
    afterSubmit,
    dealId,
    businessId,
    dealItemId,
    dealItemDescription,
    businessDescription,
    dealDescription,
    purchaseOrderId,
    purchaseOrderDescription,
    salesOrderId,
    salesOrderDescription,
    cargoUnitInstanceContainerItemId,
    cargoUnitInstanceContainerItemDescription,
  } = props
  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const history = useHistory()
  const awsS3 = useAWSS3()

  const [deleteFile] = useMutation(
    gql`
      mutation DeleteFile($input: DeleteFileInput!) {
        deleteFile(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`File Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Files'],
    }
  )

  const [createFile] = useMutation(
    gql`
      mutation CreateFile($input: CreateFileInput!) {
        createFile(input: $input) {
          file {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`File Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Files'],
    }
  )

  const [updateFile] = useMutation(
    gql`
      mutation UpdateFile($input: UpdateFileInput!) {
        updateFile(input: $input) {
          file {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`File Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Files'],
    }
  )

  let associatedRecordType = ''
  if (file) {
    if (file.business) {
      associatedRecordType = 'business'
    } else if (file.deal) {
      associatedRecordType = 'deal'
    } else if (file.purchaseOrder) {
      associatedRecordType = 'purchaseOrder'
    } else if (file.salesOrder) {
      associatedRecordType = 'salesOrder'
    } else if (file.cargoUnitInstanceContainerItem) {
      associatedRecordType = 'cargo'
    } else if (file.dealItem) {
      associatedRecordType = 'dealItem'
    }
  } else if (dealId) {
    associatedRecordType = 'deal'
  } else if (businessId) {
    associatedRecordType = 'business'
  } else if (salesOrderId) {
    associatedRecordType = 'salesOrder'
  } else if (purchaseOrderId) {
    associatedRecordType = 'purchaseOrder'
  } else if (cargoUnitInstanceContainerItemId) {
    associatedRecordType = 'cargo'
  } else if (dealItemId) {
    associatedRecordType = 'dealItem'
  }
  const formik = useFormik({
    initialValues: file
      ? {
          displayName: file.displayName,
          associatedRecordType,
          dealId: file.deal && file.deal.id,
          dealItemId: file.dealItem && file.dealItem.id,
          dealItemDescription: file.dealItem && file.dealItem.description,
          dealDescription: file.deal && file.deal.description,
          businessId: file.business && file.business.id,
          businessDescription: file.business && file.business.name,
          supplierVisibility: file.supplierVisibility,
          buyerVisibility: file.buyerVisibility,
          businessVisibility: file.businessVisibility,
          cargoUnitInstanceContainerItemId:
            file.cargoUnitInstanceContainerItem?.id,
          cargoUnitInstanceContainerItemDescription:
            file.cargoUnitInstanceContainerItem &&
            `Load ${file.cargoUnitInstanceContainerItem.cargoUnitInstanceContainer.cargoUnitInstance.loadId} | Deal ${file.cargoUnitInstanceContainerItem.cargoUnitInstanceContainer.cargoUnitInstance.deal?.dealNumber}`,
          purchaseOrderId: file.purchaseOrder?.id,
          purchaseOrderDescription: file.purchaseOrder?.description,
          salesOrderId: file.salesOrder?.id,
          salesOrderDescription: file.salesOrder?.description,
        }
      : {
          displayName: '',
          associatedRecordType,
          dealId,
          dealItemId,
          dealItemDescription,
          dealDescription,
          businessId,
          cargoUnitInstanceContainerItemId,
          cargoUnitInstanceContainerItemDescription,
          businessDescription,
          salesOrderId,
          salesOrderDescription,
          purchaseOrderId,
          purchaseOrderDescription,
          file: null,
          supplierVisibility: true,
          buyerVisibility: true,
          businessVisibility: true,
        },
    validationSchema: Yup.object().shape({
      displayName: Yup.string().required('Required'),
      associatedRecordType: Yup.string().required('Required'),
      dealItemId: Yup.mixed().when('associatedRecordType', {
        is: 'dealItem',
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed(),
      }),
      businessId: Yup.mixed().when('associatedRecordType', {
        is: 'business',
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed(),
      }),
      dealId: Yup.mixed().when('associatedRecordType', {
        is: 'deal',
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed(),
      }),
      dealDescription: Yup.mixed().nullable(),
      purchaseOrderId: Yup.mixed().when('associatedRecordType', {
        is: 'purchaseOrder',
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed(),
      }),
      purchaseOrderDescription: Yup.mixed().nullable(),
      salesOrderId: Yup.mixed().when('associatedRecordType', {
        is: 'salesOrder',
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed(),
      }),
      salesOrderDescription: Yup.mixed().nullable(),
      cargoUnitInstanceContainerItemId: Yup.mixed().when(
        'associatedRecordType',
        {
          is: 'cargo',
          then: Yup.mixed().required('Required'),
          otherwise: Yup.mixed(),
        }
      ),
      cargoUnitInstanceContainerItemDescription: Yup.mixed().nullable(),
      businessDescription: Yup.mixed().nullable(),
      file: Yup.mixed()
        .nullable()
        .test('file', 'File Required', (value) => {
          return file || value
        }),
      supplierVisibility: Yup.mixed(),
      buyerVisibility: Yup.mixed(),
      businessVisibility: Yup.mixed(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const variables = {
        displayName: values.displayName,
      }
      if (values.associatedRecordType === 'business') {
        variables.businessId = values.businessId
        variables.dealId = null
        variables.purchaseOrderId = null
        variables.salesOrderId = null
        variables.dealItemId = null
        variables.cargoUnitInstanceContainerItemId = null
        variables.businessVisibility = values.businessVisibility
      } else if (values.associatedRecordType === 'deal') {
        variables.businessId = null
        variables.purchaseOrderId = null
        variables.salesOrderId = null
        variables.dealItemId = null
        variables.cargoUnitInstanceContainerItemId = null
        variables.dealId = values.dealId
        variables.supplierVisibility = values.supplierVisibility
        variables.buyerVisibility = values.buyerVisibility
      } else if (values.associatedRecordType === 'cargo') {
        variables.businessId = null
        variables.dealId = null
        variables.dealItemId = null
        variables.purchaseOrderId = null
        variables.salesOrderId = null
        variables.cargoUnitInstanceContainerItemId =
          values.cargoUnitInstanceContainerItemId
        variables.supplierVisibility = values.supplierVisibility
        variables.buyerVisibility = values.buyerVisibility
      } else if (values.associatedRecordType === 'salesOrder') {
        variables.businessId = null
        variables.dealId = null
        variables.dealItemId = null
        variables.purchaseOrderId = null
        variables.salesOrderId = values.salesOrderId
        variables.cargoUnitInstanceContainerItemId = null
        variables.supplierVisibility = values.supplierVisibility
        variables.buyerVisibility = values.buyerVisibility
      } else if (values.associatedRecordType === 'purchaseOrder') {
        variables.businessId = null
        variables.dealId = null
        variables.dealItemId = null
        variables.purchaseOrderId = values.purchaseOrderId
        variables.salesOrderId = null
        variables.cargoUnitInstanceContainerItemId = null
        variables.supplierVisibility = values.supplierVisibility
        variables.buyerVisibility = values.buyerVisibility
      } else if (values.associatedRecordType === 'dealItem') {
        variables.businessId = null
        variables.dealId = null
        variables.dealItemId = values.dealItemId
        variables.purchaseOrderId = null
        variables.salesOrderId = null
        variables.cargoUnitInstanceContainerItemId = null
        variables.supplierVisibility = values.supplierVisibility
        variables.buyerVisibility = values.buyerVisibility
      }
      if (values.file) {
        variables.file = values.file[0].file
      }
      if (file) {
        variables.id = file.id
        updateFile({
          variables: {
            input: {
              fileInput: variables,
            },
          },
        })
      } else {
        createFile({
          variables: {
            input: {
              fileInput: variables,
            },
          },
        })
      }
    },
  })
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col md={6}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="input"
              name="displayName"
              className="form-control-sm"
              value={formik.values.displayName}
              onChange={formik.handleChange}
              isInvalid={formik.errors.displayName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.displayName}
            </Form.Control.Feedback>
          </Col>
          {!dealItemId && (
            <Col md={6}>
              <Form.Label>Associated Record Type</Form.Label>
              <Form.Control
                name="associatedRecordType"
                as="select"
                className="form-control-sm"
                value={formik.values.associatedRecordType}
                onChange={formik?.handleChange}
                isInvalid={formik?.errors?.associatedRecordType}
              >
                <option value="">- - -</option>
                <option value="business">Business</option>
                <option value="deal">Deal</option>
                <option value="dealItem">Deal Product</option>
                <option value="cargo">Cargo</option>
                <option value="purchaseOrder">Purchase Order</option>
                <option value="salesOrder">Sales Order</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik?.errors?.associatedRecordType}
              </Form.Control.Feedback>
            </Col>
          )}
        </Form.Group>
        <Form.Group as={Row}>
          {formik.values.associatedRecordType === 'business' && (
            <>
              <Col md={6}>
                <Form.Label>
                  Business
                  {formik.values.businessId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/business/${formik.values.businessId}`)
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <BusinessSearchInput
                  formik={formik}
                  error={formik.errors.businessId}
                />
              </Col>
              <Col md={6}>
                <Form.Label>Visible to Clients</Form.Label>
                <Form.Check
                  label={formik.values.businessVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`businessVisibility`, e.target.checked)
                  }}
                  checked={formik.values.businessVisibility}
                />
              </Col>
            </>
          )}
          {formik.values.associatedRecordType === 'deal' && (
            <>
              <Col sm="12" md={6}>
                <Form.Label>
                  Deal
                  {formik.values.dealId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/deal/${formik.values.dealId}`)
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <DealSearchInput formik={formik} error={formik.errors.dealId} />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Suppliers</Form.Label>
                <Form.Check
                  label={formik.values.supplierVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`supplierVisibility`, e.target.checked)
                  }}
                  checked={formik.values.supplierVisibility}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Buyers</Form.Label>
                <Form.Check
                  label={formik.values.buyerVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`buyerVisibility`, e.target.checked)
                  }}
                  checked={formik.values.buyerVisibility}
                />
              </Col>
            </>
          )}
          {formik.values.associatedRecordType === 'dealItem' && (
            <>
              <Col sm="12" md={6}>
                <Form.Label>
                  Deal Product
                  {formik.values.dealItemId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/deal/${formik.values.dealId}`)
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <DealItemSearchInput
                  formik={formik}
                  error={formik.errors.dealItemId}
                  disabled={dealItemId}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Suppliers</Form.Label>
                <Form.Check
                  label={formik.values.supplierVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`supplierVisibility`, e.target.checked)
                  }}
                  checked={formik.values.supplierVisibility}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Buyers</Form.Label>
                <Form.Check
                  label={formik.values.buyerVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`buyerVisibility`, e.target.checked)
                  }}
                  checked={formik.values.buyerVisibility}
                />
              </Col>
            </>
          )}
          {formik.values.associatedRecordType === 'cargo' && (
            <>
              <Col sm="12" md={6}>
                <Form.Label>
                  Cargo
                  {formik.values.cargoId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/deal/${formik.values.dealId}`)
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <CargoUnitInstanceContainerItemSearchInput
                  formik={formik}
                  error={formik.errors.dealId}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Suppliers</Form.Label>
                <Form.Check
                  label={formik.values.supplierVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`supplierVisibility`, e.target.checked)
                  }}
                  checked={formik.values.supplierVisibility}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Buyers</Form.Label>
                <Form.Check
                  label={formik.values.buyerVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`buyerVisibility`, e.target.checked)
                  }}
                  checked={formik.values.buyerVisibility}
                />
              </Col>
            </>
          )}
          {formik.values.associatedRecordType === 'salesOrder' && (
            <>
              <Col sm="12" md={6}>
                <Form.Label>
                  Sales Order
                  {formik.values.salesOrderId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/deal/${formik.values.dealId}`)
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <SalesOrderSearchInput
                  formik={formik}
                  error={formik.errors.dealId}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Suppliers</Form.Label>
                <Form.Check
                  label={formik.values.supplierVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`supplierVisibility`, e.target.checked)
                  }}
                  checked={formik.values.supplierVisibility}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Buyers</Form.Label>
                <Form.Check
                  label={formik.values.buyerVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`buyerVisibility`, e.target.checked)
                  }}
                  checked={formik.values.buyerVisibility}
                />
              </Col>
            </>
          )}
          {formik.values.associatedRecordType === 'purchaseOrder' && (
            <>
              <Col sm="12" md={6}>
                <Form.Label>
                  Purchase Order
                  {formik.values.purchaseOrderId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/deal/${formik.values.dealId}`)
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <PurchaseOrderSearchInput
                  formik={formik}
                  error={formik.errors.dealId}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Suppliers</Form.Label>
                <Form.Check
                  label={formik.values.supplierVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`supplierVisibility`, e.target.checked)
                  }}
                  checked={formik.values.supplierVisibility}
                />
              </Col>
              <Col md={3}>
                <Form.Label>Visible to Buyers</Form.Label>
                <Form.Check
                  label={formik.values.buyerVisibility ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`buyerVisibility`, e.target.checked)
                  }}
                  checked={formik.values.buyerVisibility}
                />
              </Col>
            </>
          )}
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <FilePond
              maxFileSize="100MB"
              allowFileSizeValidation
              files={formik.values.file}
              maxFiles={1}
              labelMaxFileSize="Maximum file size is {filesize}"
              onupdatefiles={(file) => {
                formik.setFieldValue('file', file)
              }}
              labelIdle='Drag and drop files or <span class="filepond--label-action">Browse</span>'
            />
            {formik.errors?.file && (
              <div>
                <small className="text-danger">{formik.errors.file}</small>
              </div>
            )}
          </Col>
        </Form.Group>
        {file && (
          <>
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayAuditLog(!displayAuditLog)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayAuditLog ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">History</Form.Label>
              </Col>
            </Row>
            {displayAuditLog && (
              <AuditLog
                contentType={file.contentType.model}
                id={file.id}
                tableHeight={400}
                fetchPolicy="no-cache"
              />
            )}
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {file && (
              <>
                <Col md={3}>
                  <Button
                    variant="outline-primary"
                    block
                    onClick={() => {
                      downloadS3Object(
                        awsS3,
                        file.fileName,
                        file.displayName,
                        () => {
                          toast.success(`File Downloaded`)
                        }
                      )
                    }}
                  >
                    <CloudArrowDown className="mr-2" />
                    Download
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    variant="outline-danger"
                    block
                    onClick={() => {
                      setSubmitting(true)
                      setDeleting(true)
                      deleteFile({
                        variables: {
                          input: {
                            ids: file.id,
                          },
                        },
                      })
                    }}
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              </>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading
                message={deleting ? 'Deleting File...' : 'Saving File...'}
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default FileForm
