import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../libs/apollo'
import Layout from '../components/layout/Layout'

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  let user = useReactiveVar(loggedInUserVar)
  if (user) {
    return (
      <Layout>
        <Route {...rest} render={(props) => <Component {...props} />} />
      </Layout>
    )
  } else {
    return <Redirect to="/" />
  }
}

export default AuthenticatedRoute
