import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Col, Tab } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  InfoCircle,
  CashCoin,
  Receipt,
  ClockHistory,
  Bell,
} from 'react-bootstrap-icons'
import NotificationsTable from '../../notification/NotificationsTable'
import Loading from '../../common/Loading'
import Tabs from '../../common/Tabs'
import SalesOrderForm from './SalesOrderForm'
import AuditLog from '../../audit_log/AuditLog'
import Payments from '../payment/Payments'
import Invoices from '../invoice/Invoices'

const SalesOrder = (props) => {
  const { salesOrderId } = useParams()

  const [activeTab, setActiveTab] = useState('Details')
  const { error, data } = useQuery(
    gql`
      query SalesOrder($id: ID!) {
        salesOrder(id: $id) {
          id
          number
          description
          totalAmount
          netTerms
          termsFee
          termsOn
          contentType {
            model
          }
          salesOrderItems {
            edges {
              node {
                id
                unitOfMeasure {
                  id
                  shortName
                  pluralName
                }
                dealItem {
                  id
                  supplier {
                    business {
                      name
                    }
                  }
                  deal {
                    id
                    buyer {
                      business {
                        name
                      }
                    }
                    dealNumber
                  }
                }
                quantity
                amount
                contentType {
                  model
                }
                dealItem {
                  productVariant {
                    product {
                      name
                    }
                  }
                }
              }
            }
          }
          buyer {
            id
            business {
              id
              name
              invoiceEmail
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: salesOrderId,
      },
    }
  )
  if (error) return <>Error loading sales order</>
  if (!data)
    return (
      <span className="mt-5">
        <Loading message="Loading Sales Order..." />
      </span>
    )
  const { salesOrder } = data
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Sales Order #{salesOrder.number}
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Details"
          eventKey="Details"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <InfoCircle className="mr-2" />
              Details
            </b>
          }
        >
          <Row>
            <Col md={6}>
              <SalesOrderForm salesOrder={salesOrder} />
            </Col>
          </Row>
        </Tab>
        <Tab
          key="Deals"
          eventKey="Deals"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CashCoin className="mr-2" />
              Payments
            </b>
          }
        >
          <Payments salesOrderId={salesOrder.id} />
        </Tab>
        <Tab
          key="Invoice"
          eventKey="Invoices"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Receipt className="mr-2" />
              Invoices
            </b>
          }
        >
          <Invoices salesOrder={salesOrder} />
        </Tab>
        <Tab
          key="Notifications"
          eventKey="Notifications"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Bell className="mr-2" />
              Notifications
            </b>
          }
        >
          <NotificationsTable salesOrderId={salesOrder.id} />
        </Tab>
        <Tab
          key="History"
          eventKey="History"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <ClockHistory className="mr-2" />
              History
            </b>
          }
        >
          <AuditLog
            contentTypesRelayIds={{
              [salesOrder.contentType.model]: [salesOrder.id],
              [salesOrder.salesOrderItems.edges[0].node.contentType.model]:
                salesOrder.salesOrderItems.edges.map((edge) => edge.node.id),
            }}
            recordColumns
            tableHeight={400}
          />
        </Tab>
      </Tabs>
    </>
  )
}
export default SalesOrder
