import React from 'react'
import { Modal } from 'react-bootstrap'
import FileForm from './FileForm'
import { FileEarmarkText } from 'react-bootstrap-icons'

const FileModal = (props) => {
  const {
    showModal,
    dealItemId,
    toggleModal,
    file,
    dealId,
    dealItemDescription,
    dealDescription,
    businessDescription,
    businessId,
    purchaseOrderId,
    purchaseOrderDescription,
    salesOrderId,
    salesOrderDescription,
    cargoUnitInstanceContainerItemId,
    cargoUnitInstanceContainerItemDescription,
  } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size={'lg'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FileEarmarkText className="mr-2" />
              {file ? <>{file.displayName}</> : <>New File</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FileForm
              file={file}
              afterSubmit={toggleModal}
              dealId={dealId}
              dealDescription={dealDescription}
              businessDescription={businessDescription}
              businessId={businessId}
              purchaseOrderId={purchaseOrderId}
              purchaseOrderDescription={purchaseOrderDescription}
              salesOrderId={salesOrderId}
              dealItemId={dealItemId}
              dealItemDescription={dealItemDescription}
              salesOrderDescription={salesOrderDescription}
              cargoUnitInstanceContainerItemId={
                cargoUnitInstanceContainerItemId
              }
              cargoUnitInstanceContainerItemDescription={
                cargoUnitInstanceContainerItemDescription
              }
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default FileModal
