import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'

const DeleteInvoicesModal = (props) => {
  const {
    showModal,
    toggleModal,
    stripeInvoiceId,
    stripeInvoiceIds,
    setCheckedInvoiceIds,
  } = props
  const [loading, setLoading] = useState(false)
  let _stripeInvoiceIds = [stripeInvoiceId]
  if (stripeInvoiceIds) {
    _stripeInvoiceIds = stripeInvoiceIds
  }
  const [deleteStripeInvoice] = useMutation(
    gql`
      mutation DeleteStripeInvoiceMutation(
        $deleteStripeInvoiceInput: DeleteStripeInvoiceInput!
      ) {
        deleteStripeInvoice(input: $deleteStripeInvoiceInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Deleting Invoices')
        toggleModal()
        if (setCheckedInvoiceIds) {
          setCheckedInvoiceIds([])
        }
        setLoading(false)
      },
      refetchQueries: ['StripeInvoices'],
      errorPolicy: 'all',
    }
  )
  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Invoice{stripeInvoiceIds && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the invoice
                  {stripeInvoiceIds && <>s</>}? Open invoices will be voided in
                  Stripe when deleted.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ offset: 2, span: 8 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={loading}
                  onClick={() => {
                    setLoading(true)
                    deleteStripeInvoice({
                      variables: {
                        deleteStripeInvoiceInput: {
                          stripeInvoiceIds: _stripeInvoiceIds,
                        },
                      },
                    })
                  }}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
            {loading && (
              <Row>
                <Col>
                  <Loading />
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteInvoicesModal
