import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Image } from 'react-bootstrap'
import EmailLogin from './EmailLogin'
import { useReactiveVar } from '@apollo/client'
import { settingsVar, loggedInUserVar } from '../../libs/apollo'
import toast from 'react-hot-toast'
import { useAWSS3 } from '../../libs/aws'

const LoginBox = () => {
  const [logoImage, setLogoImage] = useState()
  const location = useLocation()
  const history = useHistory()
  const settings = useReactiveVar(settingsVar)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const awsS3 = useAWSS3()
  let md = 6
  if (location.pathname === '/') {
    md = 12
  }

  useEffect(() => {
    if (location && location.search === '?created') {
      toast.success('Account Created')
      localStorage.setItem('initialLoginView', 'email')
      history.push('/')
    }
  }, [location])

  useEffect(() => {
    if (settings && awsS3?.client) {
      getS3Object(settings.thumbnailImageName)
    }
  }, [settings, awsS3?.client])

  async function getS3Object(Key) {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          setLogoImage(awsS3.encodeS3ImageData(data.Body))
        }
      }
    )
  }

  useEffect(() => {
    if (loggedInUser && !loggedInUser.autoLogOut) {
      if (loggedInUser.permissions.isEmployee) {
        history.push('/deals')
      }
    }
  }, [loggedInUser])

  if (!settings) return <></>

  return (
    <>
      <Row
        className="justify-content-md-center align-items-center"
        style={{ height: '70vh' }}
      >
        <Col md={3}>
          <div className="text-center">
            {logoImage && (
              <Image
                style={{ height: '50px' }}
                src={`data:image/jpeg;base64,${logoImage}`}
                alt="Studio logo"
              />
            )}
            <p style={{ fontSize: '20px', marginTop: '20px' }}>
              Login to {settings.name}
            </p>
          </div>
          <div>
            <EmailLogin />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default LoginBox
