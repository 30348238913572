import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal, Tab } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'
import { FilePond } from 'react-filepond'
import { Folder, Trash } from 'react-bootstrap-icons'
import Tabs from '../../common/Tabs'
import FolderStructure from './FolderStructure'

const EditBoxConnector = (props) => {
  const { showModal, toggleModal, boxConnector } = props
  if (!showModal || !boxConnector) return <></>
  const [submitting, setSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState('Organizations')
  const [disabling, setDisabling] = useState(false)
  const [authJson, setAuthJson] = useState()
  const [update] = useMutation(
    gql`
      mutation UpdateBoxConnector(
        $updateBoxConnectorInput: UpdateBoxConnectorInput!
      ) {
        updateBoxConnector(input: $updateBoxConnectorInput) {
          boxConnector {
            id
            authValid
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (data.updateBoxConnector.boxConnector.authValid) {
          toast.success('Box Integration Enabled')
        } else if (disabling) {
          toast.success('Box Integration Disabled')
          setDisabling(false)
        } else {
          toast.error('Invalid Box Credentials')
        }
        innerToggleModel()
      },
      refetchQueries: ['BoxConnector', 'BoxConnectorValid'],
      errorPolicy: 'all',
    }
  )

  const innerToggleModel = () => {
    toggleModal()
    setSubmitting(false)
    setAuthJson()
    setActiveTab('Organizations')
    formik.resetForm()
  }

  const removeIds = (folderStructure) => {
    if (Array.isArray(folderStructure)) {
      folderStructure.forEach((element) => {
        removeIds(element)
      })
    } else if (
      typeof folderStructure === 'object' &&
      folderStructure !== null
    ) {
      delete folderStructure._id // Remove the _id property
      Object.keys(folderStructure).forEach((key) => {
        removeIds(folderStructure[key]) // Recursively apply to children
      })
    }
    return folderStructure // Return the modified object
  }

  const formik = useFormik({
    initialValues: {
      auth: null,
      asUser: null,
      rootBoxFolderId: null,
      organizationFolderStructure: null,
      createforOrganizations: null,
      jobFolderStructure: null,
      createForJobs: null,
      createForSession: null,
      sessionFolderStructure: null,
      employeeFolderStructure: null,
      createForEmployees: null,
      subjectGroupFolderStructure: null,
      createForSubjectGroups: null,
    },
    validationSchema: Yup.object().shape({
      auth: Yup.mixed(),
      asUser: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      rootBoxFolderId: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      organizationFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForOrganizations: Yup.boolean().required('Required'),
      jobFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      subjectFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForJobs: Yup.boolean().required('Required'),
      employeeFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForSessions: Yup.boolean().required('Required'),
      sessionFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForEmployees: Yup.boolean().required('Required'),
      subjectGroupFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForSubjectGroups: Yup.boolean().required('Required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const folderStructures = getCachedFolderStructures()
      const organizationFolderStructure = removeIds(
        JSON.parse(folderStructures.organizationFolderStructure)
      )
      const subjectFolderStructure = removeIds(
        JSON.parse(folderStructures.subjectFolderStructure)
      )
      const jobFolderStructure = removeIds(
        JSON.parse(folderStructures.jobFolderStructure)
      )
      const employeeFolderStructure = removeIds(
        JSON.parse(folderStructures.employeeFolderStructure)
      )
      const subjectGroupFolderStructure = removeIds(
        JSON.parse(folderStructures.subjectGroupFolderStructure)
      )
      const sessionFolderStructure = removeIds(
        JSON.parse(folderStructures.sessionFolderStructure)
      )
      update({
        variables: {
          updateBoxConnectorInput: {
            boxConnectorInput: {
              id: boxConnector.id,
              auth: authJson,
              asUser: values.asUser,
              subjectFolderStructure: JSON.stringify(subjectFolderStructure),
              rootBoxFolderId: values.rootBoxFolderId,
              organizationFolderStructure: JSON.stringify(
                organizationFolderStructure
              ),
              createForOrganizations: values.createForOrganizations,
              jobFolderStructure: JSON.stringify(jobFolderStructure),
              sessionFolderStructure: JSON.stringify(sessionFolderStructure),
              createForSessions: values.createForSessions,
              createForJobs: values.createForJobs,
              employeeFolderStructure: JSON.stringify(employeeFolderStructure),
              createForEmployees: values.createForEmployees,
              subjectGroupFolderStructure: JSON.stringify(
                subjectGroupFolderStructure
              ),
              createForSubjectGroups: values.createForSubjectGroups,
            },
          },
        },
      })
    },
  })

  const handleFileUpload = (credentials) => {
    try {
      const reader = new FileReader()
      reader.onload = () => {
        setAuthJson(reader.result)
      }
      reader.readAsText(credentials[0]?.file)
      formik.setFieldValue('auth', credentials)
    } catch {
      //
    }
  }

  useEffect(() => {
    if (showModal) {
      setCachedFoldersFromNode()
      formik.setValues({
        asUser: boxConnector.asUser,
        subjectFolderStructure: boxConnector.subjectFolderStructure,
        rootBoxFolderId: boxConnector.rootBoxFolderId,
        organizationFolderStructure: boxConnector.organizationFolderStructure,
        createForOrganizations: boxConnector.createForOrganizations,
        jobFolderStructure: boxConnector.jobFolderStructure,
        createForJobs: boxConnector.createForJobs,
        employeeFolderStructure: boxConnector.employeeFolderStructure,
        createForEmployees: boxConnector.createForEmployees,
        subjectGroupFolderStructure: boxConnector.subjectGroupFolderStructure,
        createForSubjectGroups: boxConnector.createForSubjectGroups,
        createForSessions: boxConnector.createForSessions,
        sessionFolderStructure: boxConnector.sessionFolderStructure,
      })
    }
  }, [showModal])

  const getCachedFolderStructures = () => {
    return JSON.parse(localStorage.getItem('folderStructures'))
  }

  const getCachedFolderStructure = (formikFieldName) => {
    const folderStructures = getCachedFolderStructures()
    return folderStructures[formikFieldName]
  }

  const setCachedFolderStructure = (formikFieldName, state) => {
    const folderStructures = getCachedFolderStructures()
    folderStructures[formikFieldName] = JSON.stringify(state)
    localStorage.setItem('folderStructures', JSON.stringify(folderStructures))
  }

  const setCachedFoldersFromNode = () => {
    localStorage.setItem(
      'folderStructures',
      JSON.stringify({
        sessionFolderStructure: boxConnector.sessionFolderStructure,
        subjectFolderStructure: boxConnector.subjectFolderStructure,
        organizationFolderStructure: boxConnector.organizationFolderStructure,
        jobFolderStructure: boxConnector.jobFolderStructure,
        employeeFolderStructure: boxConnector.employeeFolderStructure,
        subjectGroupFolderStructure: boxConnector.subjectGroupFolderStructure,
      })
    )
  }

  if (
    !formik.values.subjectFolderStructure ||
    !formik.values.sessionFolderStructure ||
    !formik.values.organizationFolderStructure ||
    !formik.values.jobFolderStructure ||
    !formik.values.employeeFolderStructure ||
    !formik.values.subjectGroupFolderStructure
  )
    return <></>

  return (
    <>
      <div className="updateFotomerchantConnector">
        <Modal
          size="lg"
          show={showModal}
          onHide={innerToggleModel}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/box-logo.png'}
                  style={{ height: '25px', marginTop: '-10px' }}
                  alt="Organization logo"
                />
                <span>Configure Box</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Box User
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="asUser"
                    value={formik.values.asUser}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.asUser}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.asUser}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Root Folder ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="rootBoxFolderId"
                    value={formik.values.rootBoxFolderId}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.rootBoxFolderId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.rootBoxFolderId}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Folder Structures
                </Form.Label>
              </Form.Group>
              <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                <Tab
                  key="Organizations"
                  eventKey="Organizations"
                  style={{ marginTop: '10px' }}
                  title={<b>Organizations</b>}
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="createforOrganizations"
                          type="checkbox"
                          label="Create Folders"
                          checked={formik.values.createForOrganizations}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `createForOrganizations`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {formik.values.createForOrganizations && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          folderStructure={JSON.parse(
                            getCachedFolderStructure(
                              'organizationFolderStructure'
                            )
                          )}
                          formik={formik}
                          setCachedFolderStructure={setCachedFolderStructure}
                          root={'organization'}
                          formikField={'organizationFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Subject Groups"
                  eventKey="Subject Groups"
                  style={{ marginTop: '10px' }}
                  title={<b>Subject Groups</b>}
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="createForSubjectGroups"
                          type="checkbox"
                          label="Create Folders"
                          checked={formik.values.createForSubjectGroups}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `createForSubjectGroups`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {formik.values.createForSubjectGroups && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure(
                              'subjectGroupFolderStructure'
                            )
                          )}
                          formik={formik}
                          root={'subjectGroup'}
                          formikField={'subjectGroupFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Subjects"
                  eventKey="Subjects"
                  style={{ marginTop: '10px' }}
                  title={<b>Subjects</b>}
                >
                  <Row className="mt-3 ml-2 mb-5">
                    <Col md={12}>
                      <FolderStructure
                        setCachedFolderStructure={setCachedFolderStructure}
                        getCachedFolderStructure={getCachedFolderStructure}
                        folderStructure={JSON.parse(
                          getCachedFolderStructure('subjectFolderStructure')
                        )}
                        formik={formik}
                        root={'subject'}
                        formikField={'subjectFolderStructure'}
                      />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  key="Jobs"
                  eventKey="Jobs"
                  style={{ marginTop: '10px' }}
                  title={<b>Jobs</b>}
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="createForJobs"
                          type="checkbox"
                          label="Create Folders"
                          checked={formik.values.createForJobs}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `createForJobs`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {formik.values.createForJobs && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('jobFolderStructure')
                          )}
                          formik={formik}
                          root={'job'}
                          formikField={'jobFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Sessions"
                  eventKey="Sessions"
                  style={{ marginTop: '10px' }}
                  title={<b>Sessions</b>}
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="create"
                          type="checkbox"
                          label="Create Folders"
                          checked={formik.values.createForSessions}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `createForSessions`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {formik.values.createForSessions && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('sessionFolderStructure')
                          )}
                          formik={formik}
                          root={'session'}
                          formikField={'sessionFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Employees"
                  eventKey="Employees"
                  style={{ marginTop: '10px' }}
                  title={<b>Employees</b>}
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="createForEmployees"
                          type="checkbox"
                          label="Create Folders"
                          checked={formik.values.createForEmployees}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `createForEmployees`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {formik.values.createForEmployees && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('employeeFolderStructure')
                          )}
                          formik={formik}
                          root={'employee'}
                          formikField={'employeeFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
              </Tabs>
              <Form.Group as={Row}>
                <Form.Label column sm="6">
                  API Credentials
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs={12}>
                  <FilePond
                    files={formik.values.auth}
                    onupdatefiles={handleFileUpload}
                    acceptedFileTypes={['application/json']}
                    allowFileSizeValidation
                    maxFileSize="15MB"
                    labelMaxFileSize="Maximum preview image size is {filesize}"
                    maxFiles={1}
                    name="files"
                    labelIdle='Drag and drop a file or <span class="filepond--label-action">Browse</span>'
                  />
                  {formik.errors?.auth && (
                    <span style={{ color: 'red' }}>{formik.errors?.auth}</span>
                  )}
                </Col>
              </Form.Group>
              <Button
                disabled={submitting}
                type="submit"
                size="sm"
                variant="outline-primary"
                className="mt-2"
              >
                <Folder className="mr-2" />
                Save
              </Button>
              {boxConnector.authValid && (
                <Button
                  disabled={submitting}
                  onClick={() => {
                    setDisabling(true)
                    update({
                      variables: {
                        updateBoxConnectorInput: {
                          boxConnectorInput: {
                            id: boxConnector.id,
                            disable: true,
                          },
                        },
                      },
                    })
                  }}
                  size="sm"
                  variant="outline-danger"
                  className="mt-2"
                >
                  <Trash className="mr-2" />
                  Disable
                </Button>
              )}
              {submitting && <Loading />}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditBoxConnector
