import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import './Layout.css'

const ScrapGoLayout = (props) => {
  return (
    <>
      <div className="openLayout">
        <span className="header">
          <Header signUp />
        </span>
        <div className="container mb-5">{props.children}</div>
        <span className="footer">
          <Footer openLayout signUp />
        </span>
      </div>
    </>
  )
}

export default ScrapGoLayout
