import React, { useEffect, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { Row, Col, Form } from 'react-bootstrap'
import './EmailNotificationTemplateBuilder.css'
import { CaretDown, CaretRight } from 'react-bootstrap-icons'

const EmailNotificationTemplateBuilder = (props) => {
  const {
    notificationTemplate,
    emailEditorRef,
    formik,
    reloadEmailEditorRef,
    setReloadEmailEditorRef,
    show,
  } = props
  const [emailEditorLoaded, setEmailEditorLoaded] = useState(false)
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    if (emailEditorLoaded && notificationTemplate && emailEditorRef) {
      const templateDesign = JSON.parse(
        notificationTemplate.emailTemplateDesign
      )
      emailEditorRef.current.editor.loadDesign(templateDesign)
    }
    if (reloadEmailEditorRef) {
      setReloadEmailEditorRef(false)
    }
  }, [
    emailEditorLoaded,
    notificationTemplate,
    emailEditorRef,
    reloadEmailEditorRef,
  ])
  const onLoad = () => {
    setEmailEditorLoaded(true)
  }
  if (!notificationTemplate) return <></>
  const availableTemplateImages = []
  if (notificationTemplate.images) {
    if (notificationTemplate.images.includes('studio_logo')) {
      availableTemplateImages.push('Studio')
    }
    if (notificationTemplate.images.includes('organization_logo')) {
      availableTemplateImages.push('Org')
    }
    if (notificationTemplate.images.includes('session_package_preview')) {
      availableTemplateImages.push('Package')
    }
  }
  return (
    <div className="emailNotificationTemplateBuilder">
      <Row className="mt-3">
        <Col className="d-flex align-items-center">
          <button
            type="button"
            onClick={() => setDisplay(!display)}
            className="px-0 btn-link mr-1"
            style={{ marginTop: '-10px' }}
          >
            <>{display ? <CaretDown size={20} /> : <CaretRight size={20} />}</>
          </button>
          <h5>
            {notificationTemplate?.name?.includes('Ticket') ? (
              <>Template</>
            ) : (
              <>Email Template</>
            )}
          </h5>
        </Col>
      </Row>
      <div style={display ? {} : { display: 'none' }}>
        {!notificationTemplate?.name?.includes('Ticket') && (
          <Form.Group className="mt-4 mb-3">
            <Row>
              <Col xs={12} md="auto" className="d-flex align-items-center">
                <Form.Label>Email Subject</Form.Label>
              </Col>
              <Col xs={12} md={6} className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  name="emailSubject"
                  value={formik.values.emailSubject}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.values.emailSubject}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        )}
        <Row>
          <Col xs={12}>
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              projectId={36622}
              options={{
                customJS: [
                  `${window.location.protocol}//${window.location.host}/template_builder_custom_tools/studio_logo.js`,
                  `${window.location.protocol}//${window.location.host}/template_builder_custom_tools/organization_logo.js`,
                  `${window.location.protocol}//${window.location.host}/template_builder_custom_tools/session_package_preview.js`,
                ],
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default EmailNotificationTemplateBuilder
