import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import validator from 'validator'
import { useLocation, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { CaretDown, CaretRight, Trash, Folder } from 'react-bootstrap-icons'
import AuditLog from '../audit_log/AuditLog'
import EmployeeSearchInput from '../common/node_search_input/EmployeeSearchInput'
import UnitOfMeasureSearchInput from '../common/node_search_input/UnitOfMeasureSearchInput'
import Loading from '../common/Loading'
import { toTitleCase } from '../../libs/utils'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const BusinessForm = (props) => {
  const { business, afterSubmit, hideAuditLog } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const TERMS = [
    {
      value: 'NET_5_WEEK_ENDING_DELIVERY',
      description: 'Net 5 Week Ending Delivery',
    },
    {
      value: 'NET_10_WEEK_ENDING_DELIVERY',
      description: 'Net 10 Week Ending Delivery',
    },
    { value: 'NET_5', description: 'Net 5' },
    { value: 'NET_10', description: 'Net 10' },
  ]
  const STATUS = [
    { value: 'LEAD', description: 'Lead' },
    { value: 'CONTACTED', description: 'Contacted' },
    { value: 'CONTACTED_NO_RESPONSE', description: 'Contacted No Response' },
    { value: 'UNQUALIFIED', description: 'Unqualified' },
    { value: 'PENDING_APPROVAL', description: 'Pending Approval' },
    { value: 'APPROVED', description: 'Approved' },
  ]
  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog_, setDisplayAuditLog] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const [deleteBusiness] = useMutation(
    gql`
      mutation DeleteBusiness($input: DeleteBusinessInput!) {
        deleteBusiness(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Business Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
        if (location.pathname.includes('/business/')) {
          history.push('/businesses')
        }
      },
      refetchQueries: ['Businesses'],
    }
  )

  const [createBusiness] = useMutation(
    gql`
      mutation CreateBusiness($input: CreateBusinessInput!) {
        createBusiness(input: $input) {
          business {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Business Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Businesses', 'Business'],
    }
  )

  const [updateBusiness] = useMutation(
    gql`
      mutation UpdateBusiness($input: UpdateBusinessInput!) {
        updateBusiness(input: $input) {
          business {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Business Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Businesses', 'Business'],
    }
  )

  const formik = useFormik({
    initialValues: business
      ? {
          name: business.name,
          invoiceEmail: business.invoiceEmail,
          buyer: business.buyer ? true : false,
          supplier: business.supplier ? true : false,
          deadWeightThreshold: business.deadWeightThreshold,
          unitOfMeasureId:
            business.deadWeightUnitOfMeasure &&
            business.deadWeightUnitOfMeasure.id,
          unitOfMeasureDescription:
            business.deadWeightUnitOfMeasure &&
            toTitleCase(business.deadWeightUnitOfMeasure.pluralName),
          supplierNetTerms: business.supplier && business.supplier.netTerms,
          buyerNetTerms: business.buyer && business.buyer.netTerms,
          status: business.status,
          employees: business.salespeople.edges.map((edge) => ({
            id: edge.node.id,
            description: edge.node.user.fullName,
          })),
        }
      : {
          name: '',
          buyer: false,
          supplier: false,
          invoiceEmail: '',
          employees: [],
          deadWeightThreshold: null,
          unitOfMeasureId: null,
          unitOfMeasureDescription: null,
          supplierNetTerms: '- - -',
          buyerNetTerms: '- - -',
          status: '- - -',
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      buyer: Yup.boolean().required('Required'),
      invoiceEmail: Yup.string()
        .nullable()
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      supplier: Yup.boolean()
        .required('Required')
        .test(
          'supplierOrBuyer',
          'Must Be Supplier or Buyer',
          (value, context) => {
            return context.parent.buyer || value
          }
        ),
      deadWeightThreshold: Yup.number().nullable(),
      supplierNetTerms: Yup.string()
        .nullable()
        .when('supplier', {
          is: true,
          then: Yup.string()
            .required('Required')
            .test('supplierNetTerms', 'Required', (value) => value !== '- - -'),
          otherwise: Yup.string().nullable(),
        }),
      buyerNetTerms: Yup.string()
        .nullable()
        .when('buyer', {
          is: true,
          then: Yup.string()
            .required('Required')
            .test('buyerNetTerms', 'Required', (value) => value !== '- - -'),
          otherwise: Yup.string().nullable(),
        }),
      status: Yup.string()
        .required('Required')
        .test('status', 'Required', (value) => value !== '- - -'),
      unitOfMeasureId: Yup.string().nullable(),
      unitOfMeasureDescription: Yup.string().nullable(),
      employees: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const variables = {
        name: values.name,
        status: values.status,
        buyer: values.buyer,
        invoiceEmail: values.invoiceEmail,
        supplier: values.supplier,
        deadWeightThreshold: values.deadWeightThreshold,
        deadWeightUnitOfMeasureId: values.unitOfMeasureId,
        salespeopleIds: values.employees.map((employee) => employee.id),
        supplierNetTerms: values.supplierNetTerms,
        buyerNetTerms: values.buyerNetTerms,
      }
      if (business) {
        variables.id = business.id
        updateBusiness({
          variables: {
            input: {
              businessInput: variables,
            },
          },
        })
      } else {
        createBusiness({
          variables: {
            input: {
              businessInput: variables,
            },
          },
        })
      }
    },
  })
  if (!loggedInUser) return <></>
  const isBuyerSupplier =
    loggedInUser.permissions.isBuyer || loggedInUser.permissions.isSupplier
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="input"
              name="name"
              disabled={isBuyerSupplier}
              className="form-control-sm"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {!isBuyerSupplier && (
          <Form.Group as={Row}>
            <Col>
              <Form.Label>Status</Form.Label>
              <Form.Control
                name="status"
                as="select"
                className="form-control-sm"
                value={formik.values.status}
                onChange={formik?.handleChange}
                isInvalid={formik?.errors?.status}
              >
                <option value="- - -">- - -</option>
                {STATUS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.description}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik?.errors?.status}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        )}
        {!isBuyerSupplier && (
          <>
            <Form.Group as={Row}>
              <Col sm="12" md={12}>
                <Form.Label>Salespeople</Form.Label>
                <EmployeeSearchInput
                  formik={formik}
                  multiple
                  searchDescription="salespeople"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="12" md={12}>
                <Form.Label>Stripe Invoice Email Recipient</Form.Label>
                <Form.Control
                  as="input"
                  name="invoiceEmail"
                  className="form-control-sm"
                  value={formik.values.invoiceEmail}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.invoiceEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.invoiceEmail}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="12" md={12}>
                <Form.Label>Dead Weight Threshold</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  name="deadWeightThreshold"
                  className="form-control-sm"
                  step="1"
                  min="0"
                  value={formik.values.deadWeightThreshold}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.deadWeightThreshold}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.deadWeightThreshold}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm="12" md={12}>
                <Form.Label>Dead Weight Unit</Form.Label>
                <UnitOfMeasureSearchInput
                  formik={formik}
                  error={formik.errors.unitOfMeasureId}
                />
              </Col>
            </Form.Group>
          </>
        )}
        <Form.Group as={Row}>
          <Col sm={12} md={6}>
            <Form.Label>Supplier</Form.Label>
            <Form.Check
              disabled={isBuyerSupplier}
              label={formik.values.supplier ? 'Yes' : 'No'}
              onChange={(e) => {
                formik.setFieldValue(`supplier`, e.target.checked)
                if (!e.target.checked) {
                  formik.setFieldValue('supplierNetTerms', null)
                }
              }}
              checked={formik.values.supplier}
            />
          </Col>
          <Col sm={12} md={6}>
            <Form.Label>Buyer</Form.Label>
            <Form.Check
              disabled={isBuyerSupplier}
              label={formik.values.buyer ? 'Yes' : 'No'}
              onChange={(e) => {
                formik.setFieldValue(`buyer`, e.target.checked)
                if (!e.target.checked) {
                  formik.setFieldValue('buyerNetTerms', null)
                }
              }}
              checked={formik.values.buyer}
            />
          </Col>
          <Col>
            <small className="text-danger">{formik.errors.supplier}</small>
          </Col>
        </Form.Group>
        {formik.values.supplier && (
          <Form.Group as={Row}>
            <Col>
              <Form.Label>Supplier Net Terms</Form.Label>
              <Form.Control
                disabled={isBuyerSupplier}
                name="supplierNetTerms"
                as="select"
                className="form-control-sm"
                value={formik.values.supplierNetTerms}
                onChange={formik?.handleChange}
                isInvalid={formik?.errors?.supplierNetTerms}
              >
                <option value="- - -">- - -</option>
                {TERMS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.description}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik?.errors?.supplierNetTerms}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        )}
        {formik.values.buyer && (
          <Form.Group as={Row}>
            <Col>
              <Form.Label>Buyer Net Terms</Form.Label>
              <Form.Control
                disabled={isBuyerSupplier}
                name="buyerNetTerms"
                as="select"
                className="form-control-sm"
                value={formik.values.buyerNetTerms}
                onChange={formik?.handleChange}
                isInvalid={formik?.errors?.buyerNetTerms}
              >
                <option value="- - -">- - -</option>
                {TERMS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.description}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik?.errors?.buyerNetTerms}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        )}
        {business && !hideAuditLog && (
          <>
            <Row>
              <Col md={3} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayAuditLog(!displayAuditLog_)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayAuditLog_ ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label column sm="12" md="auto">
                  History
                </Form.Label>
              </Col>
            </Row>
            {displayAuditLog_ && (
              <Row>
                <Col md={12}>
                  <AuditLog
                    contentType={business.contentType.model}
                    id={business.id}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {!isBuyerSupplier && (
          <div className="footer-modal">
            <Form.Row className="mt-2">
              <Col md={3}>
                <Button
                  type="submit"
                  block
                  variant="outline-primary"
                  disabled={submitting}
                >
                  <Folder className="mr-2" />
                  Save
                </Button>
              </Col>

              {business && (
                <Col md={3}>
                  <Button
                    variant="outline-danger"
                    block
                    onClick={() => {
                      setSubmitting(true)
                      setDeleting(true)
                      deleteBusiness({
                        variables: {
                          input: {
                            ids: business.id,
                          },
                        },
                      })
                    }}
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              )}
            </Form.Row>
            {submitting && (
              <div className="mt-2">
                <Loading
                  message={
                    deleting ? 'Deleting Business...' : 'Saving Business...'
                  }
                />
              </div>
            )}
          </div>
        )}
      </Form>
    </>
  )
}

export default BusinessForm
