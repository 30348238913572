import React, { useEffect, useState, useRef } from 'react'
import { Form, Row, Col, Button, Badge } from 'react-bootstrap'
import {
  useMutation,
  useLazyQuery,
  gql,
  useReactiveVar,
  useQuery,
} from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import {
  Envelope,
  PhoneVibrate,
  CaretDown,
  CaretRight,
  Folder,
  Trash,
  DashCircle,
  Bell,
  ArrowRepeat,
  Download,
} from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import EmailNotificationTestForm from './EmailNotificationTestForm'
import SMSNotificationTestForm from './SMSNotificationTestForm'
import NotificationsTable from './NotificationsTable'
import EmailNotificationTemplateBuilder from './EmailNotificationTemplateBuilder'
import SMSNotificationTemplateBuilder from './SMSNotificationTemplateBuilder'
import PushNotificationTemplateBuilder from './PushNotificationTemplateBuilder'
import NewCustomTriggerModal from './NewCustomTriggerModal'
import DeleteCustomTriggerModal from './DeleteCustomTriggerModal'
import DatePicker from 'react-datepicker'
import { loggedInUserVar } from '../../libs/apollo'
import { useHistory, useLocation } from 'react-router-dom'
import Loading from '../common/Loading'
import CopyTriggerModal from './CopyTriggerModal'
import { useAWSS3 } from '../../libs/aws'
import BusinessSearchInput from '../common/node_search_input/BusinessSearchInput'

const Notifications = (props) => {
  const { business, employee, copyTrigger, setCopyTriggerId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const location = useLocation()
  const history = useHistory()
  const canSend = ['Administrator'].includes(loggedInUser.permissions.group)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [notificationTriggers, setNotificationTriggers] = useState({})
  const [showCopyTriggerModal, setShowCopyTriggerModal] = useState(false)
  const [copyAllTriggers, setCopyAllTriggers] = useState(false)
  const [reloadEmailEditorRef, setReloadEmailEditorRef] = useState(false)
  const [canSetCustomStartEndTime, setCanSetCustomStartEndTime] =
    useState(false)
  const [currentNotificationTrigger, setCurrentNotificationTrigger] =
    useState(null)
  const [
    currentNotificationTriggerEmailTemplate,
    setCurrentNotificationTriggerEmailTemplate,
  ] = useState()
  const [
    currentNotificationTriggerSMSTemplate,
    setCurrentNotificationTriggerSMSTemplate,
  ] = useState()
  const [notificationRecipients, setNotificationRecipients] = useState(null)
  const [notificationStartDetail, setNotificationStartDetail] = useState(null)
  const [notificationOccurrenceDetail, setNotificationOccurrenceDetail] =
    useState(null)
  const [showComponent, setShowComponent] = useState(false)
  const [noCurrentTrigger, setNoCurrentTrigger] = useState(false)
  const [showOccurrenceDeltaFields, setShowOccurrenceDeltaFields] =
    useState(false)
  const [showStartDeltaFields, setShowStartDeltaFields] = useState(false)
  const [showRepeatToggle, setShowRepeatToggle] = useState(false)
  const [showSMSTemplate, setShowSMSTemplate] = useState(false)
  const [notificationsLoading, setNotificationsLoading] = useState(true)
  const [resetCurrent, setResetCurrent] = useState(false)
  const [nonDefaultTrigger, setNonDefaultTemplate] = useState(false)
  const [displayTemplateVariables, setDisplayTemplateVariables] =
    useState(false)
  const [showNewTriggerModal, setShowNewTriggerModal] = useState(false)
  const [showDeleteTriggerModal, setShowDeleteTriggerModal] = useState(false)
  const [currentTriggerCustom, setCurrentTriggerCustom] = useState(false)
  const emailEditorRef = useRef(null)
  const awsS3 = useAWSS3()

  const getS3Object = async (Key, fileName, postDownload = null) => {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          let blob = new Blob([data.Body], { type: data.ContentType })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          if (postDownload) {
            postDownload()
          }
        }
      }
    )
  }

  const { data: twilioConnectorData } = useQuery(
    gql`
      query TwilioConnector {
        twilioConnectors {
          edges {
            node {
              customValid
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const { data: myUser } = useQuery(
    gql`
      query MyUser($id: ID!) {
        myUser(id: $id) {
          employee {
            id
            smtpValid
            smtpUser
          }
        }
      }
    `,
    {
      variables: {
        id: loggedInUser.id,
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data: smtpConnector } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customUser
              customValid
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const toggleNewTriggerModal = () => {
    setShowNewTriggerModal((prevState) => !prevState)
  }

  const toggleDeleteTriggerModal = () => {
    setShowDeleteTriggerModal((prevState) => !prevState)
  }

  const NOTIFICATION_TRIGGER_DELTAS = ['minutes', 'hours', 'days', 'weeks']

  const [updateNotificationTemplateMutation] = useMutation(
    gql`
      mutation UpdateNotificationTemplateMutation(
        $updateNotificationTemplateInput: UpdateNotificationTemplateInput!
      ) {
        updateNotificationTemplate(input: $updateNotificationTemplateInput) {
          notificationTemplate {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      refetchQueries: ['NotificationTriggers'],
    }
  )

  const [notificationTriggersQuery, { data: notificationTriggersData }] =
    useLazyQuery(
      gql`
        query NotificationTriggers(
          $default: Boolean
          $custom: Boolean
          $business: ID
          $orCustom: Boolean
        ) {
          notificationTriggers(
            orderBy: "name"
            default: $default
            custom: $custom
            business: $business
            orCustom: $orCustom
          ) {
            edges {
              node {
                id
                archived
                name
                using
                custom
                smtpRateLimit
                smtpRateLimitSecondDelta
                enabled
                smsEnabled
                startDateTime
                endDateTime
                business {
                  id
                }
                customStartEndTime
                emailEnabled
                recurring
                scheduledTrigger
                startDelta
                startDeltaCount
                occurrenceDelta
                occurrenceDeltaCount
                lastSentAt
                notificationTemplateChannels {
                  edges {
                    node {
                      id
                      channelType
                      notificationTemplate {
                        id
                        name
                        bccEmails
                        file {
                          fileName
                        }
                        ccEmails
                        emailSubject
                        emailTemplateDesign
                        smsContent
                        dynamicContext
                        images
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      { fetchPolicy: 'network-only', pollInterval: 5000 }
    )

  const [updateNotificationTriggerMutation] = useMutation(
    gql`
      mutation UpdateNotificationTriggerMutation(
        $updateNotificationTriggerInput: UpdateNotificationTriggerInput!
      ) {
        updateNotificationTrigger(input: $updateNotificationTriggerInput) {
          notificationTrigger {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        let savingTemplates = true
        if (
          currentNotificationTrigger?.business &&
          formik.values.using != 'BUSINESS'
        ) {
          savingTemplates = false
        }
        if (savingTemplates) {
          toast.success(
            `${notificationTriggerName(
              currentNotificationTrigger
            )} Template Saved`
          )
        } else {
          toast.success('Saved')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['NotificationTriggers'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      custom: '',
      ccEmails: '',
      bccEmails: '',
      enabled: '',
      smtpRateLimit: null,
      smtpRateLimitSecondDelta: null,
      emailSubject: '',
      smsEnabled: '',
      emailEnabled: '',
      startDelta: '',
      using: '',
      startDeltaCount: 0,
      occurrenceDelta: '',
      occurrenceDeltaCount: 0,
      recurring: '',
      organizationName: '',
      organizationId: '',
      businessId: business ? business.id : '',
      businessDescription: business ? business.name : '',
      startDateTime: null,
      endDateTime: null,
      customStartEndTime: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().test(
        'name',
        'cannot update non custom trigger name',
        (value, context) => {
          if (context.parent.custom === false && value) {
            return false
          } else {
            return true
          }
        }
      ),
      using: Yup.string().nullable(),
      smsEnabled: Yup.bool().required('Required'),
      emailEnabled: Yup.bool().required('Required'),
      startDeltaCount: Yup.number()
        .nullable()
        .test('isLessThanEndDate', 'must be less than 1000', (value) => {
          let valid = true
          if (value && value > 1000 && showStartDeltaFields) {
            valid = false
          }
          return valid
        })
        .test('isLessThanEndDate', 'must be greater than 0', (value) => {
          let valid = true
          if (value && value < 1 && showStartDeltaFields) {
            valid = false
          }
          return valid
        }),
      occurrenceDeltaCount: Yup.string()
        .nullable()
        .test(
          'isLessThanEndDate',
          'must be less than 1000',
          (value, context) => {
            let valid = true
            if (
              context.parent.recurring &&
              value &&
              value > 1000 &&
              showOccurrenceDeltaFields
            ) {
              valid = false
            }
            return valid
          }
        )
        .test(
          'isLessThanEndDate',
          'must be greater than 0',
          (value, context) => {
            let valid = true
            if (
              context.parent.recurring &&
              value &&
              value < 1 &&
              showOccurrenceDeltaFields
            ) {
              valid = false
            }
            return valid
          }
        ),
      businessDescription: Yup.string().nullable(),
      businessId: Yup.string().nullable(),
      smtpRateLimit: Yup.number()
        .required('Required')
        .max(1000, 'must be less than 1000')
        .min(1, 'must be greater than'),
      smtpRateLimitSecondDelta: Yup.number()
        .required('Required')
        .max(1000, 'must be less than 1000')
        .min(1, 'must be greater than'),
      startDateTime: Yup.date()
        .nullable()
        .test(
          'isLessThanEndDate',
          'Start date must not exceed end date',
          (value, context) => {
            let valid = true
            if (value && context.parent.endDateTime) {
              const startDate = new Date(value)
              const endDate = new Date(context.parent.endDateTime)
              endDate.setHours(23)
              endDate.setMinutes(59)
              endDate.setSeconds(59)
              if (endDate < startDate) {
                valid = false
              }
            }
            return valid
          }
        ),
      customStartEndTime: Yup.bool().nullable(),
      endDateTime: Yup.date().nullable(),
      recurring: Yup.bool().required('requiried'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      let handleTemplates = true
      if (currentNotificationTrigger?.business && values.using != 'BUSINESS') {
        handleTemplates = false
      }
      if (handleTemplates) {
        emailEditorRef.current.editor.exportHtml((data) => {
          let errors = ''
          let emailTemplateContent
          let emailTemplateDesign
          if (
            currentNotificationTriggerSMSTemplate &&
            handleTemplates &&
            (values.smsEnabled || currentTriggerCustom) &&
            !editSMSNotificationTemplateForm.values.smsContent.includes(
              '{{ unsubscribe_link }}'
            )
          ) {
            errors = 'SMS template must include {{ unsubscribe_link }}'
          }
          emailTemplateContent = data?.html.replace(
            /\{\{%20(.*?)%20\}\}/g,
            '{{ $1 }}'
          )
          emailTemplateDesign = JSON.stringify(data?.design).replace(
            /\{\{%20(.*?)%20\}\}/g,
            '{{ $1 }}'
          )
          if (
            (values.emailEnabled || currentTriggerCustom) &&
            handleTemplates &&
            !currentNotificationTrigger?.name?.includes('Ticket') &&
            !emailTemplateContent.includes('{{ unsubscribe_link }}')
          ) {
            if (errors) {
              errors = `Email and ${errors}`
            } else {
              errors = 'Email template must include {{ unsubscribe_link }}'
            }
          }
          if (errors) {
            toast.error(errors)
          } else {
            const variables = {
              updateNotificationTriggerInput: {
                notificationTriggerInput: {
                  enabled: values.enabled,
                  smtpRateLimit: values.smtpRateLimit,
                  smtpRateLimitSecondDelta: values.smtpRateLimitSecondDelta,
                  id: currentNotificationTrigger.id,
                  smsEnabled: values.smsEnabled,
                  using: values.using,
                  emailEnabled: values.emailEnabled,
                  emailSubject: values.emailSubject,
                  recurring: values.recurring,
                },
              },
            }
            if (canSetCustomStartEndTime) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.customStartEndTime =
                values.customStartEndTime
              if (values.customStartEndTime) {
                if (values.startDateTime) {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                    DateTime.utc(
                      values.startDateTime.getFullYear(),
                      values.startDateTime.getMonth() + 1,
                      values.startDateTime.getDate(),
                      0,
                      0
                    ).toISO()
                } else {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                    null
                }
                if (formik.values.endDateTime) {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                    DateTime.utc(
                      values.endDateTime.getFullYear(),
                      values.endDateTime.getMonth() + 1,
                      values.endDateTime.getDate(),
                      23,
                      59
                    ).toISO()
                } else {
                  variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                    null
                }
              }
            }
            if (currentNotificationTrigger.custom) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.name =
                values.name
            }
            if (values.startDelta) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDelta =
                values.startDelta
            }
            if (values.startDeltaCount) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDeltaCount =
                values.startDeltaCount
            }
            if (values.occurrenceDelta) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDelta =
                values.occurrenceDelta
            }
            if (values.occurrenceDeltaCount) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDeltaCount =
                values.occurrenceDeltaCount
            }
            updateNotificationTriggerMutation({
              variables,
            })
            emailTemplateDesign = emailTemplateDesign
              .replace(/{{%20/g, '{{ ')
              .replace(/%20}}/g, ' }}')
            updateNotificationTemplateMutation({
              variables: {
                updateNotificationTemplateInput: {
                  notificationTemplateInput: {
                    id: currentNotificationTriggerEmailTemplate.id,
                    channel: 'email',
                    emailSubject: values.emailSubject,
                    bccEmails: values.bccEmails,
                    ccEmails: values.ccEmails,
                    emailTemplateContent,
                    emailTemplateDesign,
                  },
                },
              },
            })
            if (currentNotificationTriggerSMSTemplate?.id) {
              updateNotificationTemplateMutation({
                variables: {
                  updateNotificationTemplateInput: {
                    notificationTemplateInput: {
                      id: currentNotificationTriggerSMSTemplate.id,
                      channel: 'sms',
                      smsContent:
                        editSMSNotificationTemplateForm.values.smsContent,
                    },
                  },
                },
              })
            }
          }
        })
      } else {
        const variables = {
          updateNotificationTriggerInput: {
            notificationTriggerInput: {
              enabled: values.enabled,
              id: currentNotificationTrigger.id,
              smsEnabled: values.smsEnabled,
              using: values.using,
              emailEnabled: values.emailEnabled,
              emailSubject: values.emailSubject,
              recurring: values.recurring,
            },
          },
        }
        if (canSetCustomStartEndTime) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.customStartEndTime =
            values.customStartEndTime
          if (values.customStartEndTime) {
            if (values.startDateTime) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                DateTime.utc(
                  values.startDateTime.getFullYear(),
                  values.startDateTime.getMonth() + 1,
                  values.startDateTime.getDate(),
                  0,
                  0
                ).toISO()
            } else {
              variables.updateNotificationTriggerInput.notificationTriggerInput.startDateTime =
                null
            }
            if (formik.values.endDateTime) {
              variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                DateTime.utc(
                  values.endDateTime.getFullYear(),
                  values.endDateTime.getMonth() + 1,
                  values.endDateTime.getDate(),
                  23,
                  59
                ).toISO()
            } else {
              variables.updateNotificationTriggerInput.notificationTriggerInput.endDateTime =
                null
            }
          }
        }
        if (currentNotificationTrigger.custom) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.name =
            values.name
        }
        if (values.startDelta) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.startDelta =
            values.startDelta
        }
        if (values.startDeltaCount) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.startDeltaCount =
            values.startDeltaCount
        }
        if (values.occurrenceDelta) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDelta =
            values.occurrenceDelta
        }
        if (values.occurrenceDeltaCount) {
          variables.updateNotificationTriggerInput.notificationTriggerInput.occurrenceDeltaCount =
            values.occurrenceDeltaCount
        }
        updateNotificationTriggerMutation({
          variables,
        })
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue(`bccEmails`, '')
    formik.setFieldValue(`ccEmails`, '')
    if (!formik.values.businessId) {
      setResetCurrent(false)
      setResetCurrent(true)
      setNonDefaultTemplate(false)
      let variables = {
        default: true,
      }
      if (business) {
        variables.custom = true
      }
      notificationTriggersQuery({
        variables,
      })
    } else {
      setResetCurrent(false)
      setResetCurrent(true)
      setNonDefaultTemplate(true)
      let variables = {
        business: formik.values.businessId,
      }
      if (business) {
        variables.orCustom = true
      }
      notificationTriggersQuery({
        variables,
      })
    }
  }, [formik.values.businessId])

  const editSMSNotificationTemplateForm = useFormik({
    initialValues: {
      smsContent: '',
    },
    validationSchema: Yup.object().shape({
      smsContent: Yup.string().max(300, 'Too long'),
    }),
    validateOnChange: false,
  })

  useEffect(() => {
    if (notificationTriggersData) {
      const numberMap = {
        One: 1,
        Two: 2,
        Three: 3,
        Four: 4,
        Five: 5,
      }
      const currentNotificationTriggers = {}
      const sortedTriggers = [
        ...notificationTriggersData.notificationTriggers.edges,
      ].sort((a, b) => {
        const isCustomA = a.node.custom
        const isCustomB = b.node.custom
        const numA = (a.node.name.match(/One|Two|Three|Four|Five/g) || [])[0]
        const numB = (b.node.name.match(/One|Two|Three|Four|Five/g) || [])[0]
        if (!isCustomA && !isCustomB) {
          if (numA && numB) {
            return numberMap[numA] - numberMap[numB]
          } else {
            return a.node.name.localeCompare(b.node.name)
          }
        } else if (isCustomA && !isCustomB) {
          return 1
        } else if (!isCustomA && isCustomB) {
          return -1
        } else {
          return a.node.name.localeCompare(b.node.name)
        }
      })
      if (sortedTriggers.length === 0) {
        setNoCurrentTrigger(true)
        setCurrentNotificationTrigger()
        if (setCopyTriggerId) {
          setCopyTriggerId()
        }
      }
      sortedTriggers.forEach((notificationTrigger, index) => {
        if (
          (!currentNotificationTrigger && index === 0) ||
          (resetCurrent && index === 0)
        ) {
          setCurrentNotificationTrigger(notificationTrigger.node)
          if (setCopyTriggerId) {
            setCopyTriggerId(notificationTrigger.node.id)
          }
          setNoCurrentTrigger(false)
          setResetCurrent(false)
          setCurrentTriggerCustom(notificationTrigger.node.custom)
        } else if (
          !showDeleteTriggerModal &&
          currentNotificationTrigger &&
          currentNotificationTrigger.id === notificationTrigger.node.id
        ) {
          setCurrentTriggerCustom(notificationTrigger.node.custom)
          setNoCurrentTrigger(false)
          setCurrentNotificationTrigger(notificationTrigger.node)
          if (setCopyTriggerId) {
            setCopyTriggerId(notificationTrigger.node.id)
          }
        }
        currentNotificationTriggers[notificationTrigger.node.id] =
          notificationTrigger.node
      })
      setReloadEmailEditorRef(true)
      setNotificationTriggers(currentNotificationTriggers)
    }
  }, [notificationTriggersData])

  useEffect(() => {
    if (currentNotificationTrigger) {
      let startDelta = ''
      let startDeltaCount = 0
      if (currentNotificationTrigger.startDelta) {
        startDelta = currentNotificationTrigger.startDelta.toLowerCase()
        startDeltaCount = currentNotificationTrigger.startDeltaCount
      }
      let occurrenceDelta = ''
      let occurrenceDeltaCount = 0
      if (currentNotificationTrigger.occurrenceDelta) {
        occurrenceDelta =
          currentNotificationTrigger.occurrenceDelta.toLowerCase()
        occurrenceDeltaCount = currentNotificationTrigger.occurrenceDeltaCount
      }
      let emailSubject
      let bccEmails
      let ccEmails
      setCurrentNotificationTriggerEmailTemplate()
      setCurrentNotificationTriggerSMSTemplate()
      currentNotificationTrigger.notificationTemplateChannels.edges.forEach(
        (notificationTemplateChannel) => {
          if (notificationTemplateChannel.node.channelType === 'Email') {
            setCurrentNotificationTriggerEmailTemplate(
              notificationTemplateChannel.node.notificationTemplate
            )
            emailSubject =
              notificationTemplateChannel.node.notificationTemplate.emailSubject
            bccEmails =
              notificationTemplateChannel.node.notificationTemplate.bccEmails
            ccEmails =
              notificationTemplateChannel.node.notificationTemplate.ccEmails
          } else if (notificationTemplateChannel.node.channelType === 'SMS') {
            setCurrentNotificationTriggerSMSTemplate(
              notificationTemplateChannel.node.notificationTemplate
            )
          }
        }
      )
      let enabled
      if (nonDefaultTrigger) {
        enabled = currentNotificationTrigger.enabled
      }
      setCanSetCustomStartEndTime(false)
      formik.setErrors({})
      formik.setValues({
        startDelta,
        startDeltaCount,
        occurrenceDelta,
        occurrenceDeltaCount,
        emailSubject,
        enabled,
        bccEmails,
        ccEmails,
        using: currentNotificationTrigger.using,
        smtpRateLimit: currentNotificationTrigger.smtpRateLimit,
        smtpRateLimitSecondDelta:
          currentNotificationTrigger.smtpRateLimitSecondDelta,
        custom: currentNotificationTrigger.custom,
        smsEnabled: currentNotificationTrigger.smsEnabled,
        emailEnabled: currentNotificationTrigger.emailEnabled,
        recurring: currentNotificationTrigger.recurring,
        businessId: formik.values.businessId,
        businessDescription: formik.values.businessDescription,
        customStartEndTime:
          currentNotificationTrigger.customStartEndTime != null
            ? currentNotificationTrigger.customStartEndTime
            : false,
        startDateTime: currentNotificationTrigger.startDateTime
          ? new Date(
              DateTime.fromISO(
                currentNotificationTrigger.startDateTime
              ).toLocaleString()
            )
          : null,
        endDateTime: currentNotificationTrigger.endDateTime
          ? new Date(
              DateTime.fromISO(
                currentNotificationTrigger.endDateTime
              ).toLocaleString()
            )
          : null,
      })
      if (currentNotificationTrigger.custom) {
        formik.setFieldValue('name', currentNotificationTrigger.name)
      }
      if (currentNotificationTrigger.name === 'Account Created') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Everyone')
        setNotificationStartDetail('When an account is created')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'New Supplier') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Clients')
        setNotificationStartDetail('When a supplier is created')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Supplier Product Pricing Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Supplier clients')
        setNotificationStartDetail('Once a supplier is onborded')
        setNotificationOccurrenceDetail('')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(true)
      } else if (currentNotificationTrigger.name === 'New Buyer') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Clients')
        setNotificationStartDetail('When a buyer is created')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Business Invoice Sent') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Clients')
        setNotificationStartDetail('When an invoice is sent')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Business Invoice Paid') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Clients')
        setNotificationStartDetail('When an invoice is paid')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Business Invoice Void') {
        setShowSMSTemplate(true)
        setNotificationRecipients('Clients')
        setNotificationStartDetail('When an invoice is void or deleted')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Business Invoice Payment Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Clients')
        setNotificationStartDetail('after an invoice is sent')
        setNotificationOccurrenceDetail('until an invoice is paid')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name === 'Business Invoice Payment Failed'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Clients')
        setNotificationStartDetail('When an invoice payment fails')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name ===
        'Salesperson Invoice Payment Reminder'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Salespeople')
        setNotificationStartDetail('after an invoice is sent')
        setNotificationOccurrenceDetail('until an invoice is paid')
        setShowOccurrenceDeltaFields(true)
        setShowStartDeltaFields(true)
        setShowRepeatToggle(true)
      } else if (
        currentNotificationTrigger.name === 'Salesperson Invoice Payment Failed'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Salespeople')
        setNotificationStartDetail('When an invoice payment fails')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Salesperson Invoice Void'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Salespeople')
        setNotificationStartDetail('When an invoice is void or deleted')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Salesperson Invoice Paid'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Salespeople')
        setNotificationStartDetail('When an invoice is paid')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (
        currentNotificationTrigger.name === 'Salesperson Invoice Sent'
      ) {
        setShowSMSTemplate(true)
        setNotificationRecipients('Salespeople')
        setNotificationStartDetail('When an invoice is sent')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Email Confirmation') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Everyone')
        setNotificationStartDetail('When creating an account')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.name === 'Password Reset') {
        setShowSMSTemplate(false)
        setNotificationRecipients('Everyone')
        setNotificationStartDetail('When requesting a password reset')
        setNotificationOccurrenceDetail('No')
        setShowOccurrenceDeltaFields(false)
        setShowStartDeltaFields(false)
        setShowRepeatToggle(false)
      } else if (currentNotificationTrigger.custom) {
        setShowSMSTemplate(true)
      }
      if (!showComponent) {
        setShowComponent(true)
      }
    }
  }, [currentNotificationTrigger])

  const handleNotificationTriggerChange = (event) => {
    const trigger = notificationTriggers[event.target.value]
    setCurrentNotificationTrigger(trigger)
    if (setCopyTriggerId) {
      setCopyTriggerId(trigger.id)
    }
    if (trigger.custom) {
      setCurrentTriggerCustom(true)
    } else {
      setCurrentTriggerCustom(false)
    }
    formik.setFieldValue('ccEmails', '')
    formik.setFieldValue('bccEmails', '')
  }

  const repeatDetail = () => {
    let detail
    if (showOccurrenceDeltaFields) {
      if (formik.values.recurring) {
        if (formik.values.occurrenceDeltaCount) {
          let _notificationStartDetail = notificationOccurrenceDetail
          if (formik.values.endDateTime) {
            const formatterDate = DateTime.fromJSDate(
              formik.values.endDateTime
            ).toFormat('MMMM d, yyyy')
            _notificationStartDetail = `unit ${formatterDate} 11:59pm`
          }
          detail = `Every ${formik.values.occurrenceDeltaCount} ${formik.values.occurrenceDelta} ${_notificationStartDetail}`
        } else {
          detail = 'Time range required'
        }
      } else {
        detail = 'Does not repeat'
      }
    } else {
      detail = notificationOccurrenceDetail
    }
    return detail
  }

  const startDetail = () => {
    let detail
    if (showStartDeltaFields) {
      if (formik.values.startDeltaCount) {
        let _notificationStartDetail = notificationStartDetail
        if (formik.values.startDateTime) {
          const formatterDate = DateTime.fromJSDate(
            formik.values.startDateTime
          ).toFormat('MMMM d, yyyy')
          _notificationStartDetail = `after ${formatterDate} 12:00am`
        }
        detail = `${formik.values.startDeltaCount} ${formik.values.startDelta} ${_notificationStartDetail}`
      } else {
        detail = 'Time range required'
      }
    } else {
      detail = notificationStartDetail
    }
    return detail
  }

  const notificationTriggerName = (trigger) => {
    const defaultTriggers = [
      'Account Created',
      'Password Reset',
      'Email Confirmation',
      'Supplier Product Pricing Reminder',
      'New Supplier',
      'New Buyer',
      'Business Invoice Sent',
      'Business Invoice Paid',
      'Business Invoice Void',
      'Business Invoice Payment Reminder',
      'Business Invoice Payment Failed',
      'Salesperson Invoice Sent',
      'Salesperson Invoice Paid',
      'Salesperson Invoice Void',
      'Salesperson Invoice Payment Reminder',
      'Salesperson Invoice Payment Failed',
    ]
    let currentNotificationTriggerDescription = `${trigger.name}`
    if (defaultTriggers.includes(trigger.name)) {
      if (!formik.values.businessName) {
        currentNotificationTriggerDescription = trigger.name
      } else {
        currentNotificationTriggerDescription = `${trigger.name} ${formik.values.businessName}`
      }
    } else if (trigger.custom) {
      currentNotificationTriggerDescription = `${trigger.name} Custom`
    }
    return currentNotificationTriggerDescription
  }

  const sendEmployeeEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              sendToEmployeeId: employee.id,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              sendToEmployeeJobId: formik.values.jobId,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendEmployeeEmailFromMe = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      sendNotificationTrigger({
        variables: {
          sendNotificationTriggerInput: {
            notificationTriggerInput: {
              id: currentNotificationTrigger.id,
              senderEmployeeId: myUser.myUser.employee.id,
              sendToEmployeeId: employee.id,
              channel: 'Email',
              ccEmails: formik.values.ccEmails,
              bccEmails: formik.values.bccEmails,
              sendToEmployeeJobId: formik.values.jobId,
              modifiedTemplate: data.html.replace(
                /\{\{%20(.*?)%20\}\}/g,
                '{{$1}}'
              ),
            },
          },
        },
      })
    })
  }

  const sendEmployeeSMS = () => {
    sendNotificationTrigger({
      variables: {
        sendNotificationTriggerInput: {
          notificationTriggerInput: {
            id: currentNotificationTrigger.id,
            sendToEmployeeId: employee.id,
            channel: 'SMS',
            sendToEmployeeJobId: formik.values.jobId,
            modifiedTemplate: editSMSNotificationTemplateForm.values.smsContent,
          },
        },
      },
    })
  }

  // const sendClientEmail = () => {
  //   emailEditorRef.current.editor.exportHtml((data) => {
  //     sendNotificationTrigger({
  //       variables: {
  //         sendNotificationTriggerInput: {
  //           notificationTriggerInput: {
  //             id: currentNotificationTrigger.id,
  //             sendToUserId: contact.id,
  //             channel: 'Email',
  //             ccEmails: formik.values.ccEmails,
  //             sendToSubjectJobId: formik.values.jobId,
  //             bccEmails: formik.values.bccEmails,
  //             sendToContactJobId: formik.values.jobId,
  //             sendToSubjectSubjectGroupId:
  //               formik.values.subjectDetailSubjectGroupId,
  //             sendToSubjectSessionId: formik.values.subjectDetailSessionId,
  //             modifiedTemplate: data.html.replace(
  //               /\{\{%20(.*?)%20\}\}/g,
  //               '{{$1}}'
  //             ),
  //           },
  //         },
  //       },
  //     })
  //   })
  // }

  // const sendClientEmailFromMe = () => {
  //   if (
  //     formik.values.subjectDetailSubjectGroupId &&
  //     formik.values.subjectDetailSessionId
  //   ) {
  //     formik.setFieldError(
  //       'subjectDetailSubjectGroupId',
  //       'cannot choose subject group and session'
  //     )
  //   } else {
  //     if (formik.errors.subjectDetailSubjectGroupId) {
  //       formik.setFieldError('subjectDetailSubjectGroupId', null)
  //     }
  //     emailEditorRef.current.editor.exportHtml((data) => {
  //       sendNotificationTrigger({
  //         variables: {
  //           sendNotificationTriggerInput: {
  //             notificationTriggerInput: {
  //               id: currentNotificationTrigger.id,
  //               senderEmployeeId: myUser.myUser.employee.id,
  //               sendToUserId: contact.id,
  //               channel: 'Email',
  //               sendToSubjectJobId: formik.values.jobId,
  //               ccEmails: formik.values.ccEmails,
  //               bccEmails: formik.values.bccEmails,
  //               sendToSubjectSubjectGroupId:
  //                 formik.values.subjectDetailSubjectGroupId,
  //               sendToSubjectSessionId: formik.values.subjectDetailSessionId,
  //               modifiedTemplate: data.html.replace(
  //                 /\{\{%20(.*?)%20\}\}/g,
  //                 '{{$1}}'
  //               ),
  //             },
  //           },
  //         },
  //       })
  //     })
  //   }
  // }

  // const sendClientSMS = () => {
  //   if (
  //     formik.values.subjectDetailSubjectGroupId &&
  //     formik.values.subjectDetailSessionId
  //   ) {
  //     formik.setFieldError(
  //       'subjectDetailSubjectGroupId',
  //       'cannot choose subject group and session'
  //     )
  //   } else {
  //     if (formik.errors.subjectDetailSubjectGroupId) {
  //       formik.setFieldError('subjectDetailSubjectGroupId', null)
  //     }
  //     sendNotificationTrigger({
  //       variables: {
  //         sendNotificationTriggerInput: {
  //           notificationTriggerInput: {
  //             id: currentNotificationTrigger.id,
  //             sendToUserId: contact.id,
  //             channel: 'SMS',
  //             sendToSubjectJobId: formik.values.jobId,
  //             sendToSubjectSubjectGroupId:
  //               formik.values.subjectDetailSubjectGroupId,
  //             sendToSubjectSessionId: formik.values.subjectDetailSessionId,
  //             modifiedTemplate:
  //               editSMSNotificationTemplateForm.values.smsContent,
  //           },
  //         },
  //       },
  //     })
  //   }
  // }

  const [sendNotificationTrigger] = useMutation(
    gql`
      mutation SendNotificationTrigger(
        $sendNotificationTriggerInput: SendNotificationTriggerInput!
      ) {
        sendNotificationTrigger(input: $sendNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        toast.success('Notification Sent')
      },
      refetchQueries: ['NotificationsQuery'],
    }
  )

  if (!myUser || !smtpConnector || !canSend || !twilioConnectorData)
    return (
      <Row className="mt-3">
        <Col>
          <Loading message="Loading Notifications..." />
        </Col>
      </Row>
    )
  const smtpNode = smtpConnector.smtpConnectors.edges[0].node
  const twilioConnector = twilioConnectorData.twilioConnectors.edges[0].node
  if (noCurrentTrigger) {
    return (
      <>
        <div>
          {(!smtpNode.customValid || !twilioConnector.customValid) && (
            <Row className="mb-1">
              {!smtpNode.customValid && (
                <Col md={3}>
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Email Server Credentials Required to Send Email
                  </Badge>
                </Col>
              )}
              {!twilioConnector.customValid && (
                <Col md={1}>
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Twilio Credentials Required to Send SMS
                  </Badge>
                </Col>
              )}
            </Row>
          )}
          {canMutate && (
            <>
              <Row className="mb-4">
                <Col md={3}>
                  <Button variant="link" onClick={toggleNewTriggerModal}>
                    <Bell className="mr-2" />
                    Add Notification
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
        <h5 className="mt-4">Sent Notifications</h5>
        <NotificationsTable
          business={business}
          notificationsLoading={notificationsLoading}
          setNotificationsLoading={setNotificationsLoading}
        />

        <NewCustomTriggerModal
          parentFormik={formik}
          setCurrentNotificationTrigger={setCurrentNotificationTrigger}
          setCurrentTriggerCustom={setCurrentTriggerCustom}
          showModal={showNewTriggerModal}
          toggleModal={toggleNewTriggerModal}
        />
      </>
    )
  }

  let showFields = true
  if (formik.values.businessId && formik.values.using != 'BUSINESS') {
    showFields = false
  }

  if (!showComponent)
    return (
      <Row className="mt-3">
        <Col>
          <Loading message="Loading Notifications..." />
        </Col>
      </Row>
    )

  if (showComponent) {
    const dynamicTemplateVariables =
      currentNotificationTriggerEmailTemplate?.dynamicContext
        ? JSON.parse(currentNotificationTriggerEmailTemplate.dynamicContext)
        : {}
    return (
      <>
        <div className="notifications">
          <Form onSubmit={formik.handleSubmit}>
            {!copyTrigger && (
              <>
                <Row className="mb-1">
                  <Col>
                    <h1 className="mt-3 mb-2">
                      {notificationTriggerName(currentNotificationTrigger)}{' '}
                      {!currentNotificationTrigger?.name?.includes(
                        'Ticket'
                      ) && <>Notification</>}
                    </h1>
                  </Col>
                </Row>
                {(!smtpNode.customValid || !twilioConnector.customValid) && (
                  <Row className="mb-1">
                    {!smtpNode.customValid && (
                      <Col md={3}>
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="danger"
                        >
                          Email Server Credentials Required to Send Email
                        </Badge>
                      </Col>
                    )}
                    {!twilioConnector.customValid && (
                      <Col md={1}>
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="danger"
                        >
                          Twilio Credentials Required to Send SMS
                        </Badge>
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
            <Row className="mt-2 mb-2">
              {canMutate && (
                <>
                  <Col md={1} className="mr-5">
                    <Button variant="link" onClick={toggleNewTriggerModal}>
                      <Bell className="mr-2" />
                      Add Notification
                    </Button>
                  </Col>
                  {business && canMutate && (
                    <>
                      {!currentTriggerCustom && (
                        <Col md={1}>
                          <Button
                            variant="link"
                            onClick={() => {
                              setShowCopyTriggerModal(true)
                              setCopyAllTriggers(true)
                            }}
                          >
                            <ArrowRepeat className="mr-2" />
                            Sync All
                          </Button>
                        </Col>
                      )}
                    </>
                  )}
                  <Col md={1} className={'mr-5'}>
                    <Button
                      variant="link"
                      onClick={() => {
                        setShowCopyTriggerModal(true)
                      }}
                    >
                      <ArrowRepeat className="mr-2" />
                      Sync {business && <>Current</>}{' '}
                      {!business && <>Template</>}
                    </Button>
                  </Col>
                </>
              )}
              <Col md={2} className="mr-3">
                <Button
                  variant="link"
                  onClick={() => {
                    getS3Object(
                      currentNotificationTriggerEmailTemplate.file.fileName,
                      `${currentNotificationTriggerEmailTemplate.name}.html`,
                      () => {
                        toast.success('Email Template Downloaded')
                      }
                    )
                  }}
                >
                  <Download className="mr-2" />
                  Download Email Template
                </Button>
              </Col>
              {currentNotificationTriggerSMSTemplate && (
                <Col md={2}>
                  <Button
                    variant="link"
                    onClick={() => {
                      getS3Object(
                        currentNotificationTriggerSMSTemplate.file.fileName,
                        `${currentNotificationTriggerSMSTemplate.name}.txt`,
                        () => {
                          toast.success('SMS Template Downloaded')
                        }
                      )
                    }}
                  >
                    <Download className="mr-2" />
                    Download SMS Template
                  </Button>
                </Col>
              )}
            </Row>
            {employee &&
              (smtpNode.customValid || twilioConnector.customValid) && (
                <>
                  <Row className="mb-2">
                    {twilioConnector.customValid && (
                      <Col md={2}>
                        <Button variant="link" onClick={sendEmployeeSMS}>
                          <PhoneVibrate className="mr-2" />
                          Send Employee SMS
                        </Button>
                      </Col>
                    )}
                    {smtpNode.customValid && (
                      <Col md={2}>
                        <Button variant="link" onClick={sendEmployeeEmail}>
                          <Envelope className="mr-2" />
                          Send Employee Email
                        </Button>
                      </Col>
                    )}
                    {myUser.myUser.employee.smtpValid && (
                      <Col md={2} style={{ marginLeft: '40px' }}>
                        <Button
                          variant="link"
                          onClick={sendEmployeeEmailFromMe}
                        >
                          <Envelope className="mr-2" />
                          Send Employee Email From Your Email
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}

            {!business && (
              <Form.Group as={Row}>
                <Col md={2}>
                  <Form.Label column sm="12" md="auto">
                    Business
                  </Form.Label>
                </Col>
                <Col md={copyTrigger ? 8 : 4}>
                  <BusinessSearchInput formik={formik} />
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row}>
              <Col md={2}>
                <Form.Label column sm="12" md="auto">
                  Template
                </Form.Label>
              </Col>
              <Col sm="12" md={copyTrigger ? 8 : 4}>
                <Form.Control
                  name="notificationTrigger"
                  as="select"
                  value={currentNotificationTrigger.id}
                  className="form-control form-control-sm"
                  onChange={(event) => handleNotificationTriggerChange(event)}
                >
                  {Object.entries(notificationTriggers).map(
                    (notificationTrigger) => {
                      return (
                        <option
                          key={notificationTrigger[0]}
                          value={notificationTrigger[0]}
                        >
                          {notificationTriggerName(notificationTrigger[1])}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
              </Col>
            </Form.Group>
            {currentNotificationTrigger.custom && canMutate && !copyTrigger && (
              <>
                <Form.Group as={Row}>
                  <Col md={2}>
                    <Form.Label column sm="12" md="auto">
                      Template Name
                    </Form.Label>
                  </Col>
                  <Col sm="12" md={4}>
                    <Form.Control
                      type="text"
                      name="name"
                      className="form-control-sm"
                      disabled={!canMutate}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </>
            )}
            {!copyTrigger && (
              <>
                {!currentTriggerCustom && (
                  <>
                    {nonDefaultTrigger && (
                      <>
                        <Form.Group as={Row}>
                          <Col md={2}>
                            <Form.Label column sm="12" md="auto">
                              Using
                            </Form.Label>
                          </Col>
                          <Col sm="12" md={4}>
                            <Form.Control
                              name="using"
                              as="select"
                              className="form-control form-control-sm"
                              disabled={!canMutate}
                              value={formik.values.using}
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.using}
                            >
                              <option value={'DEFAULT'}>
                                Default Template
                              </option>
                              <option value={'BUSINESS'}>
                                Businesses Template
                              </option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.using}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col md={2}>
                            <Form.Label column sm="12" md="auto">
                              Enabled
                            </Form.Label>
                          </Col>
                          <Col sm="12" md={6}>
                            <Form.Check
                              name="enabled"
                              type="checkbox"
                              disabled={!canMutate}
                              label={formik.values.enabled ? 'Yes' : 'No'}
                              checked={formik.values.enabled}
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.enabled}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.enabled}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </>
                    )}
                    {showFields && (
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Send Emails
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={6}>
                          <Form.Check
                            name="emailEnabled"
                            type="checkbox"
                            disabled={!canMutate}
                            label={formik.values.emailEnabled ? 'Yes' : 'No'}
                            checked={formik.values.emailEnabled}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.emailEnabled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.emailEnabled}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    )}

                    {showSMSTemplate && showFields && (
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Send SMS
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={6}>
                          <Form.Check
                            name="smsEnabled"
                            type="checkbox"
                            disabled={!canMutate}
                            label={formik.values.smsEnabled ? 'Yes' : 'No'}
                            checked={formik.values.smsEnabled}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.smsEnabled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.smsEnabled}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    )}
                    {showFields && canMutate && (
                      <>
                        <Form.Group as={Row}>
                          <Col md={2}>
                            <Form.Label column sm="12" md="auto">
                              Emails Per Batch
                            </Form.Label>
                          </Col>
                          <Col sm="12" md={1}>
                            <Form.Control
                              name="smtpRateLimit"
                              type="number"
                              value={formik.values.smtpRateLimit}
                              className="form-control-sm"
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.smtpRateLimit}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.smtpRateLimit}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col md={2}>
                            <Form.Label column sm="12" md="auto">
                              Seconds Per Batch
                            </Form.Label>
                          </Col>
                          <Col sm="12" md={1}>
                            <Form.Control
                              name="smtpRateLimitSecondDelta"
                              type="number"
                              value={formik.values.smtpRateLimitSecondDelta}
                              className="form-control-sm"
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.smtpRateLimitSecondDelta}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.smtpRateLimitSecondDelta}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </>
                    )}
                    {showFields && (
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Recipients
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={6}>
                          <Form.Control
                            readOnly
                            plaintext
                            value={notificationRecipients}
                          />
                        </Col>
                      </Form.Group>
                    )}

                    {showStartDeltaFields && showFields ? (
                      <>
                        {canSetCustomStartEndTime && (
                          <>
                            <Form.Group as={Row}>
                              <Col md={2}>
                                <Form.Label column sm="12" md="auto">
                                  Custom Start End Dates
                                </Form.Label>
                              </Col>
                              <Col sm="12" md={6}>
                                <>
                                  <Form.Check
                                    className="d-inline-block"
                                    name="customStartEndTime"
                                    type="checkbox"
                                    disabled={!canMutate}
                                    checked={formik.values.customStartEndTime}
                                    onChange={() => {
                                      if (formik.values.customStartEndTime) {
                                        formik.setFieldValue(
                                          'startDateTime',
                                          null
                                        )
                                        formik.setFieldValue(
                                          'endDateTime',
                                          null
                                        )
                                        formik.setFieldError(
                                          'startDateTime',
                                          null
                                        )
                                        formik.setFieldError(
                                          'endDateTime',
                                          null
                                        )
                                      }
                                      formik.setFieldValue(
                                        'customStartEndTime',
                                        !formik.values.customStartEndTime
                                      )
                                    }}
                                    isInvalid={formik.errors.customStartEndTime}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.customStartEndTime}
                                  </Form.Control.Feedback>
                                </>
                              </Col>
                              {formik.values.customStartEndTime && (
                                <Form.Group as={Row} className="mt-3">
                                  <Col md={2} className="ml-1">
                                    <Form.Label column sm="12" md="auto">
                                      Start Date
                                    </Form.Label>
                                  </Col>
                                  <Col
                                    className="d-flex align-items-center pr-0"
                                    md={2}
                                  >
                                    {formik.values.startDateTime && (
                                      <div
                                        className="text-danger"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            'startDateTime',
                                            null
                                          )
                                          formik.setFieldError(
                                            'startDateTime',
                                            null
                                          )
                                        }}
                                      >
                                        <DashCircle className="mr-2 text-danger" />
                                      </div>
                                    )}
                                    <DatePicker
                                      name="startDateTime"
                                      className="form-control"
                                      showPopperArrow={false}
                                      popperPlacement="auto"
                                      disabled={!canMutate}
                                      selected={formik.values.startDateTime}
                                      onChange={(date) =>
                                        formik.setFieldValue(
                                          'startDateTime',
                                          date
                                        )
                                      }
                                      popperModifiers={{
                                        flip: {
                                          behavior: ['bottom'],
                                        },
                                        preventOverflow: {
                                          enabled: false,
                                        },
                                        hide: {
                                          enabled: false,
                                        },
                                      }}
                                    />
                                    {formik.errors.startDateTime && (
                                      <small className="text-danger">
                                        {formik.errors.startDateTime}
                                      </small>
                                    )}
                                  </Col>
                                  {formik.values.recurring && (
                                    <>
                                      <Col md={1}>
                                        <Form.Label column sm="12" md="auto">
                                          End Date
                                        </Form.Label>
                                      </Col>
                                      <Col
                                        className="d-flex align-items-center pr-0"
                                        md={2}
                                      >
                                        {formik.values.endDateTime && (
                                          <div
                                            className="text-danger"
                                            onClick={() => {
                                              formik.setFieldValue(
                                                'endDateTime',
                                                null
                                              )
                                              formik.setFieldError(
                                                'endDateTime',
                                                null
                                              )
                                            }}
                                          >
                                            <DashCircle className="mr-2 text-danger" />
                                          </div>
                                        )}
                                        <DatePicker
                                          name="endDateTime"
                                          className="form-control"
                                          showPopperArrow={false}
                                          popperPlacement="auto"
                                          disabled={!canMutate}
                                          selected={formik.values.endDateTime}
                                          onChange={(date) =>
                                            formik.setFieldValue(
                                              'endDateTime',
                                              date
                                            )
                                          }
                                          popperModifiers={{
                                            flip: {
                                              behavior: ['bottom'],
                                            },
                                            preventOverflow: {
                                              enabled: false,
                                            },
                                            hide: {
                                              enabled: false,
                                            },
                                          }}
                                        />
                                        {formik.errors.endDateTime && (
                                          <small className="text-danger">
                                            {formik.errors.endDateTime}
                                          </small>
                                        )}
                                      </Col>
                                    </>
                                  )}
                                </Form.Group>
                              )}
                            </Form.Group>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {showFields && (
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Sends
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={6}>
                          <Form.Control
                            readOnly
                            plaintext
                            value={startDetail()}
                          />
                        </Col>
                      </Form.Group>
                    )}
                    {showStartDeltaFields && showFields ? (
                      <>
                        <Row>
                          <Form.Group
                            as={Col}
                            md={{
                              span: 1,
                              offset: 2,
                            }}
                          >
                            <Form.Control
                              type="number"
                              className="form-control-sm"
                              name="startDeltaCount"
                              disabled={!canMutate}
                              value={formik.values.startDeltaCount}
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.startDeltaCount}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.startDeltaCount}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} md={2}>
                            <Form.Control
                              name="startDelta"
                              as="select"
                              className="form-control-sm"
                              disabled={!canMutate}
                              value={formik.values.startDelta}
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.startDelta}
                            >
                              {NOTIFICATION_TRIGGER_DELTAS.map((delta) => (
                                <option key={delta} value={delta}>
                                  {delta}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.startDelta}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    {showFields && (
                      <Form.Group as={Row}>
                        <Col md={2}>
                          <Form.Label column sm="12" md="auto">
                            Repeats
                          </Form.Label>
                        </Col>
                        <Col sm="12" md={6}>
                          {showRepeatToggle ? (
                            <>
                              <Form.Check
                                className="d-inline-block"
                                name="recurring"
                                type="checkbox"
                                label={repeatDetail()}
                                disabled={!canMutate}
                                checked={formik.values.recurring}
                                onChange={formik.handleChange}
                                isInvalid={formik.errors.recurring}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.recurring}
                              </Form.Control.Feedback>
                            </>
                          ) : (
                            <span style={{ marginTop: '-5px' }}>
                              {repeatDetail()}
                            </span>
                          )}
                        </Col>
                      </Form.Group>
                    )}

                    {showOccurrenceDeltaFields &&
                    showFields &&
                    formik.values.recurring ? (
                      <>
                        <Row>
                          <Form.Group
                            as={Col}
                            md={{
                              span: 1,
                              offset: 2,
                            }}
                          >
                            <Form.Control
                              type="number"
                              name="occurrenceDeltaCount"
                              className="form-control-sm"
                              disabled={!canMutate}
                              value={formik.values.occurrenceDeltaCount}
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.occurrenceDeltaCount}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.occurrenceDeltaCount}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} md={2}>
                            <Form.Control
                              name="occurrenceDelta"
                              as="select"
                              className="form-control-sm"
                              disabled={!canMutate}
                              value={formik.values.occurrenceDelta}
                              onChange={formik.handleChange}
                              isInvalid={formik.errors.occurrenceDelta}
                            >
                              {NOTIFICATION_TRIGGER_DELTAS.map((delta) => (
                                <option key={delta} value={delta}>
                                  {delta}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.occurrenceDelta}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
            {!currentNotificationTrigger?.name?.includes('Ticket') &&
              !copyTrigger &&
              showFields && (
                <>
                  <Form.Group as={Row}>
                    <Col md={2}>
                      <Form.Label column sm="12" md="auto">
                        Email CCs
                      </Form.Label>
                    </Col>
                    <Col sm="12" md={4}>
                      <Form.Control
                        type="text"
                        className="form-control-sm"
                        name="ccEmails"
                        value={formik.values.ccEmails}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.ccEmails}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.ccEmails}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col md={2}>
                      <Form.Label column sm="12" md="auto">
                        Email BCCs
                      </Form.Label>
                    </Col>
                    <Col sm="12" md={4}>
                      <Form.Control
                        type="text"
                        name="bccEmails"
                        className="form-control-sm"
                        value={formik.values.bccEmails}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.bccEmails}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.bccEmails}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </>
              )}
            {!copyTrigger && showFields && (
              <>
                <Row className="mt-4">
                  <Col className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() =>
                        setDisplayTemplateVariables(!displayTemplateVariables)
                      }
                      className="px-0 btn-link mr-1"
                      style={{ marginTop: '-10px' }}
                    >
                      <>
                        {displayTemplateVariables ? (
                          <>
                            <CaretDown size={20} />
                          </>
                        ) : (
                          <>
                            <CaretRight size={20} />
                          </>
                        )}
                      </>
                    </button>
                    <h5>Template Variables</h5>
                  </Col>
                </Row>
                {displayTemplateVariables && (
                  <Row>
                    {Object.keys(dynamicTemplateVariables).map(
                      (dynamicTemplateVariable) => {
                        if (
                          !employee ||
                          (!dynamicTemplateVariable.includes('session') &&
                            !dynamicTemplateVariable.includes('subject_group'))
                        ) {
                          const displayVariable = `{{ ${dynamicTemplateVariable} }}`
                          return (
                            <Col key={dynamicTemplateVariable} xs={12} md={3}>
                              <Badge
                                style={{
                                  color: 'black',
                                }}
                              >
                                {displayVariable}
                              </Badge>
                            </Col>
                          )
                        }
                      }
                    )}
                  </Row>
                )}
              </>
            )}

            {!copyTrigger && (
              <>
                {showFields && (
                  <>
                    <EmailNotificationTemplateBuilder
                      notificationTemplate={
                        currentNotificationTriggerEmailTemplate
                      }
                      emailEditorRef={emailEditorRef}
                      formik={formik}
                      setReloadEmailEditorRef={setReloadEmailEditorRef}
                      reloadEmailEditorRef={reloadEmailEditorRef}
                    />
                    <PushNotificationTemplateBuilder
                      notificationTemplate={
                        currentNotificationTriggerSMSTemplate
                      }
                      formik={editSMSNotificationTemplateForm}
                    />
                    <SMSNotificationTemplateBuilder
                      notificationTemplate={
                        currentNotificationTriggerSMSTemplate
                      }
                      formik={editSMSNotificationTemplateForm}
                    />
                  </>
                )}

                {canMutate && (
                  <Row className="mt-3">
                    <Col md={2}>
                      <Button type="submit" block variant="outline-primary">
                        <Folder className="mr-2" />
                        Save {showFields && <>Template</>}
                      </Button>
                    </Col>
                  </Row>
                )}
                {currentNotificationTrigger.custom && canMutate && (
                  <Row className="mt-3">
                    <Col>
                      <Button
                        variant="outline-danger"
                        onClick={toggleDeleteTriggerModal}
                      >
                        <Trash className="mr-2" />
                        Delete Template
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Form>
          {!copyTrigger && showFields && (
            <>
              <Row>
                <Col>
                  <h5 className="mt-5 mb-4">
                    Test {notificationTriggerName(currentNotificationTrigger)}{' '}
                    Notification
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <EmailNotificationTestForm
                    notificationTrigger={currentNotificationTrigger}
                  />
                </Col>
              </Row>
              {showSMSTemplate && (
                <Row className="mt-2 mb-5">
                  <Col>
                    <SMSNotificationTestForm
                      notificationTrigger={currentNotificationTrigger}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}

          {!copyTrigger && (
            <>
              <h5 className="mt-4">Sent Notifications</h5>
              <NotificationsTable
                employee={employee}
                business={business}
                notificationsLoading={notificationsLoading}
                setNotificationsLoading={setNotificationsLoading}
              />
            </>
          )}
        </div>
        <NewCustomTriggerModal
          parentFormik={formik}
          setCurrentNotificationTrigger={setCurrentNotificationTrigger}
          setCurrentTriggerCustom={setCurrentTriggerCustom}
          showModal={showNewTriggerModal}
          toggleModal={toggleNewTriggerModal}
        />
        <DeleteCustomTriggerModal
          notificationTrigger={currentNotificationTrigger}
          showModal={showDeleteTriggerModal}
          toggleModal={toggleDeleteTriggerModal}
          setResetCurrent={setResetCurrent}
          setNonDefaultTemplate={setNonDefaultTemplate}
          notificationTriggersQuery={notificationTriggersQuery}
          parentFormik={formik}
          business={business}
          employee={employee}
        />
        <CopyTriggerModal
          showModal={showCopyTriggerModal}
          toggleModal={() => {
            setShowCopyTriggerModal(false)
            setCopyAllTriggers(false)
          }}
          all={copyAllTriggers}
          business={business}
          notificationTriggerId={currentNotificationTrigger?.id}
        />
      </>
    )
  }
}

export default Notifications
