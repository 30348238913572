import React, { useEffect, useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import AuditLog from '../../AuditLog'
import { CaretRight, CaretDown } from 'react-bootstrap-icons'

const APICallModal = (props) => {
  const { showModal, toggleModal, apiCall } = props
  const [displayRequest, setDisplayRequest] = useState(false)
  const [displayResponse, setDisplayResponse] = useState(false)
  const [displayHistory, setDisplayHistory] = useState(false)

  const formatRequest = (request) => {
    let formattedQuery = ''
    const spacesPerIndent = 2
    let indentLevel = 0

    for (const char of request) {
      switch (char) {
        case '{':
        case '[':
          indentLevel++
          formattedQuery +=
            char + '\n' + ' '.repeat(indentLevel * spacesPerIndent)
          break
        case '}':
        case ']':
          indentLevel--
          formattedQuery +=
            '\n' + ' '.repeat(indentLevel * spacesPerIndent) + char
          break
        case ',':
          formattedQuery +=
            char + '\n' + ' '.repeat(indentLevel * spacesPerIndent)
          break
        default:
          formattedQuery += char
          break
      }
    }

    return formattedQuery
  }

  const formatResponse = (response) => {
    try {
      const jsonObj = JSON.parse(response)
      return JSON.stringify(jsonObj, null, 2)
    } catch (e) {
      return 'Error in formatting JSON: ' + e
    }
  }

  useEffect(() => {
    if (!showModal) {
      setDisplayHistory(false)
      setDisplayRequest(false)
      setDisplayResponse(false)
    }
  }, [showModal])

  if (!showModal) return <></>
  return (
    <>
      <div id="apiKeyModal">
        <Modal size={'lg'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>API Call {apiCall.recordId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayRequest(!displayRequest)}
                  className="px-0 btn-link mr-2"
                  style={{ marginTop: '-10px' }}
                >
                  {displayRequest ? (
                    <>
                      <CaretDown size={20} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={20} />
                    </>
                  )}
                </button>
                <h5>Request</h5>
              </Col>
            </Row>
            {displayRequest && (
              <Row>
                <Col>
                  <pre>
                    <code>{formatRequest(apiCall.apiRequest)}</code>
                  </pre>
                </Col>
              </Row>
            )}

            <Row>
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  style={{ marginTop: '-10px' }}
                  onClick={() => setDisplayResponse(!displayResponse)}
                  className="px-0 btn-link mr-2"
                >
                  {displayResponse ? (
                    <>
                      <CaretDown size={20} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={20} />
                    </>
                  )}
                </button>
                <h5>Response</h5>
              </Col>
            </Row>
            {displayResponse && (
              <Row>
                <Col>
                  <pre>
                    <code>{formatResponse(apiCall.apiResponse)}</code>
                  </pre>
                </Col>
              </Row>
            )}

            <Row>
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  style={{ marginTop: '-10px' }}
                  onClick={() => setDisplayHistory(!displayHistory)}
                  className="px-0 btn-link mr-2"
                >
                  {displayHistory ? (
                    <>
                      <CaretDown size={20} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={20} />
                    </>
                  )}
                </button>
                <h5>Record Change History</h5>
              </Col>
            </Row>
            {displayHistory && (
              <AuditLog apiCallId={apiCall.id} fetchPolicy={'no-cache'} />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default APICallModal
