import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { DNA } from 'react-loader-spinner'
import { settingsVar } from '../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const InactiveTenant = () => {
  const settings = useReactiveVar(settingsVar)
  const history = useHistory()

  useEffect(() => {
    if (history && settings && settings.tenantActive) {
      history.push('/')
    }
  }, [settings, history])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <DNA visible={true} height="300" width="300" ariaLabel="dna-loading" />
        <div
          style={{
            marginTop: '20px',
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#000',
          }}
        >
          <Row>
            <Col>
              <h1>ScrapGo Account Disabled</h1>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default InactiveTenant
