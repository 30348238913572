import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import Layout from '../components/layout/Layout'
import { EMPLOYEE_GROUPS } from '../libs/constant'
import { loggedInUserVar } from '../libs/apollo'

const EmployeeRoute = ({ component: Component, ...rest }) => {
  let user = useReactiveVar(loggedInUserVar)
  if (user) {
    let userGroup = user?.groups.edges[0].node.name
    if (EMPLOYEE_GROUPS.includes(userGroup)) {
      return (
        <Layout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </Layout>
      )
    } else {
      return <Redirect to="/" />
    }
  } else {
    return <Redirect to="/" />
  }
}

export default EmployeeRoute
