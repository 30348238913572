import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const ProductPricesCalendar = (props) => {
  const { productPrices, toggleModal } = props
  const monthMilliseconds = 2592000000
  const DragAndDropCalendar = withDragAndDrop(Calendar)
  const localizer = momentLocalizer(moment)
  const eventStyleGetter = () => {
    const style = {
      color: 'black',
      borderRadius: '0px',
      fontSize: '.7em',
      border: 'none',
    }
    return {
      className: '',
      style,
    }
  }
  const calendarProductPrices = productPrices
    .filter(
      (productPrice) =>
        productPrice.startDate !== null && productPrice.endDate !== null
    )
    .map((productPrice) => ({
      ...productPrice,
      startDate: new Date(productPrice.node.startDate),
      endDate: new Date(productPrice.node.endDate),
    }))
  return (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <DragAndDropCalendar
            popup
            localizer={localizer}
            step={5}
            dayLayoutAlgorithm="no-overlap"
            timeslots={12}
            defaultDate={new Date()}
            views={['month', 'week', 'day']}
            defaultView={'month'}
            events={calendarProductPrices}
            style={{ height: 600 }}
            titleAccessor={(event) => {
              const price = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(event.node.price)
              const quantity = `${Math.round(event.node.quantity)}/${
                event.node.unitOfMeasure.shortName
              }`
              if (event.node.supplier) {
                return `Supplier ${event.node.supplier.business.name} | Product ${event.node.productVariant.product.name} | ${quantity} at ${price}/${event.node.unitOfMeasure.shortName}`
              } else if (event.node.buyer) {
                return `Buyer ${event.node.buyer.business.name} | Product ${event.node.productVariant.product.name} | ${quantity} at ${price}/${event.node.unitOfMeasure.shortName}`
              } else {
                return `Deleted Business | Product ${event.node.productVariant.product.material.name} ${event.node.productVariant.product.commodityForm.name} | ${quantity} at ${price}/${event.node.unitOfMeasure.shortName}`
              }
            }}
            showMultiDayTimes
            startAccessor="startDate"
            endAccessor="endDate"
            // onRangeChange={(rangeEvent) => handleRangeChange(rangeEvent)}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={(event) => {
              toggleModal(event.node)
            }}
            // onNavigate={(defaultDate) => handleNavigate(defaultDate)}
            // onView={(view) => handleViewChange(view)}
          />
        </Col>
      </Row>
    </>
  )
}

export default ProductPricesCalendar
