import React, { useState } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import { Collection, CardChecklist } from 'react-bootstrap-icons'
import Tabs from '../common/Tabs'
// import Fulfillments from './Fulfillments'
import TaskGroupTemplates from './task_group_template/TaskGroupTemplates'
import TaskTemplates from './task_template/TaskTemplates'
import TaskGroups from './task_group/TaskGroups'
import Tasks from './task/Tasks'

const TaskTabs = (props) => {
  const { dealId, businessId, employeeId, productId, tableHeight } = props
  const [activeTab, setActiveTab] = useState('Tasks')
  return (
    <>
      {!dealId && !businessId && !employeeId && !productId && (
        <Row className="mt-3">
          <Col>
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              <h1 className="mb-0" style={{ marginRight: '10px' }}>
                Tasks
              </h1>
            </div>
          </Col>
        </Row>
      )}
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Tasks"
          eventKey="Tasks"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CardChecklist className="mr-2" />
              Tasks
            </b>
          }
        >
          <Tasks
            dealId={dealId}
            businessId={businessId}
            productId={productId}
            tableHeight={tableHeight}
          />
        </Tab>
        <Tab
          key="Task Groups"
          eventKey="Task Groups"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Collection className="mr-2" />
              Task Groups
            </b>
          }
        >
          <TaskGroups
            dealId={dealId}
            businessId={businessId}
            productId={productId}
            tableHeight={tableHeight}
          />
        </Tab>
        {!dealId && !businessId && !employeeId && !productId && (
          <Tab
            key="Task Templates"
            eventKey="Task Templates"
            style={{ marginTop: '10px' }}
            title={
              <b>
                <CardChecklist className="mr-2" />
                Task Templates
              </b>
            }
          >
            <TaskTemplates tableHeight={tableHeight} />
          </Tab>
        )}
        {!dealId && !businessId && !employeeId && !productId && (
          <Tab
            key="Task Group Templates"
            eventKey="Task Group Templates"
            style={{ marginTop: '10px' }}
            title={
              <b>
                <Collection className="mr-2" />
                Task Group Templates
              </b>
            }
          >
            <TaskGroupTemplates tableHeight={tableHeight} />
          </Tab>
        )}
      </Tabs>
    </>
  )
}
export default TaskTabs
