import React, { useState } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import {
  Database,
  Robot,
  Plug,
  Lightbulb,
  Table,
  QuestionLg,
} from 'react-bootstrap-icons'
import Tabs from '../common/Tabs'
import ModelOutputFormats from './model_output_formats/ModelOutputFormats'
import Prompts from './prompts/Prompts'

const AITabs = () => {
  const [activeTab, setActiveTab] = useState('Models')
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Artificial Intelligence
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Models"
          eventKey="Models"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Robot className="mr-2" />
              Models
            </b>
          }
        ></Tab>
        <Tab
          key="DataSources"
          eventKey="DataSources"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Plug className="mr-2" />
              Data Sources
            </b>
          }
        ></Tab>
        <Tab
          key="Prompts"
          eventKey="Prompts"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <QuestionLg className="mr-2" />
              Prompts
            </b>
          }
        >
          <Prompts />
        </Tab>
        <Tab
          key="TrainingSets"
          eventKey="TrainingSets"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Lightbulb className="mr-2" />
              Training Sets
            </b>
          }
        ></Tab>
        <Tab
          key="ModelOutputFormats"
          eventKey="ModelOutputFormats"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Table className="mr-2" />
              Model Output Formats
            </b>
          }
        >
          <ModelOutputFormats />
        </Tab>
        <Tab
          key="ModelOutputs"
          eventKey="ModelOutputs"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Database className="mr-2" />
              Model Outputs
            </b>
          }
        ></Tab>
      </Tabs>
    </>
  )
}
export default AITabs
