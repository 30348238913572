import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'

const DeleteCustomTriggerModal = (props) => {
  const {
    showModal,
    toggleModal,
    notificationTrigger,
    setResetCurrent,
    setNonDefaultTemplate,
    notificationTriggersQuery,
    employee,
    parentFormik,
    business,
  } = props

  const [mutation] = useMutation(
    gql`
      mutation DeleteCustomTrigger(
        $deleteNotificationTriggerInput: DeleteNotificationTriggerInput!
      ) {
        deleteNotificationTrigger(input: $deleteNotificationTriggerInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        parentFormik.setFieldValue(`bccEmails`, '')
        parentFormik.setFieldValue(`ccEmails`, '')
        if (!business) {
          setResetCurrent(false)
          setResetCurrent(true)
          setNonDefaultTemplate(false)
          let variables = {
            default: true,
          }
          if (employee) {
            variables.custom = true
          }
          notificationTriggersQuery({
            variables,
          })
        } else {
          setResetCurrent(false)
          setResetCurrent(true)
          setNonDefaultTemplate(true)
          let variables = {
            businessId: business.id,
            orCustom: true,
          }
          notificationTriggersQuery({
            variables,
          })
        }
        toggleModal()
        toast.success('Notification Template Deleted')
      },
      errorPolic: 'all',
    }
  )

  if (!showModal) return <></>
  return (
    <>
      <div className="customNotificationTrigger">
        <Modal
          size="md"
          show={showModal}
          onHide={toggleModal}
          aria-labelledby="new-title"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Notification Template
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the notification template?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  type="submit"
                  variant="outline-danger"
                  className="mt-2"
                  block
                  onClick={() => {
                    mutation({
                      variables: {
                        deleteNotificationTriggerInput: {
                          notificationTriggerIds: [notificationTrigger.id],
                        },
                      },
                    })
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteCustomTriggerModal
