import React from 'react'
import { Modal } from 'react-bootstrap'
import PurchaseOrderForm from './PurchaseOrderForm'
import { FileEarmarkBarGraphFill } from 'react-bootstrap-icons'

const PurchaseOrderModal = (props) => {
  const { showModal, toggleModal, purchaseOrder, deal } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal
          size={purchaseOrder ? 'xl' : 'lg'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FileEarmarkBarGraphFill className="mr-2" />
              {purchaseOrder ? (
                <>Purchase Order #{purchaseOrder.number}</>
              ) : (
                <>New Purchase Order</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PurchaseOrderForm
              purchaseOrder={purchaseOrder}
              afterSubmit={toggleModal}
              showModal={showModal}
              deal={deal}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default PurchaseOrderModal
