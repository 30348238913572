import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const CargoProviderSearchInput = (props) => {
  const { formik, disabled, multiple, searchDescription, error } = props

  return (
    <SearchInput
      formik={formik}
      nodeName="cargoProvider"
      nodeNamePlural="cargoProviders"
      disabled={disabled}
      multiple={multiple}
      error={error}
      searchDescription={
        searchDescription ? searchDescription : 'cargo providers'
      }
      gql={gql`
        query CargoProviders($first: Int, $after: String, $search: String) {
          cargoProviders(
            first: $first
            after: $after
            orderBy: "name"
            name_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                name
                id
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default CargoProviderSearchInput
