import React from 'react'
import { Modal } from 'react-bootstrap'
import SalesOrderForm from './SalesOrderForm'
import { Truck } from 'react-bootstrap-icons'

const SalesOrderModal = (props) => {
  const { showModal, toggleModal, salesOrder, deal } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal
          size={salesOrder ? 'xl' : 'lg'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Truck className="mr-2" />
              {salesOrder ? (
                <>Sales Order #{salesOrder.number}</>
              ) : (
                <>New Sales Order</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SalesOrderForm
              salesOrder={salesOrder}
              afterSubmit={toggleModal}
              showModal={showModal}
              deal={deal}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SalesOrderModal
