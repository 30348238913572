import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import BusinessInfo from './business_info/BusinessInfo'
import BoxConnector from './box/BoxConnector'
import EmailSetting from './email/EmailSetting'
import OpenAI from './OpenAI'
import TwilioConnector from './twilio/TwilioConnector'
import Stripe from './Stripe'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import Intercom from './intercom/Intercom'
import Tabs from '../common/Tabs'
import { Tab, Row, Col } from 'react-bootstrap'
import GoogleCalendarConnector from './google_calendar/GoogleCalendarConnector'
import TenantInvoiceTable from '../billing/tenant/TenantInvoiceTable'
import AuditLog from './AuditLog'
import API from './api/API'
import { InfoCircle, Plug, ClockHistory } from 'react-bootstrap-icons'

const StudioSettings = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [activeTab, setActiveTab] = useState('StudioInfo')

  const admin = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser.permissions.group
  )
  const { loading, error, data } = useQuery(
    gql`
      query SettingsQuery {
        settings {
          edges {
            node {
              id
              name
              email
              colorScheme
              phoneNumber
              website
              facebookUrl
              twitterUrl
              linkedinUrl
              instagramUrl
              intercomAppId
              instagramInFooter
              facebookInFooter
              twitterInFooter
              linkedinInFooter
              openAiEnabled
              timezone
              displayLogoInNav
              displayNameInNav
              logoImage {
                defaultImageName
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    }
  )

  if (loading || !data) return <></>
  if (error) return <p>Error loading settings</p>
  const settings = data.settings.edges[0].node
  return (
    <>
      <Row className="mb-2 mt-3">
        <Col>
          <div className="d-flex align-items-center">
            <h1 className="mb-0 d-inline">Settings</h1>
          </div>
        </Col>
      </Row>
      <Tabs onSelect={(tab) => setActiveTab(tab)} activeKey={activeTab}>
        <Tab
          eventKey="StudioInfo"
          title={
            <b>
              <InfoCircle className="mr-2" />
              Details
            </b>
          }
        >
          <BusinessInfo settings={settings} />
        </Tab>
        {admin && (
          <Tab
            eventKey="Integrations"
            title={
              <b>
                <Plug className="mr-2" />
                Integrations
              </b>
            }
          >
            {admin && (
              <>
                {' '}
                <API />
                {/* <GoogleCalendarConnector /> */}
                <Intercom settings={settings} />
                <OpenAI settings={settings} />
                <TwilioConnector />
                <EmailSetting />
                <Stripe />
                <BoxConnector />
              </>
            )}
          </Tab>
        )}
        {admin && (
          <Tab
            eventKey="History"
            title={
              <b>
                <ClockHistory className="mr-2" />
                History
              </b>
            }
          >
            <div className="mt-3">
              <AuditLog />
            </div>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

export default StudioSettings
