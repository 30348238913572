import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  Coin,
  Trash,
  Dot,
  CalendarWeek,
  List,
  GeoAlt,
} from 'react-bootstrap-icons'
import ProductPriceModal from './ProductPriceModal'
import Loading from '../../common/Loading'
import { loggedInUserVar } from '../../../libs/apollo'
import { DateTime } from 'luxon'
import ProductPricesCalendar from './ProductPricesCalendar'
import ProductPricesMap from './ProductPricesMap'
import DeleteProductPricesModal from './DeleteProductPriceModal'

const ProductPrices = (props) => {
  const {
    productId,
    businessId,
    locationId,
    supplierBusiness,
    buyerBusiness,
    tableHeight,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [view, setView] = useState('list')
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editNode, setEditNode] = useState()
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const [filterSupplier, setFilterSupplier] = useState()
  const [filterBuyer, setFilterBuyer] = useState()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query ProductVariantBusinessPrices(
        $cursor: String
        $search: String
        $product: String
        $business: String
        $location: ID
      ) {
        productVariantBusinessPrices(
          first: 20
          after: $cursor
          search: $search
          product: $product
          business: $business
          location: $location
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              startDate
              endDate
              unitOfMeasure {
                shortName
                pluralName
                id
              }
              contentType {
                model
              }
              client {
                user {
                  fullName
                }
              }
              location {
                id
                fullAddress
              }
              supplier {
                business {
                  id
                  name
                }
              }
              buyer {
                business {
                  id
                  name
                }
              }
              productVariant {
                product {
                  id
                  name
                  attributes {
                    id
                  }
                  attributeValues {
                    attribute {
                      id
                      name
                    }
                    productAttributeValues {
                      id
                      value
                    }
                  }
                }
                productAttributeValues {
                  edges {
                    node {
                      id
                      value
                      attribute {
                        name
                      }
                    }
                  }
                }
              }
              price
              secondaryPrice
              quantity
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterSupplier, filterBuyer])

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.productVariantBusinessPrice?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    if (productId) {
      variables.product = productId
    }
    if (businessId) {
      variables.business = businessId
    }
    if (locationId) {
      variables.location = locationId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.productVariantBusinessPrices) {
      let text = 'Search 0 records'
      if (queryData.productVariantBusinessPrices.nodeCount > 0) {
        text = `Search ${queryData.productVariantBusinessPrices.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Product Attributes',
      id: 'attributes',
      accessor: (row) => {
        return (
          <>
            {row.node.productVariant.productAttributeValues.edges.map(
              (edge, index) => (
                <span key={index}>
                  <Dot className="mr-2" />
                  <span>
                    {edge.node.attribute.name} | {edge.node.value}
                  </span>
                  <br />
                </span>
              )
            )}
          </>
        )
      },
    },
    {
      Header: 'Location',
      id: 'location',
      accessor: 'node.location.fullAddress',
    },
    {
      Header: 'Client',
      id: 'client',
      accessor: 'node.client.user.fullName',
    },
    {
      Header: 'Side',
      id: 'side',
      accessor: (row) => {
        if (row.node.supplier) {
          return 'Supplier'
        } else {
          return 'Buyer'
        }
      },
    },
    {
      Header: 'Price',
      id: 'price',
      accessor: (row) => {
        const price = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(row.node.price)
        const secondaryPrice = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(row.node.secondaryPrice)
        return (
          <>
            <span>
              Price {price}
              <br />
              Secondary {secondaryPrice}
            </span>
          </>
        )
      },
    },
    {
      Header: 'Quantity',
      id: 'quantity',
      accessor: (row) => {
        return `${Math.round(row.node.quantity)} / ${
          row.node.unitOfMeasure.shortName
        }`
      },
    },
    {
      Header: 'Effective',
      id: 'effective',
      accessor: (row) => {
        const start =
          row.node.startDate &&
          DateTime.fromISO(row.node.startDate).toFormat('MMMM d, yyyy')
        const end =
          row.node.endDate &&
          DateTime.fromISO(row.node.endDate).toFormat('MMMM d, yyyy')
        return (
          <>
            {start && (
              <>
                Start {start}
                <br />
              </>
            )}
            {end && <>End {end}</>}
          </>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.productVariantBusinessPrices?.edges.forEach(
                    (edge) => {
                      if (!checkedNodeIds.includes(edge.node.id)) {
                        appendIds.push(edge.node.id)
                      }
                    }
                  )
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]
  if (!businessId) {
    tableColumns.splice(0, 0, {
      Header: 'Business',
      id: 'business',
      accessor: (row) => {
        if (row.node.supplier) {
          return `${row.node.supplier.business.name} (Supplier)`
        } else if (row.node.buyer) {
          return `${row.node.buyer.business.name} (Buyer)`
        }
      },
    })
  }
  if (!productId) {
    tableColumns.splice(
      0,
      0,
      ...[
        {
          Header: 'Product Name',
          id: 'name',
          accessor: 'node.productVariant.product.name',
        },
      ]
    )
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Product Prices..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>
  return (
    <>
      <Row style={{ marginTop: '-15px' }}>
        <Col>
          <Button
            variant="link"
            onClick={() => {
              if (view === 'list') {
                setView('calendar')
              } else if (view === 'calendar') {
                setView('map')
              } else if (view === 'map') {
                setView('list')
              }
            }}
            className="mr-2"
          >
            <div className="d-flex align-items-center">
              {view === 'calendar' && (
                <CalendarWeek className="text-primary mr-1" />
              )}
              {view === 'list' && <List className="text-primary mr-1" />}
              {view === 'map' && <GeoAlt className="text-primary mr-1" />}
              {view === 'calendar' && <>Calendar</>}
              {view === 'list' && <>List</>}
              {view === 'map' && <>Map</>}
            </div>
          </Button>
          {canMutate && !locationId && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <Coin className="mr-2" />
              New Product Price
            </Button>
          )}
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedNodeIds.length === 1 ? (
                    <>Delete Product Price</>
                  ) : (
                    <>Delete Product Prices</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          {view === 'list' && (
            <SortableInfiniteTable
              tableData={
                queryData?.productVariantBusinessPrices?.edges
                  ? queryData?.productVariantBusinessPrices?.edges
                  : []
              }
              loadingMessage="Loading Product Prices..."
              tableColumns={tableColumns}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={
                queryData?.productVariantBusinessPrices?.pageInfo?.hasNextPage
              }
              onTdClicks={{
                business: onTdClick,
                client: onTdClick,
                quantity: onTdClick,
                price: onTdClick,
                material: onTdClick,
                form: onTdClick,
                attributes: onTdClick,
                location: onTdClick,
              }}
              tableHeight={tableHeight ? tableHeight : 800}
              rowPointer
              hideGlobalFilter
            />
          )}
          {view === 'map' && (
            <ProductPricesMap
              productPrices={queryData?.productVariantBusinessPrices?.edges}
              toggleModal={toggleModal}
            />
          )}
          {view === 'calendar' && (
            <ProductPricesCalendar
              toggleModal={toggleModal}
              productPrices={queryData?.productVariantBusinessPrices?.edges}
            />
          )}
        </Col>
      </Row>

      {showForm && (
        <ProductPriceModal
          businessId={businessId}
          showModal={showForm}
          productPrice={editNode}
          toggleModal={toggleModal}
          supplierBusiness={supplierBusiness}
          buyerBusiness={buyerBusiness}
        />
      )}
      {showDeleteModal && (
        <DeleteProductPricesModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedNodeIds={setCheckedNodeIds}
          ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
          id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
        />
      )}
    </>
  )
}
export default ProductPrices
