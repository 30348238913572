import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useParams } from 'react-router-dom'
import validator from 'validator'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'

const ResetPassword = () => {
  const { accountEmail } = useParams()
  const [mutation] = useMutation(
    gql`
      mutation ResetPassword($resetUserPasswordInput: ResetUserPasswordInput!) {
        resetUserPassword(input: $resetUserPasswordInput) {
          resetEmailSent
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Password Reset Email Sent If Account Exists')
      },
      onError: (error) => {
        toast.success('Password Reset Email Sent If Account Exists')
      },
    }
  )
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: accountEmail ? accountEmail : '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(3, 'Email is too short')
        .max(100, 'Email is too long')
        .required('Email is required')
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      mutation({
        variables: {
          resetUserPasswordInput: {
            email: values.email,
          },
        },
      })
    },
  })
  return (
    <>
      <Row className="mt-4 mb-2">
        <Col md={6}>
          <h1>Reset Password</h1>
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit} className="mt-2">
        <Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              isInvalid={formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-3">
          <Col xs={12} md={3}>
            <Button variant="outline-primary" block type="submit">
              Request New Password
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <Link className="p-0 mt-4 btn btn-link" to="/">
              Back to Login
            </Link>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ResetPassword
