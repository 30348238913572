import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import {
  CaretDown,
  CaretRight,
  Trash,
  Folder,
  XCircle,
  ArrowCounterclockwise,
  ArrowUpRightCircle,
} from 'react-bootstrap-icons'
import AuditLog from '../../audit_log/AuditLog'
import BusinessSearchInput from '../../common/node_search_input/BusinessSearchInput'
import LocationSearchInput from '../../common/node_search_input/LocationSearchInput'
import Loading from '../../common/Loading'
import Products from '../product/Products'
import UnitOfMeasureSearchInput from '../../common/node_search_input/UnitOfMeasureSearchInput'
import { toTitleCase } from '../../../libs/utils'
import LocationModal from '../../location/LocationModal'

const ProductPriceForm = (props) => {
  const {
    productPrice,
    afterSubmit,
    businessId,
    supplierBusiness,
    buyerBusiness,
  } = props
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [displayHistory, setDisplayHistory] = useState(false)

  const [showLocationModal, setShowLocationModal] = useState(false)
  const history = useHistory()

  const [deleteProductPrice] = useMutation(
    gql`
      mutation DeleteProductPrice($input: DeleteProductPriceInput!) {
        deleteProductVariantBusinessPrice(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Product Price Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['ProductVariantBusinessPrices'],
    }
  )

  const [createProductPrice] = useMutation(
    gql`
      mutation CreateProductVariantBusinessPrice(
        $input: CreateProductVariantBusinessPriceInput!
      ) {
        createProductVariantBusinessPrice(input: $input) {
          productVariantBusinessPrice {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Product Price Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['ProductVariantBusinessPrices'],
    }
  )

  const [updateProductPrice] = useMutation(
    gql`
      mutation UpdateProductVariantBusinessPric(
        $input: UpdateProductVariantBusinessPriceInput!
      ) {
        updateProductVariantBusinessPrice(input: $input) {
          productVariantBusinessPrice {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Product Price Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['ProductVariantBusinessPrices'],
    }
  )

  const formik = useFormik({
    initialValues: productPrice
      ? {
          unitOfMeasureId: productPrice.unitOfMeasure.id,
          unitOfMeasureDescription: toTitleCase(
            productPrice.unitOfMeasure.pluralName
          ),
          locationId: productPrice.location.id,
          locationDescription: productPrice.location.fullAddress,
          businessId: productPrice.supplier
            ? productPrice.supplier.business.id
            : productPrice.buyer.business.id,
          businessDescription: productPrice.supplier
            ? productPrice.supplier.business.name
            : productPrice.buyer.business.name,
          supplier: productPrice.supplier ? true : false,
          buyer: productPrice.supplier ? false : true,
          productId: productPrice.productVariant.product.id,
          productName: productPrice.productVariant.product.name,
          attributeValues: productPrice.productVariant.product.attributeValues,
          selectedProductAttributeValues:
            productPrice.productVariant.productAttributeValues.edges.map(
              (edge) => ({
                id: edge.node.id,
              })
            ),
          price: productPrice.price,
          secondaryPrice: productPrice.secondaryPrice,
          autoEndPreviousPrice: null,
          quantity: productPrice.quantity,
          startDate: productPrice.startDate && new Date(productPrice.startDate),
          endDate: productPrice.endDate && new Date(productPrice.endDate),
        }
      : {
          unitOfMeasureId: '',
          unitOfMeasureDescription: '',
          startDate: new Date(),
          endDate: null,
          locationDescription: '',
          locationId: '',
          businessId: businessId || '',
          businessDescription: '',
          supplier: supplierBusiness && !buyerBusiness ? true : false,
          autoEndPreviousPrice: true,
          buyer: buyerBusiness && !supplierBusiness ? true : false,
          productName: '',
          attributeValues: [],
          selectedProductAttributeValues: [],
          price: null,
          secondaryPrice: null,
          quantity: null,
        },
    validationSchema: Yup.object().shape({
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
      unitOfMeasureId: Yup.string().required('Required'),
      unitOfMeasureDescription: Yup.string().required('Required'),
      locationId: Yup.string().required('Required'),
      locationDescription: Yup.string().nullable(),
      businessId: Yup.string().required('Required'),
      businessDescription: Yup.string().nullable(),
      productId: Yup.mixed().required('Required'),
      autoEndPreviousPrice: Yup.mixed().nullable(),
      productMaterial: Yup.mixed().nullable(),
      productForm: Yup.mixed().nullable(),
      attributeValues: Yup.array().of(Yup.object()).nullable(),
      selectedProductAttributeValues: Yup.array().of(
        Yup.object().shape({
          id: Yup.mixed().required('Required'),
        })
      ),
      quantity: Yup.mixed().required('Required'),
      price: Yup.mixed().required('Required'),
      secondaryPrice: Yup.mixed().required('Required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const variables = {
        price: values.price,
        secondaryPrice: values.secondaryPrice,
        quantity: values.quantity,
        productId: values.productId,
        supplier: values.supplier,
        buyer: values.buyer,
        startDate: values.startDate,
        endDate: values.endDate,
        productAttributeValueIds: values.selectedProductAttributeValues.map(
          (selectedProductAttributeValue) => selectedProductAttributeValue.id
        ),
        businessId: values.businessId,
        locationId: values.locationId,
        unitOfMeasureId: values.unitOfMeasureId,
      }
      if (productPrice) {
        variables.id = productPrice.id
        updateProductPrice({
          variables: {
            input: {
              productVariantBusinessPriceInput: variables,
            },
          },
        })
      } else {
        variables.autoEndPreviousPrice = values.autoEndPreviousPrice
        createProductPrice({
          variables: {
            input: {
              productVariantBusinessPriceInput: variables,
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (
      (formik.errors.businessId || formik.errors.locationId) &&
      !formik.values.supplier &&
      !formik.values.buyer &&
      !businessId
    ) {
      formik.setFieldError('buyerSupplier', 'Buyer or Supplier Required')
    }
  }, [formik.errors])
  return (
    <>
      <LocationModal
        locationId={formik.values.locationId}
        showModal={showLocationModal}
        toggleModal={setShowLocationModal}
      />
      <Form onSubmit={formik.handleSubmit}>
        {!formik.values.productId && (
          <>
            <Row>
              <Col md={4}>
                <Form.Label>
                  Select Product
                  {formik.errors.productId && (
                    <span className="text-danger ml-1">
                      {formik.errors.productId}
                    </span>
                  )}
                </Form.Label>
              </Col>
            </Row>
            <Products productPriceForm formik={formik} />
          </>
        )}
        {formik.values.productId && (
          <>
            <Row>
              <Form.Label>
                <span
                  className="mr-2 btn-link p-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    formik.setFieldValue(`productId`, null)
                    formik.setFieldValue(`productName`, null)
                    formik.setFieldValue(`attributeValues`, null)
                    formik.setFieldValue(`selectedProductAttributeValues`, [])
                  }}
                >
                  <ArrowCounterclockwise /> Change Product
                </span>
              </Form.Label>
            </Row>
            <Form.Group as={Row}>
              <Col md={4}>
                <Form.Label>Product</Form.Label>
                <Form.Control
                  className="form-control-sm"
                  disabled={true}
                  value={formik.values.productName}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              {formik.values.attributeValues.map(
                (productAttribute, productAttributeIndex) => (
                  <Col md={4} key={productAttributeIndex}>
                    <Form.Label>
                      Product {productAttribute.attribute.name}
                    </Form.Label>
                    <Form.Control
                      name={`selectedProductAttributeValues[${productAttributeIndex}].id`}
                      as="select"
                      className="form-control-sm"
                      value={
                        formik.values.selectedProductAttributeValues[
                          productAttributeIndex
                        ].id
                      }
                      onChange={formik.handleChange}
                      isInvalid={
                        productAttributeIndex <
                          formik.errors.selectedProductAttributeValues
                            ?.length &&
                        formik.errors.selectedProductAttributeValues[
                          productAttributeIndex
                        ].id
                      }
                    >
                      <option value="- - -">- - -</option>
                      {productAttribute.productAttributeValues.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {productAttributeIndex <
                        formik.errors.selectedProductAttributeValues?.length &&
                        formik.errors.selectedProductAttributeValues[
                          productAttributeIndex
                        ].id && (
                          <>
                            {productAttributeIndex <
                              formik.errors.selectedProductAttributeValues
                                ?.length &&
                              formik.errors.selectedProductAttributeValues[
                                productAttributeIndex
                              ].id}
                          </>
                        )}
                    </Form.Control.Feedback>
                  </Col>
                )
              )}
            </Form.Group>
            {(!businessId ||
              (businessId && supplierBusiness && buyerBusiness)) && (
              <>
                {formik.errors.buyerSupplier && (
                  <Row>
                    <Col>
                      <div>
                        <small className="text-danger">
                          {formik.errors.buyerSupplier}
                        </small>
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}

            <Form.Group as={Row}>
              {(formik.values.supplier || formik.values.buyer) &&
                !businessId && (
                  <Col sm="12" md={6}>
                    <Form.Label>
                      {formik.values.buyer && <>Buyer</>}
                      {formik.values.supplier && <>Supplier</>}
                      {!formik.values.supplier && !formik.values.buyer && (
                        <>Business</>
                      )}
                      {formik.values.businessId && (
                        <span
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-2 btn-link p-0"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push(
                              `/business/${formik.values.businessId}`
                            )
                          }}
                        >
                          <ArrowUpRightCircle />
                        </span>
                      )}
                    </Form.Label>
                    <BusinessSearchInput
                      formik={formik}
                      suppliers={formik.values.supplier}
                      buyers={formik.values.buyer}
                      error={formik.errors.businessId}
                      searchDescription={
                        formik.values.supplier
                          ? 'search suppliers'
                          : 'search buyers'
                      }
                    />
                  </Col>
                )}
              {(!businessId ||
                (businessId && supplierBusiness && buyerBusiness)) && (
                <>
                  {!formik.values.buyer && (
                    <Col sm={12} md={3}>
                      <Form.Label>Supplier</Form.Label>
                      <Form.Check
                        label={formik.values.supplier ? 'Yes' : 'No'}
                        onChange={(e) => {
                          formik.setFieldValue(`supplier`, e.target.checked)
                        }}
                        checked={formik.values.supplier}
                      />
                    </Col>
                  )}

                  {!formik.values.supplier && (
                    <Col sm={12} md={3}>
                      <Form.Label>Buyer</Form.Label>
                      <Form.Check
                        label={formik.values.buyer ? 'Yes' : 'No'}
                        onChange={(e) => {
                          formik.setFieldValue(`buyer`, e.target.checked)
                        }}
                        checked={formik.values.buyer}
                      />
                    </Col>
                  )}
                </>
              )}
            </Form.Group>
            {(formik.values.supplier || formik.values.buyer || businessId) && (
              <Form.Group as={Row}>
                <Col sm="12" md={6}>
                  <Form.Label>
                    Location
                    {formik.values.locationId && (
                      <span
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 btn-link p-0"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowLocationModal(true)
                        }}
                      >
                        <ArrowUpRightCircle />
                      </span>
                    )}
                  </Form.Label>
                  <LocationSearchInput
                    formik={formik}
                    disabled={!formik.values.businessId}
                    error={formik.errors.locationId}
                    businessId={formik.values.businessId}
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Col sm="12" md={2}>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  name={`price`}
                  className="form-control-sm"
                  step="0.01"
                  min="1"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.price}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.price && <>{formik.errors.price}</>}
                </Form.Control.Feedback>
              </Col>
              <Col sm="12" md={2}>
                <Form.Label>Secondary Price</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  name={`secondaryPrice`}
                  className="form-control-sm"
                  step="0.01"
                  min="0"
                  value={formik.values.secondaryPrice}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.secondaryPrice}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.secondaryPrice && (
                    <>{formik.errors.secondaryPrice}</>
                  )}
                </Form.Control.Feedback>
              </Col>
              <Col sm="12" md={2}>
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  name={`quantity`}
                  className="form-control-sm"
                  step="0.000001"
                  min="1"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.quantity}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.quantity && <>{formik.errors.quantity}</>}
                </Form.Control.Feedback>
              </Col>
              <Col sm="12" md={4}>
                <Form.Label>Unit</Form.Label>
                <UnitOfMeasureSearchInput
                  formik={formik}
                  error={formik.errors.unitOfMeasureId}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col md={2}>
                <div>
                  <Form.Label>
                    Effective Start Date
                    {formik.values.startDate && (
                      <span
                        type="button"
                        className="p-0 ml-2 btn-link"
                        onClick={() => {
                          formik.setFieldValue(`startDate`, null)
                        }}
                      >
                        <XCircle />
                      </span>
                    )}
                  </Form.Label>
                </div>
                <div>
                  <DatePicker
                    name="startDate"
                    className="form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    selected={formik.values.startDate}
                    onChange={(date) => formik.setFieldValue('startDate', date)}
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                </div>
                {formik.errors.startDate && (
                  <div>
                    <small className="text-danger">
                      {formik.errors.startDate}
                    </small>
                  </div>
                )}
              </Col>
              <Col md={2}>
                <div>
                  <Form.Label>
                    Effective End Date
                    {formik.values.endDate && (
                      <span
                        type="button"
                        className="p-0 ml-2 btn-link"
                        onClick={() => {
                          formik.setFieldValue(`endDate`, null)
                        }}
                      >
                        <XCircle />
                      </span>
                    )}
                  </Form.Label>
                </div>
                <div>
                  <DatePicker
                    name="endDate"
                    className="form-control-sm"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    selected={formik.values.endDate}
                    onChange={(date) => formik.setFieldValue('endDate', date)}
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                </div>
                {formik.errors.startDate && (
                  <div>
                    <small className="text-danger">
                      {formik.errors.endDate}
                    </small>
                  </div>
                )}
              </Col>
            </Form.Group>
          </>
        )}
        {productPrice && (
          <>
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayHistory(!displayHistory)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayHistory ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">History</Form.Label>
              </Col>
            </Row>
            {displayHistory && (
              <Row className="mt-3">
                <Col>
                  <AuditLog
                    contentType={productPrice.contentType.model}
                    id={productPrice.id}
                    tableHeight={400}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {productPrice && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deleteProductPrice({
                      variables: {
                        input: {
                          ids: productPrice.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading
                message={
                  deleting
                    ? 'Deleting Product Price...'
                    : 'Saving Product Price...'
                }
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default ProductPriceForm
