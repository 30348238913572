import React from 'react';
import { useLocale } from '../../locale/LocaleContext';
import { FormatErrorMessage } from './FormatErrorMessage';
import './FormatRawPreview.scss';
const RAW_PREVIEW_SIZE = 500;
export const FormatRawPreview = React.memo(({ chunk, warning, onCancelClick }) => {
    const chunkSlice = chunk.slice(0, RAW_PREVIEW_SIZE);
    const chunkHasMore = chunk.length > RAW_PREVIEW_SIZE;
    const l10n = useLocale('fileStep');
    return (React.createElement("div", { className: "CSVImporter_FormatRawPreview" },
        React.createElement("div", { className: "CSVImporter_FormatRawPreview__scroll" },
            React.createElement("pre", { className: "CSVImporter_FormatRawPreview__pre" },
                chunkSlice,
                chunkHasMore && React.createElement("aside", null, "..."))),
        warning ? (React.createElement(FormatErrorMessage, { onCancelClick: onCancelClick }, l10n.getDataFormatError(warning.message || String(warning)))) : null));
});
