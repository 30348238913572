import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import {
  GraphUpArrow,
  Trash,
  Funnel,
  Dot,
  CalendarWeek,
  List,
} from 'react-bootstrap-icons'
import DealModal from './DealModal'
import Loading from '../common/Loading'
import DeleteDealModal from './DeleteDealModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useHistory } from 'react-router-dom'
import DealsCalendar from './DealsCalendar'

const Deals = (props) => {
  const {
    businessId,
    locationId,
    employeeId,
    productId,
    cargoProviderId,
    tableHeight,
  } = props
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editNode, setEditNode] = useState()
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const [filterMobile, setFilterMobile] = useState()
  const [view, setView] = useState('list')

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Deals(
        $cursor: String
        $mobile: Boolean
        $business: String
        $location: String
        $employee: String
        $product: String
        $cargoProvider: String
        $search: String
      ) {
        deals(
          first: 20
          after: $cursor
          mobile: $mobile
          business: $business
          location: $location
          employee: $employee
          cargoProvider: $cargoProvider
          product: $product
          search: $search
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              salespeople {
                edges {
                  node {
                    id
                    user {
                      fullName
                    }
                  }
                }
              }
              dealNumber
              buyer {
                business {
                  id
                  name
                }
                effectiveProductVariantBusinessPrices {
                  productVariant {
                    id
                  }
                  endDate
                  price
                  secondaryPrice
                }
              }
              supplier {
                business {
                  id
                  name
                }
                effectiveProductVariantBusinessPrices {
                  productVariant {
                    id
                  }
                  endDate
                  price
                  secondaryPrice
                }
              }
              pickupLocation {
                id
                fullAddress
              }
              deliveryLocation {
                id
                fullAddress
              }
              estimatedPickupDate
              estimatedDeliveryDate
              mobile
              dealItems {
                edges {
                  node {
                    id
                    description
                    supplierQuantity
                    buyerQuantity
                    productVariant {
                      id
                      product {
                        name
                      }
                    }
                    buyerUnitOfMeasure {
                      shortName
                    }
                    supplierUnitOfMeasure {
                      shortName
                    }
                    supplierPrice
                    supplierSecondaryPrice
                    buyerPrice
                    buyerSecondaryPrice
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterMobile])

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.deals?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    if (filterMobile) {
      variables.mobile = true
    }
    if (employeeId) {
      variables.employee = employeeId
    }
    if (cargoProviderId) {
      variables.cargoProvider = cargoProviderId
    }
    if (businessId) {
      variables.business = businessId
    }
    if (locationId) {
      variables.location = locationId
    }
    if (productId) {
      variables.product = productId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.deals) {
      let text = 'Search 0 records'
      if (queryData.deals.nodeCount > 0) {
        text = `Search ${queryData.deals.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {
    if (loggedInUser.permissions.isEmployee) {
      history.push(`/deal/${cell.row.original.node.id}`)
    } else {
      toggleModal(cell.row.original.node)
    }
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Number',
      id: 'number',
      accessor: 'node.dealNumber',
    },
    {
      Header: 'Buyer',
      id: 'buyer',
      accessor: (row) => {
        if (row.node.buyer) {
          return row.node.buyer?.business.name
        }
      },
    },
    {
      Header: 'Supplier',
      id: 'supplier',
      accessor: (row) => {
        if (row.node.supplier) {
          return row.node.supplier?.business.name
        }
      },
    },
    {
      Header: 'Pickup',
      id: 'pickup',
      accessor: (row) => {
        if (row.node.pickupLocation) {
          return row.node.pickupLocation.fullAddress
        }
      },
    },
    {
      Header: 'Delivery',
      id: 'delivery',
      accessor: (row) => {
        if (row.node.deliveryLocation) {
          return row.node.deliveryLocation.fullAddress
        }
      },
    },
    {
      Header: 'Product',
      id: 'product',
      accessor: (row) => {
        if (row.node.dealItems.edges.length > 0) {
          const dealItem = row.node.dealItems.edges[0].node
          if (dealItem.productVariant) {
            return dealItem.productVariant.product.description
          }
        }
      },
    },
    {
      Header: 'Supplier Price',
      id: 'supplierPrice',
      accessor: (row) => {
        if (row.node.dealItems.edges.length > 0) {
          const dealItem = row.node.dealItems.edges[0].node
          const price = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(dealItem.supplierPrice)
          const secondaryPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(dealItem.supplierSecondaryPrice)
          return `Primary ${price} Secondary ${secondaryPrice}`
        }
      },
    },
    {
      Header: 'Buyer Price',
      id: 'buyerPrice',
      accessor: (row) => {
        if (row.node.dealItems.edges.length > 0) {
          const dealItem = row.node.dealItems.edges[0].node
          const price = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(dealItem.buyerPrice)
          const secondaryPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(dealItem.buyerSecondaryPrice)
          return `Primary ${price} Secondary ${secondaryPrice}`
        }
      },
    },
    {
      Header: 'Supplier Quantity',
      id: 'supplierQuantity',
      accessor: (row) => {
        if (row.node.dealItems.edges.length > 0) {
          const dealItem = row.node.dealItems.edges[0].node
          return `${Math.round(dealItem.supplierQuantity)} / ${
            dealItem.supplierUnitOfMeasure?.shortName &&
            dealItem.supplierUnitOfMeasure.shortName
          }`
        }
      },
    },
    {
      Header: 'Buyer Quantity',
      id: 'buyerQuantity',
      accessor: (row) => {
        if (row.node.dealItems.edges.length > 0) {
          const dealItem = row.node.dealItems.edges[0].node
          return `${Math.round(dealItem.buyerQuantity)} / ${
            dealItem.buyerUnitOfMeasure?.shortName &&
            dealItem.buyerUnitOfMeasure.shortName
          }`
        }
      },
    },
    {
      Header: 'Salespeople',
      id: 'salespeople',
      accessor: (row) => {
        return (
          <>
            {row.node.salespeople.edges.map((edge, index) => (
              <span key={index}>
                <Dot className="mr-2" />
                {edge.node.user.fullName}
                <br />
              </span>
            ))}
          </>
        )
      },
    },
    {
      Header: 'Mobile',
      id: 'mobile',
      accessor: (row) => {
        return row.node.mobile ? 'Yes' : 'No'
      },
    },
  ]

  if (loggedInUser.employee) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.businesses?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Deals..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>
  return (
    <>
      {!businessId &&
        !locationId &&
        !employeeId &&
        !productId &&
        !cargoProviderId && (
          <Row className="mb-2 mt-3">
            <Col>
              <div className="d-flex align-items-center">
                <h1 className="mb-0 d-inline">Deals</h1>
              </div>
            </Col>
          </Row>
        )}
      <Row
        style={{
          marginTop:
            businessId ||
            locationId ||
            employeeId ||
            productId ||
            cargoProviderId
              ? '-10px'
              : '-15px',
        }}
      >
        <Col>
          <Button
            variant="link"
            onClick={() => {
              if (view === 'list') {
                setView('calendar')
              } else {
                setView('list')
              }
            }}
            className="mr-2"
          >
            <div className="d-flex align-items-center">
              {view === 'calendar' ? (
                <CalendarWeek className="text-primary mr-1" />
              ) : (
                <List className="text-primary mr-1" />
              )}

              {view === 'calendar' ? <span>Calendar</span> : <span>List</span>}
            </div>
          </Button>
          {canMutate && !locationId && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <GraphUpArrow className="mr-2" />
              New Deal
            </Button>
          )}
          <Button
            variant="link"
            onClick={() => {
              setFilterMobile(!filterMobile)
            }}
          >
            <span>
              <Funnel className="mr-2" />
              {!filterMobile ? <>Mobile</> : <>All</>}
            </span>
          </Button>
          {checkedNodeIds.length > 0 && canMutate && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedNodeIds.length === 1 ? (
                    <>Delete Deal</>
                  ) : (
                    <>Delete Deals</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      {view === 'list' && (
        <Row className="mt-2 mb-3">
          <Col md="12">
            <SortableInfiniteTable
              tableData={queryData?.deals?.edges ? queryData?.deals?.edges : []}
              loadingMessage="Loading Deals..."
              tableColumns={tableColumns}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={queryData?.deals?.pageInfo?.hasNextPage}
              onTdClicks={{
                number: (cell) => onTdClick(cell),
                supplier: (cell) => onTdClick(cell),
                buyer: (cell) => onTdClick(cell),
                pickup: (cell) => onTdClick(cell),
                delivery: (cell) => onTdClick(cell),
                product: (cell) => onTdClick(cell),
                price: (cell) => onTdClick(cell),
                supplierQuantity: (cell) => onTdClick(cell),
                mobile: (cell) => onTdClick(cell),
              }}
              tableHeight={tableHeight ? tableHeight : 800}
              rowPointer
              hideGlobalFilter
            />
          </Col>
        </Row>
      )}
      {view === 'calendar' && <DealsCalendar />}

      {showForm && (
        <DealModal
          showModal={showForm}
          toggleModal={toggleModal}
          deal={editNode}
        />
      )}
      {showDeleteModal && (
        <DeleteDealModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedNodeIds={setCheckedNodeIds}
          ids={checkedNodeIds.length > 1 ? checkedNodeIds : null}
          id={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
        />
      )}
    </>
  )
}
export default Deals
