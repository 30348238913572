import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { loggedInUserVar } from '../../../libs/apollo'
import toast from 'react-hot-toast'
import {
  CloudArrowDown,
  CashStack,
  SlashCircle,
  ReceiptCutoff,
  CaretDown,
  CaretRight,
  XCircle,
} from 'react-bootstrap-icons'
import SortableTable from '../../common/SortableTable'
import AuditLog from '../../audit_log/AuditLog'
import PaymentsTable from '../payment/Payments'
import NotificationsTable from '../../notification/NotificationsTable'
import SalesOrders from '../sales_order/SalesOrders'

const InvoiceModal = (props) => {
  const { showModal, toggleModal, stripeInvoiceId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [displayHistory, setDispalyHistory] = useState(false)
  const [paidManuallyBy, setPaidManuallyBy] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [showInvoiceItems, setShowInvoiceItems] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showPaidMaunallyBy, setShowPaidManuallyBy] = useState(false)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser.permissions.group
  )

  const [updateInvoice] = useMutation(
    gql`
      mutation UpdateStripeInvoice(
        $updateStripeInvoiceInput: UpdateStripeInvoiceInput!
      ) {
        updateStripeInvoice(input: $updateStripeInvoiceInput) {
          stripeInvoice {
            id
            paidManually
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (data.updateStripeInvoice.stripeInvoice.paidManually) {
          toast.success('Invoice Paid Manually')
        } else {
          toast.success('Invoice Voided')
        }
      },
      refetchQueries: ['StripeInvoices', 'StripeInvoice'],
      errorPolicy: 'all',
    }
  )

  const [query, { data }] = useLazyQuery(
    gql`
      query StripeInvoice($stripeInvoiceId: ID!) {
        stripeInvoice(id: $stripeInvoiceId) {
          id
          recordId
          created
          paymentFailed
          paidManually
          paidOn
          price
          status
          recipientEmail
          amountRemaining
          amountPaid
          amountDue
          effectiveAt
          dueDate
          stripeResource
          stripeInvoiceId
          invoicePdfUrl
          contentType {
            model
          }
          invoicePaymentUrl
          salesOrder {
            id
            buyer {
              business {
                name
              }
            }
          }
          stripePaymentIntents {
            edges {
              node {
                processing
              }
            }
          }
          paidManuallyBy
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (stripeInvoiceId) {
      query({
        variables: {
          stripeInvoiceId,
        },
      })
    }
  }, [stripeInvoiceId])

  useEffect(() => {
    if (
      data?.stripeInvoice?.paidManuallyBy &&
      showModal &&
      data?.stripeInvoice.id === stripeInvoiceId &&
      !paidManuallyBy
    ) {
      setPaidManuallyBy(data?.stripeInvoice.paidManuallyBy)
      setShowPaidManuallyBy(true)
    } else if (!showModal) {
      setPaidManuallyBy()
      setShowPaidManuallyBy(false)
      setShowInvoiceItems(false)
      setShowNotifications(false)
    }
  }, [data, showModal])

  if (!data?.stripeInvoice) return <></>
  const stripeInvoice = data.stripeInvoice
  const stripeResource = JSON.parse(stripeInvoice.stripeResource)

  return (
    <>
      <div className="invoiceModal">
        <Modal size={'xl'} show={showModal} onHide={() => toggleModal()}>
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              <Row>
                <Col>Invoice #{stripeResource.number}</Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showPaidMaunallyBy && (
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Label>
                    Paid Manually With
                    {!stripeInvoice.paidManually && (
                      <span
                        type="button"
                        className="p-0 ml-2 btn-link"
                        onClick={() => {
                          setShowPaidManuallyBy(false)
                          setPaidManuallyBy()
                        }}
                      >
                        <XCircle />
                      </span>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={paidManuallyBy}
                    disabled={!canMutate}
                    onChange={(e) => setPaidManuallyBy(e.target.value)}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <p>Invoice Details</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <SortableTable
                  columns={[
                    {
                      Header: 'ID',
                      id: 'id',
                      accessor: () => {
                        return stripeInvoice.recordId
                      },
                    },
                    {
                      Header: 'Business',
                      id: 'business',
                      accessor: (row) => {
                        return row.salesOrder.buyer.business.name
                      },
                    },
                    {
                      Header: 'Status',
                      id: 'status',
                      accessor: (row) => {
                        if (row.paidManually) {
                          return (
                            <span style={{ color: 'green' }}>
                              Paid Manually
                            </span>
                          )
                        }
                        const status = `${stripeResource.status
                          .charAt(0)
                          .toUpperCase()}${stripeResource.status
                          .substr(1)
                          .toLowerCase()}`
                        let color
                        if (status === 'Open') {
                          color = 'red'
                        } else if (status === 'Void') {
                          color = 'orange'
                        } else if (status === 'Paid') {
                          color = 'green'
                        }
                        return <span style={{ color }}>{status}</span>
                      },
                    },
                    {
                      Header: 'Emailed To',
                      id: 'emailedTo',
                      accessor: () => {
                        return stripeResource.customer_email
                      },
                    },
                    {
                      Header: 'Amount Due',
                      id: 'due',
                      accessor: () => {
                        return `$${(stripeResource.amount_due / 100).toFixed(
                          2
                        )}`
                      },
                    },
                    {
                      Header: 'Amount Paid',
                      id: 'paid',
                      accessor: (row) => {
                        if (row.paidManually) {
                          return `$${(stripeResource.amount_due / 100).toFixed(
                            2
                          )}`
                        } else {
                          return `$${(stripeResource.amount_paid / 100).toFixed(
                            2
                          )}`
                        }
                      },
                    },
                    {
                      Header: 'Amount Remaining',
                      id: 'remaining',
                      accessor: (row) => {
                        if (row.paidManually) {
                          return '$0.00'
                        }
                        return `$${(
                          stripeResource.amount_remaining / 100
                        ).toFixed(2)}`
                      },
                    },
                    {
                      Header: 'Sent On',
                      id: 'sentOn',
                      accessor: () => {
                        const dt = DateTime.fromSeconds(
                          stripeResource.effective_at
                        )
                        return dt.toFormat('MMMM d, yyyy')
                      },
                    },
                    {
                      Header: 'Due On',
                      id: 'dueOn',
                      accessor: () => {
                        const dt = DateTime.fromSeconds(stripeResource.due_date)
                        return dt.toFormat('MMMM d, yyyy')
                      },
                    },
                    {
                      Header: 'Paid On',
                      id: 'paidOn',
                      accessor: () => {
                        if (stripeInvoice.paidOn) {
                          return DateTime.fromISO(
                            stripeInvoice.paidOn
                          ).toFormat('MMMM dd, yyyy')
                        }
                      },
                    },
                  ]}
                  hideGlobalFilter={true}
                  data={[stripeInvoice]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Sales Order</p>
              </Col>
            </Row>
            <SalesOrders
              id={stripeInvoice.salesOrder.id}
              fetchPolicy="no-cache"
            />
            <Row>
              <Col>
                <p>Payment</p>
              </Col>
            </Row>
            <PaymentsTable stripeInvoiceId={stripeInvoiceId} invoiceModal />
            <Row className="mt-3 mb-2">
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setShowNotifications(!showNotifications)}
                  className="px-0 btn-link mr-1"
                  style={{ marginTop: '-10px' }}
                >
                  <>
                    {showNotifications ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label>Notifications</Form.Label>
              </Col>
            </Row>
            {showNotifications && (
              <NotificationsTable stripeInvoice={stripeInvoice.id} />
            )}
            <Row className="mt-3 mb-2">
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDispalyHistory(!displayHistory)}
                  className="px-0 btn-link mr-1"
                  style={{ marginTop: '-10px' }}
                >
                  <>
                    {displayHistory ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label>History</Form.Label>
              </Col>
            </Row>
            {displayHistory && (
              <Row>
                <Col md={12}>
                  <AuditLog
                    contentType={stripeInvoice.contentType.model}
                    id={stripeInvoice?.id}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={3}>
                <Button
                  block
                  variant="outline-primary"
                  onClick={() => {
                    window.open(stripeResource.hosted_invoice_url, '_blank')
                  }}
                >
                  <CashStack /> Open Stripe
                </Button>
              </Col>
              <Col md={3}>
                <Button variant="outline-primary" block>
                  <a href={stripeResource.invoice_pdf} download>
                    <CloudArrowDown /> Download
                  </a>
                </Button>
              </Col>
              {canMutate &&
                !stripeInvoice.paidManually &&
                stripeResource.status === 'open' && (
                  <Col md={3}>
                    <Button
                      block
                      disabled={submitting}
                      variant="outline-primary"
                      onClick={() => {
                        if (!showPaidMaunallyBy) {
                          setShowPaidManuallyBy(true)
                        } else {
                          if (paidManuallyBy) {
                            setSubmitting(true)
                            updateInvoice({
                              variables: {
                                updateStripeInvoiceInput: {
                                  stripeInvoiceInput: {
                                    id: stripeInvoiceId,
                                    paidManually: true,
                                    paidManuallyBy,
                                  },
                                },
                              },
                            })
                          } else {
                            toast.error('Paid Manually With Required')
                          }
                        }
                      }}
                    >
                      <ReceiptCutoff /> Paid Manually
                    </Button>
                  </Col>
                )}
              {canMutate && stripeInvoice.paidManually && (
                <Col md={3}>
                  <Button
                    block
                    variant="outline-primary"
                    disabled={submitting}
                    onClick={() => {
                      if (paidManuallyBy) {
                        setSubmitting(true)
                        updateInvoice({
                          variables: {
                            updateStripeInvoiceInput: {
                              stripeInvoiceInput: {
                                id: stripeInvoiceId,
                                paidManually: true,
                                paidManuallyBy,
                              },
                            },
                          },
                        })
                      } else {
                        toast.error('Paid Manually With Required')
                      }
                    }}
                  >
                    <ReceiptCutoff /> Paid Manually
                  </Button>
                </Col>
              )}
              {canMutate && stripeResource.status === 'open' && (
                <Col md={3}>
                  <Button
                    block
                    variant="outline-danger"
                    disabled={submitting}
                    onClick={() => {
                      setSubmitting(true)
                      updateInvoice({
                        variables: {
                          updateStripeInvoiceInput: {
                            stripeInvoiceInput: {
                              id: stripeInvoiceId,
                              void: true,
                            },
                          },
                        },
                      })
                    }}
                  >
                    <SlashCircle /> Void
                  </Button>
                </Col>
              )}
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default InvoiceModal
