import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import {
  CaretDown,
  CaretRight,
  Trash,
  Folder,
  Plus,
} from 'react-bootstrap-icons'
import AuditLog from '../../audit_log/AuditLog'
import Loading from '../../common/Loading'

const PromptForm = (props) => {
  const { prompt, afterSubmit } = props
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [displayHistory, setDisplayHistory] = useState(false)

  const [deletePrompt] = useMutation(
    gql`
      mutation DeleteOpenAIChatCompletionPrompt(
        $input: DeleteOpenAIChatCompletionPromptInput!
      ) {
        deleteOpenAiChatCompletionPrompt(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Prompt Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['OpenAIChatCompletionPrompts'],
    }
  )

  const [createPrompt] = useMutation(
    gql`
      mutation CreateOpenAIChatCompletionPrompt(
        $input: CreateOpenAIChatCompletionPromptInput!
      ) {
        createOpenAiChatCompletionPrompt(input: $input) {
          openAiChatCompletionPrompt {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Prompt Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['OpenAIChatCompletionPrompts'],
    }
  )

  const [updatePrompt] = useMutation(
    gql`
      mutation UpdateOpenAIChatCompletionPrompt(
        $input: UpdateOpenAIChatCompletionPromptInput!
      ) {
        updateOpenAiChatCompletionPrompt(input: $input) {
          openAiChatCompletionPrompt {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Prompt Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['OpenAIChatCompletionPrompts'],
    }
  )

  const formik = useFormik({
    initialValues: prompt
      ? {
          name: prompt.name,
          prompt: prompt.prompt,
        }
      : {
          name: '',
          prompt: '',
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      prompt: Yup.string().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (prompt) {
        values.id = prompt.id
        updatePrompt({
          variables: {
            input: {
              openAiChatCompletionPromptInput: values,
            },
          },
        })
      } else {
        createPrompt({
          variables: {
            input: {
              openAiChatCompletionPromptInput: values,
            },
          },
        })
      }
    },
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="input"
              name="name"
              className="form-control-sm"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Prompt</Form.Label>
            <Form.Control
              as="textarea"
              name="prompt"
              value={formik.values.prompt}
              onChange={formik.handleChange}
              isInvalid={formik.errors.prompt}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.prompt}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {prompt && (
          <>
            <Row>
              <Col md={3} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayHistory(!displayHistory)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayHistory ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label column sm="12" md="auto">
                  History
                </Form.Label>
              </Col>
            </Row>
            {displayHistory && (
              <AuditLog contentType={prompt.contentType.model} id={prompt.id} />
            )}
            <div className="mb-3" />
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {prompt && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deletePrompt({
                      variables: {
                        input: {
                          ids: prompt.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading
                message={deleting ? 'Deleting Prompt...' : 'Saving Prompt...'}
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default PromptForm
