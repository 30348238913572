import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { CaretDown, CaretRight, Gear } from 'react-bootstrap-icons'
import WebhooksModal from './webhook/WebhooksModal'
import APIKeysModal from './api_key/APIKeysModal'

const API = () => {
  const [display, setDisplay] = useState(false)
  const [showAPIKeysModal, setShowAPIKeysModal] = useState(false)
  const [showWebhooksModal, setShowWebhooksModal] = useState(false)

  const toggleAPIKeysModal = () => {
    setShowAPIKeysModal((prevState) => !prevState)
  }

  const toggleWebhooksModal = () => {
    setShowWebhooksModal((prevState) => !prevState)
  }

  return (
    <>
      {showAPIKeysModal && (
        <APIKeysModal
          showModal={showAPIKeysModal}
          toggleModal={toggleAPIKeysModal}
        />
      )}
      {showWebhooksModal && (
        <WebhooksModal
          showModal={showWebhooksModal}
          toggleModal={toggleWebhooksModal}
        />
      )}
      <div>
        <Row className="mt-3 mb-1">
          <Col md={12} className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplay(!display)}
              className="px-0 btn-link mr-2"
            >
              <>
                {display ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/api.png'}
                style={{ height: '30px' }}
                alt="Organization logo"
              />
              ScrapGo API
            </h4>
          </Col>
        </Row>
        {display && (
          <>
            <Row>
              <Col>
                <div>
                  <Button variant="link" onClick={toggleAPIKeysModal}>
                    <Gear className="mr-2" />
                    Configure API Keys
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <Button variant="link" onClick={toggleWebhooksModal}>
                    <Gear className="mr-2" />
                    Configure Webhooks
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  )
}

export default API
