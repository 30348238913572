import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import {
  Trash,
  ArrowCounterclockwise,
  Coin,
  CaretRight,
  CaretDown,
} from 'react-bootstrap-icons'
import Products from '../product/product/Products'
import UnitOfMeasureSearchInput from '../common/node_search_input/UnitOfMeasureSearchInput'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import { gql, useLazyQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import { DateTime } from 'luxon'
import { FilePond } from 'react-filepond'
import Files from '../files/Files'

const DealItem = (props) => {
  const { formik, index } = props
  const [displayFiles, setDisplayFiles] = useState(false)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const isBuyer = loggedInUser.permissions.isBuyer
  const isSupplier = loggedInUser.permissions.isSupplier
  const isBuyerSupplier = isBuyer || isSupplier
  const dealItem = formik.values.dealItems[index]

  const [getProductVariant] = useLazyQuery(
    gql`
      query ProductVariants($product: ID!, $attributeValues: String!) {
        productVariants(
          product: $product
          attributeValues: $attributeValues
          first: 10
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.productVariants.edges.length > 0) {
          formik.setFieldValue(
            `dealItems[${index}].productVariantId`,
            data.productVariants.edges[0].node.id
          )
          formik.setFieldValue(
            `dealItems[${index}].productVariantAvailable`,
            true
          )
        }
      },
    }
  )

  useEffect(() => {
    if (index < formik.values.dealItems?.length) {
      if (
        dealItem.productId &&
        dealItem.selectedProductAttributeValues.every(
          (item) => item.id !== null
        ) &&
        !dealItem.productViantId
      ) {
        getProductVariant({
          variables: {
            product: dealItem.productId,
            attributeValues: dealItem.selectedProductAttributeValues
              .map((item) => item.id)
              .join(','),
          },
        })
      } else {
        formik.setFieldValue(
          `dealItems[${index}].productVariantAvailable`,
          false
        )
        formik.setFieldValue(`dealItems[${index}].productVariantId`, null)
      }
    }
  }, [formik.values])

  return (
    <>
      <div
        className={
          formik.values.dealItems.length > 1
            ? 'border border-secondary bg-white rounded my-3 px-3 pb-3'
            : ''
        }
      >
        {!dealItem.productId && (
          <>
            <Row className={formik.values.dealItems.length > 1 ? 'mt-3' : ''}>
              <Col md={4}>
                <Form.Label>
                  Select Product
                  {index < formik.errors.dealItems?.length &&
                    formik.errors.dealItems[index]?.productId && (
                      <span className="text-danger ml-1">
                        {formik.errors.dealItems[index].productId}
                      </span>
                    )}
                </Form.Label>
              </Col>
            </Row>
            <Products dealItemForm formik={formik} formikIndex={index} />
          </>
        )}
        {dealItem.productId && (
          <>
            {!isBuyerSupplier && (
              <Row className={formik.values.dealItems.length > 1 ? 'mt-3' : ''}>
                <Form.Label>
                  <span
                    className="mr-2 btn-link p-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      formik.setFieldValue(
                        `dealItems[${index}].productId`,
                        null
                      )
                      formik.setFieldValue(
                        `dealItems[${index}].productName`,
                        null
                      )
                      formik.setFieldValue(
                        `dealItems[${index}].productAttributes`,
                        null
                      )
                      formik.setFieldValue(
                        `dealItems[${index}].selectedProductAttributeValues`,
                        []
                      )
                    }}
                  >
                    <ArrowCounterclockwise /> Change Product
                  </span>
                </Form.Label>
              </Row>
            )}

            <Form.Group as={Row}>
              <Col md={4}>
                <Form.Label>Product</Form.Label>
                <Form.Control
                  className="form-control-sm"
                  disabled={true}
                  value={dealItem.productName}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              {dealItem.attributeValues.map(
                (productAttribute, productAttributeIndex) => (
                  <Col md={4} key={productAttributeIndex}>
                    <Form.Label>
                      Product {productAttribute.attribute.name}
                    </Form.Label>
                    <Form.Control
                      name={`dealItems[${index}].selectedProductAttributeValues[${productAttributeIndex}].id`}
                      as="select"
                      disabled={isBuyerSupplier}
                      className="form-control-sm"
                      value={
                        dealItem.selectedProductAttributeValues[
                          productAttributeIndex
                        ].id
                      }
                      onChange={formik.handleChange}
                      isInvalid={
                        index < formik.errors.dealItems?.length &&
                        productAttributeIndex <
                          formik.errors.dealItems[index]
                            .selectedProductAttributeValues?.length &&
                        formik.errors.dealItems[index]
                          .selectedProductAttributeValues[productAttributeIndex]
                          .id
                      }
                    >
                      <option value="- - -">- - -</option>
                      {productAttribute.productAttributeValues.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {index < formik.errors.dealItems?.length &&
                        productAttributeIndex <
                          formik.errors.dealItems[index]
                            .selectedProductAttributeValues?.length &&
                        formik.errors.dealItems[index]
                          .selectedProductAttributeValues[productAttributeIndex]
                          .id && (
                          <>
                            {index < formik.errors.dealItems?.length &&
                              productAttributeIndex <
                                formik.errors.dealItems[index]
                                  .selectedProductAttributeValues?.length &&
                              formik.errors.dealItems[index]
                                .selectedProductAttributeValues[
                                productAttributeIndex
                              ].id}
                          </>
                        )}
                    </Form.Control.Feedback>
                  </Col>
                )
              )}
            </Form.Group>
          </>
        )}
        {(!isBuyerSupplier || isSupplier) && (
          <Form.Group as={Row} className={dealItem.productId ? 'mt-3' : ''}>
            <Col sm="12" md={2}>
              <Form.Label>
                {dealItem.productVariantAvailable && (
                  <span
                    className="ml-2 btn-link p-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const productPrice =
                        formik.values.supplierProductPrices.find(
                          (productPrice) =>
                            productPrice.productVariant.id ===
                            dealItem.productVariantId
                        )
                      if (productPrice) {
                        formik.setFieldValue(
                          `dealItems[${index}].supplierPrice`,
                          productPrice.price
                        )
                        formik.setFieldValue(
                          `dealItems[${index}].supplierSecondaryPrice`,
                          productPrice.secondaryPrice
                        )
                        toast.success(
                          `Set Supplier Price Effective Through ${DateTime.fromISO(
                            productPrice.endDate
                          ).toFormat('MMMM d, yyyy')}`
                        )
                      } else {
                        toast.error(
                          'Supplier Has No Effective Price For The Product'
                        )
                      }
                    }}
                  >
                    <Coin className="mr-2" />
                  </span>
                )}
                Supplier Price
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                name={`dealItems[${index}].supplierPrice`}
                className="form-control-sm"
                step="0.01"
                min="1"
                value={dealItem.supplierPrice}
                onChange={formik.handleChange}
                isInvalid={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.supplierPrice
                }
              />
              <Form.Control.Feedback type="invalid">
                {index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.supplierPrice && (
                    <>{formik.errors.dealItems[index]?.supplierPrice}</>
                  )}
              </Form.Control.Feedback>
            </Col>
            <Col sm="12" md={2}>
              <Form.Label>Secondary Price</Form.Label>
              <Form.Control
                as="input"
                type="number"
                name={`dealItems[${index}].supplierSecondaryPrice`}
                className="form-control-sm"
                step="0.01"
                min="0"
                value={dealItem.supplierSecondaryPrice}
                onChange={formik.handleChange}
                isInvalid={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.supplierSecondaryPrice
                }
              />
              <Form.Control.Feedback type="invalid">
                {index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.supplierSecondaryPrice && (
                    <>
                      {formik.errors.dealItems[index]?.supplierSecondaryPrice}
                    </>
                  )}
              </Form.Control.Feedback>
            </Col>
            <Col sm="12" md={2}>
              <Form.Label>Supplier Quantity</Form.Label>
              <Form.Control
                as="input"
                type="number"
                name={`dealItems[${index}].supplierQuantity`}
                className="form-control-sm"
                step="0.000001"
                min="1"
                value={dealItem.supplierQuantity}
                onChange={formik.handleChange}
                isInvalid={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.supplierQuantity
                }
              />
              <Form.Control.Feedback type="invalid">
                {index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.supplierQuantity && (
                    <>{formik.errors.dealItems[index]?.supplierQuantity}</>
                  )}
              </Form.Control.Feedback>
            </Col>
            <Col sm="12" md={4}>
              <Form.Label>Supplier Unit</Form.Label>
              <UnitOfMeasureSearchInput
                formik={formik}
                dropdown
                formikValue={`dealItems[${index}].supplierUnitOfMeasure`}
                error={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.supplierUnitOfMeasureId
                }
              />
            </Col>
          </Form.Group>
        )}
        {(!isBuyerSupplier || isBuyer) && (
          <Form.Group as={Row}>
            <Col sm="12" md={2}>
              <Form.Label>
                {dealItem.productVariantAvailable && (
                  <span
                    className="ml-2 btn-link p-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const productPrice =
                        formik.values.buyerProductPrices.find(
                          (productPrice) =>
                            productPrice.productVariant.id ===
                            dealItem.productVariantId
                        )
                      if (productPrice) {
                        formik.setFieldValue(
                          `dealItems[${index}].buyerPrice`,
                          productPrice.price
                        )
                        formik.setFieldValue(
                          `dealItems[${index}].buyerSecondaryPrice`,
                          productPrice.secondaryPrice
                        )
                        toast.success(
                          `Set Buyer Price Effective Through ${DateTime.fromISO(
                            productPrice.endDate
                          ).toFormat('MMMM d, yyyy')}`
                        )
                      } else {
                        toast.error(
                          'Buyer Has No Effective Price For The Product'
                        )
                      }
                    }}
                  >
                    <Coin className="mr-2" />
                  </span>
                )}
                Buyer Price
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                name={`dealItems[${index}].buyerPrice`}
                className="form-control-sm"
                step="0.01"
                min="1"
                value={dealItem.buyerPrice}
                onChange={formik.handleChange}
                isInvalid={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.buyerPrice
                }
              />
              <Form.Control.Feedback type="invalid">
                {index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.buyerPrice && (
                    <>{formik.errors.dealItems[index]?.buyerPrice}</>
                  )}
              </Form.Control.Feedback>
            </Col>
            <Col sm="12" md={2}>
              <Form.Label>Secondary Price</Form.Label>
              <Form.Control
                as="input"
                type="number"
                name={`dealItems[${index}].buyerSecondaryPrice`}
                className="form-control-sm"
                step="0.01"
                min="0"
                value={dealItem.buyerSecondaryPrice}
                onChange={formik.handleChange}
                isInvalid={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.buyerSecondaryPrice
                }
              />
              <Form.Control.Feedback type="invalid">
                {index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.buyerSecondaryPrice && (
                    <>{formik.errors.dealItems[index]?.buyerSecondaryPrice}</>
                  )}
              </Form.Control.Feedback>
            </Col>
            <Col sm="12" md={2}>
              <Form.Label>Buyer Quantity</Form.Label>
              <Form.Control
                as="input"
                type="number"
                name={`dealItems[${index}].buyerQuantity`}
                className="form-control-sm"
                step="0.000001"
                min="0"
                value={dealItem.buyerQuantity}
                onChange={formik.handleChange}
                isInvalid={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.buyerQuantity
                }
              />
              <Form.Control.Feedback type="invalid">
                {index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.buyerQuantity && (
                    <>{formik.errors.dealItems[index]?.buyerQuantity}</>
                  )}
              </Form.Control.Feedback>
            </Col>
            <Col sm="12" md={4}>
              <Form.Label>Buyer Unit</Form.Label>
              <UnitOfMeasureSearchInput
                formik={formik}
                dropdown
                formikValue={`dealItems[${index}].buyerUnitOfMeasure`}
                error={
                  index < formik.errors.dealItems?.length &&
                  formik.errors.dealItems[index]?.buyerUnitOfMeasureId
                }
              />
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row} className="mt-5">
          <Col md={{ span: 10, offset: 1 }}>
            <FilePond
              maxFileSize="100MB"
              allowFileSizeValidation
              files={formik.values.dealItems[index].images}
              maxFiles={1}
              labelMaxFileSize="Maximum file size is {filesize}"
              onupdatefiles={(file) => {
                formik.setFieldValue(`dealItems[${index}].images`, file)
              }}
              acceptedFileTypes={['image/png', 'image/jpeg']}
              labelIdle='Drag and drop product images or <span class="filepond--label-action">Browse</span>'
            />
          </Col>
        </Form.Group>
        {dealItem.id && (
          <>
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayFiles(!displayFiles)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayFiles ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">Product Images</Form.Label>
              </Col>
            </Row>
            {displayFiles && (
              <Row className="mt-3">
                <Col>
                  <Files
                    fetchPolicy="no-cache"
                    tableHeight={200}
                    dealItemId={dealItem.id}
                    dealItemDescription={dealItem.description}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {!isBuyerSupplier && (
          <Row className={dealItem.productId ? 'mt-3' : ''}>
            <Col>
              <div>
                <p size="sm">
                  <small
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      const newDealItems = [...formik.values.dealItems]
                      newDealItems.splice(index, 1)
                      formik.setFieldValue('dealItems', newDealItems)
                    }}
                  >
                    <Trash className="me-1" /> Remove Product
                  </small>
                </p>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  )
}

export default DealItem
