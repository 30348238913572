export default {
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  TEMPLATE_BUILDER_PROJECT_ID:
    process.env.REACT_APP_TEMPLATE_BUILDER_PROJECT_ID,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  AWS: {
    REGION: process.env.REACT_APP_AWS_REGION,
    ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
    SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
  },
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  VERSION: process.env.REACT_APP_VERSION,
}
