import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Col, Tab, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  InfoCircle,
  Truck,
  CashCoin,
  Bell,
  GraphUpArrow,
  Coin,
  ClockHistory,
  CardChecklist,
  Dot,
} from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import AuditLog from '../../audit_log/AuditLog'
import Tabs from '../../common/Tabs'
import Deals from '../../deal/Deals'
import Fulfillments from '../../cargo/fulfilment/Fulfillments'
import Billing from '../../billing/Billing'
import TaskTabs from '../../task/TaskTabs'
import NotificationsTable from '../../notification/NotificationsTable'
import ProductPrices from '../product_prices/ProductPrices'
import ProductForm from './ProductForm'

const Product = () => {
  const { productId } = useParams()
  const [activeTab, setActiveTab] = useState('Details')
  const { error, data } = useQuery(
    gql`
      query Product($id: ID!) {
        product(id: $id) {
          id
          dealCount
          name
          contentType {
            model
          }
          attributeValues {
            attribute {
              id
              name
            }
            productAttributeValues {
              value
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: productId,
      },
    }
  )
  if (error) return <>Error loading product</>
  if (!data)
    return (
      <span className="mt-5">
        <Loading message="Loading Product..." />
      </span>
    )
  const { product } = data
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Product
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Details"
          eventKey="Details"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <InfoCircle className="mr-2" />
              Details
            </b>
          }
        >
          <Row>
            <Col md={8}>
              <ProductForm product={product} />
            </Col>
          </Row>
        </Tab>
        <Tab
          key="Deals"
          eventKey="Deals"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <GraphUpArrow className="mr-2" />
              Deals
            </b>
          }
        >
          <Deals productId={product.id} />
        </Tab>
        <Tab
          key="Fulfillment"
          eventKey="Fulfillment"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Truck className="mr-2" />
              Fulfillment
            </b>
          }
        >
          <Fulfillments productId={product.id} />
        </Tab>
        <Tab
          key="Prices"
          eventKey="Prices"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Coin className="mr-2" />
              Prices
            </b>
          }
        >
          <ProductPrices productId={product.id} />
        </Tab>
        <Tab
          key="Tasks"
          eventKey="Tasks"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CardChecklist className="mr-2" />
              Tasks
            </b>
          }
        >
          <TaskTabs productId={product.id} />
        </Tab>
        <Tab
          key="Billing"
          eventKey="Billing"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CashCoin className="mr-2" />
              Billing
            </b>
          }
        >
          <Billing productId={product.id} />
        </Tab>
        <Tab
          key="Notifications"
          eventKey="Notifications"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Bell className="mr-2" />
              Notifications
            </b>
          }
        >
          <NotificationsTable productId={product.id} />
        </Tab>
        <Tab
          key="History"
          eventKey="History"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <ClockHistory className="mr-2" />
              History
            </b>
          }
        >
          <AuditLog
            contentType={product.contentType.model}
            id={product.id}
            topRowMargin
          />
        </Tab>
      </Tabs>
    </>
  )
}
export default Product
