import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EmployeeSearchInput = (props) => {
  const { formik, disabled, multiple, searchDescription, error } = props

  return (
    <SearchInput
      formik={formik}
      nodeName="employee"
      nodeNamePlural="employees"
      disabled={disabled}
      searchDescription={searchDescription ?? 'employees'}
      multiple={multiple}
      error={error}
      gql={gql`
        query Employees($first: Int, $after: String, $search: String) {
          employees(
            first: $first
            after: $after
            orderBy: "user__full_name"
            user_FullName_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                user {
                  fullName
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.user.fullName
      }}
    />
  )
}

export default EmployeeSearchInput
