import React from 'react'
import { Modal } from 'react-bootstrap'
import ModelOutputFormatForm from './ModelOutputFormatForm'
import { Table } from 'react-bootstrap-icons'

const ModelOutputFormatModal = (props) => {
  const { showModal, toggleModal, modelOutputFormat } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size="xl" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Table className="mr-2" />
              {modelOutputFormat ? (
                <>{modelOutputFormat.name} Model Output Format</>
              ) : (
                <>New Model Output Format</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ModelOutputFormatForm
              modelOutputFormat={modelOutputFormat}
              afterSubmit={toggleModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ModelOutputFormatModal
