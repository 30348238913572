import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const DealsCalendar = (props) => {
  const monthMilliseconds = 2592000000
  const DragAndDropCalendar = withDragAndDrop(Calendar)
  const localizer = momentLocalizer(moment)
  const eventStyleGetter = () => {
    const style = {
      color: 'black',
      borderRadius: '0px',
      fontSize: '.7em',
      border: 'none',
    }
    return {
      className: '',
      style,
    }
  }

  return (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <DragAndDropCalendar
            popup
            localizer={localizer}
            step={5}
            dayLayoutAlgorithm="no-overlap"
            timeslots={12}
            defaultDate={new Date()}
            views={['month', 'week', 'day']}
            defaultView={'month'}
            events={[]}
            style={{ height: 600 }}
            titleAccessor="calendarName"
            showMultiDayTimes
            startAccessor="startDateTime"
            endAccessor="endDateTime"
            // onRangeChange={(rangeEvent) => handleRangeChange(rangeEvent)}
            eventPropGetter={eventStyleGetter}
            // onSelectEvent={handleEventClick}
            // onNavigate={(defaultDate) => handleNavigate(defaultDate)}
            // onView={(view) => handleViewChange(view)}
          />
        </Col>
      </Row>
    </>
  )
}

export default DealsCalendar
