import React from 'react'
import { Modal } from 'react-bootstrap'
import PromptForm from './PromptForm'
import { Table } from 'react-bootstrap-icons'

const PromptModal = (props) => {
  const { showModal, toggleModal, prompt } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size="lg" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Table className="mr-2" />
              {prompt ? <>{prompt.name} Prompt</> : <>New Prompt</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PromptForm prompt={prompt} afterSubmit={toggleModal} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default PromptModal
