import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import {
  Collection,
  Trash,
  Dot,
  CheckCircle,
  XCircle,
} from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
// import BusinessModal from './BusinessModal'
import Loading from '../../common/Loading'
// import DeleteBusinessModal from './DeleteBusinessModal'
import { loggedInUserVar } from '../../../libs/apollo'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'

const TaskGroups = (props) => {
  const { dealId, businessId, productId, tableHeight } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser.permissions.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const [filterSupplier, setFilterSupplier] = useState()
  const [filterBuyer, setFilterBuyer] = useState()
  const history = useHistory()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query TaskGroups(
        $cursor: String
        $deal: ID
        $business: String
        $product: String
        $search: String
      ) {
        taskGroups(
          first: 20
          after: $cursor
          deal: $deal
          business: $business
          product: $product
          search: $search
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              tasks {
                edges {
                  node {
                    name
                    complete
                  }
                }
              }
              deal {
                dealNumber
              }
              cargoUnitInstance {
                loadId
              }
              dealItem {
                deal {
                  dealNumber
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterSupplier, filterBuyer])

  const queryVariables = () => {
    const variables = {
      cursor: queryData?.taskGroups?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    if (dealId) {
      variables.deal = dealId
    }
    if (businessId) {
      variables.business = businessId
    }
    if (productId) {
      variables.product = productId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.taskGroups) {
      let text = 'Search 0 records'
      if (queryData.taskGroups.nodeCount > 0) {
        text = `Search ${queryData.taskGroups.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    query({ variables })
  }

  const onTdClick = (cell) => {}

  const toggleModal = () => {
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Fulfillment',
      id: 'fulfillment',
      accessor: (row) => {
        if (row.node.cargoUnitInstance) {
          return row.node.cargoUnitInstance.loadId
        }
      },
    },
    {
      Header: 'Type',
      id: 'name',
      accessor: 'node.name',
    },
    {
      Header: 'Tasks',
      id: 'tasks',
      accessor: (row) => {
        return (
          <>
            {row.node.tasks.edges.map((edge, index) => (
              <span key={index}>
                <Dot className="mr-2" />
                {edge.node.complete ? (
                  <span style={{ color: 'green' }}>
                    <CheckCircle className="mr-2" />
                  </span>
                ) : (
                  <span style={{ color: 'red' }}>
                    <XCircle className="mr-2" />
                  </span>
                )}
                {edge.node.name}
                <br />
              </span>
            ))}
          </>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.businesses?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.includes(row.node.id)}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]
  if (!dealId) {
    tableColumns.splice(0, 0, {
      Header: 'Deal',
      id: 'deal',
      accessor: 'node.deal.dealNumber',
    })
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Task Groups..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>
  return (
    <>
      <Row
        style={{
          marginTop: canMutate && !productId && !businessId ? '-10px' : '',
        }}
      >
        <Col>
          {canMutate && !productId && !businessId && (
            <Button
              variant="link"
              onClick={() => {
                toggleModal()
              }}
            >
              <Collection className="mr-2" />
              New Task Group
            </Button>
          )}
          {checkedNodeIds.length > 0 && (
            <>
              {canMutate && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedNodeIds.length === 1 ? (
                    <>Delete Task Group</>
                  ) : (
                    <>Delete Task Group</>
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.taskGroups?.edges ? queryData?.taskGroups?.edges : []
            }
            loadingMessage="Loading Task Groups..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.taskGroups?.pageInfo?.hasNextPage}
            onTdClicks={{
              name: (cell) => onTdClick(cell),
            }}
            tableHeight={tableHeight ? tableHeight : 800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
    </>
  )
}
export default TaskGroups
