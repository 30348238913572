import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import App from './App'
import { client } from './libs/apollo'
import * as serviceWorker from './serviceWorker'

const main = () => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>,
    document.getElementById('root')
  )
  serviceWorker.unregister()
}

main()
