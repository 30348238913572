import React, { useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Receipt } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ArrowUpRightCircle } from 'react-bootstrap-icons'
import SalesOrderSearchInput from '../../common/node_search_input/SalesOrderSearchInput'
import { useHistory } from 'react-router-dom'

const CreateInvoiceModal = (props) => {
  const { showModal, toggleModal, salesOrder } = props
  const [loading, setLoading] = useState(false)
  const [pending, setPending] = useState(false)
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      invoiceFooter: '',
      total: salesOrder?.totalAmount,
      invoiceEmail: salesOrder?.buyer.business.invoiceEmail,
      salesOrderId: salesOrder?.id,
      businessId: salesOrder?.buyer.business.id,
      salesOrderDescription: salesOrder?.description,
      daysUntilDue: 14,
    },
    onSubmit: (values) => {
      setLoading(true)
      sendInvoice({
        variables: {
          createStripeInvoiceInput: {
            stripeInvoiceInput: {
              salesOrderId: values.salesOrderId,
              invoiceFooter: values.invoiceFooter,
              daysUntilDue: values.daysUntilDue,
            },
          },
        },
      })
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      salesOrderId: Yup.string().required('Required'),
      businessId: Yup.string().nullable(),
      salesOrderDescription: Yup.string().nullable(),
      invoiceFooter: Yup.string().nullable(),
      invoiceEmail: Yup.mixed().required(
        'Business Requires Stripe Invoice Email Recipient'
      ),
      total: Yup.mixed().required('Required'),
      daysUntilDue: Yup.string().required('Required'),
    }),
  })

  const [sendInvoice] = useMutation(
    gql`
      mutation CreateStripeInvoice(
        $createStripeInvoiceInput: CreateStripeInvoiceInput!
      ) {
        createStripeInvoice(input: $createStripeInvoiceInput) {
          created
        }
      }
    `,
    {
      onCompleted: () => {
        if (pending) {
          setPending(false)
          toast.success('Invoice Sent')
        } else {
          toast.success('Invoice Sent')
        }
        setLoading(false)
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: ['StripeInvoices'],
    }
  )

  const innerToggle = () => {
    setLoading(false)
    formik.resetForm()
    toggleModal()
  }

  const setAdditionalSalesOrderFields = (node, _) => {
    formik.setFieldValue('total', node.totalAmount)
    formik.setFieldValue('invoiceEmail', node.buyer.business.invoiceEmail)
    formik.setFieldValue('businessId', node.buyer.business.invoiceEmail)
  }

  if (!showModal) return <></>
  return (
    <>
      <Modal
        size={'lg'}
        show={showModal}
        onHide={innerToggle}
        className="invmodal detail"
      >
        <Modal.Header closeButton>
          <Modal.Title id="new-title">Send Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form onSubmit={formik.handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Sales Order</Form.Label>
                  <SalesOrderSearchInput
                    formik={formik}
                    disabled={salesOrder}
                    error={formik.errors.salesOrderId}
                    setAdditionalFields={setAdditionalSalesOrderFields}
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Total</Form.Label>
                  <Form.Control
                    type="text"
                    name="total"
                    className="form-control-sm"
                    value={formik.values.total && `$${formik.values.total}`}
                    disabled={true}
                    isInvalid={formik.errors.total}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.total}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>
                    Stripe Invoice Email Recipient
                    {formik.values.businessId &&
                      !formik.values.invoiceEmail && (
                        <span
                          className="ml-2 btn-link p-0"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push(
                              `/business/${formik.values.businessId}`
                            )
                          }}
                        >
                          <ArrowUpRightCircle />
                        </span>
                      )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="invoiceEmail"
                    className="form-control-sm"
                    value={formik.values.invoiceEmail}
                    disabled={true}
                    isInvalid={formik.errors.invoiceEmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.invoiceEmail}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label>Days Until Due</Form.Label>
                  <Form.Control
                    type="number"
                    name="daysUntilDue"
                    className="form-control-sm"
                    value={formik.values.daysUntilDue}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.daysUntilDue}
                  />
                  {formik.errors.daysUntilDue && (
                    <small className="text-danger">
                      {formik.errors.daysUntilDue}
                    </small>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Label>Invoice Footer</Form.Label>
                  <Form.Control
                    style={{ height: '100px' }}
                    as="textarea"
                    name="invoiceFooter"
                    value={formik.values.invoiceFooter}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.invoiceFooter}
                  />
                  {formik.errors.invoiceFooter && (
                    <small className="text-danger">
                      {formik.errors.invoiceFooter}
                    </small>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4}>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    block
                    disabled={loading}
                    type="submit"
                  >
                    <Receipt className="mr-2" />
                    Send
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
          {loading && <Loading message="Sending Invoice..." />}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CreateInvoiceModal
