import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import {
  XCircle,
  Trash,
  Folder,
  CaretDown,
  CaretRight,
  ArrowUpRightCircle,
} from 'react-bootstrap-icons'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import AuditLog from '../audit_log/AuditLog'
import AddressAutocomplete from './AddressAutocomplete'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import Map from './Map'
import BusinessSearchInput from '../common/node_search_input/BusinessSearchInput'
import Fulfillments from '../cargo/fulfilment/Fulfillments'
import Deals from '../deal/Deals'
import ProductPrices from '../product/product_prices/ProductPrices'

const LocationForm = (props) => {
  const {
    locationId,
    afterSubmit,
    businessId,
    setLocationDescription,
    showModal,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canModify = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser.permissions.group)
  const [submitting, setSubmitting] = useState(false)
  const [displayHistory, setDisplayHistory] = useState(false)
  const [displayFulfillments, setDisplayFulfillments] = useState(false)
  const [displayDeals, setDisplayDeals] = useState(false)
  const [displayMap, setDisplayMap] = useState(false)
  const [displayPrices, setDisplayPrices] = useState(false)
  const [hasLocation, setHasLocation] = useState(locationId ? true : false)
  const [location, setLocation] = useState()
  const history = useHistory()

  useEffect(() => {
    if (!showModal) {
      setDisplayFulfillments(false)
      setDisplayDeals(false)
      setDisplayMap(false)
      setDisplayPrices(false)
      setLocation()
    }
  }, [showModal])

  const [getLocation] = useLazyQuery(
    gql`
      query Location($id: ID!) {
        location(id: $id) {
          id
          dealCount
          name
          salesTax
          addressLineOne
          billingLocation
          addressLineTwo
          city
          state
          zipCode
          archived
          latitude
          mapDefault
          longitude
          contentType {
            model
            id
          }
          business {
            id
            name
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        setLocation(data.location)
        formik.setValues({
          id: data.location.id,
          name: data.location.name,
          addressLineOne: data.location.addressLineOne,
          addressLineTwo: data.location.addressLineTwo,
          city: data.location.city,
          state: data.location.state,
          zipCode: data.location.zipCode,
          billingLocation: data.location.billingLocation,
          businessId: data.location.business?.id,
          businessDescription: data.location.business?.name,
          mapDefault: data.location.mapDefault,
        })
        let description
        if (data.location.name) {
          description = data.location.name
        }
        if (data.location.addressLineOne) {
          description = data.location.addressLineOne
        } else {
          description = 'Unnamed Location'
        }
        setLocationDescription(description)
      },
      variables: {
        id: locationId,
      },
    }
  )

  const [deleteLocation] = useMutation(
    gql`
      mutation DeleteLocations($deleteLocationInput: DeleteLocationInput!) {
        deleteLocation(input: $deleteLocationInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Location Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Locations'],
    }
  )

  const [createLocation] = useMutation(
    gql`
      mutation createLocation($input: CreateLocationInput!) {
        createLocation(input: $input) {
          location {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Location Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Locations'],
    }
  )

  const [updateLocation] = useMutation(
    gql`
      mutation UpdateLocation($input: UpdateLocationInput!) {
        updateLocation(input: $input) {
          location {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Location Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Locations'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zipCode: '',
      mapDefault: false,
      billingLocation: false,
      businessId: businessId ? businessId : '',
      businessDescription: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      addressLineOne: Yup.string().nullable(),
      addressLineTwo: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
      mapDefault: Yup.bool().nullable(),
      billingLocation: Yup.bool().nullable(),
      businessId: Yup.string().required('Required'),
      businessDescription: Yup.string().nullable(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (!hasLocation && !location) {
        formik.setFieldError('location', 'required')
      } else {
        setSubmitting(true)
        const variables = {
          name: values.name,
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          billingLocation: values.billingLocation,
          businessId: values.businessId,
          mapDefault: values.mapDefault,
        }
        if (values.id) {
          variables.id = values.id
          updateLocation({
            variables: {
              input: {
                locationInput: variables,
              },
            },
          })
        } else {
          createLocation({
            variables: {
              input: {
                locationInput: variables,
              },
            },
          })
        }
      }
    },
  })

  useEffect(() => {
    if (hasLocation && formik.errors.location) {
      formik.setFieldError('location', null)
    }
  }, [hasLocation])

  useEffect(() => {
    if (locationId) {
      getLocation()
    }
  }, [locationId])

  const handleDelete = () => {
    setSubmitting(true)
    deleteLocation({
      variables: {
        deleteLocationInput: {
          ids: location.id,
        },
      },
    })
  }

  const handleResetValues = () => {
    formik.setFieldValue('zipCode', '')
    formik.setFieldValue('city', '')
    formik.setFieldValue('addressName', '')
    formik.setFieldValue('state', '')
    formik.setFieldValue('addressLineOne', '')
    formik.setFieldValue('addressLineTwo', '')
    setHasLocation(false)
  }

  const handleAddressSelect = (addressData) => {
    formik.setFieldValue(
      'zipCode',
      addressData.address?.postal_code ? addressData?.address?.postal_code : ''
    )
    formik.setFieldValue(
      'city',
      addressData.address?.locality ? addressData?.address?.locality : ''
    )
    formik.setFieldValue(
      'addressName',
      addressData?.name ? addressData?.name : ''
    )
    formik.setFieldValue(
      'state',
      addressData?.address
        ? addressData?.address?.administrative_area_level_1
        : ''
    )
    formik.setFieldValue(
      'addressLineOne',
      addressData?.name ? addressData?.name : ''
    )
    formik.setFieldValue(
      'addressLineTwo',
      addressData?.neighborhood ? addressData?.neighborhood : ''
    )
    setHasLocation(true)
  }

  return (
    <>
      <div id="locationForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={12}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="form-control-sm"
                disabled={!canModify}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            {hasLocation ? (
              <>
                <Col md={location ? 11 : 10}>
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    disabled={hasLocation || !canModify}
                    placeholder="search locations"
                    className="form-control-sm"
                    value={
                      formik.values.addressLineOne +
                      ' ' +
                      formik.values.city +
                      ' ' +
                      formik.values.state +
                      ' ' +
                      formik.values.zipCode
                    }
                  />
                </Col>
                {canModify && (
                  <Col xs="auto" style={{ marginTop: '30px' }}>
                    <button
                      type="button"
                      className="btn-link mb-1"
                      onClick={() => handleResetValues()}
                    >
                      <XCircle />
                    </button>
                  </Col>
                )}
              </>
            ) : (
              <Col md={12}>
                <Form.Label>Location</Form.Label>
                <AddressAutocomplete
                  onSelect={handleAddressSelect}
                  error={formik.errors.location}
                />
              </Col>
            )}
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={12}>
              <Form.Label>Address Line Two</Form.Label>
              <Form.Control
                type="text"
                name="addressLineTwo"
                className="form-control-sm"
                disabled={!canModify}
                value={formik.values.addressLineTwo}
                onChange={formik.handleChange}
                isInvalid={formik.errors.addressLineTwo}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.addressLineTwo}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {!businessId && (
            <Form.Group as={Row}>
              <Col sm="12" md={12}>
                <Form.Label>
                  Business
                  {formik.values.businessId && (
                    <span
                      className="ml-2 btn-link p-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/business/${formik.values.businessId}`)
                      }}
                    >
                      <ArrowUpRightCircle />
                    </span>
                  )}
                </Form.Label>
                <BusinessSearchInput
                  formik={formik}
                  error={formik.errors.businessId}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Col md={location ? 2 : 6}>
              <Form.Label>Billing Location</Form.Label>
              <Form.Check
                name="billingLocation"
                type="checkbox"
                disabled={!canModify}
                label={formik.values.billingLocation ? 'Yes' : 'No'}
                checked={formik.values.billingLocation}
                onChange={(e) => {
                  formik.setFieldValue(`billingLocation`, e.target.checked)
                }}
              />
            </Col>
            <Col md={location ? 2 : 6}>
              <Form.Label>Map Default Location</Form.Label>
              <Form.Check
                name="mapDefault"
                type="checkbox"
                disabled={!canModify}
                label={formik.values.mapDefault ? 'Yes' : 'No'}
                checked={formik.values.mapDefault}
                onChange={(e) => {
                  formik.setFieldValue(`mapDefault`, e.target.checked)
                }}
              />
            </Col>
          </Form.Group>
          {location && (
            <>
              <Row className="mt-3">
                <Col>
                  <button
                    type="button"
                    onClick={() => setDisplayDeals(!displayDeals)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayDeals ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Deals</Form.Label>
                </Col>
              </Row>
              {displayDeals && (
                <Row className="mt-3">
                  <Col>
                    <Deals locationId={location.id} tableHeight={200} />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col>
                  <button
                    type="button"
                    onClick={() => setDisplayFulfillments(!displayFulfillments)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayFulfillments ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Fulfillment</Form.Label>
                </Col>
              </Row>
              {displayFulfillments && (
                <Row className="mt-3">
                  <Col>
                    <Fulfillments locationId={location.id} tableHeight={200} />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col>
                  <button
                    type="button"
                    onClick={() => setDisplayPrices(!displayPrices)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayPrices ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Product Prices</Form.Label>
                </Col>
              </Row>
              {displayPrices && (
                <Row className="mt-3">
                  <Col>
                    <ProductPrices locationId={location.id} tableHeight={200} />
                  </Col>
                </Row>
              )}
              <Row className="mt-3 mb-3">
                <Col>
                  <button
                    type="button"
                    onClick={() => setDisplayMap(!displayMap)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayMap ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Map</Form.Label>
                </Col>
              </Row>
              {displayMap && (
                <Row className="mt-3">
                  <Col>
                    <Map location={location} />
                  </Col>
                </Row>
              )}
              <Row className="mt-3 mb-4">
                <Col>
                  <button
                    type="button"
                    onClick={() => setDisplayHistory(!displayHistory)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayHistory ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">History</Form.Label>
                </Col>
              </Row>
              {displayHistory && (
                <Row className="mt-3">
                  <Col>
                    <AuditLog
                      contentType={location.contentType.model}
                      id={location.id}
                      tableHeight={400}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
          {canModify && (
            <>
              <div className="footer-modal">
                <Form.Row className="mt-2">
                  <Col md={location ? 2 : 4}>
                    <Button
                      type="submit"
                      block
                      variant="outline-primary"
                      disabled={submitting}
                    >
                      <Folder className="mr-2" />
                      Save
                    </Button>
                  </Col>

                  {location && (
                    <>
                      <Col md={location ? 2 : 4}>
                        <Button
                          block
                          variant="outline-danger"
                          onClick={handleDelete}
                          disabled={submitting}
                        >
                          <Trash className="mr-2" />
                          Delete
                        </Button>
                      </Col>
                    </>
                  )}
                </Form.Row>
              </div>
            </>
          )}

          {submitting && <Loading />}
        </Form>
      </div>
    </>
  )
}

export default LocationForm
