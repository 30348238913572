import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'
import { toTitleCase } from '../../../libs/utils'

const UnitOfMeasureSearchInput = (props) => {
  const { formik, disabled, formikValue, error, dropdown } = props

  return (
    <SearchInput
      formik={formik}
      nodeName="unitOfMeasure"
      nodeNamePlural="unitOfMeasures"
      searchDescription="units"
      disabled={disabled}
      formikValue={formikValue}
      dropdown={dropdown}
      error={error}
      gql={gql`
        query UnitOfMeasures($first: Int, $after: String, $search: String) {
          unitOfMeasures(
            first: $first
            after: $after
            orderBy: "plural_name"
            pluralName_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                pluralName
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return toTitleCase(node.pluralName)
      }}
    />
  )
}

export default UnitOfMeasureSearchInput
