import React, { useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Trash, ArrowUpRightCircle } from 'react-bootstrap-icons'
import DealSearchInput from '../../common/node_search_input/DealSearchInput'
import DealItemSearchInput from '../../common/node_search_input/DealItemSearchInput'
import UnitOfMeasureSearchInput from '../../common/node_search_input/UnitOfMeasureSearchInput'
import { useHistory } from 'react-router-dom'

const SalesOrderItem = (props) => {
  const { formik, index, deal } = props
  const history = useHistory()
  const setAdditionalDealItemFields = (node, _) => {
    if (node) {
      formik.setFieldValue(
        `salesOrderItems[${index}].quantity`,
        node.buyerQuantity
      )
      formik.setFieldValue(`salesOrderItems[${index}].price`, node.buyerPrice)
      formik.setFieldValue(
        `salesOrderItems[${index}].unitOfMeasureId`,
        node.buyerUnitOfMeasure.id
      )
      formik.setFieldValue(
        `salesOrderItems[${index}].unitOfMeasureDescription`,
        node.buyerUnitOfMeasure.shortName
      )
      formik.setErrors({})
    }
  }

  useEffect(() => {
    if (!formik.values.salesOrderItems[index].dealItemId) {
      formik.setFieldValue(`salesOrderItems[${index}].quantity`, '')
      formik.setFieldValue(`salesOrderItems[${index}].price`, '')
      formik.setFieldValue(`salesOrderItems[${index}].unitOfMeasureId`, '')
      formik.setFieldValue(
        `salesOrderItems[${index}].unitOfMeasureDescription`,
        ''
      )
      formik.setErrors({})
    }
  }, [formik.values.salesOrderItems[index].dealItemId])

  return (
    <>
      <div
        className={
          formik.values.salesOrderItems?.length > 1
            ? 'border border-secondary bg-white rounded my-3 px-3 pb-3'
            : ''
        }
      >
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>
              Deal
              {formik.values.salesOrderItems[index].dealId && (
                <span
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 btn-link p-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(
                      `/deal/${formik.values.salesOrderItems[index].dealId}`
                    )
                  }}
                >
                  <ArrowUpRightCircle />
                </span>
              )}
            </Form.Label>
            <DealSearchInput
              formik={formik}
              disabled={deal}
              buyer={formik.values.buyerId}
              formikValue={`salesOrderItems[${index}].deal`}
              error={
                index < formik.errors.salesOrderItems?.length &&
                formik.errors.salesOrderItems[index]?.dealId
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>Deal Product</Form.Label>
            <DealItemSearchInput
              formik={formik}
              dropdown
              disabled={!formik.values.salesOrderItems[index].dealId}
              deal={formik.values.salesOrderItems[index].dealId}
              formikValue={`salesOrderItems[${index}].dealItem`}
              setAdditionalFields={setAdditionalDealItemFields}
              error={
                index < formik.errors.salesOrderItems?.length &&
                formik.errors.salesOrderItems[index]?.dealItemId
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={4}>
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name={`salesOrderItems[${index}].quantity`}
              className="form-control-sm"
              step="0.0001"
              min="1"
              disabled={!formik.values.salesOrderItems[index].dealItemId}
              value={formik.values.salesOrderItems[index].quantity}
              onChange={formik.handleChange}
              isInvalid={
                index < formik.errors.salesOrderItems?.length &&
                formik.errors.salesOrderItems[index]?.quantity
              }
            />
            <Form.Control.Feedback type="invalid">
              {index < formik.errors.salesOrderItems?.length &&
                formik.errors.salesOrderItems[index]?.quantity && (
                  <>{formik.errors.salesOrderItems[index]?.quantity}</>
                )}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={4}>
            <Form.Label>Price</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name={`salesOrderItems[${index}].price`}
              className="form-control-sm"
              step="0.01"
              disabled={!formik.values.salesOrderItems[index].dealItemId}
              min="1"
              value={formik.values.salesOrderItems[index].price}
              onChange={formik.handleChange}
              isInvalid={
                index < formik.errors.salesOrderItems?.length &&
                formik.errors.salesOrderItems[index]?.price
              }
            />
            <Form.Control.Feedback type="invalid">
              {index < formik.errors.salesOrderItems?.length &&
                formik.errors.salesOrderItems[index]?.price && (
                  <>{formik.errors.salesOrderItems[index]?.price}</>
                )}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={4}>
            <Form.Label>Unit</Form.Label>
            <UnitOfMeasureSearchInput
              disabled={!formik.values.salesOrderItems[index].dealItemId}
              formikValue={`salesOrderItems[${index}].unitOfMeasure`}
              formik={formik}
              error={
                index < formik.errors.salesOrderItems?.length &&
                formik.errors.salesOrderItems[index]?.unitOfMeasureId
              }
            />
          </Col>
        </Form.Group>

        <Row>
          <Col>
            <div>
              <p size="sm">
                <small
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => {
                    const newSalesOrderItems = [
                      ...formik.values.salesOrderItems,
                    ]
                    newSalesOrderItems.splice(index, 1)
                    formik.setFieldValue('salesOrderItems', newSalesOrderItems)
                  }}
                >
                  <Trash className="me-1" /> Remove Sales Order Item
                </small>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default SalesOrderItem
