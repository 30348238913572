import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const CargoUnitInstanceContainerItemSearchInput = (props) => {
  const { formik, disabled, multiple, searchDescription, error } = props

  return (
    <SearchInput
      formik={formik}
      nodeName="cargoUnitInstanceContainerItem"
      nodeNamePlural="cargoUnitInstanceContainerItems"
      disabled={disabled}
      searchDescription={searchDescription ?? 'cargo'}
      multiple={multiple}
      error={error}
      gql={gql`
        query CargoUnitInstanceContainerItems(
          $first: Int
          $after: String
          $search: String
        ) {
          cargoUnitInstanceContainerItems(
            first: $first
            after: $after
            orderBy: "-pickup_date"
            search: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                cargoUnitInstanceContainer {
                  cargoUnitInstance {
                    loadId
                    deal {
                      dealNumber
                    }
                  }
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        const cargoUnitInstance =
          node.cargoUnitInstanceContainer.cargoUnitInstance
        return `Load ${cargoUnitInstance.loadId} | Deal ${cargoUnitInstance.deal.dealNumber}`
      }}
    />
  )
}

export default CargoUnitInstanceContainerItemSearchInput
