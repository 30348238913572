import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Col, Tab } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  InfoCircle,
  CashCoin,
  Receipt,
  ClockHistory,
  Bell,
} from 'react-bootstrap-icons'
import NotificationsTable from '../../notification/NotificationsTable'
import Loading from '../../common/Loading'
import Tabs from '../../common/Tabs'
import PurchaseOrderForm from './PurchaseOrderForm'
import AuditLog from '../../audit_log/AuditLog'
import Payments from '../payment/Payments'
import Invoices from '../invoice/Invoices'

const PurchaseOrder = () => {
  const { purchaseOrderId } = useParams()
  const [activeTab, setActiveTab] = useState('Details')
  const { error, data } = useQuery(
    gql`
      query PurchaseOrder($id: ID!) {
        purchaseOrder(id: $id) {
          id
          number
          totalAmount
          netTerms
          termsFee
          termsOn
          contentType {
            model
          }
          purchaseOrderItems {
            edges {
              node {
                id
                unitOfMeasure {
                  id
                  shortName
                  pluralName
                }
                dealItem {
                  id
                  supplier {
                    business {
                      name
                    }
                  }
                  deal {
                    id
                    buyer {
                      business {
                        name
                      }
                    }
                    dealNumber
                  }
                }
                quantity
                totalAmount
                contentType {
                  model
                }
                dealItem {
                  productVariant {
                    product {
                      name
                    }
                  }
                }
              }
            }
          }
          supplier {
            id
            business {
              id
              name
              invoiceEmail
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: purchaseOrderId,
      },
    }
  )
  if (error) return <>Error loading purchase order</>
  if (!data)
    return (
      <span className="mt-5">
        <Loading message="Loading Purchase Order..." />
      </span>
    )
  const { purchaseOrder } = data
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              Purchase Order #{purchaseOrder.number}
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Details"
          eventKey="Details"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <InfoCircle className="mr-2" />
              Details
            </b>
          }
        >
          <Row>
            <Col md={6}>
              <PurchaseOrderForm purchaseOrder={purchaseOrder} />
            </Col>
          </Row>
        </Tab>
        <Tab
          key="Deals"
          eventKey="Deals"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CashCoin className="mr-2" />
              Payments
            </b>
          }
        >
          <Payments purchaseOrderId={purchaseOrder.id} />
        </Tab>
        <Tab
          key="Invoice"
          eventKey="Invoices"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Receipt className="mr-2" />
              Invoices
            </b>
          }
        >
          <Invoices purchaseOrderId={purchaseOrder.id} />
        </Tab>
        <Tab
          key="Notifications"
          eventKey="Notifications"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Bell className="mr-2" />
              Notifications
            </b>
          }
        >
          <NotificationsTable purchaseOrderId={purchaseOrder.id} />
        </Tab>
        <Tab
          key="History"
          eventKey="History"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <ClockHistory className="mr-2" />
              History
            </b>
          }
        >
          <AuditLog
            contentTypesRelayIds={{
              [purchaseOrder.contentType.model]: [purchaseOrder.id],
              [purchaseOrder.purchaseOrderItems.edges[0].node.contentType
                .model]: purchaseOrder.purchaseOrderItems.edges.map(
                (edge) => edge.node.id
              ),
            }}
            recordColumns
            tableHeight={400}
          />
        </Tab>
      </Tabs>
    </>
  )
}
export default PurchaseOrder
