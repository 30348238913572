import React from 'react'
import { Modal } from 'react-bootstrap'
import FulfillmentForm from './FulfillmentForm'
import { Truck } from 'react-bootstrap-icons'

const FulfillmentModal = (props) => {
  const { showModal, toggleModal, fulfillment, dealId, cargoProviderId } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size="lg" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Truck className="mr-2" />
              {fulfillment ? <>Edit Fulfillment</> : <>New Fulfillment</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FulfillmentForm
              dealId={dealId}
              fulfillment={fulfillment}
              afterSubmit={toggleModal}
              cargoProviderId={cargoProviderId}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default FulfillmentModal
