import React from 'react'
import './StatusCircle.css'

const StatusCircle = (props) => {
  const { color, width, height, message, messageFontSize } = props
  let _color = color ? color : '#4CAF50'
  let _width = width ? width : '12px'
  let _height = height ? height : '12px'
  let _messageFontSize = messageFontSize ? messageFontSize : '12px'
  return (
    <div className="status-container">
      <div
        className="flashing-circle"
        style={{
          backgroundColor: _color,
          width: _width,
          height: _height,
        }}
      />
      {message && (
        <span className="status-message" style={{ fontSize: _messageFontSize }}>
          {message}
        </span>
      )}
    </div>
  )
}

export default StatusCircle
