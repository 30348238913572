import React, { useState, useEffect, useRef } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useGoogleMaps } from '../../../libs/googleMaps'

function ProductPricesMap(props) {
  const { productPrices, toggleModal } = props
  const { googleMaps } = useGoogleMaps()
  const mapRef = useRef()
  const mapInstanceRef = useRef()

  const [getLocations, { data: locationData }] = useLazyQuery(
    gql`
      query DefaultLocation {
        locations(mapDefault: true) {
          nodeCount
          edges {
            node {
              id
              latitude
              longitude
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (productPrices) {
      getLocations()
    }
  }, [productPrices])

  useEffect(() => {
    if (locationData && googleMaps && productPrices) {
      const mapOptions = {
        center: { lat: 41.739685, lng: -87.55442 },
        zoom: 10,
      }
      if (locationData && locationData.locations.edges.length > 0) {
        const defaultLocation = locationData.locations.edges[0].node
        if (defaultLocation.latitude && defaultLocation.longitude) {
          mapOptions.center.lat = parseFloat(defaultLocation.latitude)
          mapOptions.center.lng = parseFloat(defaultLocation.longitude)
        }
      }
      mapInstanceRef.current = new googleMaps.Map(mapRef.current, mapOptions)
      productPrices.forEach((edge) => {
        const location = edge.node.location
        if (location.latitude && location.longitude) {
          const marker = new googleMaps.Marker({
            position: {
              lat: parseFloat(location.latitude),
              lng: parseFloat(location.longitude),
            },
            map: mapInstanceRef.current,
          })
          marker.addListener('click', () => {
            toggleModal(edge.node)
          })
        }
      })
    }
  }, [googleMaps, productPrices, locationData])
  return (
    <>
      <div ref={mapRef} style={{ height: '100vh', width: '100%' }} />
    </>
  )
}

export default ProductPricesMap
