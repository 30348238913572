import React, { useState, useEffect } from 'react'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { Form, Row, Col, Button, Badge, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import validator from 'validator'
import PhoneInput from 'react-phone-input-2'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { Binoculars, BinocularsFill } from 'react-bootstrap-icons'
import DeleteEmployeeModal from './DeleteEmployeeModal'
import { Folder, Trash } from 'react-bootstrap-icons'
import { US_STATES } from '../../libs/constant'
import { client } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import PermissionTable from './PermissionTable'
import { formatDateTimeToString } from '../../libs/utils'
import Loading from '../common/Loading'
import PermissionsModal from './PermissionsModal'

const EditEmployeeModal = (props) => {
  const { employee } = props
  let groups = []
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const cantMutate = [
    'Equipment Manager',
    'Scheduling Analyst',
    'General Staff',
  ].includes(loggedInUser.permissions.group)
  const [submitting, setSubmitting] = useState(false)
  const [showPermissions, setShowPermissions] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const [groupsQuery, { error: groupsQueryError, data: groupsQueryData }] =
    useLazyQuery(
      gql`
        query GroupsQuery {
          groups {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  const [updateEmployeeMutation] = useMutation(
    gql`
      mutation UpdateEmployee($updateEmployeeInput: UpdateEmployeeInput!) {
        updateEmployee(input: $updateEmployeeInput) {
          employee {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Employee Saved`)
      },
      errorPolicy: 'all',
      refetchQueries: ['EmployeeQuery'],
    }
  )

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      abbreviatedName: '',
      email: '',
      phoneNumber: '',
      secondaryPhoneNumber: '',
      secondaryEmail: '',
      groupId: '',
      active: '',
      addressLineTwo: '',
      addressLineOne: '',
      city: '',
      state: '',
      smsNotificationsEnabled: true,
      emailNotificationsEnabled: true,
      zipCode: '',
      salesPercent: '',
      photoPercent: '',
      payCommission: '',
      password: '',
      confirmPassword: '',
      rainPercent: '',
      taskPercent: '',
      servicePercent: '',
      smtpHost: '',
      smtpPort: 0,
      smtpUser: '',
      smtpTls: false,
      smtpSsl: false,
      smtpPassword: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .nullable()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
        )
        .max(100, 'Password is too long'),
      confirmPassword: Yup.string()
        .nullable()
        .min(2, 'Confirm password is too short')
        .max(100, 'Confirm password is too long')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      firstName: Yup.string()
        .min(2, 'First name is too short')
        .max(100, 'First name  is too long')
        .required('First name  is required'),
      lastName: Yup.string()
        .min(2, 'Last name  is too short')
        .max(100, 'Last name  is too long')
        .required('Last name  is required'),
      abbrevietedName: Yup.string()
        .max(100, 'Abbrevieted name  is too long')
        .nullable(),
      secondaryEmail: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .nullable()
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      email: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .required('Email  is required')
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        })
        .test(
          'isUnique',
          'Account with this email already exists',
          async (value) => {
            let valid = true
            if (value) {
              const { data } = await client.query({
                query: gql`
                  query NewSubjectFormUsersQuery($emailIexact: String) {
                    users(email_Iexact: $emailIexact) {
                      nodeCount
                      edges {
                        node {
                          email
                        }
                      }
                    }
                  }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                  emailIexact: value,
                },
              })
              let matchesCurrent = false
              if (data.users.edges.length > 0) {
                data.users.edges.forEach((node) => {
                  if (
                    !matchesCurrent &&
                    node.node.email === employee.user.email
                  ) {
                    matchesCurrent = true
                  }
                })
                if (!matchesCurrent) {
                  valid = false
                }
              }
            }
            return valid
          }
        ),
      phoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      secondaryPhoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      smsNotificationsEnabled: Yup.bool().required('Required'),
      emailNotificationsEnabled: Yup.bool().required('Required'),
      addressLineOne: Yup.string()
        .min(1, 'Address line one is too short')
        .max(100, 'Address line one is too long')
        .nullable(),
      addressLineTwo: Yup.string()
        .min(1, 'Address line two is too short')
        .nullable()
        .max(100, 'Address line two is too long'),
      city: Yup.string()
        .min(1, 'City is too short')
        .max(100, 'City is too long')
        .nullable(),
      state: Yup.string()
        .min(3, 'State is too short')
        .max(100, 'State is too long')
        .nullable()
        .test('isState', 'Invalid state', (value) => {
          let valid = true
          if (value && !US_STATES.includes(value)) {
            valid = false
          }
          return valid
        }),
      zipCode: Yup.string()
        .min(3, 'Zip code is too short')
        .max(100, 'Zip code is too long')
        .nullable(),
      smtpTls: Yup.boolean().nullable(),
      smtpSsl: Yup.boolean().nullable(),
      smtpHost: Yup.string().nullable(),
      smtpPort: Yup.number().nullable(),
      smtpUser: Yup.string().nullable(),
      smtpPassword: Yup.string().nullable(),
      salesPercent: Yup.number().integer().nullable().min(0).max(100),
      photoPercent: Yup.number().integer().nullable().min(0).max(100),
      payCommission: Yup.number().integer().nullable().min(0).max(100),
      rainPercent: Yup.number().integer().nullable().min(0).max(100),
      taskPercent: Yup.number().integer().nullable().min(0).max(100),
      servicePercent: Yup.number().integer().nullable().min(0).max(100),
      groupId: Yup.string()
        .required('Required')
        .test('isGroup', 'Invalid permissions', (value) => {
          let valid = false
          if (value) {
            groups.map((group) => {
              if (!valid && value === group.id) {
                valid = true
              }
            })
          }
          return valid
        }),
      active: Yup.bool().required('Required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      updateEmployeeMutation({
        variables: {
          updateEmployeeInput: {
            employeeInput: {
              id: employee.id,
              // defaultRoleId: values.defaultRoleId,
              userFirstName: values.firstName,
              userLastName: values.lastName,
              userAbbreviatedName: values.abbreviatedName,
              userSecondaryPhoneNumber: values.secondaryPhoneNumber,
              userSecondaryEmail: values.secondaryEmail,
              userEmail: values.email,
              userPassword: values.password,
              userPhoneNumber: values.phoneNumber,
              userActive: values.active,
              groupId: values.groupId,
              userAddressLineOne: values.addressLineOne,
              userAddressLineTwo: values.addressLineTwo,
              userCity: values.city,
              userState: values.state,
              userZipCode: values.zipCode,
              salesPercent: values.salesPercent,
              photoPercent: values.photoPercent,
              userEmailNotificationsEnabled: values.emailNotificationsEnabled,
              userSmsNotificationsEnabled: values.smsNotificationsEnabled,
              payCommission: values.payCommission,
              rainPercent: values.rainPercent,
              taskPercent: values.taskPercent,
              servicePercent: values.servicePercent,
              smtpHost: values.smtpHost,
              smtpPort: values.smtpPort,
              smtpUser: values.smtpUser,
              smtpTls: values.smtpTls,
              smtpSsl: values.smtpSsl,
              smtpPassword: values.smtpPassword,
            },
          },
        },
      })
    },
  })

  const handleGroupsQueryData = () => {
    const currentGroups = []
    groupsQueryData.groups.edges.forEach((group) => {
      if (
        group.node.name !== 'Subject' &&
        group.node.name !== 'Organization Contact'
      ) {
        currentGroups.push(group.node)
      }
    })
    return currentGroups
  }
  const { data: smtpData } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customHost
              customPort
              customUser
              customTls
              customPassword
              customValid
              customSsl
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (employee && smtpData) {
      groupsQuery()
      const smtpConnector = smtpData.smtpConnectors.edges[0].node
      formik.setValues({
        firstName: employee.user.firstName,
        // defaultRoleId: '',
        lastName: employee.user.lastName,
        password: '',
        confirmPassword: '',
        abbreviatedName: employee.user.abbreviatedName,
        email: employee.user.email,
        secondaryEmail: employee.user.secondaryEmail,
        phoneNumber: employee.user.phoneNumber,
        secondaryPhoneNumber: employee.user.secondaryPhoneNumber,
        internalEmployeeId: employee.internalEmployeeId,
        groupId: employee.user.groups.edges[0].node.id,
        active: employee.user.isActive,
        addressLineOne: employee.user.addressLineOne,
        addressLineTwo: employee.user.addressLineTwo,
        city: employee.user.city,
        state: employee.user.state,
        zipCode: employee.user.zipCode,
        smsNotificationsEnabled: employee.user.smsNotificationsEnabled,
        emailNotificationsEnabled: employee.user.emailNotificationsEnabled,
        payCommission: employee.payCommission,
        photoPercent: employee.photoPercent,
        rainPercent: employee.rainPercent,
        salesPercent: employee.salesPercent,
        servicePercent: employee.servicePercent,
        taskPercent: employee.taskPercent,
        smtpHost: employee.smtpHost
          ? employee.smtpHost
          : smtpConnector.customHost,
        smtpPort: employee.smtpPort
          ? employee.smtpPort
          : smtpConnector.customPort,
        smtpUser: employee.smtpUser,
        smtpTls: [true, false].includes(employee.smtpTls)
          ? employee.smtpTls
          : smtpConnector.customTls,
        smtpSsl: [true, false].includes(employee.smtpSsl)
          ? employee.smtpSsl
          : smtpConnector.customSsl,
        smtpPassword: employee.smtpPassword,
      })
    }
  }, [employee, smtpData])

  if (!groupsQueryData) return <></>
  groups = handleGroupsQueryData()
  return (
    <>
      <div className="editEmployeeModal">
        <DeleteEmployeeModal
          showModal={showDeleteModal}
          toggleModal={toggleDeleteModal}
          employeeId={employee.id}
        />
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Row>
              <Col md={4}>
                <Form.Label className="mb-0">First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  readOnly={cantMutate}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  readOnly={cantMutate}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  readOnly={cantMutate}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Secondary Email</Form.Label>
                <Form.Control
                  type="text"
                  name="secondaryEmail"
                  readOnly={cantMutate}
                  value={formik.values.secondaryEmail}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.secondaryEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.secondaryEmail}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="pt-1 d-inline-block">
                  Phone Number
                </Form.Label>
                <PhoneInput
                  country="us"
                  name="phoneNumber"
                  disabled={cantMutate}
                  regions={['north-america']}
                  value={formik.values.phoneNumber}
                  onChange={(phone, country) =>
                    formik.setFieldValue('phoneNumber', phone)
                  }
                />
                {formik.errors.phoneNumber ? (
                  <span className="text-danger">
                    {formik.errors.phoneNumber}
                  </span>
                ) : null}
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="pt-1 d-inline-block">
                  Secondary Phone Number
                </Form.Label>
                <PhoneInput
                  country="us"
                  name="secondaryPhoneNumber"
                  disabled={cantMutate}
                  regions={['north-america']}
                  value={formik.values.secondaryPhoneNumber}
                  onChange={(phone, country) =>
                    formik.setFieldValue('secondaryPhoneNumber', phone)
                  }
                />
                {formik.errors.secondaryPhoneNumber ? (
                  <span className="text-danger">
                    {formik.errors.secondaryPhoneNumber}
                  </span>
                ) : null}
              </Col>
            </Row>
          </Form.Group>
          <Row>
            <Col md={8}>
              <p className="text-secondary title-space mb-1">ACCESS RIGHTS</p>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <hr />
            </Col>
          </Row>

          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Last Login</Form.Label>
                <Form.Control
                  disabled={true}
                  value={
                    employee.user.lastLogin
                      ? formatDateTimeToString(
                          new Date(employee.user.lastLogin)
                        )
                      : 'Never'
                  }
                />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Last Login Location</Form.Label>
                <Form.Control
                  disabled={true}
                  value={
                    employee.user.loginLocation
                      ? employee.user.loginLocation
                      : ''
                  }
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label className="mb-0">Confirm Password</Form.Label>

                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              {['Scheduling Manager', 'Administrator'].includes(
                loggedInUser.permissions.group
              ) && (
                <Col xs={12} md={4}>
                  <Form.Label className="mb-0">Permissions</Form.Label>
                  {!cantMutate && (
                    <Button
                      type="button"
                      onClick={() => setShowPermissions(!showPermissions)}
                      className="px-0 btn-link ml-2"
                    >
                      <>
                        {showPermissions ? (
                          <>
                            <BinocularsFill size={15} />{' '}
                            <span style={{ fontSize: '14px' }}></span>
                          </>
                        ) : (
                          <>
                            <Binoculars size={15} />{' '}
                            <span style={{ fontSize: '14px' }}></span>
                          </>
                        )}
                      </>
                    </Button>
                  )}

                  <Form.Control
                    name="groupId"
                    as="select"
                    disabled={cantMutate}
                    value={formik.values.groupId}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.groupId}
                  >
                    {groups.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.groupId}
                  </Form.Control.Feedback>
                </Col>
              )}
            </Row>
          </Form.Group>
          {!cantMutate && (
            <Form.Group>
              <Form.Row>
                <Col xs={12} md={2} className="d-flex align-items-center">
                  <Form.Label className="mb-0">Login Access</Form.Label>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Check
                    name="active"
                    disabled={cantMutate}
                    style={{ width: '20px', height: '20px' }}
                    checked={formik.values.active}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.active}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.active}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
            </Form.Group>
          )}

          <Form.Group>
            <>
              <Row>
                <Col md={8}>
                  <p className="text-secondary title-space mb-1">
                    NOTIFCATIONS
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <hr />
                </Col>
              </Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Check
                    inline
                    label="Receive SMS"
                    disabled={cantMutate}
                    checked={formik.values.smsNotificationsEnabled}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'smsNotificationsEnabled',
                        e.target.checked
                      )
                    }
                  />

                  <Form.Check
                    inline
                    label="Receive Email"
                    disabled={cantMutate}
                    checked={formik.values.emailNotificationsEnabled}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'emailNotificationsEnabled',
                        e.target.checked
                      )
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Row className="mt-3">
                <Col md={8}>
                  <p className="text-secondary title-space mb-1">
                    PERSONAL EMAIL SENDER
                    {(employee.smtpHost ||
                      employee.smtpPassword ||
                      employee.smtpPort ||
                      employee.smtpUser) &&
                      employee.smtpValid && (
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="success"
                        >
                          Valid Credentials
                        </Badge>
                      )}
                    {(employee.smtpHost ||
                      employee.smtpPassword ||
                      employee.smtpPort ||
                      employee.smtpUser) &&
                      employee.smtpValid === false && (
                        <Badge
                          className="ml-2"
                          style={{ fontSize: '12px' }}
                          variant="danger"
                        >
                          Disabled
                        </Badge>
                      )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <hr />
                </Col>
              </Row>
              <Form.Group>
                <Row>
                  <Col xs={12} md={4}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="string"
                      name="smtpUser"
                      disabled={cantMutate}
                      value={formik.values.smtpUser}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.smtpUser}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.smtpUser}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="smtpPassword"
                      disabled={cantMutate}
                      value={formik.values.smtpPassword}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.smtpPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.smtpPassword}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col xs={12} md={3}>
                    <Form.Label>Email Server Host</Form.Label>
                    <Form.Control
                      type="string"
                      name="smtpHost"
                      disabled={cantMutate}
                      value={formik.values.smtpHost}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.smtpHost}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.smtpHost}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={12} md={2}>
                    <Form.Label>Email Server Port</Form.Label>
                    <Form.Control
                      type="number"
                      name="smtpPort"
                      disabled={cantMutate}
                      value={formik.values.smtpPort}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.smtpPort}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.smtpPort}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={12} md={2}>
                    <Form.Label>TLS Authentication</Form.Label>
                    <Form.Check
                      name="smtpTls"
                      type="checkbox"
                      disabled={cantMutate}
                      checked={formik.values.smtpTls}
                      onChange={(e) => {
                        formik.setFieldValue(`smtpTls`, e.target.checked)
                        if (e.target.checked && formik.values.smtpSsl) {
                          formik.setFieldValue(`smtpSsl`, false)
                        }
                      }}
                      isInvalid={formik.errors.smtpTls}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.smtpTls}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={12} md={2}>
                    <Form.Label>SSL Authentication</Form.Label>
                    <Form.Check
                      name="smtpSsl"
                      type="checkbox"
                      disabled={cantMutate}
                      checked={formik.values.smtpSsl}
                      onChange={(e) => {
                        formik.setFieldValue(`smtpSsl`, e.target.checked)
                        if (e.target.checked && formik.values.smtpTls) {
                          formik.setFieldValue(`smtpTls`, false)
                        }
                      }}
                      isInvalid={formik.errors.smtpSsl}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.smtpSsl}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
            </>
          </Form.Group>

          {!cantMutate &&
            window.location.hostname.includes('srschedule.com') && (
              <>
                <Row>
                  <Col md={8}>
                    <p className="text-secondary title-space mb-1">
                      PROFIT SHARING
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <hr />
                  </Col>
                </Row>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={2}>
                      <Form.Label>Photo Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="photoPercent"
                        disabled={cantMutate}
                        value={formik.values.photoPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.photoPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.photoPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Sales Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="salesPercent"
                        disabled={cantMutate}
                        value={formik.values.salesPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.salesPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.salesPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Rain Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="rainPercent"
                        disabled={cantMutate}
                        value={formik.values.rainPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.rainPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.rainPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Service Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="servicePercent"
                        disabled={cantMutate}
                        value={formik.values.servicePercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.servicePercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.servicePercent}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={2}>
                      <Form.Label>Task Percent</Form.Label>
                      <Form.Control
                        type="number"
                        name="taskPercent"
                        disabled={cantMutate}
                        value={formik.values.taskPercent}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.taskPercent}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.taskPercent}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={2}>
                      <Form.Label>Pay Commission</Form.Label>
                      <Form.Control
                        type="number"
                        name="payCommission"
                        disabled={cantMutate}
                        value={formik.values.payCommission}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.payCommission}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.payCommission}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </>
            )}

          {!cantMutate && (
            <div className="mb-3">
              <Button
                type="submit"
                variant="outline-primary"
                className="mt-2"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
              <Button
                disabled={submitting}
                variant="outline-danger"
                onClick={toggleDeleteModal}
              >
                <Trash className="mr-2" />
                Delete
              </Button>
            </div>
          )}
          {submitting && (
            <Row>
              <Col>
                <Loading message="Saving Employee..." />
              </Col>
            </Row>
          )}
        </Form>
        <PermissionsModal
          showModal={showPermissions}
          hideModal={() => setShowPermissions()}
        />
      </div>
    </>
  )
}

export default EditEmployeeModal
