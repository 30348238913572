import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col, Tab } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import Tabs from '../../common/Tabs'
import {
  Plus,
  ArrowUpRightCircle,
  Trash,
  Folder,
  CashCoin,
  Receipt,
  ClockHistory,
  Bell,
  FileEarmarkText,
} from 'react-bootstrap-icons'
import BusinessSearchInput from '../../common/node_search_input/BusinessSearchInput'
import Loading from '../../common/Loading'
import SalesOrderItem from './SalesOrderItem'
import { formatCurrency } from '../../../libs/utils'
import AuditLog from '../../audit_log/AuditLog'
import Payments from '../payment/Payments'
import Invoices from '../invoice/Invoices'
import NotificationsTable from '../../notification/NotificationsTable'
import { FilePond } from 'react-filepond'
import Files from '../../files/Files'

const SalesOrderForm = (props) => {
  const { salesOrder, afterSubmit, showModal, deal } = props
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [activeTab, setActiveTab] = useState('Files')
  const history = useHistory()
  const TERMS = [
    {
      value: 'NET_5_WEEK_ENDING_DELIVERY',
      description: 'Net 5 Week Ending Delivery',
    },
    {
      value: 'NET_10_WEEK_ENDING_DELIVERY',
      description: 'Net 10 Week Ending Delivery',
    },
    { value: 'NET_5', description: 'Net 5' },
    { value: 'NET_10', description: 'Net 10' },
  ]

  const [deleteSalesOrder] = useMutation(
    gql`
      mutation DeleteSalesOrder($input: DeleteSalesOrderInput!) {
        deleteSalesOrder(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Sales Order Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['SalesOrders'],
    }
  )

  const [createSalesOrder] = useMutation(
    gql`
      mutation CreateSalesOrder($input: CreateSalesOrderInput!) {
        createSalesOrder(input: $input) {
          salesOrder {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Sales Order Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['SalesOrders'],
    }
  )

  const [updateSalesOrder] = useMutation(
    gql`
      mutation UpdateSalesOrder($input: UpdateSalesOrderInput!) {
        updateSalesOrder(input: $input) {
          salesOrder {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Sales Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['SalesOrders'],
    }
  )

  let initialValues = {
    buyerBusinessId: '',
    buyerId: '',
    buyerBusinessDescription: '',
    number: '',
    fileSupplierVisibility: true,
    fileBuyerVisibility: true,
    deletePreviousFiles: true,
    total: 0,
    netTerms: null,
    termsFee: null,
    termsOn: false,
    salesOrderItems: [],
  }
  if (salesOrder) {
    initialValues = {
      buyerBusinessId: salesOrder.buyer.business.id,
      buyerId: salesOrder.buyer.id,
      buyerBusinessDescription: salesOrder.buyer.business.name,
      number: salesOrder.number,
      fileSupplierVisibility: true,
      fileBuyerVisibility: true,
      deletePreviousFiles: true,
      total: 0,
      netTerms: salesOrder.netTerms,
      termsFee: salesOrder.termsFee,
      termsOn: salesOrder.termsOn,
      salesOrderItems: salesOrder.salesOrderItems.edges.map((edge) => {
        const salesOrderItem = edge.node
        const dealItem = salesOrderItem.dealItem
        const deal = dealItem.deal
        let dealDescription = deal.dealNumber
        if (deal.buyer) {
          dealDescription = `${dealDescription} | Buyer ${deal.buyer.business.name}`
        }
        if (dealItem.supplier) {
          dealDescription = `${dealDescription} | Supplier ${dealItem.supplier.business.name}`
        }
        return {
          id: salesOrderItem.id,
          dealId: deal.id,
          dealItemId: dealItem.id,
          dealDescription: dealDescription,
          quantity: salesOrderItem.quantity,
          price: salesOrderItem.amount,
          unitOfMeasureId: salesOrderItem.unitOfMeasure.id,
          unitOfMeasureDescription: salesOrderItem.unitOfMeasure.pluralName,
        }
      }),
    }
  } else if (deal) {
    const dealItem =
      deal.dealItems.edges.length > 0 && deal.dealItems.edges[0].node
    initialValues = {
      buyerBusinessId: deal.buyer ? deal.buyer?.business.id : '',
      buyerId: deal.buyer ? deal.buyer?.id : '',
      buyerBusinessDescription: deal.buyer ? deal.buyer?.business.name : '',
      number: '',
      total: 0,
      netTerms: null,
      termsFee: null,
      fileSupplierVisibility: true,
      fileBuyerVisibility: true,
      deletePreviousFiles: true,
      termsOn: false,
      salesOrderItems: dealItem
        ? deal.dealItems.edges.map((edge) => {
            const dealItem = edge.node
            let dealDescription = deal.dealNumber
            if (deal.buyer) {
              dealDescription = `${dealDescription} | Buyer ${deal.buyer.business.name}`
            }
            if (dealItem.supplier) {
              dealDescription = `${dealDescription} | Supplier ${dealItem.supplier.business.name}`
            }
            return {
              dealId: deal.id,
              dealItemId: dealItem.id,
              dealDescription: dealDescription,
              quantity: dealItem.buyerQuantity,
              price: dealItem.buyerPrice,
              unitOfMeasureId: dealItem.buyerUnitOfMeasure
                ? dealItem.buyerUnitOfMeasure.id
                : '',
              unitOfMeasureDescription: dealItem.buyerUnitOfMeasure
                ? dealItem.buyerUnitOfMeasure.pluralName
                : '',
            }
          })
        : [],
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      buyerBusinessId: Yup.string().required('Required'),
      buyerId: Yup.string().required('Required'),
      buyerBusinessDescription: Yup.string().required('Required'),
      fileSupplierVisibility: Yup.boolean().nullable(),
      fileBuyerVisibility: Yup.boolean().nullable(),
      deletePreviousFiles: Yup.boolean().nullable(),
      number: Yup.string().required('Required'),
      netTerms: Yup.mixed().test('required', 'Required', (value, context) => {
        let valid = true
        if (context.parent.termsOn && (!value || value === '- - -')) {
          valid = false
        }
        return valid
      }),
      termsFee: Yup.mixed().test('required', 'Required', (value, context) => {
        let valid = true
        if (context.parent.termsOn && !value) {
          valid = false
        }
        return valid
      }),
      termsOn: Yup.boolean().required('Required'),
      file: Yup.mixed().nullable(),
      salesOrderItems: Yup.array().of(
        Yup.object().shape({
          dealId: Yup.string().required('Required'),
          dealItemId: Yup.string().required('Required'),
          dealDescription: Yup.string().required('Required'),
          quantity: Yup.mixed().required('Required'),
          price: Yup.mixed().required('Required'),
          unitOfMeasureId: Yup.string().required('Required'),
          unitOfMeasureDescription: Yup.string().required('Required'),
        })
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (formik.values.salesOrderItems.length === 0) {
        formik.setFieldError('missingSalesOrderItems', 'Required')
      } else {
        const variables = {
          buyerId: values.buyerId,
          number: values.number,
          netTerms: values.termsOn ? values.netTerms : null,
          termsFee: values.termsOn ? values.termsFee : null,
          termsOn: values.termsOn,
          salesOrderItems: values.salesOrderItems.map((salesOrderItem) => ({
            id: salesOrderItem.id,
            dealItemId: salesOrderItem.dealItemId,
            quantity: salesOrderItem.quantity,
            amount: salesOrderItem.price,
            unitOfMeasureId: salesOrderItem.unitOfMeasureId,
          })),
        }
        if (values.file) {
          variables.file = values.file[0].file
          variables.fileBuyerVisibility = values.fileBuyerVisibility
          variables.fileSupplierVisibility = values.fileSupplierVisibility
          if (salesOrder) {
            variables.deleteFiles = values.deletePreviousFile
          }
        }
        if (salesOrder) {
          variables.id = salesOrder.id
          updateSalesOrder({
            variables: {
              input: {
                salesOrderInput: variables,
              },
            },
          })
        } else {
          createSalesOrder({
            variables: {
              input: {
                salesOrderInput: variables,
              },
            },
          })
        }
      }
    },
  })

  useEffect(() => {
    if (
      !formik.values.buyerBusinessId &&
      formik.values.salesOrderItems.length > 0
    ) {
      formik.setFieldValue('salesOrderItems', [])
    }
  }, [formik.values.buyerBusinessId, formik.values.salesOrderItems])

  useEffect(() => {
    formik.setFieldValue(
      'total',
      formik.values.salesOrderItems.reduce((accumulator, item) => {
        return accumulator + item.quantity * item.price
      }, 0)
    )
  }, [formik.values.salesOrderItems])

  useEffect(() => {
    if (showModal === false) {
      formik.resetForm()
    }
  }, [showModal])

  const setAdditionalBusinessFields = (node, _) => {
    formik.setFieldValue('buyerId', node.buyer.id)
  }
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={3}>
            <Form.Label>Sales Order Number</Form.Label>
            <Form.Control
              as="input"
              name="number"
              className="form-control-sm"
              value={formik.values.number}
              onChange={formik.handleChange}
              isInvalid={formik.errors.number}
            />
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.number}
            </Form.Control.Feedback>
          </Col>
          <Col md={3}>
            <Form.Label>Total</Form.Label>
            <Form.Control
              as="input"
              className="form-control-sm"
              disabled={true}
              value={formatCurrency(formik.values.total)}
            />
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>
              Buyer
              {formik.values.buyerBusinessId && (
                <span
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 btn-link p-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/business/${formik.values.buyerBusinessId}`)
                  }}
                >
                  <ArrowUpRightCircle />
                </span>
              )}
            </Form.Label>
            <BusinessSearchInput
              formik={formik}
              buyers
              disabled={deal}
              setAdditionalFields={setAdditionalBusinessFields}
              formikValue="buyerBusiness"
              searchDescription="buyers"
              error={formik.errors.buyerId}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={12} md={2}>
            <Form.Label>Terms</Form.Label>
            <Form.Check
              label={formik.values.termsOn ? 'Yes' : 'No'}
              onChange={(e) => {
                formik.setFieldValue(`termsOn`, e.target.checked)
                if (!e.target.checked) {
                  formik.setFieldValue('netTerms', '- - -')
                }
              }}
              checked={formik.values.termsOn}
              isInvalid={formik.errors.termsOn}
            />
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.termsOn}
            </Form.Control.Feedback>
          </Col>
          {formik.values.termsOn && (
            <>
              <Col sm="12" md={4}>
                <Form.Label>Terms Fee</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  name={'termsFee'}
                  className="form-control-sm"
                  step="0.01"
                  min="1"
                  isInvalid={formik.errors.termsFee}
                  value={formik.values.termsFee}
                  onChange={formik.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formik?.errors?.termsFee}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Net Terms</Form.Label>
                <Form.Control
                  name="netTerms"
                  as="select"
                  className="form-control-sm"
                  value={formik.values.netTerms}
                  onChange={formik?.handleChange}
                  isInvalid={formik?.errors?.netTerms}
                >
                  <option value="- - -">- - -</option>
                  {TERMS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik?.errors?.netTerms}
                </Form.Control.Feedback>
              </Col>
            </>
          )}
        </Form.Group>
        {!salesOrder && (
          <>
            <Form.Group as={Row}>
              <Col>
                <FilePond
                  maxFileSize="100MB"
                  allowFileSizeValidation
                  files={formik.values.file}
                  maxFiles={1}
                  labelMaxFileSize="Maximum file size is {filesize}"
                  onupdatefiles={(file) => {
                    formik.setFieldValue('file', file)
                  }}
                  labelIdle='Drag and drop file or <span class="filepond--label-action">Browse</span>'
                />
                {formik.errors?.file && (
                  <div>
                    <small className="text-danger">{formik.errors.file}</small>
                  </div>
                )}
              </Col>
            </Form.Group>
            {formik.values.file?.length === 1 && (
              <Form.Group as={Row} style={{ marginTop: '-15px' }}>
                <Col md={4}>
                  <Form.Label>File Visible to Suppliers</Form.Label>
                  <Form.Check
                    label={formik.values.fileSupplierVisibility ? 'Yes' : 'No'}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `fileSupplierVisibility`,
                        e.target.checked
                      )
                    }}
                    checked={formik.values.fileSupplierVisibility}
                  />
                </Col>
                <Col md={4}>
                  <Form.Label>File Visible to Buyers</Form.Label>
                  <Form.Check
                    label={formik.values.fileBuyerVisibility ? 'Yes' : 'No'}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `fileBuyerVisibility`,
                        e.target.checked
                      )
                    }}
                    checked={formik.values.fileBuyerVisibility}
                  />
                </Col>
              </Form.Group>
            )}
          </>
        )}
        <div
          className={
            formik.values.salesOrderItems.length > 0
              ? 'bg-light border p-4'
              : 'd-none'
          }
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          {formik.values.salesOrderItems.map((_, index) => (
            <SalesOrderItem
              key={index}
              formik={formik}
              index={index}
              deal={deal}
            />
          ))}
        </div>
        {formik.errors.missingSalesOrderItems && (
          <small className="text-danger">
            {formik.errors.missingSalesOrderItems}
          </small>
        )}
        {formik.values.buyerBusinessId && (
          <Form.Group as={Row}>
            <Col>
              <Button
                className={
                  formik.values.salesOrderItems.length > 0
                    ? ' mt-3 ps-0'
                    : 'ps-0 mt-0'
                }
                variant="link"
                size="sm"
                onClick={() => {
                  formik.setFieldValue('salesOrderItems', [
                    ...formik.values.salesOrderItems,
                    {
                      dealId: '',
                      dealDescription: '',
                      dealItemId: '',
                      dealItemDescription: '',
                      quantity: null,
                      amount: null,
                      unitOfMeasureId: null,
                      unitOfMeasureDescription: null,
                    },
                  ])
                }}
              >
                <Plus size={18} /> Add Sales Order Item
              </Button>
            </Col>
          </Form.Group>
        )}
        {salesOrder && (
          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => {
              setActiveTab(tab)
            }}
          >
            <Tab
              key="Files"
              eventKey="Files"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <CashCoin className="mr-2" />
                  Files
                </b>
              }
            >
              <Files
                salesOrderId={salesOrder.id}
                salesOrderDescription={salesOrder.description}
                tableHeight={200}
              />
            </Tab>
            <Tab
              key="Payments"
              eventKey="Payments"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <CashCoin className="mr-2" />
                  Payments
                </b>
              }
            >
              <Payments salesOrderId={salesOrder.id} tableHeight={200} />
            </Tab>
            <Tab
              key="Invoice"
              eventKey="Invoices"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Receipt className="mr-2" />
                  Invoices
                </b>
              }
            >
              <Invoices salesOrder={salesOrder} tableHeight={200} />
            </Tab>
            <Tab
              key="Notifications"
              eventKey="Notifications"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <Bell className="mr-2" />
                  Notifications
                </b>
              }
            >
              <NotificationsTable
                salesOrderId={salesOrder.id}
                tableHeight={200}
              />
            </Tab>
            <Tab
              key="History"
              eventKey="History"
              style={{ marginTop: '10px' }}
              title={
                <b>
                  <ClockHistory className="mr-2" />
                  History
                </b>
              }
            >
              <AuditLog
                contentTypesRelayIds={{
                  [salesOrder.contentType.model]: [salesOrder.id],
                  [salesOrder.salesOrderItems.edges[0].node.contentType.model]:
                    salesOrder.salesOrderItems.edges.map(
                      (edge) => edge.node.id
                    ),
                }}
                recordColumns
                tableHeight={400}
              />
            </Tab>
          </Tabs>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {salesOrder && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deleteSalesOrder({
                      variables: {
                        input: {
                          ids: salesOrder.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-3">
              <Loading
                message={
                  deleting ? 'Deleting Sales Order...' : 'Saving Sales Order...'
                }
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default SalesOrderForm
