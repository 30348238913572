import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const DealSearchInput = (props) => {
  const {
    formik,
    disabled,
    multiple,
    fetchPolicy,
    searchDescription,
    setAdditionalFields,
    formikValue,
    buyer,
    supplier,
    error,
  } = props
  const variables = {}
  if (buyer) {
    variables.buyer = buyer
  }
  if (supplier) {
    variables.supplier = supplier
  }
  return (
    <SearchInput
      formik={formik}
      multiple={multiple}
      searchDescription={searchDescription ? searchDescription : 'deals'}
      nodeName="deal"
      fetchPolicy={fetchPolicy}
      setAdditionalFields={setAdditionalFields}
      nodeNamePlural="deals"
      disabled={disabled}
      formikValue={formikValue}
      variables={variables}
      error={error}
      gql={gql`
        query Deals($first: Int, $after: String, $search: String, $buyer: ID) {
          deals(
            first: $first
            after: $after
            orderBy: "-created"
            search: $search
            buyer: $buyer
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                dealNumber
                buyer {
                  business {
                    id
                    name
                  }
                }
                supplier {
                  business {
                    id
                    name
                  }
                }
                pickupLocation {
                  id
                  fullAddress
                }
                deliveryLocation {
                  id
                  fullAddress
                }
                description
                dealItems {
                  edges {
                    node {
                      id
                      deliveryLocation {
                        id
                        fullAddress
                      }
                      supplierQuantity
                      supplierUnitOfMeasure {
                        id
                        shortName
                        pluralName
                      }
                      buyerQuantity
                      buyerUnitOfMeasure {
                        id
                        shortName
                        pluralName
                      }
                      pickupLocation {
                        id
                        fullAddress
                      }
                      productVariant {
                        product {
                          name
                        }
                      }
                      supplier {
                        business {
                          id
                          name
                        }
                      }
                    }
                  }
                }
                id
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.description
      }}
    />
  )
}

export default DealSearchInput
