import React from 'react'
import { Switch } from 'react-router-dom'
import OpenRoute from './OpenRoute'
import EmployeeRoute from './EmployeeRoute'
import NotFound from '../components/NotFound'
import ResetPassword from '../components/auth/ResetPassword'
import NewPassword from '../components/auth/NewPassword'
import PublicUserNotificationSettings from '../components/notification/PublicUserNotificationSettings'
import Settings from '../components/settings/Settings'
import Notifications from '../components/notification/Notifications'
import Employees from '../components/employee/Employees'
import EmployeeDetail from '../components/employee/EmployeeDetail'
import Locations from '../components/location/Locations'
import Billing from '../components/billing/Billing'
import ConfirmEmail from '../components/user/ConfirmEmail'
import Login from '../components/auth/Login'
import { loggedInUserVar } from '../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import Files from '../components/files/Files'
import SignUp from '../components/auth/SignUp'
import InactiveTenant from '../components/InactiveTenant'
import ScrapGoRoute from './ScrapGoRoute'
import Businesses from '../components/business/Businesses'
import Deals from '../components/deal/Deals'
import ProductTabs from '../components/product/ProductTabs'
import Product from '../components/product/product/Product'
import Business from '../components/business/Business'
import Landing from '../components/landing/Landing'
import Clients from '../components/client/Clients'
import Deal from '../components/deal/Deal'
import CargoTabs from '../components/cargo/CargoTabs'
import CargoProvider from '../components/cargo/cargo_provider/CargoProvider'
import TaskTabs from '../components/task/TaskTabs'
import SalesOrder from '../components/billing/sales_order/SalesOrder'
import PurchaseOrder from '../components/billing/purchase_order/PurchaseOrder'
import AITabs from '../components/ai/AITabs'
import AuthenticatedRoute from './AuthenticatedRoute'

const Routes = () => {
  const user = useReactiveVar(loggedInUserVar)
  return (
    <Switch>
      <OpenRoute landing exact path="/" component={Landing} />
      <OpenRoute landing hideHeaderLogo exact path="/login" component={Login} />
      <OpenRoute
        exact
        path="/confirm-email/:confirmationToken/:resetPasswordToken"
        component={ConfirmEmail}
      />
      <ScrapGoRoute exact path="/sign-up" component={SignUp} />
      <ScrapGoRoute exact path="/inactive" component={InactiveTenant} />
      <OpenRoute
        exact
        path="/password-reset/:accountEmail?"
        component={ResetPassword}
      />
      <OpenRoute exact path="/new-password/:token" component={NewPassword} />
      <OpenRoute
        exact
        path="/notification/:userId"
        component={PublicUserNotificationSettings}
      />
      <AuthenticatedRoute
        exact
        path="/business/:businessId"
        component={Business}
      />
      <EmployeeRoute exact path="/product/:productId" component={Product} />
      <EmployeeRoute
        exact
        path="/sales-order/:salesOrderId"
        component={SalesOrder}
      />
      <EmployeeRoute
        exact
        path="/purchase-order/:purchaseOrderId"
        component={PurchaseOrder}
      />
      <EmployeeRoute exact path="/ai" component={AITabs} />
      <EmployeeRoute exact path="/businesses" component={Businesses} />
      <EmployeeRoute exact path="/files" component={Files} />
      <EmployeeRoute exact path="/tasks" component={TaskTabs} />
      <EmployeeRoute exact path="/clients" component={Clients} />
      <EmployeeRoute exact path="/settings" component={Settings} />
      <EmployeeRoute exact path="/notifications" component={Notifications} />
      <EmployeeRoute exact path="/employees" component={Employees} />
      <EmployeeRoute exact path="/deals" component={Deals} />
      <AuthenticatedRoute exact path="/deal/:dealId" component={Deal} />
      <EmployeeRoute exact path="/cargo" component={CargoTabs} />
      <EmployeeRoute
        exact
        path="/cargo-provider/:cargoProviderId"
        component={CargoProvider}
      />
      <EmployeeRoute exact path="/products" component={ProductTabs} />
      <EmployeeRoute
        exact
        path="/employee/:employeeId"
        component={EmployeeDetail}
      />
      <EmployeeRoute exact path="/billing" component={Billing} />
      <EmployeeRoute exact path="/locations" component={Locations} />
      <OpenRoute
        exact
        path="/confirm-email/:confirmationToken/:resetPasswordToken"
        component={ConfirmEmail}
      />
      <OpenRoute
        exact
        path="/confirm-email/:confirmationToken/:resetPasswordToken"
        component={ConfirmEmail}
      />

      <OpenRoute exact path="/new-password/:token" component={NewPassword} />
      <OpenRoute path="/not-found" component={NotFound} />
      <OpenRoute path="*" component={NotFound} />
    </Switch>
  )
}

export default Routes
