import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'
import { InfoCircle, Folder } from 'react-bootstrap-icons'

const EditTwilioConnector = (props) => {
  const { showModal, toggleModal, twilio } = props

  const [submitting, setSubmitting] = useState(false)
  const [update] = useMutation(
    gql`
      mutation UpdateTwilioConnector(
        $updateTwilioInput: UpdateTwilioConnectorInput!
      ) {
        updateTwilioConnector(input: $updateTwilioInput) {
          twilioConnector {
            customValid
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (data.updateTwilioConnector.twilioConnector.customValid) {
          toast.success('Valid Twilio Credentials')
        } else {
          toast.error('Invalid Twilio Credentials')
        }
        toggleModal()
      },
      refetchQueries: ['TwilioConnector'],
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (showModal && twilio) {
      formik.setValues({
        customAccountSid: twilio.customAccountSid,
        customAuthToken: twilio.customAuthToken,
        customSender: twilio.customSender,
      })
    }
  }, [showModal, twilio])

  const formik = useFormik({
    initialValues: {
      customAccountSid: '',
      customAuthToken: '',
      customSender: '',
    },
    validationSchema: Yup.object().shape({
      customAccountSid: Yup.string().nullable(),
      customAuthToken: Yup.string().nullable(),
      customSender: Yup.string().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      update({
        variables: {
          updateTwilioInput: {
            twilioConnectorInput: {
              customAccountSid: values.customAccountSid,
              customAuthToken: values.customAuthToken,
              customSender: values.customSender,
            },
          },
        },
      })
    },
  })

  return (
    <>
      <div className="newSessionResitReasonModal">
        <Modal
          size="lg"
          show={showModal}
          onHide={() => toggleModal()}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/twilio-logo.png'}
                  style={{ height: '25px', marginTop: '-5px' }}
                  alt="Organization logo"
                />
                Configure Twilio
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Account ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="customAccountSid"
                    value={formik.values.customAccountSid}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.customAccountSid}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.customAccountSid}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Auth Token
                </Form.Label>
                <Col>
                  <Form.Control
                    type="password"
                    name="customAuthToken"
                    value={formik.values.customAuthToken}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.customAuthToken}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.customAuthToken}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Sender
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className="text-align-left">
                        <p>
                          Sender must be formatted with a plus sign +17655373896
                        </p>
                      </Tooltip>
                    }
                  >
                    <InfoCircle className="ml-2" style={{ fontSize: '14px' }} />
                  </OverlayTrigger>
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="customSender"
                    value={formik.values.customSender}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.customSender}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.customSender}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row>
                <Col md={3}>
                  <Button
                    disabled={submitting}
                    type="submit"
                    block
                    size="sm"
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <Folder className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>

              {submitting && <Loading message="Validating Credentials..." />}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditTwilioConnector
