import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const LocationSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    businessId,
    searchDescription,
    setAdditionalFields,
    error,
  } = props
  const variables = {}
  if (businessId) {
    variables.business = businessId
  }
  return (
    <SearchInput
      formik={formik}
      nodeName="location"
      error={error}
      searchDescription={searchDescription ? searchDescription : 'locations'}
      nodeNamePlural="locations"
      formikValue={formikValue}
      disabled={disabled}
      setAdditionalFields={setAdditionalFields}
      gql={gql`
        query Locations(
          $first: Int
          $after: String
          $search: String
          $business: ID
        ) {
          locations(
            first: $first
            after: $after
            orderBy: "name"
            fullAddress_Icontains: $search
            business: $business
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                fullAddress
                name
                salesTax
                addressLineOne
                billingLocation
                addressLineTwo
                city
                state
                zipCode
                latitude
                mapDefault
                longitude
                contentType {
                  model
                  id
                }
                business {
                  id
                  name
                }
              }
            }
          }
        }
      `}
      variables={variables}
      formatDescription={(node) => {
        return node.fullAddress
      }}
    />
  )
}

export default LocationSearchInput
