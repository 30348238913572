import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import Report from '../../Report'
import DateFilter from '../../common/DateFilter'
import { useLazyQuery, gql } from '@apollo/client'
import Loading from '../../common/Loading'
import { Funnel } from 'react-bootstrap-icons'

const InvoiceChartModal = (props) => {
  const {
    showModal,
    toggleModal,
    stripeCustomerId,
    organizationId,
    subjectGroupId,
    productId,
    jobId,
    userId,
  } = props

  const [chartSeries, setChartSeries] = useState([])
  const [totalRevenue, setTotalRevenue] = useState()
  const [averageRevenue, setAverageRevenue] = useState()
  const [chartCategories, setChartCategories] = useState([])
  const [invoiceCount, setInvoiceCount] = useState()
  const [startDateFilter, setStartDateFilter] = useState()
  const [stripeInvoices, setStripeInvoices] = useState([])
  const [revenueType, setRevenueType] = useState('Total Revenue')
  const [fetchedAllStripeInvoices, setFetchedAllStripeInvoices] =
    useState(false)
  const [endDateFilter, setEndDateFilter] = useState()
  const [filterPaid, setFilterPaid] = useState(false)
  const [filterVoid, setFilterVoid] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }
    return dates
  }

  const [query, { data: stripeInvoiceData, loading }] = useLazyQuery(
    gql`
      query StripeInvoices(
        $cursor: String
        $stripeCustomerId: ID
        $startDateGte: DateTime
        $startDateLte: DateTime
        $paid: Boolean
        $void: Boolean
        $status: String
      ) {
        stripeInvoices(
          first: 250
          after: $cursor
          paid: $paid
          void: $void
          status: $status
          stripeCustomer: $stripeCustomerId
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              created
              price
              voided
              paidManually
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const queryVariables = () => {
    const variables = {
      startDateGte: startDateFilter,
      startDateLte: endDateFilter,
    }
    if (stripeCustomerId) {
      variables.stripeCustomerId = stripeCustomerId
    }
    if (userId) {
      variables.userId = userId
    }
    if (filterOpen) {
      variables.status = 'Open'
    }
    if (filterPaid) {
      variables.paid = true
    }
    if (filterVoid) {
      variables.void = true
    }
    return variables
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      query({ variables: queryVariables() })
    }
    setFetchedAllStripeInvoices(false)
    setChartSeries([])
    setStripeInvoices([])
    setChartCategories([])
    setInvoiceCount()
  }, [startDateFilter, endDateFilter, filterOpen, filterPaid, filterVoid])

  useEffect(() => {
    if (stripeInvoiceData?.stripeInvoices) {
      setStripeInvoices((prevState) => [
        ...prevState,
        ...stripeInvoiceData.stripeInvoices.edges,
      ])
      if (stripeInvoiceData.stripeInvoices.pageInfo.hasNextPage) {
        const variables = queryVariables()
        variables.cursor = stripeInvoiceData.stripeInvoices.pageInfo.endCursor
        query({ variables })
      } else {
        setFetchedAllStripeInvoices(true)
      }
    }
  }, [stripeInvoiceData])

  useEffect(() => {
    if (fetchedAllStripeInvoices) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const invoices = stripeInvoices.map((edge) => ({
        amountDue: edge.node.price,
        date: new Date(edge.node.created).toISOString().split('T')[0],
      }))
      const aggregatedData = invoices.reduce((acc, { date, amountDue }) => {
        acc[date] = (acc[date] || 0) + amountDue
        return acc
      }, {})
      setChartCategories(dateRange)
      let totalRevenue_ = invoices
        .reduce((acc, invoice) => acc + invoice.amountDue, 0)
        .toFixed(2)
      setTotalRevenue(totalRevenue_)
      setAverageRevenue(
        invoices.length > 0
          ? (parseFloat(totalRevenue_) / invoices.length).toFixed(2)
          : '0.00'
      )
      setInvoiceCount(invoices.length)
      const calculateSeriesData = () => {
        if (revenueType === 'Daily Revenue') {
          return dateRange.map((date) => aggregatedData[date] || 0)
        } else {
          let runningTotal = 0
          return dateRange.map((date) => {
            runningTotal += aggregatedData[date] || 0
            return runningTotal
          })
        }
      }

      setChartSeries(() => {
        const seriesData = calculateSeriesData()
        return [{ name: revenueType, data: seriesData }]
      })
    }
  }, [fetchedAllStripeInvoices, startDateFilter, endDateFilter, revenueType])

  const innerToggle = () => {
    setChartCategories([])
    setChartSeries([])
    toggleModal()
  }

  return (
    <>
      <div className="invoiceModal">
        <Modal size={'xl'} show={showModal} onHide={innerToggle}>
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Invoice Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col md={5}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="invoices from"
                  placeholderEnd={'invoices to'}
                />
              </Col>
              <Col className="mt-2" md={3}>
                <Form.Control
                  as="select"
                  className="form-control-sm"
                  value={revenueType}
                  onChange={(e) => setRevenueType(e.target.value)}
                >
                  <option value="Daily Revenue">Daily Revenue</option>
                  <option value="Total Revenue">Total Revenue</option>
                </Form.Control>
              </Col>
            </Row>
            {startDateFilter && endDateFilter && (
              <Row className="mb-4">
                <Col>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterPaid(!filterPaid)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterPaid ? <>Paid</> : <>All</>}
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterOpen(!filterOpen)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterOpen ? <>Open</> : <>All</>}
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterVoid(!filterVoid)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterVoid ? <>Void</> : <>All</>}
                    </span>
                  </Button>
                </Col>
              </Row>
            )}
            {startDateFilter && endDateFilter && !fetchedAllStripeInvoices && (
              <Row>
                <Col>
                  <Loading message="Loading Invoice Chart..." />
                </Col>
              </Row>
            )}
            {fetchedAllStripeInvoices && (
              <>
                <div className="statistics-summary">
                  <Row className="mb-4 justify-content-md-center">
                    <Col md={4} className="statistic">
                      <h5>Total Revenue</h5>
                      <p>${totalRevenue}</p>
                    </Col>
                    <Col md={4} className="statistic">
                      <h5>Average Invoice</h5>
                      <p>${averageRevenue}</p>
                    </Col>
                    <Col md={4} className="statistic">
                      <h5>Number of Invoices</h5>
                      <p>{invoiceCount}</p>
                    </Col>
                  </Row>
                </div>
                {chartSeries.length > 0 && (
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Report
                        series={chartSeries}
                        categories={chartCategories}
                        yAxisFormatter={(value) => {
                          if (value < 0.01 && value > -0.01) {
                            return '$0'
                          }
                          return `$${value}`
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default InvoiceChartModal
