import React from 'react'
import { Modal } from 'react-bootstrap'
import TaskForm from './TaskForm'
import { Buildings } from 'react-bootstrap-icons'

const TaskModal = (props) => {
  const { showModal, toggleModal, task, dealId } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size={task ? 'lg' : 'md'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Buildings className="mr-2" />
              {task ? <>Edit Task</> : <>New Task</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TaskForm task={task} afterSubmit={toggleModal} dealId={dealId} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default TaskModal
