import React from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import validator from 'validator'
import toast from 'react-hot-toast'
import { useMutation, gql } from '@apollo/client'
import { FileEarmarkPerson } from 'react-bootstrap-icons'

const SendConfirmationEmailModal = (props) => {
  const { showModal, toggleModal } = props
  const [sendConfirmationEmail] = useMutation(
    gql`
      mutation SendConfirmationEmailMutation(
        $input: SendUserConfirmationEmailInput!
      ) {
        sendUserConfirmationEmail(input: $input) {
          confirmationEmailSent
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        toast.success(`Verification Email Sent If Account Exists`)
        toggleModal()
      },
    }
  )
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .min(3, 'Email is too short')
        .max(100, 'Email is too long')
        .required('Email is required')
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      sendConfirmationEmail({
        variables: {
          input: {
            email: values.email,
          },
        },
      })
    },
  })
  const innerToggle = () => {
    formik.resetForm()
    toggleModal()
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="sendConfirmationEmailModal">
        <Modal
          size="md"
          show={showModal}
          onHide={innerToggle}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                Resend Email Verification
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="mb-3">
                <Col>
                  Enter your accounts email address to receive an email
                  verification link
                </Col>
              </Row>
              <Form.Group as={Row}>
                <Form.Label className="ml-1">Email</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row>
                <Col sm={4}>
                  <Button type="submit" variant="outline-primary" block>
                    <FileEarmarkPerson className="mr-2" />
                    Send
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default SendConfirmationEmailModal
