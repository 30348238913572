import React from 'react'
import { Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'

const PERMISSIONS_DATA = [
  {
    0: 'Schedule ',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Book Session Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'Read Only',
  },
  {
    0: 'Job Form',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Edit Sessions Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Subject Group List',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Subject Group Details',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Send Notifications Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Organization List',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Organization Details',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Send Notifications and Add Notes',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Subject List',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Full Access',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Subject Details',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Add, Edit and Send Notifications',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Sessions List',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Full Access',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Employee List',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Employee Details',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Session Packages',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Equipment',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'None',
    'Equipment Manager': 'Full Access',
    'General Staff': 'None',
  },
  {
    0: 'Notifications',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'None',
    'Equipment Manager': 'None',
    'General Staff': 'None',
  },
  {
    0: 'Coupons',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Stripe Dashboard',
    Administrator: 'Full Access',
    'Scheduling Manager': 'None',
    'Scheduling Analyst': 'None',
    'Equipment Manager': 'None',
    'General Staff': 'None',
  },
  {
    0: 'Integration',
    Administrator: 'Full Access',
    'Scheduling Manager': 'None',
    'Scheduling Analyst': 'None',
    'Equipment Manager': 'None',
    'General Staff': 'None',
  },
  {
    0: 'Locations',
    Administrator: 'Full Access',
    'Scheduling Manager': 'None',
    'Scheduling Analyst': 'None',
    'Equipment Manager': 'None',
    'General Staff': 'None',
  },
  {
    0: 'Cancellation Policy',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Read Only',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'No Show Policy',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Read Only',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Resit Reasons',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Read Only',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
  {
    0: 'Tasks',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'None',
    'Equipment Manager': 'None',
    'General Staff': 'None',
  },
  {
    0: 'Files',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Full Access',
    'Equipment Manager': 'None',
    'General Staff': 'None',
  },
  {
    0: 'Payments',
    Administrator: 'Full Access',
    'Scheduling Manager': 'Full Access',
    'Scheduling Analyst': 'Full Access',
    'Equipment Manager': 'Read Only',
    'General Staff': 'None',
  },
]
function PermissionTable() {
  return (
    <>
      <Table bordered responsive size="sm">
        {/* <thead> */}
        {/* <tr>
            <th>Feature</th>
            <th>Administrator</th>
            <th>Scheduling Manager</th>
            <th>Scheduling Analyst</th>
            <th>Equipment Manager</th>
            <th>General Staff</th>
          </tr>
        </thead>
        <tbody>
          {PERMISSIONS_DATA.map((p, key) => {
            return (
              <tr key={key}>
                <td>{p['0']}</td>
                <td>{p.Administrator}</td>
                <td>{p['Scheduling Manager']}</td>
                <td>{p['Scheduling Analyst']}</td>
                <td>{p['Equipment Manager']}</td>
                <td>{p['General Staff']}</td>
              </tr>
            )
          })} */}
        {/* </tbody> */}
      </Table>
    </>
  )
}

export default PermissionTable
