import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const BusinessSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    buyers,
    suppliers,
    searchDescription,
    setAdditionalFields,
    error,
  } = props
  const variables = {}
  if (buyers) {
    variables.buyers = true
  }
  if (suppliers) {
    variables.suppliers = true
  }
  return (
    <SearchInput
      formik={formik}
      nodeName="business"
      searchDescription={searchDescription ? searchDescription : 'businesses'}
      nodeNamePlural="businesses"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      gql={gql`
        query Businesses(
          $first: Int
          $after: String
          $search: String
          $buyers: Boolean
          $suppliers: Boolean
        ) {
          businesses(
            first: $first
            after: $after
            orderBy: "name"
            name_Icontains: $search
            buyers: $buyers
            suppliers: $suppliers
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                buyer {
                  id
                  effectiveProductVariantBusinessPrices {
                    productVariant {
                      id
                    }
                    price
                    secondaryPrice
                  }
                }
                supplier {
                  id
                  effectiveProductVariantBusinessPrices {
                    productVariant {
                      id
                    }
                    price
                    secondaryPrice
                  }
                }
              }
            }
          }
        }
      `}
      variables={variables}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default BusinessSearchInput
