import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import APIKeys from './APIKeys'

const APIKeysModal = (props) => {
  const { showModal, toggleModal } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="apiKeyModal">
        <Modal size={'xl'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/api.png'}
                style={{ height: '30px' }}
                alt="Organization logo"
              />
              Configure ScrapGo API Keys
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <APIKeys />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default APIKeysModal
