import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Col, Tab } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  InfoCircle,
  Truck,
  CashCoin,
  Bell,
  GeoAlt,
  GraphUpArrow,
  People,
  ClockHistory,
  CardChecklist,
  FileEarmarkText,
  Coin,
} from 'react-bootstrap-icons'
import StatusCircle from '../common/StatusCircle'
import Notifications from '../notification/Notifications'
import Loading from '../common/Loading'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import BusinessForm from './BusinessForm'
import Deals from '../deal/Deals'
import Fulfillments from '../cargo/fulfilment/Fulfillments'
import Locations from '../location/Locations'
import Billing from '../billing/Billing'
import Clients from '../client/Clients'
import TaskTabs from '../task/TaskTabs'
import ProductPrices from '../product/product_prices/ProductPrices'
import Files from '../files/Files'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Business = () => {
  const { businessId } = useParams()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('Details')

  const { error, data } = useQuery(
    gql`
      query Business($id: ID!) {
        business(id: $id) {
          id
          name
          status
          contentType {
            model
            id
          }
          deadWeightThreshold
          deadWeightUnitOfMeasure {
            id
            pluralName
          }
          invoiceEmail
          syncingNotifications
          supplier {
            id
            netTerms
          }
          buyer {
            id
            netTerms
          }
          contentType {
            model
            id
          }
          salespeople {
            edges {
              node {
                id
                user {
                  fullName
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
      variables: {
        id: businessId,
      },
    }
  )

  useEffect(() => {
    if (
      loggedInUser &&
      (loggedInUser.permissions.isBuyer ||
        loggedInUser.permissions.isSupplier) &&
      loggedInUser.client.business.id != businessId
    ) {
      history.push(`/business/${loggedInUser.client.business.id}`)
    }
  }, [loggedInUser])

  if (error) return <>Error loading business</>
  if (!data || !loggedInUser)
    return (
      <span className="mt-5">
        <Loading message="Loading Business..." />
      </span>
    )
  const { business } = data
  const isEmployee = loggedInUser.permissions.isEmployee
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              {business.name}
            </h1>
            {business.syncingNotifications && (
              <span style={{ marginTop: '5px' }}>
                <StatusCircle message="Syncing Notifications" />
              </span>
            )}
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Details"
          eventKey="Details"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <InfoCircle className="mr-2" />
              Details
            </b>
          }
        >
          <Row>
            <Col md={6}>
              <BusinessForm business={business} hideAuditLog />
            </Col>
          </Row>
        </Tab>
        <Tab
          key="Deals"
          eventKey="Deals"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <GraphUpArrow className="mr-2" />
              Deals
            </b>
          }
        >
          <Deals businessId={businessId} />
        </Tab>
        <Tab
          key="Fulfillment"
          eventKey="Fulfillment"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Truck className="mr-2" />
              Fulfillment
            </b>
          }
        >
          <Fulfillments businessId={businessId} />
        </Tab>
        {isEmployee && (
          <Tab
            key="Tasks"
            eventKey="Tasks"
            style={{ marginTop: '10px' }}
            title={
              <b>
                <CardChecklist className="mr-2" />
                Tasks
              </b>
            }
          >
            <TaskTabs businessId={businessId} />
          </Tab>
        )}

        <Tab
          key="Locations"
          eventKey="Locations"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <GeoAlt className="mr-2" />
              Locations
            </b>
          }
        >
          <Locations businessId={businessId} />
        </Tab>
        <Tab
          key="Clients"
          eventKey="Clients"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <People className="mr-2" />
              Clients
            </b>
          }
        >
          <Clients businessId={businessId} />
        </Tab>
        <Tab
          key="Product Prices"
          eventKey="Product Prices"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Coin className="mr-2" />
              Product Prices
            </b>
          }
        >
          <ProductPrices
            businessId={businessId}
            supplierBusiness={business.supplier ? true : false}
            buyerBusiness={business.buyer ? true : false}
          />
        </Tab>
        <Tab
          key="Files"
          eventKey="Files"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <FileEarmarkText className="mr-2" />
              Files
            </b>
          }
        >
          <Files businessId={businessId} businessDescription={business.name} />
        </Tab>
        <Tab
          key="Billing"
          eventKey="Billing"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CashCoin className="mr-2" />
              Billing
            </b>
          }
        >
          <Billing businessId={businessId} />
        </Tab>
        <Tab
          key="Notifications"
          eventKey="Notifications"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Bell className="mr-2" />
              Notifications
            </b>
          }
        >
          <Notifications business={business} />
        </Tab>
        {isEmployee && (
          <Tab
            key="History"
            eventKey="History"
            style={{ marginTop: '10px' }}
            title={
              <b>
                <ClockHistory className="mr-2" />
                History
              </b>
            }
          >
            <AuditLog
              contentType={business.contentType.model}
              id={business.id}
              topRowMargin
            />
          </Tab>
        )}
      </Tabs>
    </>
  )
}
export default Business
