import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import {
  CashStack,
  CloudArrowDown,
  BarChartLine,
  Trash,
  Funnel,
} from 'react-bootstrap-icons'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import CreateInvoiceModal from './CreateInvoiceModal'
import Loading from '../../common/Loading'
import DateFilter from '../../common/DateFilter'
import { loggedInUserVar } from '../../../libs/apollo'
import { useAWSS3 } from '../../../libs/aws'
import DeleteInvoicesModal from './DeleteInvoicesModal'
import InvoiceChartModal from './InvoiceChartModal'
import InvoiceModal from './InvoiceModal'

const Invoices = (props) => {
  const { stripeCustomerId, businessId, tableHeight, cachePolicy, salesOrder } =
    props
  const awsS3 = useAWSS3()
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [viewInvoice, setViewInvoice] = useState()
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [showChartModal, setShowChartModal] = useState(false)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [showDeleteInvoicesModal, setShowDeleteInvoicesModel] = useState(false)
  const [checkedInvoiceIds, setCheckedInvoiceIds] = useState([])
  const [filterPaid, setFilterPaid] = useState(false)
  const [filterPaymentFailed, setFilterPaymentFailed] = useState(false)
  const [filterVoid, setFilterVoid] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser.permissions.group
  )
  const handleInvoiceCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedInvoiceIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedInvoiceIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const [query, { error, data, fetchMore: queryFetchMore }] = useLazyQuery(
    gql`
      query StripeInvoices(
        $cursor: String
        $status: String
        $void: Boolean
        $business: String
        $paid: Boolean
        $paymentFailed: Boolean
        $searchTerm: String
        $stripeCustomerId: ID
        $startDateGte: DateTime
        $startDateLte: DateTime
      ) {
        stripeInvoices(
          first: 20
          after: $cursor
          paid: $paid
          void: $void
          status: $status
          business: $business
          paymentFailed: $paymentFailed
          stripeCustomer: $stripeCustomerId
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          search_Icontains: $searchTerm
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              created
              paymentFailed
              paidManually
              paidOn
              price
              status
              recipientEmail
              amountRemaining
              amountPaid
              amountDue
              effectiveAt
              dueDate
              salesOrder {
                number
                buyer {
                  business {
                    name
                  }
                }
              }
              stripeInvoiceId
              invoicePdfUrl
              invoicePaymentUrl
              stripePaymentIntents {
                edges {
                  node {
                    processing
                  }
                }
              }
              paidManuallyBy
            }
          }
        }
      }
    `,
    {
      fetchPolicy: cachePolicy ? cachePolicy : 'network-only',
      errorPolicy: 'all',
      pollInterval: 5000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: data?.stripeInvoices?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (stripeCustomerId) {
      variables.stripeCustomerId = stripeCustomerId
    }
    if (filterOpen) {
      variables.status = 'Open'
    }
    if (filterPaid) {
      variables.paid = true
    }
    if (filterPaymentFailed) {
      variables.paymentFailed = true
    }
    if (filterVoid) {
      variables.void = true
    }
    if (businessId) {
      variables.business = businessId
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      setInitialQueryRun(true)
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterOpen, filterPaid, filterVoid, filterPaymentFailed])

  useEffect(() => {
    if (data?.stripeInvoices?.edges) {
      let text = 'Search 0 records'
      const current = data.stripeInvoices.edges.map((stripeInvoice) => ({
        node: stripeInvoice.node,
      }))
      setInvoices(current)
      if (data.stripeInvoices.nodeCount > 0) {
        text = `Search ${data.stripeInvoices.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [data])

  const fetchMore = () => {
    const variables = queryVariables()
    queryFetchMore({
      variables,
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      variables.startDateGte = startDateFilter
      variables.startDateLte = endDateFilter
      query({ variables })
    }
  }, [startDateFilter, endDateFilter])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    variables.searchTerm = currentSearchTerm
    query({ variables })
  }

  const toggleModal = (stripeInvoiceId = null) => {
    if (stripeInvoiceId) {
      setViewInvoice(stripeInvoiceId)
    } else {
      setViewInvoice()
    }
  }

  let tableColumns = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => {
        return row.node.recordId
      },
    },
    {
      Header: 'Invoice #',
      id: 'number',
      accessor: (row) => {
        return row.node.stripeInvoiceId
      },
    },
    {
      Header: 'Sales Order',
      id: 'salesOrder',
      accessor: (row) => {
        return `#${row.node.salesOrder.number}`
      },
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => {
        if (row.node.paidManually) {
          return <span style={{ color: 'green' }}>Paid Manually</span>
        } else if (row.node.paymentFailed) {
          return <span style={{ color: 'red' }}>Payment Failed</span>
        } else if (
          row.node.stripePaymentIntents.edges.length > 0 &&
          row.node.stripePaymentIntents.edges[0].node.processing
        ) {
          return <span style={{ color: 'red' }}>Processing Payment</span>
        }
        const status = row.node.status
        let color
        if (['Open', 'Uncollectible'].includes(status)) {
          color = 'red'
        } else if (status === 'Void') {
          color = 'orange'
        } else if (status === 'Paid') {
          color = 'green'
        }
        return <span style={{ color }}>{status}</span>
      },
    },
    {
      Header: 'Emailed To',
      id: 'emailedTo',
      accessor: (row) => {
        return row.node.recipientEmail
      },
    },
    {
      Header: 'Amount Due',
      id: 'due',
      accessor: (row) => {
        return `$${(row.node.amountDue / 100).toFixed(2)}`
      },
    },
    {
      Header: 'Amount Paid',
      id: 'paid',
      accessor: (row) => {
        if (row.node.paidManually) {
          return `$${(row.node.amountDue / 100).toFixed(2)}`
        } else {
          return `$${(row.node.amountPaid / 100).toFixed(2)}`
        }
      },
    },
    {
      Header: 'Amount Remaining',
      id: 'remaining',
      accessor: (row) => {
        if (row.node.paidManually) {
          return '$0.00'
        }
        return `$${(row.node.amountRemaining / 100).toFixed(2)}`
      },
    },
    {
      Header: 'Sent On',
      id: 'sentOn',
      accessor: (row) => {
        return DateTime.fromISO(row.node.effectiveAt).toFormat('MMMM dd, yyyy')
      },
    },
    {
      Header: 'Due On',
      id: 'dueOn',
      accessor: (row) => {
        return DateTime.fromISO(row.node.dueDate).toFormat('MMMM dd, yyyy')
      },
    },
    {
      Header: 'Paid On',
      id: 'paidOn',
      accessor: (row) => {
        if (row.node.paidOn) {
          return DateTime.fromISO(row.node.paidOn).toFormat('MMMM dd, yyyy')
        }
      },
    },
    {
      Header: 'Download',
      id: 'download',
      accessor: (row) => {
        return (
          <Button variant="link">
            <a href={row.node.invoicePdfUrl} download>
              <CloudArrowDown />
            </a>
          </Button>
        )
      },
    },
    {
      Header: 'Open Stripe',
      id: 'openStripe',
      accessor: (row) => {
        return (
          <Button
            variant="link"
            onClick={() => {
              window.open(row.node.invoicePaymentUrl, '_blank')
            }}
          >
            <CashStack />
          </Button>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  invoices.forEach((invoice) => {
                    if (!checkedInvoiceIds.includes(invoice.node.id)) {
                      appendIds.push(invoice.node.id)
                    }
                  })
                  setCheckedInvoiceIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedInvoiceIds([])
                }
              }}
            />
            {checkedInvoiceIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedInvoiceIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'downloads',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedInvoiceIds.includes(row.node.id)}
                onChange={(e) => handleInvoiceCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  if (!businessId) {
    tableColumns.splice(2, 0, {
      Header: 'Business',
      id: 'business',
      accessor: (row) => {
        return row.node.salesOrder.buyer.business.name
      },
    })
  }

  const getS3Object = async (Key, fileName, postDownload = null) => {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          let blob = new Blob([data.Body], { type: data.ContentType })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          if (postDownload) {
            postDownload()
          }
        }
      }
    )
  }

  const [deleteFile] = useMutation(
    gql`
      mutation DeleteFile($deleteFileInput: DeleteFileInput!) {
        deleteFile(input: $deleteFileInput) {
          deleted
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const [downloadInvoice] = useMutation(
    gql`
      mutation DownloadStripeInvoices($input: DownloadStripeInvoicesInput!) {
        downloadStripeInvoices(input: $input) {
          file {
            id
            fileName
            displayName
            contentType
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        getS3Object(
          data.downloadStripeInvoices.file.fileName,
          data.downloadStripeInvoices.file.displayName,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
            deleteFile({
              variables: {
                deleteFileInput: {
                  fileIds: data.downloadStripeInvoices.file.id,
                },
              },
            })
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  if (!initialQueryRun || !awsS3?.client) return <></>
  if (error) return <>Error loading</>
  return (
    <>
      <div>
        {showChartModal && (
          <InvoiceChartModal
            showModal={showChartModal}
            startDateTime={startDateFilter}
            stripeCustomerId={stripeCustomerId}
            endDataTime={endDateFilter}
            toggleModal={() => {
              setShowChartModal()
            }}
          />
        )}
        <Row>
          <Col>
            {canMutate && (
              <Button
                variant="link"
                onClick={() => {
                  setShowInvoiceModal(true)
                }}
              >
                <CashStack className="mr-2" />
                Send Invoice
              </Button>
            )}
            {canMutate && (
              <Button
                variant="link"
                onClick={() => {
                  setShowChartModal(true)
                }}
              >
                <BarChartLine className="mr-2" />
                Report
              </Button>
            )}
            <Button
              variant="link"
              onClick={() => {
                setFilterPaid(!filterPaid)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterPaid ? <>Paid</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterPaymentFailed(!filterPaymentFailed)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterPaymentFailed ? <>Payment Failed</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterOpen(!filterOpen)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterOpen ? <>Open</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterVoid(!filterVoid)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterVoid ? <>Void</> : <>All</>}
              </span>
            </Button>
            {startDateFilter && endDateFilter && (
              <>
                <Button
                  variant="link"
                  disabled={downloadingPdf}
                  onClick={() => {
                    setDownloadingPdf(true)
                    downloadInvoice({
                      variables: {
                        input: {
                          fileType: 'pdf',
                          startDate: startDateFilter,
                          endDate: endDateFilter,
                          stripeCustomerId,
                          paid: filterPaid,
                          void: filterVoid,
                          open: filterOpen,
                          paymentFailed: filterPaymentFailed,
                        },
                      },
                    })
                  }}
                >
                  <CloudArrowDown className="mr-2" />
                  Download PDF
                </Button>
                <Button
                  variant="link"
                  disabled={downloadingExcel}
                  onClick={() => {
                    setDownloadingExcel(true)
                    downloadInvoice({
                      variables: {
                        input: {
                          fileType: 'xlsx',
                          startDate: startDateFilter,
                          endDate: endDateFilter,
                          stripeCustomerId,
                          paid: filterPaid,
                          void: filterVoid,
                          open: filterOpen,
                          paymentFailed: filterPaymentFailed,
                        },
                      },
                    })
                  }}
                >
                  <CloudArrowDown className="mr-2" />
                  Download Excel
                </Button>
              </>
            )}
            {checkedInvoiceIds.length > 0 && (
              <>
                {canMutate && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowDeleteInvoicesModel(true)
                    }}
                  >
                    <Trash className="mr-2" />
                    Delete Invoices
                  </Button>
                )}
              </>
            )}
          </Col>
        </Row>
        <Row className="mt-2 d-flex">
          <Col md={startDateFilter && endDateFilter ? 3 : 4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col md={4} style={{ marginTop: '-8px' }}>
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="invoices from"
              placeholderEnd={'invoices to'}
            />
          </Col>
        </Row>

        {!data && <Loading message="Loading Invoices..." />}
        {data && (
          <>
            <Row className="mt-3 mb-3">
              <Col md={12}>
                <SortableInfiniteTable
                  tableData={invoices}
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMore}
                  loadingMessage="Loading Invoices..."
                  hasMoreTableData={data?.stripeInvoices?.pageInfo?.hasNextPage}
                  onTdClicks={{
                    id: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    business: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    salesOrder: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    number: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    paidManually: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    status: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    emailedTo: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    product: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    due: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    paid: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    remaining: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    sentOn: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    dueOn: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                    organization: (row) => {
                      toggleModal(row.row.original.node.id)
                    },
                  }}
                  tableHeight={tableHeight ? tableHeight : 700}
                  rowPointer
                  hideGlobalFilter
                />
              </Col>
            </Row>
          </>
        )}
        <CreateInvoiceModal
          toggleModal={setShowInvoiceModal}
          showModal={showInvoiceModal}
          salesOrder={salesOrder}
        />
        <InvoiceModal
          showModal={viewInvoice}
          toggleModal={toggleModal}
          stripeInvoiceId={viewInvoice}
        />
        <DeleteInvoicesModal
          showModal={showDeleteInvoicesModal}
          toggleModal={() => {
            setShowDeleteInvoicesModel(false)
          }}
          setCheckedInvoiceIds={setCheckedInvoiceIds}
          stripeInvoiceIds={checkedInvoiceIds}
        />
      </div>
    </>
  )
}
export default Invoices
