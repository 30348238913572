import React, { useState, useEffect } from 'react'
import { Form, Table, Alert, Col, Row } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import Loading from '../Loading'
import { XCircle, Trash } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'

const SearchInput = (props) => {
  const {
    formik,
    nodeNamePlural,
    nodeName,
    disabled,
    searchDescription,
    gql,
    formatDescription,
    multiple,
    formikValue,
    variables,
    setAdditionalFields,
    dropdown,
    fetchPolicy,
    error,
  } = props

  const [displayResults, setDisplayResults] = useState(false)

  const [query, { data, fetchMore, error: queryError }] = useLazyQuery(gql, {
    fetchPolicy: fetchPolicy ? fetchPolicy : 'network-only',
    variables,
  })

  const formikValueFromFieldName = (path) => {
    const parts = path.replace(/\[(\w+)\]/g, '.$1').split('.')
    let current = formik.values
    for (let part of parts) {
      if (current[part] === undefined) {
        return undefined
      }
      current = current[part]
    }
    return current ? current : ''
  }

  useEffect(() => {
    if (dropdown) {
      query()
    }
  }, [dropdown])

  if (queryError) {
    return <Alert variant="danger">Error Loading</Alert>
  }

  let _formikIdValue
  let _formikDescriptionValue
  let _formikMultipleValue
  if (formikValue) {
    _formikIdValue = `${formikValue}Id`
    _formikDescriptionValue = `${formikValue}Description`
    _formikMultipleValue = `${formikValue}s`
  } else {
    _formikDescriptionValue = `${nodeName}Description`
    _formikIdValue = `${nodeName}Id`
    _formikMultipleValue = nodeNamePlural
  }

  return (
    <>
      <Row className="align-items-center" style={{ position: 'relative' }}>
        {dropdown && data && data[nodeNamePlural] && (
          <Col>
            <Form.Control
              name={_formikIdValue}
              as="select"
              disabled={disabled}
              className="form-control-sm"
              value={formikValueFromFieldName(_formikIdValue)}
              isInvalid={error}
              onChange={(e) => {
                if (e.target.value) {
                  formik.setFieldValue(_formikIdValue, e.target.value)
                  if (setAdditionalFields) {
                    setAdditionalFields(
                      data[nodeNamePlural].edges.filter(
                        (edge) => edge.node.id === e.target.value
                      )[0].node,
                      _formikIdValue
                    )
                  }
                } else {
                  formik.setFieldValue(_formikIdValue, '')
                  if (setAdditionalFields) {
                    setAdditionalFields(null, _formikIdValue)
                  }
                }
                formik.setErrors({})
              }}
            >
              <option value="">- - -</option>
              {data[nodeNamePlural].edges.map((edge) => (
                <option key={edge.node.id} value={edge.node.id}>
                  {formatDescription(edge.node)}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Col>
        )}
        {!dropdown && (
          <>
            <Col>
              <Form.Control
                placeholder={`search ${searchDescription}`}
                value={formikValueFromFieldName(_formikDescriptionValue)}
                isInvalid={error}
                onBlur={() => {
                  setDisplayResults(false)
                  formik.setFieldTouched(_formikIdValue, true)
                }}
                onChange={(e) => {
                  setDisplayResults(true)
                  formik.setFieldValue(_formikDescriptionValue, e.target.value)
                  query({
                    variables: {
                      ...variables,
                      ...{
                        first: 10,
                        search: e.target.value,
                      },
                    },
                  })
                }}
                disabled={disabled}
                readOnly={Boolean(formikValueFromFieldName(_formikIdValue))}
                className={'form-control-sm'}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Col>
            {!disabled && formikValueFromFieldName(_formikIdValue) && (
              <Col xs="auto">
                <button
                  type="button"
                  className="p-0 mr-1 text-danger btn-link"
                  onClick={() => {
                    formik.setFieldValue(_formikDescriptionValue, '')
                    formik.setFieldValue(_formikIdValue, '')
                    formik.setErrors({})
                  }}
                >
                  <XCircle />
                </button>
              </Col>
            )}
          </>
        )}
      </Row>
      {data && displayResults && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 3000,
            width: '95%',
            top: '100%',
          }}
        >
          <InfiniteScroll
            height={100}
            dataLength={data[nodeNamePlural].edges.length}
            next={() => {
              fetchMore({
                variables: {
                  ...variables,
                  ...{
                    after: data[nodeNamePlural].pageInfo.cursor,
                    first: 10,
                    search: formik.values[_formikDescriptionValue],
                  },
                },
              })
            }}
            hasMore={data[nodeNamePlural].pageInfo.hasNextPage}
            loader={<Loading />}
          >
            <Table size="sm" hover>
              <tbody>
                {data[nodeNamePlural].edges.map((edge) => {
                  const { node } = edge
                  return (
                    <tr
                      onMouseDown={() => {
                        if (multiple) {
                          setDisplayResults(false)
                          formik.setFieldValue(_formikDescriptionValue, '')
                          if (setAdditionalFields) {
                            setAdditionalFields(node, _formikMultipleValue)
                          }
                          formik.setFieldValue(
                            _formikMultipleValue,
                            [
                              {
                                description: formatDescription(node),
                                id: node.id,
                              },
                            ].concat(formik.values[_formikMultipleValue])
                          )
                        } else {
                          setDisplayResults(false)
                          formik.setFieldValue(
                            _formikDescriptionValue,
                            formatDescription(node)
                          )
                          formik.setFieldValue(_formikIdValue, node.id)
                          if (setAdditionalFields) {
                            setAdditionalFields(node, _formikIdValue)
                          }
                        }
                        formik.setErrors({})
                      }}
                      key={node.id}
                      className="hover text-decoration-none"
                    >
                      <td>
                        <small>{formatDescription(node)}</small>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      )}
      {multiple &&
        formikValueFromFieldName(_formikMultipleValue).length > 0 && (
          <div className="mt-2">
            {formikValueFromFieldName(_formikMultipleValue).map((node) => {
              return (
                <div
                  style={{ fontSize: '14px', cursor: 'pointer' }}
                  className="ml-2 badge badge-secondary"
                  key={node.id}
                >
                  {!disabled && (
                    <Trash
                      className="mr-2"
                      onClick={() => {
                        const updatedNodes = []
                        formik.values[_formikMultipleValue].forEach(
                          (currentNode) => {
                            if (currentNode.id !== node.id) {
                              updatedNodes.push(currentNode)
                            }
                          }
                        )
                        formik.setFieldValue(_formikMultipleValue, updatedNodes)
                        formik.setErrors({})
                      }}
                    />
                  )}
                  {node.description}
                </div>
              )
            })}
          </div>
        )}
    </>
  )
}

export default SearchInput
