import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import './EmailNotificationTemplateBuilder.css'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { CaretDown, CaretRight } from 'react-bootstrap-icons'

const SMSNotificationTemplateBuilder = (props) => {
  const { notificationTemplate, formik } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser.permissions.group)
  const [display, setDisplay] = useState(false)
  useEffect(() => {
    if (notificationTemplate) {
      let smsContent = ''
      if (notificationTemplate.smsContent) {
        smsContent = notificationTemplate.smsContent
      }
      formik.setFieldValue('smsContent', smsContent)
    }
  }, [notificationTemplate])
  if (!notificationTemplate) return <></>
  return (
    <div className="smsNotificationTemplateBuilder mb-3">
      <Row className="mt-3 mb-2">
        <Col className="d-flex align-items-center">
          <button
            type="button"
            onClick={() => setDisplay(!display)}
            className="px-0 btn-link mr-1"
            style={{ marginTop: '-10px' }}
          >
            <>{display ? <CaretDown size={20} /> : <CaretRight size={20} />}</>
          </button>
          <h5>SMS Template</h5>
        </Col>
      </Row>
      <div style={display ? {} : { display: 'none' }}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Control
                as="textarea"
                className="border border-dark"
                rows={12}
                name="smsContent"
                value={formik.values.smsContent}
                onChange={formik.handleChange}
                isInvalid={formik.errors.smsContent}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.smsContent}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SMSNotificationTemplateBuilder
