import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  CaretDown,
  CaretRight,
  Trash,
  Folder,
  ArrowUpRightCircle,
} from 'react-bootstrap-icons'
import DatePicker from 'react-datepicker'
import AuditLog from '../../audit_log/AuditLog'
import UnitOfMeasureSearchInput from '../../common/node_search_input/UnitOfMeasureSearchInput'
import CargoUnitSearchInput from '../../common/node_search_input/CargoUnitSearchInput'
import Loading from '../../common/Loading'
import DealSearchInput from '../../common/node_search_input/DealSearchInput'
import CargoProviderSearchInput from '../../common/node_search_input/CargoProviderSearchInput'
import LocationModal from '../../location/LocationModal'
import { toTitleCase } from '../../../libs/utils'
import Map from '../../location/Map'
import NotificationTable from '../../notification/NotificationsTable'
import Files from '../../files/Files'

const FulfillmentForm = (props) => {
  const {
    fulfillment,
    afterSubmit,
    dealId,
    cargoProviderId,
    cargoProviderDescription,
  } = props
  const [submitting, setSubmitting] = useState(false)
  const [viewLocation, setViewLocation] = useState()
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayMap, setDisplayMap] = useState(false)
  const [displayNotifications, setDisplayNotifications] = useState(false)
  const [displayFiles, setDisplayFiles] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const history = useHistory()
  const STATUS = [
    { value: 'NOT_READY_FOR_PICKUP', description: 'Not Ready for Pickup' },
    { value: 'READY_FOR_PICKUP', description: 'Ready for Pickup' },
    { value: 'CARGO_BOOKED', description: 'Cargo Booked' },
    { value: 'PICKED_UP', description: 'Picked Up' },
    { value: 'DELIVERED', description: 'Delivered' },
  ]
  const [getDeal] = useLazyQuery(
    gql`
      query Deal($id: ID!) {
        deal(id: $id) {
          id
          buyer {
            business {
              id
            }
          }
          dealItems {
            edges {
              node {
                id
                deliveryLocation {
                  id
                  fullAddress
                }
                supplierQuantity
                supplierUnitOfMeasure {
                  shortName
                  id
                  pluralName
                }
                buyerQuantity
                buyerUnitOfMeasure {
                  shortName
                  id
                  pluralName
                }
                pickupLocation {
                  id
                  fullAddress
                }
                productVariant {
                  product {
                    name
                  }
                }
                supplier {
                  business {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: dealId,
      },
      onCompleted: (data) => {
        const deal = data.deal
        formik.setFieldValue('dealId', deal.id)
        setAdditionalDealFields(deal)
      },
    }
  )

  const [deleteCargoUnitInstance] = useMutation(
    gql`
      mutation DeleteFulfillment($input: DeleteFulfillmentInput!) {
        deleteFulfillment(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Fulfillment Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['CargoUnitInstanceContainerItems'],
    }
  )

  const [createCargoUnitInstance] = useMutation(
    gql`
      mutation CreateCargoUnitInstance($input: CreateCargoUnitInstanceInput!) {
        createCargoUnitInstance(input: $input) {
          cargoUnitInstance {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Fulfillment Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['CargoUnitInstanceContainerItems'],
    }
  )

  const [updateCargoUnitInstance] = useMutation(
    gql`
      mutation UpdateCargoUnitInstance($input: UpdateCargoUnitInstanceInput!) {
        updateCargoUnitInstance(input: $input) {
          cargoUnitInstance {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Fulfillment Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['CargoUnitInstanceContainerItems'],
    }
  )
  const cargoUnitInstance =
    fulfillment && fulfillment.cargoUnitInstanceContainer.cargoUnitInstance

  const formik = useFormik({
    initialValues: fulfillment
      ? {
          dealId: fulfillment.dealItem.deal.id,
          dealItemId: fulfillment.dealItem.id,
          dealDescription: `${fulfillment.dealItem.deal.dealNumber} | Supplier ${fulfillment.dealItem.supplier.business.name} | Buyer ${fulfillment.dealItem.deal.buyer.business.name}`,
          cargoProviderId: cargoUnitInstance.cargoProvider?.id,
          cargoProviderDescription: cargoUnitInstance.cargoProvider?.name,
          price: cargoUnitInstance.price,
          additionalFees: cargoUnitInstance.additionalFees,
          quickPayFee: cargoUnitInstance.quickPayFee,
          loadId: cargoUnitInstance.loadId,
          status: cargoUnitInstance.status,
          buyerBusinessId: null,
          supplierBusinessId: null,
          pickupDate: fulfillment.pickupDate
            ? new Date(fulfillment.pickupDate)
            : new Date(),
          deliveryDate: fulfillment.deliveryDate
            ? new Date(fulfillment.deliveryDate)
            : new Date(),
          shipperLoadId: cargoUnitInstance.shipperLoadId,
          billOfLading: cargoUnitInstance.billOfLading,
          deliveryLocationId: cargoUnitInstance.deliveryLocation.id,
          pickupLocationId: cargoUnitInstance.pickupLocation.id,
          deliveryLocation: cargoUnitInstance.deliveryLocation,
          deliveryLocationDescription:
            cargoUnitInstance.deliveryLocation.fullAddress,
          pickupLocation: cargoUnitInstance.pickupLocation,
          pickupLocationDescription:
            cargoUnitInstance.pickupLocation.fullAddress,
          deadWeightThreshold: cargoUnitInstance.deadWeightThreshold,
          deadWeightUnitOfMeasureDescription:
            cargoUnitInstance.deadWeightUnitOfMeasure &&
            cargoUnitInstance.deadWeightUnitOfMeasure.shortName,
          deadWeightUnitOfMeasureId:
            cargoUnitInstance.deadWeightUnitOfMeasure &&
            cargoUnitInstance.deadWeightUnitOfMeasure.id,
          deliveryUnitOfMeasureId: fulfillment.deliveryUnitOfMeasure?.id,
          deliveryUnitOfMeasureDescription:
            fulfillment.deliveryUnitOfMeasure?.shortName,
          deliveryQuantity: fulfillment.deliveryQuantity,
          pickupUnitOfMeasureId: fulfillment.pickupUnitOfMeasure?.id,
          pickupUnitOfMeasureDescription:
            fulfillment.pickupUnitOfMeasure?.shortName,
          pickupQuantity: fulfillment.pickupQuantity,
          cargoUnitId: cargoUnitInstance.cargoUnit?.id,
          cargoUnitDescription: cargoUnitInstance.cargoUnit?.singularName,
          dealItems: [],
        }
      : {
          price: null,
          additionalFees: null,
          pickupDate: new Date(),
          deliveryDate: new Date(),
          quickPayFee: null,
          loadId: null,
          dealItemId: null,
          buyerBusinessId: null,
          supplierBusinessId: null,
          shipperLoadId: null,
          billOfLading: null,
          deadWeightThreshold: null,
          deadWeightUnitOfMeasureDescription: null,
          deadWeightUnitOfMeasureId: null,
          cargoProviderDescription: null,
          cargoProviderId: cargoProviderId || null,
          status: null,
          deliveryUnitOfMeasureId: null,
          deliveryUnitOfMeasureDescription: null,
          deliveryQuantity: null,
          pickupUnitOfMeasureId: null,
          pickupUnitOfMeasureDescription: null,
          pickupQuantity: null,
          cargoUnitId: null,
          cargoUnitDescription: null,
          dealItems: [],
        },
    validationSchema: Yup.object().shape({
      price: Yup.number().nullable(),
      additionalFees: Yup.number().nullable(),
      pickupDate: Yup.date().nullable(),
      deliveryDate: Yup.date().nullable(),
      quickPayFee: Yup.number().nullable(),
      loadId: Yup.string().nullable(),
      dealItemId: Yup.string().nullable(),
      buyerBusinessId: Yup.string().nullable(),
      supplierBusinessId: Yup.string().nullable(),
      shipperLoadId: Yup.string().nullable(),
      billOfLading: Yup.string().nullable(),
      deadWeightThreshold: Yup.number().nullable(),
      deadWeightUnitOfMeasureDescription: Yup.string().nullable(),
      deadWeightUnitOfMeasureId: Yup.string().nullable(),
      cargoProviderDescription: Yup.string().nullable(),
      deliveryLocationId: Yup.string().nullable('Required'),
      pickupLocationId: Yup.string().required('Required'),
      cargoProviderId: Yup.string().nullable(),
      status: Yup.mixed().required('Required'),
      deliveryUnitOfMeasureId: Yup.string().nullable(),
      deliveryUnitOfMeasureDescription: Yup.string().nullable(),
      deliveryQuantity: Yup.number().nullable(),
      pickupUnitOfMeasureId: Yup.string().nullable(),
      pickupUnitOfMeasureDescription: Yup.string().nullable(),
      pickupQuantity: Yup.number().nullable(),
      cargoUnitId: Yup.string().nullable(),
      cargoUnitDescription: Yup.string().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const variables = {
        status: values.status,
        price: values.price,
        deliveryQuantity: values.deliveryQuantity,
        deliveryUnitOfMeasureId: values.deliveryUnitOfMeasureId,
        pickupQuantity: values.pickupQuantity,
        pickupUnitOfMeasureId: values.pickupUnitOfMeasureId,
        additionalFees: values.additionalFees,
        quickPayFee: values.quickPayFee,
        loadId: values.loadId,
        shipperLoadId: values.shipperLoadId,
        dealItemId: values.dealItemId,
        pickupDate: values.pickupDate,
        deliveryDate: values.deliveryDate,
        billOfLading: values.billOfLading,
        deadWeightThreshold: values.deadWeightThreshold,
        deadWeightUnitOfMeasureId: values.deadWeightUnitOfMeasureId,
        cargoProviderId: values.cargoProviderId,
        cargoUnitId: values.cargoUnitId,
      }
      if (fulfillment) {
        variables.id = cargoUnitInstance.id
        updateCargoUnitInstance({
          variables: {
            input: {
              cargoUnitInstanceInput: variables,
            },
          },
        })
      } else {
        createCargoUnitInstance({
          variables: {
            input: {
              cargoUnitInstanceInput: variables,
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (dealId) {
      getDeal()
    }
  }, [dealId])

  const setAdditionalDealFields = (node, _) => {
    if (node.buyer) {
      formik.setFieldValue('buyerBusinessId', node.buyer.business.id)
    } else {
      formik.setFieldError('buyerBusinessId', 'Deal Has No Buyer')
    }
    if (node.pickupLocation) {
      formik.setFieldValue('pickupLocationId', node.pickupLocation.id)
      formik.setFieldValue(
        'pickupLocationDescription',
        node.pickupLocation.fullAddress
      )
    } else {
      formik.setFieldError('pickupLocationId', 'Deal Has No Pickup Location')
    }
    if (node.supplier) {
      formik.setFieldValue('supplierBusinessId', node.supplier.business.id)
    } else {
      formik.setFieldError('supplierBusinessId', 'Deal Has No Supplier')
    }
    if (node.deliveryLocation) {
      formik.setFieldValue('deliveryLocationId', node.deliveryLocation.id)
      formik.setFieldValue(
        'deliveryLocationDescription',
        node.deliveryLocation.fullAddress
      )
    } else {
      formik.setFieldError(
        'deliveryLocationId',
        'Deal Has No Delivery Location'
      )
    }
    formik.setFieldValue(
      'dealItems',
      node.dealItems.edges.map((edge) => edge.node)
    )
  }

  let contentTypesRelayIds
  if (fulfillment) {
    contentTypesRelayIds = {
      [fulfillment.contentType.model]: [fulfillment.id],
      [fulfillment.cargoUnitInstanceContainer.cargoUnitInstance.contentType
        .model]: [fulfillment.cargoUnitInstanceContainer.cargoUnitInstance.id],
    }
  }

  return (
    <>
      <LocationModal
        showModal={viewLocation}
        locationId={viewLocation?.id}
        toggleModal={() => setViewLocation()}
      />
      <Form onSubmit={formik.handleSubmit}>
        {!dealId && (
          <Form.Group as={Row}>
            <Col sm="12" md={12}>
              <Form.Label>
                Deal
                {formik.values.dealId && (
                  <span
                    className="ml-2 btn-link p-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push(`/deal/${formik.values.dealId}`)
                    }}
                  >
                    <ArrowUpRightCircle />
                  </span>
                )}
              </Form.Label>
              <DealSearchInput
                formik={formik}
                setAdditionalFields={setAdditionalDealFields}
                disabled={fulfillment ? true : false}
                fetchPolicy={'no-cache'}
              />
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Deal Product</Form.Label>
            {fulfillment && (
              <Form.Control
                as="input"
                className="form-control-sm"
                value={`${
                  fulfillment.dealItem.productVariant.product.name
                } | Supplier ${Math.round(
                  fulfillment.dealItem.supplierQuantity
                )} ${
                  fulfillment.dealItem.supplierUnitOfMeasure.shortName
                } | Buyer ${Math.round(fulfillment.dealItem.buyerQuantity)} ${
                  fulfillment.dealItem.buyerUnitOfMeasure.shortName
                }`}
                disabled={true}
              />
            )}
            {!fulfillment && (
              <Form.Control
                name="dealItemId"
                as="select"
                className="form-control-sm"
                value={formik.values.dealItemId}
                disabled={!formik.values.dealId}
                onChange={(e) => {
                  formik.setFieldValue('dealItemId', e.target.value)
                  const dealItem = formik.values.dealItems.find(
                    (item) => item.id === e.target.value
                  )
                  if (dealItem) {
                    if (dealItem?.pickupLocation) {
                      formik.setFieldValue(
                        'pickupLocation',
                        dealItem.pickupLocation
                      )
                      formik.setFieldValue(
                        'pickupLocationId',
                        dealItem.pickupLocation.id
                      )
                      formik.setFieldValue(
                        'pickupLocationDescription',
                        dealItem.pickupLocation.fullAddress
                      )
                    }
                    if (dealItem.supplierQuantity) {
                      formik.setFieldValue(
                        'pickupQuantity',
                        dealItem.supplierQuantity
                      )
                    }
                    if (dealItem.buyerQuantity) {
                      formik.setFieldValue(
                        'deliveryQuantity',
                        dealItem.supplierQuantity
                      )
                    }
                    if (dealItem.supplierUnitOfMeasure) {
                      formik.setFieldValue(
                        'pickupUnitOfMeasureId',
                        dealItem.supplierUnitOfMeasure.id
                      )
                      formik.setFieldValue(
                        'pickupUnitOfMeasureDescription',
                        toTitleCase(dealItem.supplierUnitOfMeasure.pluralName)
                      )
                    }
                    if (dealItem.buyerUnitOfMeasure) {
                      formik.setFieldValue(
                        'deliveryUnitOfMeasureId',
                        dealItem.buyerUnitOfMeasure.id
                      )
                      formik.setFieldValue(
                        'deliveryUnitOfMeasureDescription',
                        toTitleCase(dealItem.buyerUnitOfMeasure.pluralName)
                      )
                    }
                    if (dealItem?.pickupLocation) {
                      formik.setFieldValue(
                        'deliveryLocation',
                        dealItem.deliveryLocation
                      )
                      formik.setFieldValue(
                        'deliveryLocationId',
                        dealItem.deliveryLocation.id
                      )
                      formik.setFieldValue(
                        'deliveryLocationDescription',
                        dealItem.deliveryLocation.fullAddress
                      )
                    }
                  } else {
                    formik.setFieldValue('deliveryLocation', '')
                    formik.setFieldValue('deliveryLocationDescription', '')
                    formik.setFieldValue('pickupLocation', '')
                    formik.setFieldValue('pickupLocationDescription', '')
                  }
                }}
              >
                <option value="">- - -</option>
                {formik.values.dealItems.length > 0 &&
                  formik.values.dealItems.map((option) => {
                    let description = option.productVariant.product.name
                    if (
                      option.supplierQuantity &&
                      option.supplierUnitOfMeasure
                    ) {
                      description = `${description} | Supplier ${Math.round(
                        option.supplierQuantity
                      )}/${option.supplierUnitOfMeasure.shortName}`
                    }
                    if (option.buyerQuantity && option.buyerUnitOfMeasure) {
                      description = `${description} | Buyer ${Math.round(
                        option.buyerQuantity
                      )}/${option.buyerUnitOfMeasure.shortName}`
                    }
                    return (
                      <option key={option.id} value={option.id}>
                        {description}
                      </option>
                    )
                  })}
              </Form.Control>
            )}
          </Col>
        </Form.Group>
        {!cargoProviderId && (
          <Form.Group as={Row}>
            <Col sm="12" md={12}>
              <Form.Label>
                Cargo Provider
                {formik.values.cargoProviderId && (
                  <span
                    className="ml-2 btn-link p-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push(
                        `/cargo-provider/${formik.values.cargoProviderId}`
                      )
                    }}
                  >
                    <ArrowUpRightCircle />
                  </span>
                )}
              </Form.Label>
              <CargoProviderSearchInput formik={formik} />
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row}>
          <Col sm="12" md={12}>
            <Form.Label>Cargo</Form.Label>
            <CargoUnitSearchInput formik={formik} />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Status</Form.Label>
            <Form.Control
              name="status"
              as="select"
              className="form-control-sm"
              value={formik.values.status}
              onChange={formik?.handleChange}
              isInvalid={formik?.errors?.status}
            >
              <option value="- - -">- - -</option>
              {STATUS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik?.errors?.status}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md={6}>
            <Form.Label>
              Pickup Location
              {formik.values.pickupLocation && (
                <span
                  className="ml-2 btn-link p-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setViewLocation(formik.values.pickupLocation)
                  }}
                >
                  <ArrowUpRightCircle />
                </span>
              )}
            </Form.Label>
            <Form.Control
              as="input"
              name="pickupLocationDescription"
              className="form-control-sm"
              value={formik.values.pickupLocationDescription}
              onChange={formik.handleChange}
              disabled={true}
              isInvalid={formik.errors.pickupLocationDescription}
            />
          </Col>
          <Col md={6}>
            <div>
              <Form.Label>Pickup Date</Form.Label>
            </div>
            <div>
              <DatePicker
                name="pickupDate"
                className="form-control-sm"
                showPopperArrow={false}
                popperPlacement="auto"
                selected={formik.values.pickupDate}
                onChange={(date) => formik.setFieldValue('pickupDate', date)}
                popperModifiers={{
                  flip: {
                    behavior: ['bottom'],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
            </div>
            {formik.errors.pickupDate && (
              <div>
                <small className="text-danger">
                  {formik.errors.pickupDate}
                </small>
              </div>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md={6}>
            <Form.Label>
              Delivery Location
              {formik.values.deliveryLocation && (
                <span
                  className="ml-2 btn-link p-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setViewLocation(formik.values.deliveryLocation)
                  }}
                >
                  <ArrowUpRightCircle />
                </span>
              )}
            </Form.Label>
            <Form.Control
              as="input"
              name="deliveryLocationDescription"
              className="form-control-sm"
              value={formik.values.deliveryLocationDescription}
              onChange={formik.handleChange}
              disabled={true}
              isInvalid={formik.errors.deliveryLocationDescription}
            />
          </Col>
          <Col md={6}>
            <div>
              <Form.Label>Delivery Date</Form.Label>
            </div>
            <div>
              <DatePicker
                name="deliveryDate"
                className="form-control-sm"
                showPopperArrow={false}
                popperPlacement="auto"
                selected={formik.values.deliveryDate}
                onChange={(date) => formik.setFieldValue('deliveryDate', date)}
                popperModifiers={{
                  flip: {
                    behavior: ['bottom'],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
            </div>
            {formik.errors.deliveryDate && (
              <div>
                <small className="text-danger">
                  {formik.errors.deliveryDate}
                </small>
              </div>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md={4}>
            <Form.Label>Cargo Price</Form.Label>
            <Form.Control
              as="input"
              type="number"
              min={0}
              className="form-control-sm"
              max={100000}
              step={0.01}
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              isInvalid={formik.errors.price}
            />
          </Col>
          <Col md={4}>
            <Form.Label>Additional Fees</Form.Label>
            <Form.Control
              as="input"
              name="additionalFees"
              type="number"
              min={0}
              className="form-control-sm"
              max={100000}
              step={0.01}
              value={formik.values.additionalFees}
              onChange={formik.handleChange}
              isInvalid={formik.errors.additionalFees}
            />
          </Col>
          <Col md={4}>
            <Form.Label>Quick Pay Fee</Form.Label>
            <Form.Control
              as="input"
              name="quickPayFee"
              type="number"
              min={0}
              className="form-control-sm"
              max={100000}
              step={0.01}
              value={formik.values.quickPayFee}
              onChange={formik.handleChange}
              isInvalid={formik.errors.quickPayFee}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md={4}>
            <Form.Label>Load ID</Form.Label>
            <Form.Control
              as="input"
              name="loadId"
              className="form-control-sm"
              value={formik.values.loadId}
              onChange={formik.handleChange}
              isInvalid={formik.errors.loadId}
            />
          </Col>
          <Col md={4}>
            <Form.Label>Shipper Load ID</Form.Label>
            <Form.Control
              as="input"
              name="shipperLoadId"
              className="form-control-sm"
              value={formik.values.shipperLoadId}
              onChange={formik.handleChange}
              isInvalid={formik.errors.shipperLoadId}
            />
          </Col>
          <Col md={4}>
            <Form.Label>Bill of Lading</Form.Label>
            <Form.Control
              as="input"
              name="billOfLading"
              className="form-control-sm"
              value={formik.values.billOfLading}
              onChange={formik.handleChange}
              isInvalid={formik.errors.billOfLading}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Pickup Quantity</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name="pickupQuantity"
              className="form-control-sm"
              step=".001"
              min="0"
              value={formik.values.pickupQuantity}
              onChange={formik.handleChange}
              isInvalid={formik.errors.pickupQuantity}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.pickupQuantity}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>Pickup Unit</Form.Label>
            <UnitOfMeasureSearchInput
              formik={formik}
              formikValue={'pickupUnitOfMeasure'}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Delivery Quantity</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name="deliveryQuantity"
              className="form-control-sm"
              step=".001"
              min="0"
              value={formik.values.deliveryQuantity}
              onChange={formik.handleChange}
              isInvalid={formik.errors.deliveryQuantity}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.deliveryQuantity}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>Delivery Unit</Form.Label>
            <UnitOfMeasureSearchInput
              formik={formik}
              formikValue={'deliveryUnitOfMeasure'}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Dead Weight Threshold</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name="deadWeightThreshold"
              className="form-control-sm"
              step=".001"
              min="0"
              value={formik.values.deadWeightThreshold}
              onChange={formik.handleChange}
              isInvalid={formik.errors.deadWeightThreshold}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.deadWeightThreshold}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>Dead Weight Unit</Form.Label>
            <UnitOfMeasureSearchInput
              formik={formik}
              formikValue={'deadWeightUnitOfMeasure'}
            />
          </Col>
        </Form.Group>
        {fulfillment && (
          <>
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayFiles(!displayFiles)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayFiles ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">Files</Form.Label>
              </Col>
            </Row>
            {displayFiles && (
              <Row className="mt-3">
                <Col>
                  <Files
                    tableHeight={200}
                    fulfillmentId={fulfillment.id}
                    fulfillmentDescription={`Load ${fulfillment.cargoUnitInstanceContainer.cargoUnitInstance.loadId} | Deal ${fulfillment.cargoUnitInstanceContainer.cargoUnitInstance.deal?.dealNumber}`}
                  />
                </Col>
              </Row>
            )}
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayNotifications(!displayNotifications)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayNotifications ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">Notifications</Form.Label>
              </Col>
            </Row>
            {displayNotifications && (
              <Row className="mt-3">
                <Col>
                  <NotificationTable />
                </Col>
              </Row>
            )}
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayMap(!displayMap)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayAuditLog ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">Map</Form.Label>
              </Col>
            </Row>
            {displayMap && (
              <Row className="mt-3">
                <Col>
                  <Map
                    location={cargoUnitInstance.pickupLocation}
                    locationTwo={cargoUnitInstance.deliveryLocation}
                    locationModal
                    locationTwoModal
                    directions
                  />
                </Col>
              </Row>
            )}
            <Row className="mt-3 mb-4">
              <Col>
                <button
                  type="button"
                  onClick={() => setDisplayAuditLog(!displayAuditLog)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayAuditLog ? (
                      <>
                        <CaretDown size={17} />
                      </>
                    ) : (
                      <>
                        <CaretRight size={17} />
                      </>
                    )}
                  </>
                </button>
                <Form.Label className="mb-0">History</Form.Label>
              </Col>
            </Row>
            {displayAuditLog && (
              <Row className="mt-3">
                <Col>
                  <AuditLog contentTypesRelayIds={contentTypesRelayIds} />
                </Col>
              </Row>
            )}
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {fulfillment && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deleteCargoUnitInstance({
                      variables: {
                        input: {
                          ids: fulfillment.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-2">
              <Loading
                message={
                  deleting ? 'Deleting Fulfillment...' : 'Saving Fulfillment...'
                }
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default FulfillmentForm
