import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './GlobalSearch.css'
import LocationModal from '../location/LocationModal'
import Loading from '../common/Loading'

const GlobalSearch = () => {
  const [jobDetailId, setJobDetailId] = useState()
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [contactId, setContactId] = useState()
  const [location, setLocation] = useState()
  const [sessionId, setSessionId] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [showContactModal, setShowContactModal] = useState()
  const [showLocationModal, setShowLocationModal] = useState()
  const [showSessionDetailModal, setShowSessionDetailModal] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [hasMoreSearchFilters, setHasMoreSearchFilters] = useState(true)
  const [jobsCursor, setJobsCursor] = useState()
  const [locationsCursor, setLocationsCursor] = useState()
  const [contactsCursor, setContactsCursor] = useState()
  const [employeesCursor, setEmployeesCursor] = useState()
  const [subjectGroupsCursor, setSubjectGroupsCursor] = useState()
  const [organizationsCursor, setOrganizationsCursor] = useState()
  const [subjectsCursor, setSubjectsCursor] = useState()
  const [sessionCursor, setSessionCursor] = useState()
  const history = useHistory()
  const tableColumns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
      },
      {
        Header: '',
        accessor: 'nodeType',
      },
    ],
    []
  )
  const [
    globalSearchQuery,
    {
      data: globalSearchQueryData,
      loading,
      fetchMore: globalSearchQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query GlobalSearchQuery(
        $jobsCursor: String
        $employeesCursor: String
        $contactsCursor: String
        $subjectGroupsCursor: String
        $organizationsCursor: String
        $locationsCursor: String
        $subjectsCursor: String
        $sessionCursor: String
        $searchTerm: String
      ) {
        jobs(first: 10, after: $jobsCursor, name_Icontains: $searchTerm) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
        locations(
          first: 10
          after: $locationsCursor
          fullAddress_Icontains: $searchTerm
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              fullAddress
              addressLineOne
              billingLocation
              addressLineTwo
              city
              state
              zipCode
              archived
              latitude
              mapDefault
              longitude
              studio
              contentType {
                model
                id
              }
              subject {
                id
                user {
                  firstName
                  lastName
                }
                organization {
                  name
                }
              }
              organization {
                id
                name
              }
            }
          }
        }
        employees(first: 10, after: $employeesCursor, search: $searchTerm) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              user {
                id
                fullName
              }
            }
          }
        }
        subjectGroups(
          first: 10
          after: $subjectGroupsCursor
          subjectGroupOrganizationName: $searchTerm
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        organizations(
          first: 10
          after: $organizationsCursor
          name_Icontains: $searchTerm
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
        subjects(first: 10, after: $subjectsCursor, search: $searchTerm) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              user {
                id
                fullName
              }
            }
          }
        }
        sessions(
          first: 10
          after: $sessionCursor
          search_Icontains: $searchTerm
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              subject {
                user {
                  id
                  fullName
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )
  useEffect(() => {
    if (globalSearchQueryData) {
      handleQueryResults(globalSearchQueryData)
    }
  }, [globalSearchQueryData])

  useEffect(() => {
    setSearchResults([])
    if (searchTerm.length > 0) {
      globalSearchQuery({
        variables: {
          searchTerm,
          jobsCursor,
          contactsCursor,
          employeesCursor,
          subjectGroupsCursor,
          subjectsCursor,
          locationsCursor,
          organizationsCursor,
          sessionCursor,
        },
      })
    }
  }, [searchTerm])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setEmployeesCursor()
    setJobsCursor()
    setLocationsCursor()
    setContactsCursor()
    setSubjectGroupsCursor()
    setSubjectsCursor()
    setOrganizationsCursor()
    setSessionCursor()
    setSearchTerm(currentSearchTerm)
    setDisplaySearchResults(true)
  }

  const fetchMoreSearchTerms = () => {
    globalSearchQueryFetchMore({
      variables: {
        searchTerm,
        jobsCursor,
        locationsCursor,
        contactsCursor,
        employeesCursor,
        subjectGroupsCursor,
        subjectsCursor,
        organizationsCursor,
        sessionCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        handleQueryResults(fetchMoreResult, true)
        return fetchMoreResult
      },
    })
  }

  const handleQueryResults = (queryResults, fromFetchMore) => {
    if (searchTerm.length === 0) {
      setSearchResults([])
    } else {
      const currentSearchResults = []
      if (queryResults.employees.pageInfo.endCursor) {
        setEmployeesCursor(queryResults.employees.pageInfo.endCursor)
      }
      if (queryResults.jobs.pageInfo.endCursor) {
        setJobsCursor(queryResults.jobs.pageInfo.endCursor)
      }
      if (queryResults.locations.pageInfo.endCursor) {
        setLocationsCursor(queryResults.locations.pageInfo.endCursor)
      }
      if (queryResults.users.pageInfo.endCursor) {
        setContactsCursor(queryResults.users.pageInfo.endCursor)
      }
      if (queryResults.subjectGroups.pageInfo.endCursor) {
        setSubjectGroupsCursor(queryResults.subjectGroups.pageInfo.endCursor)
      }
      if (queryResults.organizations.pageInfo.endCursor) {
        setOrganizationsCursor(queryResults.organizations.pageInfo.endCursor)
      }
      if (queryResults.subjects.pageInfo.endCursor) {
        setSubjectsCursor(queryResults.subjects.pageInfo.endCursor)
      }
      if (queryResults.sessions.pageInfo.endCursor) {
        setSessionCursor(queryResults.sessions.pageInfo.endCursor)
      }
      if (
        queryResults.employees.pageInfo.hasNextPage ||
        queryResults.jobs.pageInfo.hasNextPage ||
        queryResults.subjectGroups.pageInfo.hasNextPage ||
        queryResults.users.pageInfo.hasNextPage ||
        queryResults.organizations.pageInfo.hasNextPage ||
        queryResults.locations.pageInfo.hasNextPage ||
        queryResults.subjects.pageInfo.hasNextPage ||
        queryResults.sessions.pageInfo.hasNextPage
      ) {
        setHasMoreSearchFilters(true)
      } else {
        setHasMoreSearchFilters(false)
      }
      queryResults.employees.edges.forEach((employee) => {
        const employeeNode = employee.node
        currentSearchResults.push({
          nodeType: 'Employee',
          nodeId: employeeNode.id,
          name: employeeNode.user.fullName,
        })
      })
      queryResults.locations.edges.forEach((location) => {
        const locationNode = location.node
        currentSearchResults.push({
          nodeType: 'Location',
          nodeId: locationNode.id,
          node: locationNode,
          name: locationNode.name
            ? locationNode.name
            : locationNode.fullAddress,
        })
      })
      queryResults.jobs.edges.forEach((job) => {
        const jobNode = job.node
        currentSearchResults.push({
          nodeType: 'Job',
          nodeId: jobNode.id,
          name: jobNode.name,
        })
      })
      queryResults.users.edges.forEach((user) => {
        const userNode = user.node
        currentSearchResults.push({
          nodeType: 'Organization Contact',
          nodeId: userNode.id,
          name: userNode.fullName,
        })
      })
      queryResults.subjectGroups.edges.forEach((subjectGroup) => {
        const subjectGroupNode = subjectGroup.node
        currentSearchResults.push({
          nodeType: 'Subject Group',
          nodeId: subjectGroupNode.id,
          name: subjectGroupNode.name,
        })
      })
      queryResults.organizations.edges.forEach((organization) => {
        const organizationNode = organization.node
        currentSearchResults.push({
          nodeType: 'Organization',
          nodeId: organizationNode.id,
          name: organizationNode.name,
        })
      })
      queryResults.subjects.edges.forEach((subject) => {
        const subjectNode = subject.node
        if (subjectNode.user) {
          currentSearchResults.push({
            nodeType: 'Subject',
            nodeId: subjectNode.id,
            name: subjectNode.user.fullName,
          })
        }
      })
      queryResults.sessions.edges.forEach((session) => {
        const sessionNode = session.node
        if (sessionNode.subject && sessionNode.subject.user) {
          currentSearchResults.push({
            nodeType: 'Session',
            nodeId: sessionNode.id,
            name: sessionNode.subject.user.fullName,
          })
        }
      })
      setSearchResults((prevState) => [...prevState, ...currentSearchResults])
    }
  }
  const handleSearchResultClick = (searchResultRow) => {
    setSearchResults([])
    const { nodeType, nodeId, node } = searchResultRow.original
    if (nodeType === 'Employee') {
      history.push(`/employee/${nodeId}`)
    } else if (nodeType === 'Subject') {
      history.push(`/subject/${nodeId}`)
    } else if (nodeType === 'Subject Group') {
      history.push(`/subject-group/${nodeId}`)
    } else if (nodeType === 'Organization') {
      history.push(`/organization/${nodeId}`)
    } else if (nodeType === 'Job') {
      setJobDetailId(nodeId)
      toggleJobDetailModal()
    } else if (nodeType === 'Organization Contact') {
      setContactId(nodeId)
      toggleContactModal()
    } else if (nodeType === 'Location') {
      setLocation(node)
      toggleLocationModal()
    } else if (nodeType === 'Session') {
      setSessionId(nodeId)
      toggleSessionModal()
    }
  }

  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
    }
    setShowJobDetailModal(!showJobDetailModal)
  }

  const toggleContactModal = () => {
    if (showContactModal) {
      setContactId(null)
    }
    setShowContactModal(!showContactModal)
  }

  const toggleLocationModal = () => {
    if (showLocationModal) {
      setLocation(null)
    }
    setShowLocationModal(!showLocationModal)
  }

  const toggleSessionModal = () => {
    if (showSessionDetailModal) {
      setSessionId(null)
    }
    setShowSessionDetailModal(!showSessionDetailModal)
  }

  const handleControlBlur = () => {
    setSearchTerm('')
    setDisplaySearchResults(false)
  }

  return (
    <>
      <div>
        <Form.Group className="mb-0 pb-0" style={{ width: '350px' }}>
          <Form.Control
            type="text"
            name="searchTerm"
            placeholder="Search"
            value={searchTerm}
            className="form-control-sm"
            onBlur={handleControlBlur}
            onChange={handleSearchTermChange}
          />
          {loading && (
            <div id="globalSearchInfiniteTable" className="border">
              <Loading height={'25'} width={'25'} />
            </div>
          )}
        </Form.Group>
        {searchResults.length > 0 && displaySearchResults ? (
          <div id="globalSearchInfiniteTable" className="border">
            <SortableInfiniteTable
              hideGlobalFilter
              tdStyle={{ width: '50%' }}
              tableHeight={200}
              tableData={searchResults}
              tableColumns={tableColumns}
              displayHeader={false}
              fetchMoreTableData={fetchMoreSearchTerms}
              hasMoreTableData={hasMoreSearchFilters}
              onRowClick={handleSearchResultClick}
              rowPointer
            />
          </div>
        ) : null}
      </div>
      <LocationModal
        location={location}
        showModal={showLocationModal}
        toggleModal={toggleLocationModal}
      />
    </>
  )
}

export default GlobalSearch
