import React, { useMemo, useRef } from 'react';
import { ColumnDragCard } from './ColumnDragCard';
import { IconButton } from '../IconButton';
import './ColumnDragTargetArea.scss';
import { useLocale } from '../../locale/LocaleContext';
const TargetBox = ({ field, hasHeaders, flexBasis, touched, assignedColumn, dragState, eventBinder, onHover, onAssign, onUnassign }) => {
    // respond to hover events when there is active mouse drag happening
    // (not keyboard-emulated one)
    const containerRef = useRef(null);
    // if this field is the current highlighted drop target,
    // get the originating column data for display
    const sourceColumn = dragState && dragState.dropFieldName === field.name
        ? dragState.column
        : null;
    // see if currently assigned column is being dragged again
    const isReDragged = dragState ? dragState.column === assignedColumn : false;
    // drag start handlers for columns that can be re-dragged (i.e. are assigned)
    const dragStartHandlers = useMemo(() => assignedColumn && !isReDragged
        ? eventBinder(assignedColumn, field.name)
        : {}, [eventBinder, assignedColumn, isReDragged, field.name]);
    const valueContents = useMemo(() => {
        if (sourceColumn) {
            return (React.createElement(ColumnDragCard, { rowCount: 3, column: sourceColumn, isDropIndicator: true }));
        }
        if (assignedColumn) {
            return (React.createElement(ColumnDragCard, { rowCount: 3, column: assignedColumn, isShadow: isReDragged, isDraggable: !isReDragged }));
        }
        const hasError = touched && !field.isOptional;
        return (React.createElement(ColumnDragCard, { rowCount: 3, hasHeaders: hasHeaders, hasError: hasError }));
    }, [hasHeaders, field, touched, assignedColumn, sourceColumn, isReDragged]);
    const l10n = useLocale('fieldsStep');
    // @todo mouse cursor changes to reflect draggable state
    return (React.createElement("section", { className: "CSVImporter_ColumnDragTargetArea__box", "aria-label": field.isOptional
            ? l10n.getDragTargetOptionalCaption(field.label)
            : l10n.getDragTargetRequiredCaption(field.label), ref: containerRef, style: { flexBasis }, onPointerEnter: () => onHover(field.name, true), onPointerLeave: () => onHover(field.name, false) },
        React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxLabel", "aria-hidden": true },
            field.label,
            field.isOptional ? null : React.createElement("b", null, "*")),
        React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValue" },
            !sourceColumn && !assignedColumn && (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp", "aria-hidden": true }, l10n.dragTargetPlaceholder)),
            React.createElement("div", Object.assign({}, dragStartHandlers, { style: { touchAction: 'none' } }), valueContents),
            dragState && !dragState.pointerStartInfo ? (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValueAction" },
                React.createElement(IconButton, { label: l10n.getDragTargetAssignTooltip(dragState.column.code), small: true, type: "forward", onClick: () => onAssign(field.name) }))) : (!sourceColumn &&
                assignedColumn && (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValueAction" },
                React.createElement(IconButton, { label: l10n.dragTargetClearTooltip, small: true, type: "close", onClick: () => onUnassign(assignedColumn) })))))));
};
export const ColumnDragTargetArea = ({ hasHeaders, fields, columns, fieldRowSize, fieldTouched, fieldAssignments, dragState, eventBinder, onHover, onAssign, onUnassign }) => {
    const l10n = useLocale('fieldsStep');
    // override flex basis for unusual situations
    const flexBasis = fieldRowSize ? `${100 / fieldRowSize}%` : undefined;
    return (React.createElement("section", { className: "CSVImporter_ColumnDragTargetArea", "aria-label": l10n.dragTargetAreaCaption }, fields.map((field) => {
        const assignedColumnIndex = fieldAssignments[field.name];
        return (React.createElement(TargetBox, { key: field.name, field: field, flexBasis: flexBasis, touched: fieldTouched[field.name], hasHeaders: hasHeaders, assignedColumn: assignedColumnIndex !== undefined
                ? columns[assignedColumnIndex]
                : null, dragState: dragState, eventBinder: eventBinder, onHover: onHover, onAssign: onAssign, onUnassign: onUnassign }));
    })));
};
