import React, { useState } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useLazyQuery, gql } from '@apollo/client'
import { DNA } from 'react-loader-spinner'
import { Send } from 'react-bootstrap-icons'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
} from '@chatscope/chat-ui-kit-react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'

const ChatGPTModal = (props) => {
  const { showModal, toggleModal } = props
  const initalGPTState = [
    {
      role: 'system',
      content:
        'ChatGPT serves as an intelligent assistant for the photography studio, providing instant support with scheduling, photography tips, equipment queries, and customer service to ensure a seamless and delightful experience for all clients.',
    },
  ]
  const [chatMessages, setChatMessages] = useState(initalGPTState)
  const [question, setQuestion] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [openAiInteractionId, setOpenAiInteractionId] = useState()

  const [openAiChat] = useLazyQuery(
    gql`
      query OpenAIChat($messages: [String!], $id: ID) {
        openAiChatCompletion(messages: $messages, id: $id) {
          id
          chatMessages
          promptTokens
          completionTokens
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.openAiChatCompletion?.chatMessages) {
          setQuestion('')
          setChatMessages(JSON.parse(data.openAiChatCompletion.chatMessages))
          setOpenAiInteractionId(data.openAiChatCompletion.id)
        }
        setSubmitting(false)
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const handleAskChatGpt = () => {
    setSubmitting(true)
    openAiChat({
      variables: {
        messages: chatMessages.concat([{ role: 'user', content: question }]),
        id: openAiInteractionId,
      },
    })
  }

  const innerToggle = () => {
    toggleModal()
    setSubmitting(false)
    setChatMessages(initalGPTState)
    setOpenAiInteractionId()
    setQuestion('')
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="xl"
          show={showModal}
          onHide={innerToggle}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/openai.png'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
              ChatGPT
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {chatMessages.length > 1 && (
              <>
                <MainContainer>
                  <ChatContainer>
                    <MessageList>
                      {chatMessages.map((chatMessage, index) => {
                        if (index > 0) {
                          return (
                            <span key={index}>
                              <Message
                                model={{
                                  message: chatMessage.content,
                                  direction:
                                    chatMessage.role == 'assistant'
                                      ? 'incoming'
                                      : 'outgoing',
                                }}
                              />
                            </span>
                          )
                        }
                      })}
                    </MessageList>
                  </ChatContainer>
                </MainContainer>
              </>
            )}
            <Row className="mt-3">
              <Col className="mt-2" md={{ span: 10, offset: 1 }}>
                <Form.Group>
                  <Form.Control
                    type="textarea"
                    name="question"
                    value={question}
                    placeholder="Send a message"
                    onChange={(e) => {
                      setQuestion(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        handleAskChatGpt()
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={1}>
                <Button
                  disabled={submitting}
                  onClick={handleAskChatGpt}
                  type="submit"
                  size="sm"
                  variant="link"
                  className="mt-2"
                >
                  <Send className="mr-2" />
                </Button>
              </Col>
            </Row>
            {submitting && (
              <Row>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <DNA
                      visible={true}
                      height="100"
                      width="100"
                      ariaLabel="dna-loading"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ChatGPTModal
