import React, { useEffect, useRef } from 'react'
// import { h2 } from 'semantic-ui-react'
import { Button } from 'react-bootstrap'
import './Landing.css'
import plywood from './image/osb-plywood.gif'
import material from './image/materialXchange.png'
import digital from './image/digital-expand.webp'
import eye from './image/eye.png'
import match from './image/match.png'
import bid from './image/bid.png'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { settingsVar } from '../../libs/apollo'

const Landing = () => {
  const history = useHistory()
  const settings = useReactiveVar(settingsVar)

  const { innerWidth: width, innerHeight: height } = window

  function Section({ children }) {
    const ref = useRef(null)

    return (
      <section ref={ref}>
        <span
          style={{
            transition: '0.8s',
            display: 'flex',
          }}
        >
          {children}
        </span>
      </section>
    )
  }

  return (
    <div>
      <div className="container">
        <div className="main-section">
          <div className="welcome_section">
            <div className="welcome-left">
              <div className="welcome-big-text">
                <h4>WELCOME</h4>
                <h1>The B2B</h1>
                <h1>Automated</h1>
                <h1>Scrap Exchange.</h1>
              </div>
              <p>
                Instant quotes, automated logistics, no commission. ScrapGo is
                the future of how scrap products are bought and sold.
              </p>
              <div className="welcome-input-btn">
                <Button
                  style={{ padding: '0 2rem 0 2rem' }}
                  variant="outline-secondary"
                  onClick={() => history.push('/login')}
                  className="login-button"
                >
                  Login to {settings?.name}
                </Button>
              </div>
            </div>
            <div className="welcome-right">
              <img className="osb-plywood" src={plywood} alt="" />
              <div className="plywood_border"></div>
            </div>
          </div>

          <div className="material_bigimg">
            <img src={material} alt="" />
          </div>
          <div className="about-us">
            <h4>The Platform</h4>
            <h2>What is Scrapgo</h2>
            <p>
              Scrapgo is an easy to use, web-based platform where Buyers and
              Sellers list truckloads of the most common scrap products and
              negotiate pricing. When prices match, a transaction occurs
              instantly. ScrapGo transfers the funds electronically and arranges
              the truck or rail delivery.
            </p>
            <p>
              Scrapgo is real-time market prices at your fingertips which means
              less time spent “shopping”, no calling around, no waiting around,
              and no paperwork. ScrapGo offers efficient, equal access to the
              markets with just the click of a button.
            </p>
            <p>
              ScrapGo is scalable. Our technology can handle any product traded
              in a commoditized market environment and is built to grow
              back-office functionality in tandem.
            </p>
          </div>

          <div className="future-commodity">
            <h2>
              <span className="lt_gray">Step into the</span> future of scrap
            </h2>
            <p>
              The team at ScrapGo is modernizing the inefficient, call around
              structure of buying and selling scrap with our digital
              marketplace.
            </p>
            <div
              className="future-icons"
              style={{ flexDirection: width <= 950 ? 'column' : 'row' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: '0 1 33%',
                  padding: '.75rem',
                }}
              >
                <img src={eye} alt="" />
                <h2>View</h2>
                <p>Scrap Products and Pricing in Real-time</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: '0 1 33%',
                  padding: '.75rem',
                }}
              >
                <img src={bid} alt="" />
                <h2>Bid</h2>
                <p>On Scrap you Need at Prices you Want to Pay</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: '0 1 33%',
                  padding: '.75rem',
                }}
              >
                <img src={match} alt="" />
                <h2>Match</h2>
                <p>Buyer and Seller Prices for Instant Transactions</p>
              </div>
            </div>
          </div>
          <div className="digital-expand">
            <div className="digital-img">
              <img src={digital} alt="" />
            </div>
            <div className="digital-expand-content">
              <h2>
                Digitally<span className="blck"> expand your network</span> to
                reach across the United States and Canada
              </h2>
              <div className="digital-box-row">
                <div className="digital-box">
                  <p className="digital-text">Years of Experience</p>
                  <Section className="digit-num">
                    100 <span className="digital-plus">+</span>
                  </Section>
                </div>
                <div className="digital-box">
                  <p className="digital-text">User Locations</p>
                  <Section className="digit-num">
                    1800 <span className="digital-plus">+</span>
                  </Section>
                </div>
                <div className="digital-box">
                  <p className="digital-text">Platform Users</p>
                  <Section className="digit-num">
                    715 <span className="digital-plus">+</span>
                  </Section>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="get-started">
            <div className="get-left">
              <div className="get-big-text">
                <h2>Get started with ScrapGo today.</h2>
              </div>
              <p>
                Create your account to buy or sell on ScrapGo for one-click
                sales, quick liquidation, and fully automated logistics.
              </p>
              <div
                className="welcome-input-btn"
                style={{
                  display: width <= 768 ? 'flex' : '',
                  flexDirection: width <= 768 ? 'column' : '',
                  alignItems: width <= 768 ? 'center' : '',
                }}
              >
                <Input
                  placeholder="enter email..."
                  onChange={(e, d) => handleEmail(d?.value)}
                />
                <button
                  style={{ width: '130px' }}
                  onClick={() => navigate(`/apply/?email=${getStartedEmail}`)}
                  className="ui button get-btn"
                >
                  Get Started
                </button>
              </div>
            </div>
            <div className="get-right">
              <img src={access} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Landing
