import React from 'react'
import { Modal } from 'react-bootstrap'
import ProductPriceForm from './ProductPriceForm'
import { Coin } from 'react-bootstrap-icons'

const ProductPriceModal = (props) => {
  const {
    showModal,
    toggleModal,
    productPrice,
    businessId,
    supplierBusiness,
    buyerBusiness,
  } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size="xl" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Coin className="mr-2" />
              {productPrice ? <>Edit Product Price</> : <>New Product Price</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductPriceForm
              productPrice={productPrice}
              afterSubmit={toggleModal}
              businessId={businessId}
              supplierBusiness={supplierBusiness}
              buyerBusiness={buyerBusiness}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ProductPriceModal
