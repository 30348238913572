import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Badge } from 'react-bootstrap'
import { useLazyQuery, useQuery, gql, useMutation } from '@apollo/client'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { formatDateTimeToString } from '../../../libs/utils'
import {
  CashCoin,
  ArrowCounterclockwise,
  CloudArrowDown,
  BarChartLine,
  Funnel,
} from 'react-bootstrap-icons'
import RefundPaymentModal from './RefundPaymentModal'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import Loading from '../../common/Loading'
import toast from 'react-hot-toast'
import DateFilter from '../../common/DateFilter'
import { useParams, useHistory } from 'react-router-dom'
import { useAWSS3 } from '../../../libs/aws'
import PaymentChartModal from './PaymentChartModal'
import ChargePaymentModal from './ChargePaymentModal'
import InvoiceModal from '../invoice/InvoiceModal'

const PaymentsTable = (props) => {
  const {
    organizationId,
    subjectId,
    subjectGroupId,
    sessionId,
    stripeInvoiceId,
    invoiceModal,
    jobId,
    tableHeight,
    cachePolicy,
  } = props
  const { contact } = useParams()
  const awsS3 = useAWSS3()
  const settings = useReactiveVar(settingsVar)
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const admin = ['Administrator'].includes(loggedInUser.permissions.group)
  const canRefund = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser.permissions.group)
  const canDownloadPayments = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
    'Organization Contact',
  ].includes(loggedInUser.permissions.group)
  const [hasMoreStripePaymentIntents, setHasMoreStripePaymentIntents] =
    useState(true)
  const [cursor, setCursor] = useState()
  const [showSubjectSessionDetailModal, setShowSubjectSessionDetailModal] =
    useState(false)
  const [searchText, setSearchText] = useState('Search')
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [jobDetailId, setJobDetailId] = useState()
  const [showChargeModal, setShowChargeModal] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [showSessionDetailModal, setShowSessionDetailModal] = useState()
  const [sessionDetailId, setSessionDetailId] = useState()
  const [showSessionRefundModal, setShowSessionRefundModal] = useState()
  const [paymentId, setPayementId] = useState()
  const [invoiceModalId, setInvoiceModalId] = useState()
  const [filterDeclined, setFilterDecline] = useState(false)
  const [filterInvoice, setFilterInvoice] = useState(false)
  const [filterSession, setFilterSession] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [showReport, setShowReport] = useState(false)

  const [deleteFile] = useMutation(
    gql`
      mutation DeleteFile($deleteFileInput: DeleteFileInput!) {
        deleteFile(input: $deleteFileInput) {
          deleted
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const getS3Object = async (Key, fileName, postDownload = null) => {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          let blob = new Blob([data.Body], { type: data.ContentType })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          if (postDownload) {
            postDownload()
          }
        }
      }
    )
  }

  const [downloadPayments] = useMutation(
    gql`
      mutation DownloadStripePaymentIntents(
        $input: DownloadStripePaymentIntentsInput!
      ) {
        downloadStripePaymentIntents(input: $input) {
          file {
            id
            fileName
            displayName
            contentType
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        getS3Object(
          data.downloadStripePaymentIntents.file.fileName,
          data.downloadStripePaymentIntents.file.displayName,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
            deleteFile({
              variables: {
                deleteFileInput: {
                  fileIds: data.downloadStripePaymentIntents.file.id,
                },
              },
            })
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [stripeExpressDashboardUrlQuery] = useLazyQuery(
    gql`
      query StripeExpressDashboardQuery {
        stripeExpressDashboardUrl {
          url
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        window.open(data.stripeExpressDashboardUrl.url, '_blank')
      },
    }
  )
  const [
    stripePaymentIntentsQuery,
    {
      error: stripePaymentIntentsQueryError,
      data: stripePaymentIntentsQueryData,
      fetchMore: stripePaymentIntentsQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query StripePaymentIntentsQuery(
        $cursor: String
        $stripeInvoice: ID
        $declined: Boolean
        $searchTerm: String
        $startDateGte: DateTime
        $startDateLte: DateTime
        $invoices: Boolean
      ) {
        stripePaymentIntents(
          first: 25
          after: $cursor
          declined: $declined
          invoices: $invoices
          stripeInvoice: $stripeInvoice
          updated_Gte: $startDateGte
          updated_Lte: $startDateLte
          search_Icontains: $searchTerm
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              declined
              processing
              refunded
              refundedBy {
                fullName
              }
              status
              stripeTransfers {
                edges {
                  node {
                    id
                  }
                }
              }
              stripePaymentMethod {
                paymentType
              }
              paidManually
              taxAmount
              description
              refundedBySystem
              amount
              netAmount
              fee
              stripeInvoice {
                id
                archived
                amountDue
                stripeInvoiceId
                paidManuallyBy
                salesOrder {
                  number
                  buyer {
                    business {
                      name
                    }
                  }
                }
              }
              updated
            }
          }
        }
      }
    `,
    {
      fetchPolicy: cachePolicy
        ? cachePolicy
        : stripeInvoiceId
        ? 'no-cache'
        : 'network-only',
      errorPolicy: 'all',
      pollInterval: 5000,
    }
  )
  const { error: stripeConnectorQueryError, data: stripeConnectorQueryData } =
    useQuery(
      gql`
        query StripeConnectorQuery {
          stripeConnectors {
            edges {
              node {
                id
                connectedAccountId
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {}
      if (organizationId) {
        variables.organization = organizationId
      }
      if (subjectGroupId) {
        variables.subjectGroup = subjectGroupId
      }
      if (stripeInvoiceId) {
        variables.stripeInvoice = stripeInvoiceId
      }
      if (sessionId) {
        variables.session = sessionId
      }
      if (subjectId) {
        variables.subject = subjectId
      }
      if (jobId) {
        variables.job = jobId
      }
      stripePaymentIntentsQuery({ variables })
    }
  }, [initialQueryRun])

  useEffect(() => {
    if (stripePaymentIntentsQueryData?.stripePaymentIntents) {
      if (
        stripePaymentIntentsQueryData.stripePaymentIntents.pageInfo.endCursor
      ) {
        setCursor(
          stripePaymentIntentsQueryData.stripePaymentIntents.pageInfo.endCursor
        )
      }
      setHasMoreStripePaymentIntents(
        stripePaymentIntentsQueryData.stripePaymentIntents.pageInfo.hasNextPage
      )
      let text = 'Search 0 records'
      if (stripePaymentIntentsQueryData?.stripePaymentIntents.nodeCount > 0) {
        text = `Search ${stripePaymentIntentsQueryData?.stripePaymentIntents.nodeCount} records`
      }
      setSearchText(text)
    }
  }, [stripePaymentIntentsQueryData])

  const handleStripeDashboardClick = () => {
    toast.success('Opening Stripe')
    stripeExpressDashboardUrlQuery()
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.variables.cursor = null
      variables.variables.startDateGte = startDateFilter
      variables.variables.startDateLte = endDateFilter
      stripePaymentIntentsQuery(variables)
    }
  }, [startDateFilter, endDateFilter])

  const queryVariables = () => {
    const variables = {
      searchTerm,
      cursor,
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (sessionId) {
      variables.session = sessionId
    }
    if (stripeInvoiceId) {
      variables.stripeInvoice = stripeInvoiceId
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (jobId) {
      variables.job = jobId
    }
    if (filterDeclined) {
      variables.declined = true
    }
    if (filterSession) {
      variables.sessions = true
    }
    if (filterInvoice) {
      variables.invoices = true
    }
    return { variables }
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.variables.cursor = null
      stripePaymentIntentsQuery(variables)
    }
  }, [filterDeclined, filterSession, filterInvoice])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    const variables = queryVariables()
    variables.variables.cursor = null
    variables.variables.searchTerm = currentSearchTerm
    setSearchTerm(currentSearchTerm)
    stripePaymentIntentsQuery(variables)
  }

  const fetchMore = () => {
    stripePaymentIntentsQueryFetchMore(queryVariables())
  }

  const toggleSessionDetailModal = () => {
    if (showSessionDetailModal) {
      setSessionDetailId(null)
    }
    setShowSessionDetailModal(!showSessionDetailModal)
  }

  const toggleSubjectSessionDetailModal = () => {
    if (showSubjectSessionDetailModal) {
      setSessionDetailId(null)
    }
    setShowSubjectSessionDetailModal(!showSubjectSessionDetailModal)
  }

  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
    }
    setShowJobDetailModal(!showJobDetailModal)
  }

  const toggleSessionRefundModal = () => {
    setShowSessionRefundModal(!showSessionRefundModal)
  }

  const tableColumns = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => {
        return (
          <div
            className="sessions-buttons"
            onClick={() => {
              if (!invoiceModal && row.node?.session) {
                setSessionDetailId(row.node.session.id)
                if (contact) {
                  toggleSubjectSessionDetailModal()
                } else {
                  toggleSessionDetailModal()
                }
              } else if (!invoiceModal && row.node?.stripeInvoice) {
                setInvoiceModalId(row.node?.stripeInvoice.id)
              }
            }}
          >
            {row.node?.recordId}
          </div>
        )
      },
    },
    {
      Header: 'Sales Order',
      id: 'salesOrder',
      accessor: (row) => {
        return `#${row.node.stripeInvoice.salesOrder.number}`
      },
    },
    {
      Header: 'Business',
      id: 'business',
      accessor: (row) => {
        return row.node.stripeInvoice.salesOrder.buyer.business.name
      },
    },
    {
      Header: 'Description',
      accessor: (row) => {
        let description = row.node.description
        if (row.node?.stripeInvoice?.archived) {
          description = `Deleted ${description}`
        }
        return (
          <div
            className="sessions-buttons"
            onClick={() => {
              if (!invoiceModal && row.node?.session) {
                setSessionDetailId(row.node.session.id)
                if (contact) {
                  toggleSubjectSessionDetailModal()
                } else {
                  toggleSessionDetailModal()
                }
              } else if (!invoiceModal && row.node?.stripeInvoice) {
                setInvoiceModalId(row.node?.stripeInvoice.id)
              }
            }}
          >
            {description}
          </div>
        )
      },
    },
    {
      Header: 'Payment Method',
      accessor: (row) => {
        return (
          <div
            className="sessions-buttons"
            onClick={() => {
              if (!invoiceModal && row.node?.session) {
                setSessionDetailId(row.node.session.id)
                if (contact) {
                  toggleSubjectSessionDetailModal()
                } else {
                  toggleSessionDetailModal()
                }
              } else if (!invoiceModal && row.node?.stripeInvoice) {
                setInvoiceModalId(row.node?.stripeInvoice.id)
              }
            }}
          >
            {row.node.stripePaymentMethod?.paymentType && (
              <>{row.node.stripePaymentMethod?.paymentType}</>
            )}
            {row.node.paidManually &&
              row.node.stripeInvoice?.paidManuallyBy && (
                <>{row.node.stripeInvoice?.paidManuallyBy}</>
              )}
          </div>
        )
      },
    },
    {
      Header: 'Amount',
      accessor: (row) => {
        return (
          <div
            className="sessions-buttons"
            onClick={() => {
              if (!invoiceModal && row.node?.session) {
                setSessionDetailId(row.node.session.id)
                if (contact) {
                  toggleSubjectSessionDetailModal()
                } else {
                  toggleSessionDetailModal()
                }
              } else if (!invoiceModal && row.node?.stripeInvoice) {
                setInvoiceModalId(row.node?.stripeInvoice.id)
              }
            }}
          >
            {((!row.node.processing &&
              row.node.paidManually &&
              row.node.stripeInvoice) ||
              (row.node.processing && row.node.stripeInvoice)) && (
              <>${(row.node.stripeInvoice.amountDue / 100).toFixed(2)}</>
            )}
            {!row.node.processing && !row.node.paidManually && (
              <>${(row.node.amount / 100).toFixed(2)}</>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Status',
      accessor: (row) => {
        return (
          <div
            className="sessions-buttons"
            onClick={() => {
              if (row.node.status == 'Declined' && !row.node.stripeInvoice) {
                setShowChargeModal({
                  sessionId: row.node.session.id,
                  userId: row.node.stripePaymentMethod.stripeCustomer.user.id,
                  amount: row.node.amount / 100,
                  description: row.node.description,
                  declinedStripePaymentIntentId: row.node.id,
                })
              } else {
                if (!invoiceModal && row.node.session) {
                  setSessionDetailId(row.node.session.id)
                  if (contact) {
                    toggleSubjectSessionDetailModal()
                  } else {
                    toggleSessionDetailModal()
                  }
                } else if (row.node?.stripeInvoice) {
                  setInvoiceModalId(row.node?.stripeInvoice.id)
                }
              }
            }}
          >
            {row.node.paidManually ? 'Paid Manually' : row.node.status}
          </div>
        )
      },
    },
    {
      Header: 'On',
      accessor: (row) => {
        const updatedDateTime = formatDateTimeToString(
          new Date(row.node.updated)
        )
        return (
          <div
            className="sessions-buttons"
            onClick={() => {
              if (!invoiceModal && row.node.session) {
                setSessionDetailId(row.node.session.id)
                if (contact) {
                  toggleSubjectSessionDetailModal()
                } else {
                  toggleSessionDetailModal()
                }
              } else if (!invoiceModal && row.node?.stripeInvoice) {
                setInvoiceModalId(row.node?.stripeInvoice.id)
              }
            }}
          >
            {updatedDateTime}
          </div>
        )
      },
    },
  ]
  if (canRefund) {
    const columns = [
      {
        Header: 'Stripe Fee',
        accessor: (row) => {
          return (
            <div
              className="sessions-buttons"
              onClick={() => {
                if (!invoiceModal && row.node?.session) {
                  setSessionDetailId(row.node.session.id)
                  if (contact) {
                    toggleSubjectSessionDetailModal()
                  } else {
                    toggleSessionDetailModal()
                  }
                } else if (!invoiceModal && row.node?.stripeInvoice) {
                  setInvoiceModalId(row.node?.stripeInvoice.id)
                }
              }}
            >
              {!row.node.paidManually && !row.node.processing && (
                <>${(row.node.fee / 100).toFixed(2)}</>
              )}
              {row.node.processing && row.node.stripeInvoice && <>N/A</>}
            </div>
          )
        },
      },
      {
        Header: 'Net Amount',
        accessor: (row) => {
          return (
            <div
              className="sessions-buttons"
              onClick={() => {
                if (!invoiceModal && row.node?.session) {
                  setSessionDetailId(row.node.session.id)
                  if (contact) {
                    toggleSubjectSessionDetailModal()
                  } else {
                    toggleSessionDetailModal()
                  }
                } else if (row.node?.stripeInvoice) {
                  setInvoiceModalId(row.node?.stripeInvoice.id)
                }
              }}
            >
              {((!row.node.processing &&
                row.node.paidManually &&
                row.node.stripeInvoice) ||
                (row.node.processing && row.node.stripeInvoice)) && (
                <>${(row.node.stripeInvoice.amountDue / 100).toFixed(2)}</>
              )}
              {!row.node.processing && !row.node.paidManually && (
                <>${(row.node.netAmount / 100).toFixed(2)}</>
              )}
            </div>
          )
        },
      },
    ]
    if (settings.chargeStateSalesTax) {
      columns.splice(2, 0, {
        Header: 'Tax',
        accessor: (row) => {
          return (
            <div
              className="sessions-buttons"
              onClick={() => {
                if (!invoiceModal && row.node?.session) {
                  setSessionDetailId(row.node.session.id)
                  if (contact) {
                    toggleSubjectSessionDetailModal()
                  } else {
                    toggleSessionDetailModal()
                  }
                } else if (!invoiceModal && row.node?.stripeInvoice) {
                  setInvoiceModalId(row.node?.stripeInvoice.id)
                }
              }}
            >
              {row.node.taxAmount && (
                <>${(row.node.taxAmount / 100).toFixed(2)}</>
              )}
              {row.node.stripeInvoice && <>On Invoice</>}
            </div>
          )
        },
      })
    }
    tableColumns.splice(6, 0, ...columns)
    tableColumns.push({
      Header: 'Refund',
      accessor: (row) => {
        if (!row.node.paidManually) {
          if (!row.node.refunded && !row.node.declined) {
            return (
              <div
                className="sessions-buttons btn-link"
                onClick={() => {
                  setPayementId(row.node.id)
                  toggleSessionRefundModal()
                }}
              >
                <ArrowCounterclockwise
                  className="ml-2"
                  style={{ fontSize: '18px' }}
                />{' '}
                Refund
              </div>
            )
          } else if (row.node.refundedBy?.fullName) {
            return `By ${row.node.refundedBy?.fullName}`
          }
        }
      },
    })
  }
  if (subjectId) {
    tableColumns.splice(1, 1)
  }
  if (organizationId) {
    tableColumns.splice(3, 1)
  }
  if (!initialQueryRun || !stripeConnectorQueryData) return <></>

  if (stripePaymentIntentsQueryError || stripeConnectorQueryError)
    return <>Error loading payments</>
  return (
    <>
      {invoiceModalId && (
        <InvoiceModal
          showModal={invoiceModalId}
          toggleModal={() => setInvoiceModalId()}
          stripeInvoiceId={invoiceModalId}
          hideOrganization={organizationId ? true : false}
        />
      )}
      {showChargeModal && (
        <ChargePaymentModal
          showModal={true}
          toggleModal={() => setShowChargeModal()}
          sessionId={showChargeModal.sessionId}
          amount={showChargeModal.amount}
          userId={showChargeModal.userId}
          description={showChargeModal.description}
          declinedStripePaymentIntentId={
            showChargeModal.declinedStripePaymentIntentId
          }
          chargeUserPaymentMethod
        />
      )}
      {showReport && (
        <PaymentChartModal
          showModal={showReport}
          startDateTime={startDateFilter}
          organizationId={organizationId}
          subjectGroupId={subjectGroupId}
          sessionId={sessionId}
          subjectId={subjectId}
          jobId={jobId}
          endDataTime={endDateFilter}
          toggleModal={() => {
            setShowReport()
          }}
        />
      )}
      {!stripeConnectorQueryData.stripeConnectors.edges[0].node
        .connectedAccountId ? (
        <>
          <Row className="mb-2 mt-1">
            <Col>
              <Badge
                className="ml-2"
                style={{ fontSize: '12px' }}
                variant="danger"
              >
                Stripe Disabled
              </Badge>
            </Col>
          </Row>
        </>
      ) : null}
      <>
        {!stripeInvoiceId && (
          <>
            <Row>
              <Col>
                {stripeConnectorQueryData.stripeConnectors.edges[0].node
                  .connectedAccountId &&
                  admin && (
                    <Button variant="link" onClick={handleStripeDashboardClick}>
                      <span>
                        <CashCoin className="mr-2" />
                        Stripe Dashboard
                      </span>
                    </Button>
                  )}
                {canRefund && !stripeInvoiceId && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowReport(true)
                    }}
                  >
                    <span>
                      <BarChartLine className="mr-2" />
                      Report
                    </span>
                  </Button>
                )}
                {!stripeInvoiceId && (
                  <>
                    <Button
                      variant="link"
                      onClick={() => {
                        setFilterDecline(!filterDeclined)
                      }}
                    >
                      <span>
                        <Funnel className="mr-2" />
                        {!filterDeclined ? <>Payment Failed</> : <>All</>}
                      </span>
                    </Button>
                  </>
                )}
                {startDateFilter && endDateFilter && canDownloadPayments && (
                  <>
                    <Button
                      variant="link"
                      disabled={downloadingPdf}
                      onClick={() => {
                        setDownloadingPdf(true)
                        downloadPayments({
                          variables: {
                            input: {
                              fileType: 'pdf',
                              startDate: startDateFilter,
                              endDate: endDateFilter,
                              subjectId,
                              organizationId,
                              subjectGroupId,
                              sessionId,
                              sessions: filterSession,
                              invoices: filterInvoice,
                              declined: filterDeclined,
                            },
                          },
                        })
                      }}
                    >
                      <>
                        <span>
                          <CloudArrowDown className="mr-2" />
                          Download PDF
                        </span>
                      </>
                    </Button>
                    <Button
                      variant="link"
                      disabled={downloadingExcel}
                      onClick={() => {
                        setDownloadingExcel(true)
                        downloadPayments({
                          variables: {
                            input: {
                              fileType: 'xlsx',
                              startDate: startDateFilter,
                              endDate: endDateFilter,
                              subjectId,
                              organizationId,
                              subjectGroupId,
                              sessionId,
                              sessions: filterSession,
                              invoices: filterInvoice,
                              declined: filterDeclined,
                            },
                          },
                        })
                      }}
                    >
                      <>
                        <span>
                          <CloudArrowDown className="mr-2" />
                          Download Excel
                        </span>
                      </>
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} md={startDateFilter && endDateFilter ? 3 : 4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="searchTerm"
                    placeholder={searchText}
                    className="form-control-sm"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4} style={{ marginTop: '-8px', marginLeft: '-10px' }}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="payments from"
                  placeholderEnd={'payments to'}
                />
              </Col>
            </Row>
          </>
        )}

        {!stripePaymentIntentsQueryData && (
          <Loading message="Loading Payments..." />
        )}
        {stripePaymentIntentsQueryData?.stripePaymentIntents.edges &&
          (!stripeInvoiceId ||
            stripePaymentIntentsQueryData.stripePaymentIntents.edges.length >
              0) && (
            <Row>
              <Col>
                <SortableInfiniteTable
                  tableData={
                    stripePaymentIntentsQueryData.stripePaymentIntents.edges
                  }
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMore}
                  hasMoreTableData={hasMoreStripePaymentIntents}
                  tableHeight={
                    tableHeight ? tableHeight : stripeInvoiceId ? 130 : 700
                  }
                  hideGlobalFilter
                  rowPointer={invoiceModal ? false : true}
                  loadingMessage={'Loading Payments...'}
                />
              </Col>
            </Row>
          )}
        {stripeInvoiceId &&
          stripePaymentIntentsQueryData?.stripePaymentIntents?.edges.length ===
            0 && <p>- - -</p>}
        <RefundPaymentModal
          stripePaymentId={paymentId}
          showModal={showSessionRefundModal}
          toggleModal={toggleSessionRefundModal}
        />
      </>
    </>
  )
}

export default PaymentsTable
