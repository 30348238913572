import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Form, Row, Col } from 'react-bootstrap'
import { XCircle } from 'react-bootstrap-icons'
import produce from 'immer'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import './NotificationsTableSearch.css'
import DateFilter from '../common/DateFilter'

const NotificationsTableSearch = (props) => {
  const {
    appliedSearchFilters,
    setAppliedSearchFilters,
    searchText,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = props
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const [hasMoreSearchFilters, setHasMoreSearchFilters] = useState(true)
  const [employeesCursor, setEmployeesCursor] = useState()
  const [businessesCursor, setBusinessesCursor] = useState()
  const [query, { data, fetchMore }] = useLazyQuery(
    gql`
      query NotificationsJobsQueries(
        $businessesCursor: String
        $searchTerm: String
      ) {
        employees(first: 10, after: $employeesCursor, search: $searchTerm) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
        businesses(
          first: 10
          after: $businessesCursor
          name_Icontains: $searchTerm
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              name
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )
  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Sort Name',
        accessor: 'name',
      },
      {
        Header: 'Sort Type',
        accessor: 'nodeType',
      },
    ],
    []
  )
  useEffect(() => {
    if (data) {
      handleQueryResults(data)
    }
  }, [data])

  useEffect(() => {
    setSearchFilters([])
    if (searchTerm.length > 0) {
      query({
        variables: {
          searchTerm,
          businessesCursor,
          employeesCursor,
        },
      })
    }
  }, [searchTerm])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setEmployeesCursor()
    setBusinessesCursor()
    setSearchTerm(currentSearchTerm)
    setDisplaySearchResults(true)
  }
  const fetchMoreSearchTerms = () => {
    const variables = {
      employeesCursor,
      businessesCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        handleQueryResults(fetchMoreResult, true)
        return fetchMoreResult
      },
    })
  }
  const handleQueryResults = (queryResults, fromFetchMore) => {
    if (searchTerm.length === 0) {
      setSearchFilters([])
    } else {
      if (queryResults.employees.pageInfo.endCursor) {
        setEmployeesCursor(queryResults.employees.pageInfo.endCursor)
      }
      if (queryResults.businesses.pageInfo.endCursor) {
        setBusinessesCursor(queryResults.businesses.pageInfo.endCursor)
      }
      if (
        queryResults.employees.pageInfo.hasNextPage ||
        queryResults.businesses.pageInfo.hasNextPage
      ) {
        setHasMoreSearchFilters(true)
      } else {
        setHasMoreSearchFilters(false)
      }
      const currentSearchFilters = []
      const appliedSearchFilterIds = Object.keys(appliedSearchFilters)
      queryResults.employees.edges.forEach((employee) => {
        const employeeNode = employee.node
        if (!appliedSearchFilterIds.includes(employeeNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Employee',
            id: employeeNode.id,
            name: `${employeeNode.user.firstName} ${employeeNode.user.lastName}`,
          })
        }
      })
      queryResults.businesses.edges.forEach((business) => {
        const businessNode = business.node
        if (!appliedSearchFilterIds.includes(businessNode.id)) {
          currentSearchFilters.push({
            nodeType: 'Business',
            id: businessNode.id,
            name: businessNode.name,
          })
        }
      })
      if (fromFetchMore) {
        const nextSearchFilters = produce(searchFilters, (draftState) =>
          draftState.concat(currentSearchFilters)
        )
        setSearchFilters(nextSearchFilters)
      } else {
        const nextSearchFilters = produce(searchFilters, (draftState) => {
          return currentSearchFilters
        })
        setSearchFilters(nextSearchFilters)
      }
    }
  }

  const handleSearchFilterClick = (searchFilterRow) => {
    const tableRow = document.getElementById(`tableRow${searchFilterRow.id}`)
    const nodeId = searchFilterRow.original.id
    if (Object.keys(appliedSearchFilters).includes(nodeId)) {
      tableRow.classList.remove('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        delete prevSearchFilters[nodeId]
        return prevSearchFilters
      })
    } else {
      tableRow.classList.add('appliedSearchFilter')
      setAppliedSearchFilters((prevState) => {
        const prevSearchFilters = { ...prevState }
        prevSearchFilters[searchFilterRow.original.id] =
          searchFilterRow.original
        return prevSearchFilters
      })
    }
  }

  const handleRemoveSearchFilterClick = (searchFilter) => {
    setAppliedSearchFilters((prevState) => {
      const prevSearchFilters = { ...prevState }
      delete prevSearchFilters[searchFilter[0]]
      return prevSearchFilters
    })
    if (searchFilters.length > 0) {
      const nextSearchFilters = produce(searchFilters, (draftState) => {
        draftState.push(searchFilter[1])
      })
      setSearchFilters(nextSearchFilters)
    }
  }

  const handleControlBlur = () => {
    setSearchTerm('')
    setDisplaySearchResults(false)
  }

  return (
    <>
      <div className="notificationsFilterSearch">
        <Form.Group>
          <Row>
            <Col xs={12} md={4}>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onBlur={handleControlBlur}
                onChange={handleSearchTermChange}
              />
            </Col>
            <Col style={{ marginTop: '-8px' }}>
              <DateFilter
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                placeholderStart="sent from date"
                placeholderEnd="sent until date"
              />
            </Col>
          </Row>
        </Form.Group>
        <Row
          style={{ position: 'relative' }}
          className={displaySearchResults ? '' : 'd-none'}
        >
          <Col
            md={4}
            style={{
              position: 'absolute',
              zIndex: 10,
              top: 0,
              left: 12,
              backgroundColor: 'white',
              padding: 0,
              margin: 0,
            }}
          >
            {searchFilters.length > 0 ? (
              <>
                <Row className={displaySearchResults ? '' : 'd-none'}>
                  <Col xs={12} md={12}>
                    <SortableInfiniteTable
                      hideGlobalFilter
                      displayHeader={false}
                      infiniteTableId="notificationsFilterInfiniteTable"
                      tableData={searchFilters}
                      tableColumns={tableColumns}
                      fetchMoreTableData={fetchMoreSearchTerms}
                      hasMoreTableData={hasMoreSearchFilters}
                      onRowClick={handleSearchFilterClick}
                      rowPointer
                      tableSize="lg"
                    />
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            {Object.keys(appliedSearchFilters).length > 0 ? (
              <div>
                {Object.entries(appliedSearchFilters).map(
                  (appliedSearchFilter, index) => {
                    return (
                      <div
                        key={appliedSearchFilter[0]}
                        className="d-inline-block mt-2"
                      >
                        <small className="bg-secondary rounded text-white mr-2 p-1 d-flex align-items-center">
                          <XCircle
                            className="mr-2"
                            onClick={() =>
                              handleRemoveSearchFilterClick(appliedSearchFilter)
                            }
                          />
                          <span>{`${appliedSearchFilter[1].name}`} | </span>
                          <span className="ml-1">
                            <small>{appliedSearchFilter[1].nodeType}</small>
                          </span>
                        </small>
                      </div>
                    )
                  }
                )}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NotificationsTableSearch
