import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Col, Tab } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  InfoCircle,
  Truck,
  Bell,
  GraphUpArrow,
  ClockHistory,
} from 'react-bootstrap-icons'
import Notifications from '../../notification/Notifications'
import Loading from '../../common/Loading'
import AuditLog from '../../audit_log/AuditLog'
import Tabs from '../../common/Tabs'
import CargoProviderForm from './CargoProviderForm'
import Deals from '../../deal/Deals'
import Fulfillments from '../fulfilment/Fulfillments'

const CargoProvider = () => {
  const { cargoProviderId } = useParams()
  const [activeTab, setActiveTab] = useState('Details')
  const { error, data } = useQuery(
    gql`
      query CargoProvider($id: ID!) {
        cargoProvider(id: $id) {
          id
          name
          type
          contactFirstName
          contactLastName
          contactPhone
          contactEmail
          fax
          cargoUnitInstanceCount
          contentType {
            model
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: cargoProviderId,
      },
    }
  )
  if (error) return <>Error loading cargo provider</>
  if (!data)
    return (
      <span className="mt-5">
        <Loading message="Loading Cargo Provider..." />
      </span>
    )
  const { cargoProvider } = data
  return (
    <>
      <Row className="mt-3">
        <Col>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <h1 className="mb-0" style={{ marginRight: '10px' }}>
              {cargoProvider.name}
            </h1>
          </div>
        </Col>
      </Row>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Details"
          eventKey="Details"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <InfoCircle className="mr-2" />
              Details
            </b>
          }
        >
          <Row>
            <Col md={6}>
              <CargoProviderForm cargoProvider={cargoProvider} />
            </Col>
          </Row>
        </Tab>
        <Tab
          key="Deals"
          eventKey="Deals"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <GraphUpArrow className="mr-2" />
              Deals
            </b>
          }
        >
          <Deals cargoProviderId={cargoProvider.id} />
        </Tab>
        <Tab
          key="Fulfillment"
          eventKey="Fulfillment"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Truck className="mr-2" />
              Fulfillment
            </b>
          }
        >
          <Fulfillments cargoProviderId={cargoProvider.id} />
        </Tab>
        <Tab
          key="Notifications"
          eventKey="Notifications"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Bell className="mr-2" />
              Notifications
            </b>
          }
        >
          <Notifications />
        </Tab>
        <Tab
          key="History"
          eventKey="History"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <ClockHistory className="mr-2" />
              History
            </b>
          }
        >
          <AuditLog
            contentType={cargoProvider.contentType.model}
            id={cargoProvider.id}
            topRowMargin
          />
        </Tab>
      </Tabs>
    </>
  )
}
export default CargoProvider
