import React from 'react'
import { Modal } from 'react-bootstrap'
import BusinessForm from './BusinessForm'
import { Buildings } from 'react-bootstrap-icons'

const BusinessModal = (props) => {
  const { showModal, toggleModal, business } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal
          size={business ? 'xl' : 'md'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Buildings className="mr-2" />
              {business ? <>Edit {business.name}</> : <>New Business</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BusinessForm business={business} afterSubmit={toggleModal} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default BusinessModal
