import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Modal, Image } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { Folder } from 'react-bootstrap-icons'
import 'react-phone-input-2/lib/style.css'
import validator from 'validator'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, gql, useLazyQuery } from '@apollo/client'
import { FilePond } from 'react-filepond'
import { TIMEZONES } from '../../../libs/constant'
import { formatTimezone, hexToRgb, rgbToHex } from '../../../libs/utils'
import Loading from '../../common/Loading'
import { timezoneVar, settingsVar } from '../../../libs/apollo'
import toast from 'react-hot-toast'
import { ChromePicker } from 'react-color'

const EditBusinessInfoModal = (props) => {
  const { settings, showModal, toggleModal, logoImage } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [rbg, setRgb] = useState(hexToRgb(settings.colorScheme))

  const [getSettings] = useLazyQuery(
    gql`
      query PublicSettingsQuery {
        publicSettings {
          id
          name
          scrapgoInvoiceRecipient
          displayLogoInNav
          displayNameInNav
          websiteUrl
          phoneNumber
          openAiEnabled
          thumbnailImageName
          timezone
          intercomAppId
          tenantActive
          tenantId
          email
          openAiEnabled
          colorScheme
          facebookUrl
          twitterUrl
          tenantUuid
          linkedinUrl
          instagramUrl
          instagramInFooter
          facebookInFooter
          twitterInFooter
          linkedinInFooter
          tenantDomain
          apiDomain
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        settingsVar(data.publicSettings)
      },
    }
  )

  const [updateSettingsMutation] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        getSettings()
        setIsSubmitting(false)
        toast.success('Settings Saved')
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['PublicSettingsQuery', 'SettingsQuery'],
    }
  )
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      website: '',
      timezone: '',
      logoImage: '',
      displayLogoInNav: '',
      displayNameInNav: '',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: '',
      linkedinInFooter: null,
      facebookInFooter: null,
      twitterInFooter: null,
      instagramInFooter: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .nullable()
        .min(2, 'Name is too short')
        .max(100, 'Name is too ling')
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      phoneNumber: Yup.string()
        .nullable()
        .min(5, 'Phone number one is too short')
        .max(100, 'Phone number one is too long')
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      website: Yup.string()
        .nullable()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .test('isRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      linkedinUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      facebookUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      instagramUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      twitterUrl: Yup.string()
        .min(3, 'Website is too short')
        .max(100, 'Website is too long')
        .nullable()
        .test('isURL', 'Invalid website', (value) => {
          let valid = true
          if (value && !validator.isURL(value)) {
            valid = false
          }
          return valid
        }),
      linkedinInFooter: Yup.bool().nullable(),
      facebookInFooter: Yup.bool().nullable(),
      twitterInFooter: Yup.bool().nullable(),
      instagramInFooter: Yup.bool().nullable(),
      timezone: Yup.string()
        .min(3, 'Timezone is too short')
        .max(100, 'Timezone is too long')
        .required('Timezone is required')
        .test('isTimezone', 'Invalid timezone', (value) => {
          let valid = true
          if (value && !TIMEZONES.includes(value)) {
            valid = false
          }
          return valid
        }),
      email: Yup.string()
        .min(3, 'Email is too short')
        .max(100, 'Email is too long')
        .required('Email is required')
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      displayLogoInNav: Yup.bool().required(
        'Navigation includes logo is required'
      ),
      displayNameInNav: Yup.bool().required(
        'Navigation includes logo is required'
      ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      let newLogoImage
      if (values.logoImage) {
        newLogoImage = {
          image: values.logoImage[0].file,
          nameOnUpload: values.logoImage[0].filename,
        }
      }
      if (values.timezone === 'US/PACIFIC') {
        timezoneVar('America/LOS_ANGELES')
      } else if (values.timezone === 'US/CENTRAL') {
        timezoneVar('America/Chicago')
      } else if (values.timezone === 'US/EASTERN') {
        timezoneVar('America/New_York')
      } else if (values.timezone === 'US/ALASKA') {
        timezoneVar('America/Juneau')
      } else if (values.timezone === 'US/MOUNTAIN') {
        timezoneVar('America/Denver')
      } else {
        timezoneVar('Pacific/Honolulu')
      }
      let colorScheme
      if (rbg?.r) {
        colorScheme = rgbToHex(rbg.r, rbg.g, rbg.b)
      }
      updateSettingsMutation({
        variables: {
          updateSettingsInput: {
            settingsInput: {
              id: settings.id,
              name: values.name,
              phoneNumber: values.phoneNumber,
              website: values.website,
              timezone: `${values.timezone}`,
              email: values.email,
              logoImage: newLogoImage,
              linkedinUrl: values.linkedinUrl,
              facebookUrl: values.facebookUrl,
              instagramUrl: values.instagramUrl,
              twitterUrl: values.twitterUrl,
              linkedinInFooter: values.linkedinInFooter,
              facebookInFooter: values.facebookInFooter,
              twitterInFooter: values.twitterInFooter,
              instagramInFooter: values.instagramInFooter,
              displayLogoInNav: values.displayLogoInNav,
              displayNameInNav: values.displayNameInNav,
              primaryContactName: values.primaryContactName,
              primaryContactEmail: values.primaryContactEmail,
              colorScheme,
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (showModal && settings) {
      formik.setValues({
        name: settings.name,
        email: settings.email,
        phoneNumber: settings.phoneNumber,
        website: settings.website,
        timezone: formatTimezone(settings.timezone),
        displayLogoInNav: settings.displayLogoInNav,
        displayNameInNav: settings.displayNameInNav,
        linkedinUrl: settings.linkedinUrl,
        facebookUrl: settings.facebookUrl,
        instagramUrl: settings.instagramUrl,
        twitterUrl: settings.twitterUrl,
        linkedinInFooter: settings.linkedinInFooter,
        facebookInFooter: settings.facebookInFooter,
        twitterInFooter: settings.twitterInFooter,
        instagramInFooter: settings.instagramInFooter,
        primaryContactEmail: settings.primaryContactEmail,
        primaryContactName: settings.primaryContactName,
      })
    }
  }, [showModal, settings])

  const handleFileUpload = (updateLogoImage) => {
    formik.setFieldValue('logoImage', updateLogoImage)
  }

  const innerToggleModal = () => {
    toggleModal()
    formik.resetForm()
    setRgb()
  }
  if (showModal) {
    return (
      <>
        <div className="editBusinessModal">
          <Modal
            size="lg"
            show={showModal}
            onHide={innerToggleModal}
            aria-labelledby="new-title"
          >
            <Form
              onSubmit={formik.handleSubmit}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Name
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Email
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Website
                  </Form.Label>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.website}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.website}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Facebook URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="facebookUrl"
                        value={formik.values.facebookUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.facebookUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.facebookUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="facebookInFooter"
                        label="Facebook in Footer"
                        type="checkbox"
                        checked={formik.values.facebookInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `facebookInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Instagram URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="instagramUrl"
                        value={formik.values.instagramUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.instagramUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.instagramUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="instagramInFooter"
                        label="Instagram in Footer"
                        type="checkbox"
                        checked={formik.values.instagramInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `instagramInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      LinkedIn URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="linkedinUrl"
                        value={formik.values.linkedinUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.linkedinUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.linkedinUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="linkedinInFooter"
                        label="LinkedIn in Footer"
                        type="checkbox"
                        checked={formik.values.linkedinInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `linkedinInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Form.Label column sm="3">
                      Twitter URL
                    </Form.Label>
                    <Col md={6}>
                      <Form.Control
                        type="text"
                        name="twitterUrl"
                        value={formik.values.twitterUrl}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.twitterUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.twitterUrl}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Check
                        name="twitterInFooter"
                        label="Twitter in Footer"
                        type="checkbox"
                        checked={formik.values.twitterInFooter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            `twitterInFooter`,
                            e.target.checked
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Timezone
                  </Form.Label>
                  <Col md={4}>
                    <Form.Control
                      name="timezone"
                      as="select"
                      value={formik.values.timezone}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.timezone}
                    >
                      {TIMEZONES.map((timezone) => (
                        <option key={timezone} value={timezone}>
                          {timezone}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.timezone}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3" className="mr-0 pr-0">
                    Phone Number
                  </Form.Label>
                  <Col md={8}>
                    <PhoneInput
                      country="us"
                      name="phoneNumber"
                      regions={['north-america']}
                      value={formik.values.phoneNumber}
                      onChange={(phone, country) =>
                        formik.setFieldValue('phoneNumber', phone)
                      }
                    />
                    {formik.errors.phoneNumber ? (
                      <span className="text-danger">
                        {formik.errors.phoneNumber}
                      </span>
                    ) : null}
                  </Col>
                </Form.Group>
                <Row className="mb-2">
                  <Col xs={12} md={12}>
                    <Form.Label>Logo</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Image
                      fluid
                      src={`data:image/jpeg;base64,${logoImage}`}
                      alt="Studio logo"
                    />
                  </Col>
                </Row>
                <Form.Group>
                  <Row className="mt-4">
                    <Col xs={12}>
                      <Form.Label>New Logo</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 10, offset: 1 }}>
                      <FilePond
                        files={formik.values.logoImage}
                        onupdatefiles={handleFileUpload}
                        acceptedFileTypes={['image/png', 'image/jpeg']}
                        allowMultiple
                        allowFileSizeValidation
                        maxFileSize="15MB"
                        labelMaxFileSize="Maximum preview image size is {filesize}"
                        maxFiles={1}
                        name="files"
                        labelIdle='Drag and drop an image or <span class="filepond--label-action">Browse</span>'
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-4">
                    <Col xs={12}>
                      <Form.Label>Color Scheme</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 6, offset: 4 }}>
                      <ChromePicker
                        onChange={(e) => setRgb(e.rgb)}
                        color={rbg}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Col>
                      <Form.Check
                        name="displayNameInNav"
                        label="Navigation Includes Name"
                        type="checkbox"
                        checked={formik.values.displayNameInNav}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="mt-2">
                    <Col>
                      <Form.Check
                        name="displayLogoInNav"
                        label="Navigation Includes Logo"
                        type="checkbox"
                        checked={formik.values.displayLogoInNav}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col md={3}>
                    <Button
                      type="submit"
                      block
                      variant="outline-primary"
                      disabled={isSubmitting}
                    >
                      <Folder className="mr-2" />
                      Save
                    </Button>
                  </Col>
                </Row>

                {isSubmitting ? (
                  <Row className="mt-2">
                    <Col>
                      <Loading />
                    </Col>
                  </Row>
                ) : null}
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default EditBusinessInfoModal
