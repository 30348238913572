import React from 'react'
import { Tabs as BootstrapTabs } from 'react-bootstrap'
import { settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const Tabs = (props) => {
  const settings = useReactiveVar(settingsVar)
  if (!settings) return <></>

  return (
    <BootstrapTabs style={{ backgroundColor: settings.colorScheme }} {...props}>
      {props.children}
    </BootstrapTabs>
  )
}

export default Tabs
