import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'

const DeleteCargoProviderModal = (props) => {
  const { showModal, toggleModal, id, ids, setCheckedNodeIds } = props
  let _ids = [id]
  if (ids) {
    _ids = ids
  }
  const history = useHistory()

  const [mutation] = useMutation(
    gql`
      mutation DeleteCargoProvider($input: DeleteCargoProviderInput!) {
        deleteCargoProvider(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        if (ids) {
          toast.success('Cargo Provider Deleted')
        } else {
          toast.success('Cargo Provider Deleted')
        }
        if (setCheckedNodeIds) {
          setCheckedNodeIds([])
        }
        toggleModal()
        history.push('/cargo')
      },
      refetchQueries: ['CargoProviders'],
      errorPolicy: 'all',
    }
  )
  const handleDeleteClick = () => {
    mutation({
      variables: {
        input: {
          ids: _ids,
        },
      },
    })
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Cargo Provider{ids && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <p style={{ fontSize: '18px' }}>
                  Are you sure you want to delete the cargo provider
                  {ids && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteCargoProviderModal
