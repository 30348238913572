import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import {
  CaretDown,
  CaretRight,
  Trash,
  Folder,
  ArrowUpRightCircle,
} from 'react-bootstrap-icons'
import AuditLog from '../audit_log/AuditLog'
import PhoneInput from 'react-phone-input-2'
import BusinessSearchInput from '../common/node_search_input/BusinessSearchInput'
import Loading from '../common/Loading'
import Notifications from '../notification/Notifications'
import Deals from '../deal/Deals'

const ClientForm = (props) => {
  const { client, afterSubmit, modal, businessId } = props
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayNotifications, setDisplayNotifications] = useState(false)
  const history = useHistory()
  const apolloClient = useApolloClient()
  const [deleteUser] = useMutation(
    gql`
      mutation DeleteUser($input: DeleteUserInput!) {
        deleteUser(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setDeleting(false)
        toast.success(`Client Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Clients'],
    }
  )

  const [createUser] = useMutation(
    gql`
      mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input) {
          user {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Client Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Clients'],
    }
  )

  const [updateUser] = useMutation(
    gql`
      mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
          user {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Client Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Clients', 'Client'],
    }
  )

  const formik = useFormik({
    initialValues: client
      ? {
          businessId: client.business.id,
          businessDescription: client.business.name,
          firstName: client.user.firstName,
          lastName: client.user.lastName,
          email: client.user.email,
          secondaryEmail: client.user.secondaryEmail,
          phoneNumber: client.user.phoneNumber,
          isActive: client.user.isActive,
          secondaryPhoneNumber: client.user.secondaryPhoneNumber,
          emailNotificationsEnabled: client.user.emailNotificationsEnabled,
          smsNotificationsEnabled: client.user.smsNotificationsEnabled,
          receiveInvoiceEmails: client.receiveInvoiceEmails,
        }
      : {
          businessId: businessId,
          businessDescription: '',
          firstName: '',
          lastName: '',
          email: '',
          secondaryEmail: '',
          phoneNumber: '',
          secondaryPhoneNumber: '',
          isActive: true,
          emailNotificationsEnabled: true,
          smsNotificationsEnabled: true,
          receiveAccountCreatedEmail: true,
          receiveInvoiceEmails: true,
        },
    validationSchema: Yup.object().shape({
      businessId: Yup.string().required('Required'),
      businessDescription: Yup.string().nullable(),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string()
        .required('Required')
        .email('Invalid Email')
        .test('isUnique', 'Client Already Exists', async (value) => {
          let valid = true
          if (value) {
            const { data } = await apolloClient.query({
              query: gql`
                query UserEmail($emailIexact: String) {
                  users(email_Iexact: $emailIexact) {
                    nodeCount
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              `,
              fetchPolicy: 'network-only',
              variables: {
                emailIexact: value,
              },
            })
            valid =
              data.users.nodeCount === 0 ||
              (client && data.users.edges[0].node.id === client.user.id)
          }
          return valid
        }),
      secondaryEmail: Yup.string().nullable().email('Invalid Email'),
      phoneNumber: Yup.string().nullable(),
      secondaryPhoneNumber: Yup.string().nullable(),
      isActive: Yup.boolean().required('Required'),
      emailNotificationsEnabled: Yup.boolean().required('Required'),
      smsNotificationsEnabled: Yup.boolean().required('Required'),
      receiveAccountCreatedEmail: Yup.boolean().nullable(),
      receiveInvoiceEmails: Yup.boolean().required('Required'),
      password: Yup.string()
        .test('required', 'Required', (value) => {
          if (!client && !value) {
            return false
          }
          return true
        })
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
        )
        .max(100, 'Password is too long')
        .nullable(),
      confirmPassword: Yup.string()
        .min(2, 'Confirm password is too short')
        .max(100, 'Confirm password is too long')
        .test('required', 'Required', (value) => {
          if (!client && !value) {
            return false
          }
          return true
        })
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const userInput = {
        type: 'client',
        businessId: values.businessId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        secondaryEmail: values.secondaryEmail,
        phoneNumber: values.phoneNumber,
        password: values.password,
        secondaryPhoneNumber: values.secondaryPhoneNumber,
        emailNotificationsEnabled: values.emailNotificationsEnabled,
        smsNotificationsEnabled: values.smsNotificationsEnabled,
        receiveInvoiceEmails: values.receiveInvoiceEmails,
        isActive: values.isActive,
      }
      if (client) {
        userInput.id = client.user.id
        updateUser({
          variables: {
            input: {
              userInput,
            },
          },
        })
      } else {
        userInput.receiveInvoiceEmails = values.receiveInvoiceEmails
        userInput.emailConfirmed = true
        userInput.receiveAccountCreatedEmail = values.receiveAccountCreatedEmail
        createUser({
          variables: {
            input: {
              userInput,
            },
          },
        })
      }
    },
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              as="input"
              name="firstName"
              className="form-control-sm"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              isInvalid={formik.errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.firstName}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              as="input"
              name="lastName"
              className="form-control-sm"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              isInvalid={formik.errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lastName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col md={6}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              className="form-control-sm"
              value={formik.values.password}
              onChange={formik.handleChange}
              isInvalid={formik.errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </Col>
          <Col md={6}>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              className="form-control-sm"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              isInvalid={formik.errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.confirmPassword}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="12" md={6}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              as="input"
              name="email"
              className="form-control-sm"
              value={formik.values.email}
              onChange={formik.handleChange}
              isInvalid={formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Col>
          <Col sm="12" md={6}>
            <Form.Label>Secondary Email</Form.Label>
            <Form.Control
              as="input"
              name="secondaryEmail"
              className="form-control-sm"
              value={formik.values.secondaryEmail}
              onChange={formik.handleChange}
              isInvalid={formik.errors.secondaryEmail}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.secondaryEmail}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col xs={12} md={6}>
            <Form.Label>Phone Number</Form.Label>
            <PhoneInput
              country="us"
              name="phoneNumber"
              regions={['north-america']}
              value={formik.values.phoneNumber}
              onChange={(phone, country) =>
                formik.setFieldValue('phoneNumber', phone)
              }
            />
            {formik.errors.phoneNumber ? (
              <span className="text-danger">{formik.errors.phoneNumber}</span>
            ) : null}
          </Col>
          <Col xs={12} md={6}>
            <Form.Label>Secondary Phone Number</Form.Label>
            <PhoneInput
              country="us"
              name="secondaryPhoneNumber"
              regions={['north-america']}
              value={formik.values.secondaryPhoneNumber}
              onChange={(phone, country) =>
                formik.setFieldValue('secondaryPhoneNumber', phone)
              }
            />
            {formik.errors.secondaryPhoneNumber ? (
              <span className="text-danger">
                {formik.errors.secondaryPhoneNumber}
              </span>
            ) : null}
          </Col>
        </Form.Group>
        {!businessId && (
          <Form.Group as={Row}>
            <Col sm="12" md={client ? 6 : 12}>
              <Form.Label>
                Business
                {formik.values.businessId && (
                  <span
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 btn-link p-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push(`/business/${formik.values.businessId}`)
                    }}
                  >
                    <ArrowUpRightCircle />
                  </span>
                )}
              </Form.Label>
              <BusinessSearchInput formik={formik} />
            </Col>
          </Form.Group>
        )}

        {!client && (
          <>
            <Form.Group as={Row}>
              <Col sm={12} md={4}>
                <Form.Label>Login Access</Form.Label>
                <Form.Check
                  label={formik.values.isActive ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(`isActive`, e.target.checked)
                  }}
                  checked={formik.values.isActive}
                />
              </Col>
              <Col sm={12} md={4}>
                <Form.Label>Receive Email Notifications</Form.Label>
                <Form.Check
                  label={formik.values.emailNotificationsEnabled ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `emailNotificationsEnabled`,
                      e.target.checked
                    )
                  }}
                  checked={formik.values.emailNotificationsEnabled}
                />
              </Col>
              <Col sm={12} md={4}>
                <Form.Label>Receive SMS Notifications</Form.Label>
                <Form.Check
                  label={formik.values.smsNotificationsEnabled ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `smsNotificationsEnabled`,
                      e.target.checked
                    )
                  }}
                  checked={formik.values.smsNotificationsEnabled}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={12} md={4}>
                <Form.Label>Receive Account Created Email</Form.Label>
                <Form.Check
                  label={
                    formik.values.receiveAccountCreatedEmail ? 'Yes' : 'No'
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `receiveAccountCreatedEmail`,
                      e.target.checked
                    )
                  }}
                  checked={formik.values.receiveAccountCreatedEmail}
                />
              </Col>
              <Col sm={12} md={4}>
                <Form.Label>Receive Invoice Emails</Form.Label>
                <Form.Check
                  label={formik.values.receiveInvoiceEmails ? 'Yes' : 'No'}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `receiveInvoiceEmails`,
                      e.target.checked
                    )
                  }}
                  checked={formik.values.receiveInvoiceEmails}
                />
              </Col>
            </Form.Group>
          </>
        )}
        {client && (
          <Form.Group as={Row}>
            <Col sm={12} md={3}>
              <Form.Label>Login Access</Form.Label>
              <Form.Check
                label={formik.values.isActive ? 'Yes' : 'No'}
                onChange={(e) => {
                  formik.setFieldValue(`isActive`, e.target.checked)
                }}
                checked={formik.values.isActive}
              />
            </Col>
            <Col sm={12} md={3}>
              <Form.Label>Receive Email Notifications</Form.Label>
              <Form.Check
                label={formik.values.emailNotificationsEnabled ? 'Yes' : 'No'}
                onChange={(e) => {
                  formik.setFieldValue(
                    `emailNotificationsEnabled`,
                    e.target.checked
                  )
                }}
                checked={formik.values.emailNotificationsEnabled}
              />
            </Col>
            <Col sm={12} md={3}>
              <Form.Label>Receive SMS Notifications</Form.Label>
              <Form.Check
                label={formik.values.smsNotificationsEnabled ? 'Yes' : 'No'}
                onChange={(e) => {
                  formik.setFieldValue(
                    `smsNotificationsEnabled`,
                    e.target.checked
                  )
                }}
                checked={formik.values.smsNotificationsEnabled}
              />
            </Col>
            <Col sm={12} md={3}>
              <Form.Label>Receive Invoice Emails</Form.Label>
              <Form.Check
                label={formik.values.receiveInvoiceEmails ? 'Yes' : 'No'}
                onChange={(e) => {
                  formik.setFieldValue(`receiveInvoiceEmails`, e.target.checked)
                }}
                checked={formik.values.receiveInvoiceEmails}
              />
            </Col>
          </Form.Group>
        )}

        {client && modal && (
          <>
            <Row>
              <Col md={3} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayNotifications(!displayNotifications)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayAuditLog ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label column sm="12" md="auto">
                  Notifications
                </Form.Label>
              </Col>
            </Row>
            {displayNotifications && (
              <Row>
                <Col md={12}>
                  <Notifications />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={3} className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayAuditLog(!displayAuditLog)}
                  className="px-0 btn-link mr-1"
                >
                  <>
                    {displayAuditLog ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label column sm="12" md="auto">
                  History
                </Form.Label>
              </Col>
            </Row>
            {displayAuditLog && (
              <Row>
                <Col md={12}>
                  <AuditLog
                    contentType={client.user.contentType.model}
                    id={client.user.id}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        <div className="footer-modal">
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <Folder className="mr-2" />
                Save
              </Button>
            </Col>

            {client && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() => {
                    setSubmitting(true)
                    setDeleting(true)
                    deleteUser({
                      variables: {
                        input: {
                          ids: client.user.id,
                        },
                      },
                    })
                  }}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
          {submitting && (
            <div className="mt-3">
              <Loading
                message={deleting ? 'Deleting Client...' : 'Saving Client...'}
              />
            </div>
          )}
        </div>
      </Form>
    </>
  )
}

export default ClientForm
