import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Tab, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  InfoCircle,
  CashCoin,
  Bell,
  Truck,
  ClockHistory,
  CardChecklist,
  FileEarmarkText,
  GeoAlt,
} from 'react-bootstrap-icons'
import Notifications from '../notification/Notifications'
import Loading from '../common/Loading'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import Files from '../files/Files'
import DealForm from './DealForm'
import Billing from '../billing/Billing'
import Fulfillments from '../cargo/fulfilment/Fulfillments'
import TaskTabs from '../task/TaskTabs'
import Map from '../location/Map'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'

const Deal = (props) => {
  let { dealId } = props
  const params = useParams()
  if (!dealId) {
    dealId = params.dealId
  }
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [activeTab, setActiveTab] = useState('Details')
  const { error, data } = useQuery(
    gql`
      query Deal($id: ID!) {
        deal(id: $id) {
          id
          availability
          salespeople {
            edges {
              node {
                id
                user {
                  fullName
                }
              }
            }
          }
          description
          dealNumber
          buyer {
            id
            business {
              id
              name
            }
            effectiveProductVariantBusinessPrices {
              productVariant {
                id
              }
              endDate
              price
              secondaryPrice
            }
          }
          contentType {
            model
            id
          }
          supplier {
            id
            business {
              id
              name
            }
            effectiveProductVariantBusinessPrices {
              productVariant {
                id
              }
              endDate
              price
              secondaryPrice
            }
          }
          pickupLocation {
            id
            fullAddress
            latitude
            longitude
          }
          deliveryLocation {
            id
            fullAddress
            latitude
            longitude
          }
          estimatedPickupDate
          estimatedDeliveryDate
          mobile
          dealItems {
            edges {
              node {
                id
                description
                supplierQuantity
                buyerQuantity
                contentType {
                  model
                  id
                }
                productVariant {
                  id
                  productAttributeValues {
                    edges {
                      node {
                        id
                        value
                      }
                    }
                  }
                  product {
                    id
                    name
                    attributeValues {
                      attribute {
                        id
                        name
                      }
                      productAttributeValues {
                        id
                        value
                      }
                    }
                  }
                }
                buyerUnitOfMeasure {
                  id
                  pluralName
                  shortName
                }
                supplierUnitOfMeasure {
                  id
                  pluralName
                  shortName
                }
                supplierPrice
                supplierSecondaryPrice
                buyerPrice
                buyerSecondaryPrice
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: dealId,
      },
    }
  )
  if (error) return <>Error loading deal</>
  if (!data)
    return (
      <span className="mt-5">
        <Loading message="Loading Deal..." />
      </span>
    )
  const { deal } = data
  let dealItem
  const contentTypesRelayIds = {
    [deal.contentType.model]: [deal.id],
  }
  if (deal.dealItems.edges.length > 0) {
    dealItem = deal.dealItems.edges[0].node
    contentTypesRelayIds[dealItem.contentType.model] = deal.dealItems.edges.map(
      (edge) => edge.node.id
    )
  }
  const isBuyerSupplier =
    loggedInUser?.permissions.isBuyer || loggedInUser?.permissions.isSupplier
  return (
    <>
      {params.dealId && (
        <Row className="mt-3">
          <Col>
            <h1>Deal #{deal.dealNumber}</h1>
          </Col>
        </Row>
      )}
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab)
        }}
      >
        <Tab
          key="Details"
          eventKey="Details"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <InfoCircle className="mr-2" />
              Details
            </b>
          }
        >
          <Row>
            <DealForm deal={deal} />
          </Row>
        </Tab>
        <Tab
          key="Fulfillment"
          eventKey="Fulfillment"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Truck className="mr-2" />
              Fulfillment
            </b>
          }
        >
          <Fulfillments dealId={deal.id} tableHeight={200} />
        </Tab>
        {!isBuyerSupplier && (
          <Tab
            key="Tasks"
            eventKey="Tasks"
            style={{ marginTop: '10px' }}
            title={
              <b>
                <CardChecklist className="mr-2" />
                Tasks
              </b>
            }
          >
            <TaskTabs dealId={deal.id} tableHeight={400} />
          </Tab>
        )}

        <Tab
          key="Map"
          eventKey="Map"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <GeoAlt className="mr-2" />
              Map
            </b>
          }
        >
          <Map
            location={deal.pickupLocation}
            locationTwo={deal.deliveryLocation}
            locationModal
            locationTwoModal
            directions
          />
        </Tab>
        <Tab
          key="Files"
          eventKey="Files"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <FileEarmarkText className="mr-2" />
              Files
            </b>
          }
        >
          <div className="mt-4">
            <Files
              dealId={deal.id}
              dealDescription={deal.description}
              tableHeight={400}
            />
          </div>
        </Tab>
        <Tab
          key="Billing"
          eventKey="Billing"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <CashCoin className="mr-2" />
              Billing
            </b>
          }
        >
          <Billing dealId={deal.id} deal={deal} />
        </Tab>
        <Tab
          key="Notifications"
          eventKey="Notifications"
          style={{ marginTop: '10px' }}
          title={
            <b>
              <Bell className="mr-2" />
              Notifications
            </b>
          }
        >
          <Notifications />
        </Tab>
        {!isBuyerSupplier && (
          <Tab
            key="History"
            eventKey="History"
            style={{ marginTop: '10px' }}
            title={
              <b>
                <ClockHistory className="mr-2" />
                History
              </b>
            }
          >
            <AuditLog
              contentTypesRelayIds={contentTypesRelayIds}
              recordColumns
            />
          </Tab>
        )}
      </Tabs>
    </>
  )
}
export default Deal
