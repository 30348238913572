import React, { useState } from 'react'
import { Row, Col, Badge } from 'react-bootstrap'
import { CaretRight, CaretDown } from 'react-bootstrap-icons'
import ChatGPTModal from '../ChatGPTModal'
import { useMutation, gql } from '@apollo/client'

const OpenAI = (props) => {
  const [display, setDisplay] = useState(false)
  const [showChatGPTModal, setShowChatGPTModal] = useState(false)

  const toggleChatGPTModal = () => {
    setShowChatGPTModal(!showChatGPTModal)
  }

  const [updateSettings] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {},
      refetchQueries: ['SettingsQuery', 'PublicSettingsQuery'],
    }
  )

  return (
    <>
      <div className="studioInfo mt-4 mb-4">
        <Row className="mb-2">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplay(!display)}
              className="px-0 btn-link mr-2"
            >
              <>
                {display ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/openai.png'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
              ChatGPT
            </h4>
          </Col>
        </Row>
        {display && (
          <>
            <Row>
              <Col>
                {!props.settings.openAiEnabled && (
                  <Badge
                    className="ml-2"
                    style={{
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                    variant="danger"
                    onClick={() => {
                      updateSettings({
                        variables: {
                          updateSettingsInput: {
                            settingsInput: {
                              id: props.settings.id,
                              openAiEnabled: true,
                            },
                          },
                        },
                      })
                    }}
                  >
                    Disabled
                  </Badge>
                )}
                {props.settings.openAiEnabled && (
                  <>
                    <Row>
                      <Col>
                        <Badge
                          className="ml-2"
                          style={{
                            fontSize: '12px',
                            cursor: 'pointer',
                          }}
                          variant="success"
                          onClick={() => {
                            updateSettings({
                              variables: {
                                updateSettingsInput: {
                                  settingsInput: {
                                    id: props.settings.id,
                                    openAiEnabled: false,
                                  },
                                },
                              },
                            })
                          }}
                        >
                          Enabled
                        </Badge>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-3" style={{ marginLeft: '-5px' }}>
              <Col md={4}>
                <p>
                  Clicking the robot button to the right of the search header
                  opens a ChatGPT session.
                  {props.settings.openAiEnabled && (
                    <> Click the enabled icon to disable the integration.</>
                  )}
                  {!props.settings.openAiEnabled && (
                    <> Click the disabled icon to enable the integration.</>
                  )}
                </p>
              </Col>
            </Row>
          </>
        )}
      </div>
      <ChatGPTModal
        showModal={showChatGPTModal}
        toggleModal={toggleChatGPTModal}
      />
    </>
  )
}

export default OpenAI
