import React, { useState } from 'react'
import { Tab, Row, Col } from 'react-bootstrap'
import Tabs from '../common/Tabs'
import Invoices from './invoice/Invoices'
import Payments from './payment/Payments'
import PurchaseOrders from './purchase_order/PurchaseOrders'
import SalesOrders from './sales_order/SalesOrders'
import {
  CashCoin,
  Receipt,
  FileEarmarkBarGraph,
  FileEarmarkBarGraphFill,
} from 'react-bootstrap-icons'

const Billing = (props) => {
  const { businessId, dealId, productId, deal } = props
  const [activeTab, setActiveTab] = useState('Payments')
  return (
    <>
      {!businessId && !dealId && !productId && (
        <Row className="mb-2 mt-3">
          <Col>
            <div className="d-flex align-items-center">
              <h1 className="mb-0 d-inline">Billing</h1>
            </div>
          </Col>
        </Row>
      )}
      <Tabs onSelect={(tab) => setActiveTab(tab)} activeKey={activeTab}>
        <Tab
          eventKey="Payments"
          title={
            <b>
              <CashCoin className="mr-2" />
              Payments
            </b>
          }
        >
          <Payments />
        </Tab>
        <Tab
          eventKey="Invoices"
          title={
            <b>
              <Receipt className="mr-2" />
              Invoices
            </b>
          }
        >
          <Invoices businessId={businessId} />
        </Tab>
        <Tab
          eventKey="SalesOrders"
          title={
            <b>
              <FileEarmarkBarGraph className="mr-2" />
              Sales Orders
            </b>
          }
        >
          <SalesOrders dealId={dealId} deal={deal} />
        </Tab>
        <Tab
          eventKey="PurchaseOrders"
          title={
            <b>
              <FileEarmarkBarGraphFill className="mr-2" />
              Purchase Orders
            </b>
          }
        >
          <PurchaseOrders dealId={dealId} deal={deal} />
        </Tab>
      </Tabs>
    </>
  )
}

export default Billing
