import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import Report from '../../Report'
import DateFilter from '../../common/DateFilter'
import { useLazyQuery, gql } from '@apollo/client'
import Loading from '../../common/Loading'
import { Funnel } from 'react-bootstrap-icons'

const PaymentChartModal = (props) => {
  const {
    showModal,
    toggleModal,
    organizationId,
    subjectGroupId,
    subjectId,
    sessionId,
    jobId,
  } = props

  const [chartSeries, setChartSeries] = useState([])
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [averageRevenue, setAverageRevenue] = useState(0)
  const [chartCategories, setChartCategories] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [paymentIntents, setPaymentIntents] = useState([])
  const [revenueType, setRevenueType] = useState('Total Revenue')
  const [fetchedAllPaymentIntents, setFetchedAllPaymentIntents] =
    useState(false)
  const [filterDeclined, setFilterDecline] = useState(false)
  const [filterInvoice, setFilterInvoice] = useState(false)
  const [filterSession, setFilterSession] = useState(false)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }
    return dates
  }

  const [query, { data: stripePaymentIntentData, loading }] = useLazyQuery(
    gql`
      query StripePaymentIntentsQuery(
        $cursor: String
        $startDateGte: DateTime
        $startDateLte: DateTime
        $declined: Boolean
        $invoices: Boolean
      ) {
        stripePaymentIntents(
          first: 250
          declined: $declined
          invoices: $invoices
          refunded: false
          after: $cursor
          updated_Gte: $startDateGte
          updated_Lte: $startDateLte
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              created
              amount
              netAmount
              fee
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const queryVariables = () => {
    const variables = {
      startDateGte: startDateFilter,
      startDateLte: endDateFilter,
      session: sessionId,
      organization: organizationId,
      subjectGroup: subjectGroupId,
      subject: subjectId,
      job: jobId,
    }
    if (filterDeclined) {
      variables.declined = true
    }
    if (filterInvoice) {
      variables.invoices = true
    }
    return variables
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      query({ variables: queryVariables() })
    }
    setFetchedAllPaymentIntents(false)
    setChartSeries([])
    setPaymentIntents([])
    setChartCategories([])
  }, [
    startDateFilter,
    endDateFilter,
    filterDeclined,
    filterSession,
    filterInvoice,
  ])

  useEffect(() => {
    if (stripePaymentIntentData?.stripePaymentIntents) {
      setPaymentIntents((prevState) => [
        ...prevState,
        ...stripePaymentIntentData.stripePaymentIntents.edges,
      ])
      if (stripePaymentIntentData.stripePaymentIntents.pageInfo.hasNextPage) {
        const variables = {
          ...queryVariables(),
          cursor:
            stripePaymentIntentData.stripePaymentIntents.pageInfo.endCursor,
        }
        query({ variables })
      } else {
        setFetchedAllPaymentIntents(true)
      }
    }
  }, [stripePaymentIntentData])

  useEffect(() => {
    if (fetchedAllPaymentIntents) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const intents = paymentIntents.map((edge) => ({
        amount: edge.node.amount / 100,
        fee: edge.node.fee / 100,
        netAmount: edge.node.netAmount / 100,
        date: new Date(edge.node.created).toISOString().split('T')[0],
      }))

      const aggregatedAmountData = intents.reduce((acc, { date, amount }) => {
        acc[date] = (acc[date] || 0) + amount
        return acc
      }, {})
      const aggregatedFeeData = intents.reduce((acc, { date, fee }) => {
        acc[date] = (acc[date] || 0) + fee
        return acc
      }, {})
      const aggregatedNetAmountData = intents.reduce(
        (acc, { date, netAmount }) => {
          acc[date] = (acc[date] || 0) + netAmount
          return acc
        },
        {}
      )
      setChartCategories(dateRange)
      let totalRevenue_ = intents
        .reduce((acc, intent) => acc + intent.amount, 0)
        .toFixed(2)
      setTotalRevenue(totalRevenue_)
      setAverageRevenue(
        intents.length > 0
          ? (parseFloat(totalRevenue_) / intents.length).toFixed(2)
          : '0.00'
      )
      let amountSeriesData = []
      let feeSeriesData = []
      let netAmountSeriesData = []
      if (revenueType === 'Daily Revenue') {
        amountSeriesData = dateRange.map(
          (date) => aggregatedAmountData[date] || 0
        )
        feeSeriesData = dateRange.map((date) => aggregatedFeeData[date] || 0)
        netAmountSeriesData = dateRange.map(
          (date) => aggregatedNetAmountData[date] || 0
        )
      } else {
        let runningTotalAmount = 0
        let runningTotalFee = 0
        let runningTotalNetAmount = 0
        amountSeriesData = dateRange.map((date) => {
          runningTotalAmount += aggregatedAmountData[date] || 0
          return runningTotalAmount
        })
        feeSeriesData = dateRange.map((date) => {
          runningTotalFee += aggregatedFeeData[date] || 0
          return runningTotalFee
        })
        netAmountSeriesData = dateRange.map((date) => {
          runningTotalNetAmount += aggregatedNetAmountData[date] || 0
          return runningTotalNetAmount
        })
      }
      setChartSeries(() => [
        { name: 'Amount', data: amountSeriesData },
        { name: 'Stripe Fee', data: feeSeriesData },
        { name: 'Net Amount', data: netAmountSeriesData },
      ])
    }
  }, [fetchedAllPaymentIntents, startDateFilter, endDateFilter, revenueType])

  const innerToggle = () => {
    setChartCategories([])
    setChartSeries([])
    toggleModal()
  }

  return (
    <>
      <div className="invoiceModal">
        <Modal size={'xl'} show={showModal} onHide={innerToggle}>
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Payment Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col md={5}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="payments from"
                  placeholderEnd={'payments to'}
                />
              </Col>
              <Col className="mt-2" md={3}>
                <Form.Control
                  as="select"
                  className="form-control-sm"
                  value={revenueType}
                  onChange={(e) => setRevenueType(e.target.value)}
                >
                  <option value="Daily Revenue">Daily Revenue</option>
                  <option value="Total Revenue">Total Revenue</option>
                </Form.Control>
              </Col>
            </Row>
            {startDateFilter && endDateFilter && (
              <Row className="mb-4">
                <Col>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterDecline(!filterDeclined)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterDeclined ? <>Declined</> : <>All</>}
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterInvoice(!filterInvoice)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterInvoice ? <>Invoice</> : <>All</>}
                    </span>
                  </Button>
                </Col>
              </Row>
            )}
            {startDateFilter && endDateFilter && !fetchedAllPaymentIntents && (
              <Row>
                <Col>
                  <Loading message="Loading Payment Chart..." />
                </Col>
              </Row>
            )}
            {fetchedAllPaymentIntents && (
              <>
                <div className="statistics-summary">
                  <Row className="mb-4 justify-content-md-center">
                    <Col md={4} className="statistic">
                      <h5>Total Revenue</h5>
                      <p>${totalRevenue}</p>
                    </Col>
                    <Col md={4} className="statistic">
                      <h5>Average Payment</h5>
                      <p>${averageRevenue}</p>
                    </Col>
                    <Col md={4} className="statistic">
                      <h5>Number of Payments</h5>
                      <p>{paymentIntents.length}</p>
                    </Col>
                  </Row>
                </div>
                {chartSeries.length > 0 && (
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Report
                        series={chartSeries}
                        categories={chartCategories}
                        yAxisFormatter={(value) => {
                          if (value < 0.01 && value > -0.01) {
                            return '$0'
                          }
                          return `$${value}`
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default PaymentChartModal
