import React, { useState } from 'react'
import { Row, Col, Button, Badge } from 'react-bootstrap'
import { Gear, CaretRight, CaretDown } from 'react-bootstrap-icons'
import EditIntercom from './EditIntercom'

const Intercom = (props) => {
  const { settings } = props
  const [showEditModal, setShowEditModal] = useState(false)
  const [display, setDisplay] = useState(false)

  return (
    <>
      <div className="studioInfo">
        <Row className="mb-1 mt-4">
          <Col className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => setDisplay(!display)}
              className="px-0 btn-link mr-2"
            >
              <>
                {display ? (
                  <>
                    <CaretDown size={20} />
                  </>
                ) : (
                  <>
                    <CaretRight size={20} />
                  </>
                )}
              </>
            </button>
            <h4 className="mb-0">
              <img
                className="img-fluid mr-2"
                src={window.location.origin + '/intercom.png'}
                style={{ height: '25px' }}
                alt="Organization logo"
              />
              Intercom
            </h4>
          </Col>
        </Row>
        {display && (
          <>
            <Row className="mt-2">
              <Col>
                {!settings.intercomAppId && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="danger"
                  >
                    Disabled
                  </Badge>
                )}
                {settings.intercomAppId && (
                  <Badge
                    className="ml-2"
                    style={{ fontSize: '12px' }}
                    variant="success"
                  >
                    Enabled
                  </Badge>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div>
                  <Button variant="link" onClick={() => setShowEditModal(true)}>
                    <Gear className="mr-2" />
                    Configure Intercom
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        <EditIntercom
          intercomAppId={settings.intercomAppId}
          settingsId={settings.id}
          showModal={showEditModal}
          toggleModal={() => setShowEditModal((prevState) => !prevState)}
        />
      </div>
    </>
  )
}

export default Intercom
