import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const TaskGroupSearchInput = (props) => {
  const { formik, disabled, formikValue, searchDescription, dealId, error } =
    props
  const variables = {}
  if (dealId) {
    variables.deal = dealId
  }
  return (
    <SearchInput
      formik={formik}
      error={error}
      nodeName="taskGroup"
      searchDescription={searchDescription ? searchDescription : 'task groups'}
      nodeNamePlural="taskGroups"
      formikValue={formikValue}
      disabled={disabled}
      gql={gql`
        query TaskGroups(
          $first: Int
          $after: String
          $search: String
          $deal: ID
        ) {
          taskGroups(
            first: $first
            after: $after
            orderBy: "name"
            deal: $deal
            name_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `}
      variables={variables}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default TaskGroupSearchInput
