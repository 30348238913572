import React, { useEffect, useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { CaretRight, CaretDown } from 'react-bootstrap-icons'

const WebhookModal = (props) => {
  const { showModal, toggleModal, webhookEvent } = props
  const [displayEvent, setDisplayEvent] = useState(false)
  const [displayResponse, setDisplayResponse] = useState(false)

  const formatJson = (response) => {
    try {
      const jsonObj = JSON.parse(response)
      return JSON.stringify(jsonObj, null, 2)
    } catch (e) {
      return 'Error in formatting JSON: ' + e
    }
  }

  useEffect(() => {
    if (!showModal) {
      setDisplayEvent(false)
      setDisplayResponse(false)
    }
  }, [showModal])

  if (!showModal) return <></>
  return (
    <>
      <div id="apiKeyModal">
        <Modal size={'lg'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Webhook Event {webhookEvent.recordId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setDisplayEvent(!displayEvent)}
                  className="px-0 btn-link mr-2"
                  style={{ marginTop: '-10px' }}
                >
                  {displayEvent ? (
                    <>
                      <CaretDown size={20} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={20} />
                    </>
                  )}
                </button>
                <h5>Event</h5>
              </Col>
            </Row>
            {displayEvent && (
              <Row>
                <Col>
                  <pre>
                    <code>{formatJson(webhookEvent.webhookEvent)}</code>
                  </pre>
                </Col>
              </Row>
            )}

            <Row>
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  style={{ marginTop: '-10px' }}
                  onClick={() => setDisplayResponse(!displayResponse)}
                  className="px-0 btn-link mr-2"
                >
                  {displayResponse ? (
                    <>
                      <CaretDown size={20} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={20} />
                    </>
                  )}
                </button>
                <h5>Response</h5>
              </Col>
            </Row>
            {displayResponse && (
              <>
                <Row className="mt-3">
                  <Col>
                    <p>Response Status Code</p>
                  </Col>
                </Row>
                <Row>
                  <Col>{webhookEvent.responseCode}</Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <p>Response Body</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <pre>
                      <code>{formatJson(webhookEvent.responseBody)}</code>
                    </pre>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default WebhookModal
