var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo, useState, useEffect } from 'react';
import { FileStep } from './file-step/FileStep';
import { generatePreviewColumns } from './fields-step/ColumnPreview';
import { FieldsStep } from './fields-step/FieldsStep';
import { ProgressDisplay } from './ProgressDisplay';
// re-export from a central spot
export { ImporterField } from './ImporterField';
import { useFieldDefinitions } from './ImporterField';
import './Importer.scss';
import { LocaleContext } from '../locale/LocaleContext';
import { enUS } from '../locale';
export function Importer(props) {
    const { dataHandler, processChunk, defaultNoHeader, assumeNoHeaders, restartable, displayFieldRowSize, displayColumnPageSize, onStart, onComplete, onClose, children: content, locale: userLocale } = props, customPapaParseConfig = __rest(props, ["dataHandler", "processChunk", "defaultNoHeader", "assumeNoHeaders", "restartable", "displayFieldRowSize", "displayColumnPageSize", "onStart", "onComplete", "onClose", "children", "locale"]);
    // helper to combine our displayed content and the user code that provides field definitions
    const [fields, userFieldContentWrapper] = useFieldDefinitions();
    const [fileState, setFileState] = useState(null);
    const [fileAccepted, setFileAccepted] = useState(false);
    const [fieldsState, setFieldsState] = useState(null);
    const [fieldsAccepted, setFieldsAccepted] = useState(false);
    // reset field assignments when file changes
    const activeFile = fileState && fileState.file;
    useEffect(() => {
        if (activeFile) {
            setFieldsState(null);
        }
    }, [activeFile]);
    const externalPreview = useMemo(() => {
        // generate stable externally-visible data objects
        const externalColumns = fileState &&
            generatePreviewColumns(fileState.firstRows, fileState.hasHeaders);
        return (fileState &&
            externalColumns && {
            rawData: fileState.firstChunk,
            columns: externalColumns,
            skipHeaders: !fileState.hasHeaders,
            parseWarning: fileState.parseWarning
        });
    }, [fileState]);
    // fall back to enUS if no locale provided
    const locale = userLocale !== null && userLocale !== void 0 ? userLocale : enUS;
    if (!fileAccepted || fileState === null || externalPreview === null) {
        return (React.createElement(LocaleContext.Provider, { value: locale },
            React.createElement("div", { className: "CSVImporter_Importer" },
                React.createElement(FileStep, { customConfig: customPapaParseConfig, defaultNoHeader: defaultNoHeader !== null && defaultNoHeader !== void 0 ? defaultNoHeader : assumeNoHeaders, prevState: fileState, onChange: (parsedPreview) => {
                        setFileState(parsedPreview);
                    }, onAccept: () => {
                        setFileAccepted(true);
                    } }))));
    }
    if (!fieldsAccepted || fieldsState === null) {
        return (React.createElement(LocaleContext.Provider, { value: locale },
            React.createElement("div", { className: "CSVImporter_Importer" },
                React.createElement(FieldsStep, { fileState: fileState, fields: fields, prevState: fieldsState, displayFieldRowSize: displayFieldRowSize, displayColumnPageSize: displayColumnPageSize, onChange: (state) => {
                        setFieldsState(state);
                    }, onAccept: () => {
                        setFieldsAccepted(true);
                    }, onCancel: () => {
                        // keep existing preview data and assignments
                        setFileAccepted(false);
                    } }),
                userFieldContentWrapper(
                // render the provided child content that defines the fields
                typeof content === 'function'
                    ? content({
                        file: fileState && fileState.file,
                        preview: externalPreview
                    })
                    : content))));
    }
    return (React.createElement(LocaleContext.Provider, { value: locale },
        React.createElement("div", { className: "CSVImporter_Importer" },
            React.createElement(ProgressDisplay, { fileState: fileState, fieldsState: fieldsState, externalPreview: externalPreview, 
                // @todo remove assertion after upgrading to TS 4.1+
                dataHandler: dataHandler !== null && dataHandler !== void 0 ? dataHandler : processChunk, onStart: onStart, onRestart: restartable
                    ? () => {
                        // reset all state
                        setFileState(null);
                        setFileAccepted(false);
                        setFieldsState(null);
                        setFieldsAccepted(false);
                    }
                    : undefined, onComplete: onComplete, onClose: onClose }))));
}
